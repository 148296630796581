



































import Vue from "vue"
import Component from "vue-class-component"
import {Prop, Watch} from "vue-property-decorator"
import {deleteObservation, getObservationViewModeLevel, ObservationEntity} from "@/store/investigation/observation-module"
import ReferencedEntityPreview from "@/views/investigation/previews/ReferencedEntityPreview.vue"
import moment from "moment"
import {openCreateObservationDialog} from "@/views/investigation/dialogs/CreateObservationDialog.vue"
import {getInvestigation} from "@/store/investigation/investigation-module"
import {openConfirmationDialog} from "@/views/investigation/dialogs/ConfirmationDialog.vue"

@Component({
    components: {ReferencedEntityPreview}
})
export default class ObservationItem extends Vue {

    @Prop()
    private observation!: ObservationEntity

    private expanded: boolean = false

    private mounted() {
        this.showImageOnOpen()
        this.updateExpansion()
    }

    @Watch("observation")
    private showImageOnOpen() {
        const image = this.$refs.image as HTMLImageElement

        if (!!this.observation.imageId) {
            image.src = `/api/images?imageId=${this.observation.imageId}&time=${this.observation.imageTimestamp}`
        } else {
            image.src = ""
        }
    }

    private get viewModeLevel(): number {
        return getObservationViewModeLevel(this.$store)
    }

    private get observationDate() {
        return this.time.format("DD MMM YYYY")
    }

    private get observationTime() {
        return this.time.format("HH:mm")
    }

    private get time() {
        return moment(this.observation.time)
    }

    @Watch("viewModeLevel")
    private updateExpansion() {
        this.expanded = this.viewModeLevel === 1
    }

    private editObservation() {
        const allowImageUpload = this.observation.type === "Document" || this.observation.type === "Investigation"

        openCreateObservationDialog(this, {
            type: this.observation.type!!,
            observation: this.observation,
            referencedEntity: this.observation.referencedObject,
            allowImageUpload
        })
    }

    private deleteObservation() {
        openConfirmationDialog({
            message: `Are you sure you want to delete this insight?`,
            title: `Delete Insight`,
            okText: "Delete",
            onOk: () => this.performDelete(),
            showCancel: true
        })
    }

    private performDelete() {
        const investigationType = getInvestigation(this.$store)!!.investigationType
        deleteObservation(this.$store, {observationId: this.observation.id!, investigationType})
    }

    private toggleExpansion() {
        this.expanded = !this.expanded
    }
}
