import {DisplayedResultsStatus} from "@/types/investigations"

export const getLabelText = (label: DisplayedResultsStatus): string => {
    switch (label) {
        case "Unread": return "Unread"
        case "Annotated": return "Starred / Annotated / Labeled"
        case "Relevant": return "Liked"
        case "Irrelevant": return "Disliked"
        default: return "All"
    }
}
