





























import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import GenericDialog from "@/components/GenericDialog.vue"
import InvestigationService from "../../../api/investigation-service"
import {getInvestigation} from "@/store/investigation/investigation-module"
import {Watch} from "vue-property-decorator"
import {PermissionsMixin} from "@/store/user-mixin"

@Component({
    components: {GenericDialog}
})
export default class HarvestDialog extends mixins(Vue, PermissionsMixin) {

    private static splitSearchString(searchString: string): string[] {
        function onlyUnique(value: string, index: number, self: string[]) {
            return self.indexOf(value) === index
        }

        return searchString
            .split("\n")
            .map(it => it.trim())
            .filter(it => it.length !== 0)
            .filter(onlyUnique)
    }

    private static isUrl(searchString: string): boolean {
        const lower = searchString.toLowerCase()
        return lower.startsWith("http://") || lower.startsWith("https://")
    }

    private static isSearchEnginePage(searchString: string): boolean {
        try {
            const url = new URL(searchString.toLowerCase())
            return url.hostname.includes("google.")
        } catch (e) {
            return false
        }
    }

    private isOpen = false
    private message: string = ""
    private errorMessage: string = ""
    private searchString: string = ""

    private get searchPlaceholder() {
        return "Enter one or more search terms / target URLs. Items should be newline-separated."
    }

    public open() {
        this.isOpen = true
        this.errorMessage = ""
        this.message = ""
        this.searchString = ""
        document.addEventListener("keyup", this.handleKey)

        Vue.nextTick(() => {
            const nameInput = this.$refs.searchString as HTMLInputElement
            nameInput.focus()
        })
    }

    private close() {
        this.isOpen = false
        document.removeEventListener("keyup", this.handleKey)
    }

    private handleKey(e: KeyboardEvent) {
        switch (e.key) {
            case "Escape":
                this.close()
                break
        }
    }

    private startHarvest() {
        if (this.errorMessage) {
            return
        }

        if (this.searchString.trim().length === 0) {
            this.errorMessage = "Please enter a search string"
            return
        }

        const searchString = this.searchString.trim()

        this.errorMessage = ""
        this.message = ""
        this.searchString = ""
        this.close()

        this.createHarvests(searchString)
    }

    @Watch("searchString")
    private onSearchStringChanged() {
        this.errorMessage = ""
        this.message = ""

        try {
            const searchStrings = HarvestDialog.splitSearchString(this.searchString)
            const urls = searchStrings.filter(x => HarvestDialog.isUrl(x))
            const sePagesCount = urls.filter(x => HarvestDialog.isSearchEnginePage(x)).length
            const termsCount = searchStrings.length - urls.length
            const pagesCount = urls.length - sePagesCount
            const msg: string[] = []

            if (termsCount > 0) {
                msg.push(`${termsCount} term${termsCount > 1 ? "s" : ""}`)
            }
            if (pagesCount > 0) {
                msg.push(`${pagesCount} page${pagesCount > 1 ? "s" : ""}`)
            }
            if (sePagesCount > 0) {
                msg.push(`${sePagesCount} search page${sePagesCount > 1 ? "s" : ""}, of which all results are`)
            }
            if (msg.length > 0) {
                this.message = msg.join("; ") + " to be harvested"
            }

            if (termsCount > 20) {
                this.errorMessage = "Maximum 20 search terms allowed"
            } else if (sePagesCount > 20) {
                this.errorMessage = "Maximum 20 search engine URLs allowed"
            }
        } catch (_) {
            // do nothing
        }
    }

    private createHarvests(searchString: string) {
        const investigation = getInvestigation(this.$store)!!
        const searchStrings = HarvestDialog.splitSearchString(searchString)

        searchStrings.forEach(str => {
            const urlHarvest = HarvestDialog.isUrl(str)
            const forceStringHarvest = HarvestDialog.isSearchEnginePage(str)
            InvestigationService.createTopicHarvest(
                investigation.id, investigation.investigationType, str, urlHarvest, forceStringHarvest)
        })
    }
}
