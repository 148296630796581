











































import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import {PredefinedTermGroupEntity} from "@/types/investigations"
import GenericDialogNew, {createDialogMountingPoint} from "@/components/GenericDialogNew.vue"
import termsService, {BatchTermsGroup} from "@/api/investigation/terms-service"
import {InvestigationMixin} from "@/store/investigation-mixin"
import {addBatchTerms, getTerms, MAX_ALLOWED_TERMS} from "@/store/investigation/investigation-terms-module"
import {getGraphType} from "@/store/investigation/graph-module"

export function openAddPredefinedTermGroupsDialog(parent: Vue) {
    new (Vue.extend(AddPredefinedTermGroupsDialog))({parent, store: parent.$store}).$mount(createDialogMountingPoint())
}


@Component({
    components: {GenericDialogNew}
})
export default class AddPredefinedTermGroupsDialog extends mixins(Vue, InvestigationMixin) {

    private groups: PredefinedTermGroupEntity[] = []
    private currentGroup: PredefinedTermGroupEntity | null = null
    private selectedGroups: PredefinedTermGroupEntity[] = []
    private errorMessage: string | null = null

    public async mounted() {
        this.groups = await termsService.getPredefinedTermGroups(this.investigation!!.id)
        this.currentGroup = this.groups[0]
    }


    private toggleSelectedGroup(group: PredefinedTermGroupEntity) {
        if (this.isGroupSelected(group)) {
            this.selectedGroups = this.selectedGroups.filter(it => it !== group)
        } else {
            this.selectedGroups.push(group)
        }
    }


    private isGroupSelected(group: PredefinedTermGroupEntity): boolean {
        return !!this.selectedGroups.find(it => it === group)
    }


    private close() {
        (this.$refs.genericDialog as GenericDialogNew).close()
    }


    private async save() {
        this.errorMessage = null

        const selectedTerms = this.selectedGroups
            .flatMap(it => it.terms)

        if (selectedTerms.length + getTerms(this.$store).length > MAX_ALLOWED_TERMS) {
            this.errorMessage = `Cannot add more than ${MAX_ALLOWED_TERMS} terms`
            return
        }

        this.close()

        const groups = this.selectedGroups
            .map(it => ({name: it.name, terms: it.terms} as BatchTermsGroup))

        await addBatchTerms(this.$store, {groups, includeHarvests: true})
        if (getGraphType(this.$store) === null) {
            await this.$parent.$emit("refresh-documents")
        }
    }
}
