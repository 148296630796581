



































import Vue from "vue"
import Component from "vue-class-component"
import {Prop, Watch} from "vue-property-decorator"
import {Cropper} from "vue-advanced-cropper"
import {ImageCropData} from "@/api/investigation-service"

@Component({
    components: { Cropper }
})
export default class UploadImagePane extends Vue {

    private dragOver = false
    private showingImage = false
    private imageFile: File | null = null
    private imageModified = false
    private afterFirstChange = false

    @Prop({default: "rectangle-stencil"})
    private stencil!: string

    @Prop()
    private value!: string

    @Prop()
    private imageId!: string

    @Prop()
    private imageUrl!: string

    @Prop({default: false})
    private showAspectRatio!: boolean

    @Prop({default: 1})
    private aspectRatioWidth!: number

    @Prop({default: 1})
    private aspectRatioHeight!: number

    private img: string | null = null

    @Watch("imageId")
    private updateImageOnLoad() {
        if (!!this.imageId) {
            this.img = `/api/images?imageId=${this.imageId}`
            this.showingImage = true

            this.notify()
        }
    }

    @Watch("imageUrl")
    private updateImageOnPaste() {
        this.img = this.imageUrl
        this.showingImage = true
        this.notify()
    }

    private getDefaultSize() {
        const cropper = this.$refs.cropper as any

        // @ts-ignore
        return ({ visibleArea, imageSize, stencilRatio, sizeRestrictions }) => {
            return {
                width: imageSize.width,
                height: imageSize.height
            }
        }
    }

    private fileSelected(e: Event) {
        const input = e.target as HTMLInputElement

        if (input.files) {
            const file = input.files[0]
            this.setFileAsImage(file)
            this.notify()
        }

        input.value = ""
    }

    private dragFile(e: DragEvent) {
        const droppedFiles = e.dataTransfer!.files
        if (!droppedFiles) {
            return
        }
        ([...droppedFiles]).forEach(file => {
            this.setFileAsImage(file)
            this.notify()
        })
    }

    private setFileAsImage(file: File) {
        this.imageFile = file
        Vue.nextTick(() => {
            const reader = new FileReader()
            reader.onload = (e: ProgressEvent) => {
                this.img = (e.target as FileReader).result as string
            }
            reader.readAsDataURL(file)

            this.showingImage = true
            this.imageModified = true
        })
    }

    private clearImage() {
        this.imageFile = null
        this.showingImage = false
        this.imageModified = true

        this.img = null

        this.notify()
    }

    private notify() {
        if (!this.afterFirstChange) {
            this.afterFirstChange = true
            return
        }

        if (!this.showingImage) {
            this.$emit("input")
        } else {
            const { coordinates, visibleArea } = (this.$refs.cropper as any).getResult()

            if (visibleArea) {
                this.$emit("input", {
                    file: this.imageFile,
                    url: this.img,
                    width: visibleArea.width,
                    height: visibleArea.height,
                    crop: {
                        top: coordinates.top,
                        left: coordinates.left,
                        width: coordinates.width,
                        height: coordinates.height
                    }
                } as ImageCropData)
            }
        }
    }
}
