










import Vue from "vue"
import Component from "vue-class-component"
import {getGraphType} from "@/store/investigation/graph-module"
import {getInvestigation, getIsBusy, getIsRenderingGraph} from "@/store/investigation/investigation-module"

@Component
export default class SpinningLoader extends Vue {
    get spinnerText() {
        if (this.$route.path.endsWith("overview")) {
            return "Loading Overview..."
        }

        if (this.$route.path.endsWith("investigations")) {
            return "Loading Investigations..."
        }

        const graphType = getGraphType(this.$store)
        if (graphType) {
            const graphDescription = graphType === "Unified" ? "Entities" : graphType
            return `Loading ${graphDescription} Graph...`
        }

        return "Loading Documents..."
    }

    private get isInInvestigation() {
        return getInvestigation(this.$store) !== null
    }

    private get isInDocumentsPane() {
        return getGraphType(this.$store) === null
    }

    private get isInOverviewPane() {
        return this.$route.path.endsWith("overview")
    }

    get isBusy(): boolean {
        return getIsBusy(this.$store) || getIsRenderingGraph(this.$store)
    }
}
