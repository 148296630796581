
































import Vue from "vue"
import Component from "vue-class-component"
import {NewInvestigationDetails} from "@/views/investigations/NewInvestigationPage.vue"
import {createFacultyWorksInvestigation} from "@/store/investigations-module"
import {Watch} from "vue-property-decorator"
import {InvestigationCreationType, InvestigationDataSource, facultyWorksInvestigationType} from "@/types/new-investigation"


@Component
export default class NewPersonGroupInvestigationDetails extends Vue implements NewInvestigationDetails {

    private instituteName: string = ""
    private facultyName: string = ""
    private researcherNames: string = ""
    private errorMessage: string | null = null

    private get investigationType(): InvestigationCreationType {
        return facultyWorksInvestigationType
    }

    public async createInvestigation() {
        const payload = { institutionName: this.instituteName, facultyName: this.facultyName, researcherNames: this.researcherNames!, sources: ["academic publications"] as InvestigationDataSource[]}
        const highlight = await createFacultyWorksInvestigation(this.$store, payload)

        this.$router.push({name: "investigations", query: {highlight}})
    }

    private mounted() {
        this.$nextTick(() => {
            const instituteName = this.$refs.instituteName as HTMLInputElement
            instituteName.focus()
        })
        this.emitEnableNext()
    }

    @Watch("instituteName")
    @Watch("facultyName")
    @Watch("researcherNames")
    private emitEnableNext() {
        const researcherCount = this.researcherNames.split(/\r?\n/).length
        const MAX_RESEARCHERS = 300
        if (researcherCount > MAX_RESEARCHERS) {
            this.errorMessage = `Researcher list is to long, maximum ${MAX_RESEARCHERS} researchers allowed.`
        } else {
            this.errorMessage = null
        }

        const enable =
            this.instituteName.trim().length > 0 &&
            this.facultyName.trim().length > 0 &&
            this.researcherNames.trim().length > 0 && researcherCount <= MAX_RESEARCHERS
        this.$emit("enable-next", enable)
    }
}
