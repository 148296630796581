




















import Vue from "vue"
import Component from "vue-class-component"
import {getInvestigation} from "@/store/investigation/investigation-module"
import {Prop} from "vue-property-decorator"
import {copyToClipboard} from "@/utils/utils"
import PopupMenu from "@/components/PopupMenu.vue"

@Component({
      components: {PopupMenu}
    })
    export default class AliasNames extends Vue {

        @Prop()
        private containingParentName!: string

        @Prop()
        private aliasNames!: string[]

        @Prop({type: Boolean, default: true})
        private showHeader!: boolean

        private get investigationType() {
            return getInvestigation(this.$store)!.investigationType
        }

        private openUrl(url: string) {
          window.open(url, "_BLANK")
        }

        private get containingPane() {
          return this.containingParentName
        }

        private openMenu() {
          const menuOpenerButton = this.$refs.menuOpener as HTMLElement
          this.$emit("openMenu", menuOpenerButton)
        }

        private copyToClipboard() {
          const allAffiliations = this.aliasNames.slice().join("\n")
          copyToClipboard(allAffiliations)
        }
    }
