import axios from "axios"
import {InvestigationTermEntity, InvestigationTermGroupEntity, PredefinedTermGroupEntity, TermModifier} from "@/types/investigations"


export default {

    async addTerm(id: string, term: {term: string, modifier: TermModifier, group: string}): Promise<InvestigationTermEntity[] | undefined> {
        const response = await axios.post(`/api/investigations/topic/${id}/terms`, term)
        if (response) {
            return response.data as InvestigationTermEntity[]
        }
    },

    async moveTermToGroup(id: string, term: string, group: string): Promise<InvestigationTermEntity[] | undefined> {
        const response = await axios.patch(`/api/investigations/topic/${id}/terms/move-to-group`, { term, group })
        if (response) {
            return response.data as InvestigationTermEntity[]
        }
    },

    async modifyTerm(id: string, term: string, modifier: TermModifier): Promise<InvestigationTermEntity[] | undefined> {
        const response = await axios.patch(`/api/investigations/topic/${id}/terms/modify`, { term, modifier })
        if (response) {
            return response.data as InvestigationTermEntity[]
        }
    },

    async renameTerm(id: string, oldName: string, newName: string): Promise<InvestigationTermEntity[] | undefined> {
        const response = await axios.patch(`/api/investigations/topic/${id}/terms/rename`, { oldName, newName })
        if (response) {
            return response.data as InvestigationTermEntity[]
        }
    },

    async removeTerm(id: string, term: string): Promise<boolean | undefined> {
        const response = await axios.delete(`/api/investigations/topic/${id}/terms`, {data: term})
        if (response) {
            return response.data
        }
    },

    async addBatchTerms(id: string, request: BatchTermsRequest): Promise<InvestigationTermEntity[] | undefined> {
        const response = await axios.post(`/api/investigations/topic/${id}/terms/batch`, request)
        if (response) {
            return response.data as InvestigationTermEntity[]
        }
    },

    async createGroup(id: string, name: string): Promise<InvestigationTermGroupEntity[] | undefined> {
        const response = await axios.post(`/api/investigations/topic/${id}/terms/group`, name)
        if (response) {
            return response.data as InvestigationTermGroupEntity[]
        }
    },

    async renameGroup(id: string, group: InvestigationTermGroupEntity): Promise<InvestigationTermGroupEntity[] | undefined> {
        const response = await axios.patch(`/api/investigations/topic/${id}/terms/group/rename`, group)
        if (response) {
            return response.data as InvestigationTermGroupEntity[]
        }
    },

    async deleteGroup(id: string, groupId: string): Promise<InvestigationTermGroupEntity[] | undefined> {
        const response = await axios.delete(`/api/investigations/topic/${id}/terms/group/`, {data: groupId})
        if (response) {
            return response.data as InvestigationTermGroupEntity[]
        }
    },

    async modifyTermGroup(id: string, group: InvestigationTermGroupEntity): Promise<InvestigationTermGroupEntity[] | undefined> {
        const response = await axios.patch(`/api/investigations/topic/${id}/terms/group/`, group)
        if (response) {
            return response.data as InvestigationTermGroupEntity[]
        }
    },

    async getPredefinedTermGroups(id: string): Promise<PredefinedTermGroupEntity[]> {
        const response = await axios.get(`/api/investigations/topic/${id}/terms/predefined`)
        if (response) {
            return response.data as PredefinedTermGroupEntity[]
        }
        return []
    }
}


export interface BatchTermsRequest {
    readonly groups: BatchTermsGroup[],
    readonly includeHarvests: boolean
}

export interface BatchTermsGroup {
    readonly name: string,
    readonly terms: string[]
}
