

















































































import Vue from "vue"
import Component from "vue-class-component"
import {DateHistogram} from "@/types/investigations"
import {getDateFilterEnd, getDateFilterStart, getDateHistogram, setDateFilterYears} from "@/store/investigation/date-filter-module"
import {Prop, Watch} from "vue-property-decorator"
import {fetchGraphData, getIsInProfile} from "@/store/investigation/investigation-module"
import {getGraphType} from "@/store/investigation/graph-module"

@Component
export default class DateFilter extends Vue {

    private barWidth: number = 0
    private totalBarWidth = 0
    private sideMargin: number = 0
    private heightUnit: number = 0
    private filterHandle1: number = 0
    private filterHandle2: number = 0
    private baselineHeight: number = 0
    private popupContent: string | null = null
    private popupLeft: string = ""
    private popupTop: string = ""
    private popupIndex: number = -1
    private selectedElement: SVGElement | null = null
    private draggingHandle1: boolean = false
    private dragStartBarIndex: number = 0
    private textGap: number = 0

    @Prop({type: Boolean, default: true})
    private showTitle!: boolean

    @Prop({type: Boolean, default: false})
    private higher!: boolean

    get histogram(): DateHistogram {
        return getDateHistogram(this.$store)
    }

    private get title(): string {
        if (getIsInProfile(this.$store)) {
            return "Publications per Year"
        }
        return "Relevance per Year"
    }

    private mounted() {
        this.initViewProperties()
    }

    private handlePoints(handle: number): string {
        const baseX = this.sideMargin + handle * this.totalBarWidth + this.barWidth / 2
        const baseY = this.higher ? 83 : 0
        const firstPoint = {x: baseX, y: 87 + baseY}
        const secondPoint = {x: baseX + 7, y: 95 + baseY}
        const thirdPoint = {x: baseX - 7, y: 95 + baseY}
        return `${firstPoint.x} ${firstPoint.y}, ${secondPoint.x} ${secondPoint.y}, ${thirdPoint.x} ${thirdPoint.y}`
    }

    @Watch("histogram")
    private updateHistogram() {
        const canvas = this.$refs.svg as HTMLDivElement
        const interval = setInterval(() => {
            if (canvas && canvas.clientWidth && canvas.clientHeight) {
                clearInterval(interval)
                this.initViewProperties()
            }
        }, 100)
    }


    private initViewProperties() {
        const canvas = this.$refs.svg as HTMLDivElement
        const bars = getDateHistogram(this.$store).bars
        const shouldInitProperties = canvas && canvas.clientWidth && canvas.clientHeight && bars.length
        if (!shouldInitProperties) {
            return
        }

        this.sideMargin = 35
        const bottomGap = 30
        const barGap = Math.round((canvas.clientWidth - this.sideMargin * 2) / bars.length * 0.2)
        // console.log("canvas.clientWidth: ", canvas.clientWidth, "bars.length: ", bars.length, "canvas.clientHeight: ", canvas.clientHeight, canvas.getBoundingClientRect())
        this.barWidth = (canvas.clientWidth + barGap - this.sideMargin * 2) / bars.length - barGap
        if (this.barWidth > 20) {
            this.sideMargin += ((this.barWidth - 20) * bars.length) / 2
            this.barWidth = 20
        }

        this.totalBarWidth = barGap + this.barWidth
        this.baselineHeight = canvas.clientHeight - bottomGap
        this.heightUnit = canvas.clientHeight - bottomGap

        const dateHistogram = getDateHistogram(this.$store)

        const histogramStart = dateHistogram.bars[0].year
        const userSelectionStart = getDateFilterStart(this.$store) || histogramStart
        const start = Math.max(userSelectionStart, histogramStart)
        this.filterHandle1 = bars.findIndex(bar => bar.year === start)

        const histogramEnd = dateHistogram.bars[dateHistogram.bars.length - 1].year
        const userSelectionEnd = getDateFilterEnd(this.$store) || histogramEnd
        const end = Math.min(userSelectionEnd, histogramEnd)
        this.filterHandle2 = bars.findIndex(bar => bar.year === end)

        this.updateTextGap()
    }


    private minHandle(): number {
        return Math.min(this.filterHandle1, this.filterHandle2)
    }


    private maxHandle(): number {
        return Math.max(this.filterHandle1, this.filterHandle2)
    }


    private onMouseLeave() {
        this.closePopup()
    }


    private onMouseMove(event: MouseEvent) {
        if (this.selectedElement) {
            if (event.buttons === 0) {
                this.selectedElement = null
            } else {
                this.closePopup()
                this.drag(event)
            }
        } else {
            const barIndex = Math.trunc((event.offsetX - this.sideMargin) / this.totalBarWidth)
            if (barIndex >= 0 && barIndex < this.histogram.bars.length) {
                const bar = this.histogram.bars[barIndex]
                const canvas = this.$refs.svg as HTMLDivElement

                const nounForm = bar.totalCount === 1 ? "Publication" : "Publications"
                const boundingRect = canvas.getBoundingClientRect()
                this.popupContent = `${bar.relevantCount} of ${bar.totalCount} ${nounForm} - ${bar.year}`
                this.popupLeft = (this.sideMargin + barIndex * this.totalBarWidth + this.barWidth / 2 + boundingRect.left) + "px"
                this.popupTop = ((this.baselineHeight - bar.totalScore * this.heightUnit) * 0.6 + boundingRect.top) + "px"
                this.popupIndex = barIndex
            } else {
                this.closePopup()
            }
        }
    }


    private closePopup() {
        this.popupContent = null
        this.popupIndex = -1
    }


    private startDrag(event: MouseEvent) {
        const target = event.target as SVGElement
        if (target.classList.contains("year-selector")) {
            this.selectedElement = target
            this.draggingHandle1 = target.classList.contains("handle1")
            this.dragStartBarIndex = this.calculateBarIndex(event)
            document.getElementsByTagName("html")[0].classList.add("no-select")
            document.onmouseup = this.endDrag
        }
    }


    private drag(event: MouseEvent) {
        event.preventDefault()

        const barIndex = this.calculateBarIndex(event)

        if (this.draggingHandle1) {
            this.filterHandle1 = barIndex
        } else {
            this.filterHandle2 = barIndex
        }

        this.updateTextGap()
    }


    private calculateBarIndex(event: MouseEvent) {
        let barIndex = Math.trunc((event.offsetX - this.sideMargin) / this.totalBarWidth)
        barIndex = Math.max(barIndex, 0)
        barIndex = Math.min(barIndex, this.histogram.bars.length - 1)
        return barIndex
    }

    private cancelHandleFilter(event: MouseEvent) {
        const target = event.target as SVGElement
        if (!target.classList.contains("year-selector")) {
            return
        }

        const handlesOverlap = this.filterHandle1 === this.filterHandle2

        if (handlesOverlap) {
            this.filterHandle1 = 0
            this.filterHandle2 = this.histogram.bars.length - 1

        } else {
            [this.filterHandle1, this.filterHandle2] = [this.minHandle(), this.maxHandle()]

            const handle1Element = event.target as HTMLElement
            const isHandle1 = handle1Element.classList.contains("handle1")

            if (isHandle1) {
                this.filterHandle1 = 0
            } else {
                this.filterHandle2 = this.histogram.bars.length - 1
            }
        }

        this.reloadData(null, null)
    }


    private endDrag(event: MouseEvent) {
        if (this.selectedElement === null) {
            const hoveredBar = document.querySelector("rect.bar:hover")
            if (hoveredBar) {
                const [lastFilterHandle1, lastFilterHandle2] = [this.filterHandle1, this.filterHandle2]

                const index: number = parseInt(hoveredBar.getAttribute("data-index")!!, 10)
                if (lastFilterHandle1 !== index || lastFilterHandle2 !== index) {
                    this.filterHandle1 = index
                    this.filterHandle2 = index

                    const year = this.histogram.bars[index].year

                    this.reloadData(year, year)
                }
            }
        } else {
            const firstYear = this.histogram.bars[0].year
            const start = this.minHandle() > 0 ? this.minHandle() + firstYear : null
            const end = this.maxHandle() < this.histogram.bars.length - 1 ? this.maxHandle() + firstYear : null

            if (this.hasElementMoved(event)) {
                this.reloadData(start, end)
            }

            this.selectedElement = null
            document.getElementsByTagName("html")[0].classList.remove("no-select")
            document.onmouseup = null
        }
    }

    private hasElementMoved(event: MouseEvent): boolean {
        const newBarIndex = this.calculateBarIndex(event)
        return this.dragStartBarIndex !== newBarIndex
    }

    private async reloadData(start: number | null, end: number | null) {
        setDateFilterYears(this.$store, {start, end})
        getGraphType(this.$store) === null ? await this.$parent.$emit("refresh-documents") : await fetchGraphData(this.$store)
    }

    private updateTextGap() {
        const filterDiff = this.filterHandle2 - this.filterHandle1
        const absFilterDiff = Math.abs(filterDiff * this.totalBarWidth)
        if (absFilterDiff < 40) {
            this.textGap = Math.sign(filterDiff) * (40 - absFilterDiff) / 2
        } else {
            this.textGap = 0
        }
    }
}
