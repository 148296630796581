













import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import {DocumentEntity} from "@/types/investigations"
import {Prop} from "vue-property-decorator"
import {PermissionsMixin} from "@/store/user-mixin"
import {InvestigationMixin} from "@/store/investigation-mixin"
import {addDocumentLabel, removeDocumentLabel} from "@/store/investigation/documents-module"

@Component
export default class DocumentItemReviewHeader extends mixins(Vue, PermissionsMixin, InvestigationMixin) {

    @Prop()
    private document!: DocumentEntity

    @Prop()
    private isEditable!: boolean

    private foundInCvLabelExists = false
    private existingDocumentLabels: string[] = []

    private mounted() {
        this.existingDocumentLabels = this.document.labels
            .filter(it => it !== "Mine" && it !== "Not Mine")
            .sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()))

        this.foundInCvLabelExists = !!this.document.labels.find(it => it === "Found in CV")
    }

    private get documentLabels() {
        return this.existingDocumentLabels
    }

    private get classification(): string | undefined {
        return this.document.classification !== "Unclassified" ? this.document.classification : undefined
    }

    private isMineSelected(): boolean {
        return this.document.labels.some(it => it === "Mine")
    }

    private isNotMineSelected(): boolean {
        return this.document.labels.some(it => it === "Not Mine")
    }

    private selectMine() {
        if (this.isMineSelected()) {
            this.removeLabel("Mine")
        } else {
            this.removeLabel("Not Mine")
            this.addLabel("Mine")
        }
    }

    private selectNotMine() {
        if (this.isNotMineSelected()) {
            this.removeLabel("Not Mine")
        } else {
            this.removeLabel("Mine")
            this.addLabel("Not Mine")
        }
    }

    private async removeLabel(label: string) {
        const {document, investigationType} = this
        if (document.labels.includes(label)) {
            await removeDocumentLabel(this.$store, {document, label, investigationType})
        }
    }

    private async addLabel(label: string) {
        const {document, investigationType} = this
        if (!document.labels.includes(label)) {
            await addDocumentLabel(this.$store, {document, label, investigationType})
        }
    }
}
