









import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import {Prop} from "vue-property-decorator"
import {EntitySummaryDocument, MarkedUpText} from "@/types/investigations"
import TextMarkerService from "@/views/investigation/services/TextMarkerService"
import {Optional} from "@/types"
import {showTermPopupMenu} from "@/store/investigation/dialogs/term-popup-menu-module"
import {InvestigationMixin} from "@/store/investigation-mixin"
import {getSentence} from "@/utils/text-utils"
import TextSelection from "@/directives/TextSelection"

@Component({
    directives: {TextSelection}
})
export default class DocumentExcerpts extends mixins(Vue, InvestigationMixin) {

    @Prop()
    private document!: EntitySummaryDocument

    private markedUpExcerpt(text: MarkedUpText): string {
        const marker = new TextMarkerService()
        return marker.markText(text, ["yellow-highlight"])
    }

    private async textSelected(text: Optional<string>, boundingRect: DOMRect, element: HTMLElement, selection: Selection) {
        if (!this.isInPersonProfile) {
            const documentId = this.document.id
            const sentence = getSentence(element, selection)
            await showTermPopupMenu(this.$store, {term: text, termElement: element, boundingRect, showAddTerm: true, showAddPenalizedTerm: true, investigationType: this.investigationType, documentId, sentence, showAddObservation: true})
        }
    }
}
