import {getStoreAccessors} from "vuex-typescript"
import {ActionContext} from "vuex"
import {Optional} from "@/types"
import {RootState} from "@/store"
import {InvestigationType} from "@/types/investigations"


export interface TermPopupMenuData {
    term: Optional<string>
    termElement: HTMLElement
    boundingRect?: DOMRect
    centered?: boolean
    showAddTerm?: boolean
    showAddPenalizedTerm?: boolean
    investigationType: InvestigationType
    documentId?: string,
    sentence?: string,
    isInObservation?: boolean
    showAddObservation?: boolean
}


export interface TermPopupMenuState {
    termPopupMenuData: Optional<TermPopupMenuData>
}

const module = {

    getters: {
        getTermPopupMenuData(state: TermPopupMenuState): Optional<TermPopupMenuData> {
            return state.termPopupMenuData
        }
    },

    mutations: {
        setTermPopupMenuData(state: TermPopupMenuState, data: Optional<TermPopupMenuData>) {
            state.termPopupMenuData = data
        }
    },

    actions: {
        async showTermPopupMenu(context: ActionContext<TermPopupMenuState, RootState>, data: TermPopupMenuData): Promise<void> {
            setTermPopupMenuData(context, data)
        },

        async hideTermPopupMenu(context: ActionContext<TermPopupMenuState, RootState>): Promise<void> {
            setTermPopupMenuData(context, undefined)
        }
    }
}

export default module

const { read, commit, dispatch } = getStoreAccessors<TermPopupMenuState, RootState>("investigation")

export const getTermPopupMenuData = read(module.getters.getTermPopupMenuData)

const setTermPopupMenuData = commit(module.mutations.setTermPopupMenuData)

export const showTermPopupMenu = dispatch(module.actions.showTermPopupMenu)
export const hideTermPopupMenu = dispatch(module.actions.hideTermPopupMenu)
