import axios from "axios"


export default {

    async updateDocumentImage(investigationId: string, documentId: string,
                              imageType: string, file: File | null, journalImage: boolean): Promise<string> {
        const formData = new FormData()
        formData.set("file", file!)
        formData.set("fileName", file!.name)
        formData.set("journalImage", journalImage.toString())
        const response = await axios.post(`/api/investigations/${investigationId}/documents/${documentId}/image/${imageType}`, formData)
        return response.data as string
    },

    async clearDocumentImage(investigationId: string, documentId: string, imageType: string): Promise<void> {
        await axios.delete(`/api/investigations/${investigationId}/documents/${documentId}/image/${imageType}`)
    },

    async updateFullTextFile(investigationId: string, documentId: string, file: File): Promise<string> {
        const formData = new FormData()
        formData.set("file", file)

        const response = await axios.post(`/api/investigations/${investigationId}/documents/${documentId}/full-text`, formData)
        return response.data as string
    }
}
