






















import Vue from "vue"
import Component from "vue-class-component"
import GenericDialogNew, {createDialogMountingPoint} from "@/components/GenericDialogNew.vue"


export function openErrorMessageDialog() {
    new (Vue.extend(ErrorMessageDialog))().$mount(createDialogMountingPoint())
}


@Component({
    components: {GenericDialogNew}
})
export default class ErrorMessageDialog extends Vue {

    private refresh() {
        location.reload()
    }

    private close() {
        (this.$refs.genericDialog as GenericDialogNew).close()
    }
}
