
























import Vue from "vue"
import Component from "vue-class-component"
import {InvestigationCreationType, InvestigationDataSource, topicInvestigationType} from "@/types/new-investigation"
import {createTopicInvestigation} from "@/store/investigations-module"
import {Watch} from "vue-property-decorator"
import {NewInvestigationDetails} from "@/views/investigations/NewInvestigationPage.vue"

@Component
export default class NewTopicInvestigationDetails extends Vue implements NewInvestigationDetails {

    private availableDataSources: InvestigationDataSource[] = ["web pages", "academic publications"]
    private selectedSources: InvestigationDataSource[] = ["web pages", "academic publications"]
    private searchString: string = ""


    private get investigationType(): InvestigationCreationType {
        return topicInvestigationType
    }


    public async createInvestigation() {
        const payload = {searchString: this.searchString!, sources: this.selectedSources, political: false}
        const id = await createTopicInvestigation(this.$store, payload)

        this.$router.push({name: "topicInvestigation", params: {id}})
    }


    private mounted() {
        this.$nextTick(() => {
            const searchString = this.$refs.searchString as HTMLInputElement
            searchString.focus()
        })

        this.emitEnableNext()
    }

    private toggleSource(source: InvestigationDataSource) {
        this.isSelected(source) ? this.unselectSource(source) : this.selectSource(source)
    }

    private selectSource(source: InvestigationDataSource) {
        this.selectedSources.push(source)
    }

    private unselectSource(source: string) {
        this.selectedSources = this.selectedSources.filter(selectedSource => selectedSource !== source)
    }

    private isSelected(value: string) {
        return this.selectedSources.some(source => source === value)
    }


    @Watch("searchString")
    @Watch("selectedSources")
    private emitEnableNext() {
        const enable = this.searchString.trim().length > 0 && this.selectedSources.length >= 1
        this.$emit("enable-next", enable)
    }
}
