import Vue from "vue"
import VueRouter, {NavigationGuard, Route} from "vue-router"
import InvestigationsPage from "@/views/investigations/InvestigationsPage.vue"
import NewInvestigationPage from "@/views/investigations/NewInvestigationPage.vue"
import SignInPage from "@/views/SignInPage.vue"
import MainPage from "@/views/MainPage.vue"
import {fetchCurrentUser, isSignedIn} from "@/store/auth-module"
import {disambiguationSavedState, restoreInvestigationState, saveInvestigationState, setIsInOverview, setIsInReviewProfile} from "@/store/investigation/investigation-module"
import RegisterAccountPage from "@/views/RegisterAccountPage.vue"
import NotFoundPage from "@/views/NotFoundPage.vue"
import store from "@/store"
import InvestigationPage from "@/views/investigation/InvestigationPage.vue"
import TopicInvestigation from "@/views/investigation/TopicInvestigation.vue"
import PersonInvestigation from "@/views/investigation/PersonInvestigation.vue"
import ResetPage from "@/views/ResetPage.vue"
import SignUpPage from "@/views/SignUpPage.vue"
import PersonProfile from "@/views/investigation/PersonProfile.vue"
import DocumentsPane from "@/views/investigation/panes/DocumentsPane.vue"
import AuthorGraph from "@/views/investigation/graphs/AuthorGraph.vue"
import OverviewPane from "@/views/investigation/panes/OverviewPane.vue"
import ReportEditor from "@/views/investigation/panes/ReportEditor.vue"
import ReportNavigationPane from "@/views/investigation/panes/ReportNavigationPane.vue"
import TermsPane from "@/views/investigation/panes/TermsPane.vue"
import AuthorDisambiguationPane from "@/views/investigation/panes/AuthorDisambiguationPane.vue"
import EmptyPane from "@/views/investigation/panes/EmptyPane.vue"
import ClusterReportPage from "@/views/admin/ClusterReportPage.vue"
import AdminPage from "@/views/admin/AdminPage.vue"
import TimelineGraph from "@/views/investigation/graphs/TimelineGraph.vue"
import TimelineTermsPane from "@/views/investigation/panes/TimelineTermsPane.vue"
import AdminMergePage from "@/views/admin/AdminMergePage.vue"
import UnifiedGraph from "@/views/investigation/graphs/UnifiedGraph.vue"
import {fetchEnvironment} from "@/store/environment-module"
import {RawLocation} from "vue-router/types/router"


Vue.use(VueRouter)

const environmentGuard: NavigationGuard = async (to, from, next) => {
    const version = store.getters["environment/getVersion"]
    if (!version) {
        await fetchEnvironment(store)
    }
    next()
}

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            name: "sign-in",
            path: "/sign-in",
            component: SignInPage,
            beforeEnter: environmentGuard
        },
        {
            name: "sign-up",
            path: "/sign-up",
            component: SignUpPage,
            beforeEnter: environmentGuard
        },
        {
            name: "reset",
            path: "/reset",
            component: ResetPage,
            beforeEnter: environmentGuard
        },
        {
            name: "register",
            path: "/register",
            component: RegisterAccountPage,
            beforeEnter: environmentGuard
        },
        {
            name: "root",
            path: "/",
            redirect: { name: "investigations" },
            component: MainPage,
            meta: {
                requiresAuth: true
            },
            beforeEnter: environmentGuard,
            children: [
                {
                    name: "admin",
                    path: "admin",
                    component: AdminPage,
                    redirect: { name: "admin-merge" },
                    beforeEnter: async (to, from, next) => {
                        const isOmnipotentUser = store.getters["auth/isOmnipotentUser"]
                        if (isOmnipotentUser) {
                            next()
                        }
                    },
                    children: [
                        {
                            name: "clusterReport",
                            path: "cluster-report/:investigationId",
                            component: ClusterReportPage
                        },
                        {
                            name: "admin-merge",
                            path: "merge",
                            component: AdminMergePage
                        }
                    ]
                },
                {
                    name: "investigations",
                    path: "/investigations",
                    component: InvestigationsPage
                },
                {
                    name: "createInvestigation",
                    path: "/investigations/create",
                    component: NewInvestigationPage
                },
                {
                    name: "investigation",
                    path: "/investigations/:id",
                    component: InvestigationPage,
                    redirect: "/investigations/topic/:id/",
                    children: [
                        {
                            name: "topicInvestigation",
                            path: "/investigations/topic/:id",
                            component: TopicInvestigation,
                            redirect: "/investigations/topic/:id/documents",
                            beforeEnter: async (to, from, next) => {
                                next()
                            },
                            children: [
                                {
                                    name: "topicDocuments",
                                    path: "documents",
                                    meta: { allowLeftPaneExpand: false },
                                    components: {
                                        leftPane: TermsPane,
                                        middlePane: DocumentsPane
                                    }
                                },
                                {
                                    name: "topicAuthorGraph",
                                    path: "author-graph",
                                    components: {
                                        leftPane: TermsPane,
                                        middlePane: AuthorGraph
                                    }
                                },
                                {
                                    name: "topicUnifiedGraph",
                                    path: "entity-graph",
                                    components: {
                                        leftPane: TermsPane,
                                        middlePane: UnifiedGraph
                                    }
                                },
                                {
                                    name: "timeline",
                                    path: "timeline",
                                    meta: { allowLeftPaneExpand: false },
                                    components: {
                                        leftPane: TimelineTermsPane,
                                        middlePane: TimelineGraph
                                    }
                                },
                                {
                                    name: "topicReportEditor",
                                    path: "report-editor",
                                    meta: { allowLeftPaneExpand: false },
                                    components: {
                                        leftPane: ReportNavigationPane,
                                        middlePane: ReportEditor
                                    }
                                }
                            ]
                        },
                        {
                            name: "personInvestigation",
                            path: "/investigations/person/:id",
                            component: PersonInvestigation,
                            redirect: "/investigations/person/:id/:tab",
                            beforeEnter: async (to, from, next) => {
                                next()
                            },
                            children: [
                                {
                                    name: "personDocuments",
                                    path: "documents",
                                    meta: { allowLeftPaneExpand: false, layout: "middle-pane-right-pane" },
                                    components: {
                                        leftPane: EmptyPane,
                                        middlePane: DocumentsPane
                                    }
                                },
                                {
                                    name: "personAuthorGraph",
                                    path: "author-graph",
                                    meta: { layout: "middle-pane-right-pane" },
                                    components: {
                                        middlePane: AuthorGraph
                                    },
                                    children: [
                                        {
                                            name: "personAuthorGraphSummary",
                                            path: "summary/:name/:personId",
                                            components: {
                                                middlePane: AuthorGraph
                                            }
                                        }
                                    ]
                                },
                                {
                                    name: "personUnifiedGraph",
                                    path: "entity-graph",
                                    meta: { layout: "middle-pane-right-pane" },
                                    components: {
                                        middlePane: UnifiedGraph
                                    }
                                },
                                {
                                    name: "personReportEditor",
                                    path: "report-editor",
                                    meta: { allowLeftPaneExpand: false },
                                    components: {
                                        leftPane: ReportNavigationPane,
                                        middlePane: ReportEditor
                                    }
                                },
                                {
                                    name: "personDisambiguate",
                                    path: "disambiguate",
                                    meta: { allowLeftPaneExpand: false },
                                    components: {
                                        leftPane: AuthorDisambiguationPane,
                                        middlePane: DocumentsPane
                                    }
                                }
                            ]
                        },
                        {
                            name: "personInvestigationPersonProfile",
                            path: "/investigations/person/:id/profile/:personId",
                            component: PersonProfile,
                            beforeEnter: async (to, from, next) => {
                                await next()
                            },
                            children: [
                                {
                                    name: "personProfileOverview",
                                    path: "overview",
                                    meta: { layout: "middle-pane"},
                                    components: {
                                        middlePane: OverviewPane
                                    }
                                },                                {
                                    name: "personProfileDocuments",
                                    path: "documents",
                                    meta: { allowLeftPaneExpand: false },
                                    components: {
                                        middlePane: DocumentsPane
                                    }
                                },
                                {
                                    name: "personProfileAuthorGraph",
                                    path: "author-graph",
                                    meta: { layout: "middle-pane-right-pane" },
                                    components: {
                                        middlePane: AuthorGraph
                                    }
                                },
                                {
                                    name: "personProfileTermGraph",
                                    path: "entity-graph",
                                    meta: { layout: "middle-pane-right-pane" },
                                    components: {
                                        middlePane: UnifiedGraph
                                    }
                                }
                            ]
                        },
                        {
                            name: "topicInvestigationPersonProfile",
                            path: "/investigations/topic/:id/profile/:personId",
                            component: PersonProfile,
                            beforeEnter: async (to, from, next) => {
                                await next()
                            },
                            children: [
                                {
                                    name: "topicProfileOverview",
                                    path: "overview",
                                    meta: { layout: "middle-pane"},
                                    components: {
                                        middlePane: OverviewPane
                                    }
                                },
                                {
                                    name: "topicProfileDocuments",
                                    path: "documents",
                                    meta: { allowLeftPaneExpand: false },
                                    components: {
                                        middlePane: DocumentsPane
                                    }
                                },
                                {
                                    name: "topicProfileAuthorGraph",
                                    path: "author-graph",
                                    meta: { layout: "middle-pane-right-pane" },
                                    components: {
                                        middlePane: AuthorGraph
                                    }
                                },
                                {
                                    name: "topicProfileTermGraph",
                                    path: "entity-graph",
                                    meta: { layout: "middle-pane-right-pane" },
                                    components: {
                                        middlePane: UnifiedGraph
                                    }
                                }
                            ]
                        },
                        {
                            path: "/investigations/person/:id/review_profile/:personId",
                            component: PersonProfile,
                            children: [
                                {
                                    name: "personReviewProfileDocuments",
                                    path: "",
                                    meta: { allowLeftPaneExpand: false },
                                    components: {
                                        middlePane: DocumentsPane
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            path: "*",
            component: NotFoundPage
        }
    ]
})

router.beforeEach((async (to: Route, from: Route, next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void) => {
    setIsInOverview(store, to.name === "personProfileOverview")
    if (to.name === "personProfileOverview" && from.name !== "personProfileOverview") {
        saveInvestigationState(store)
    } else if (to.name !== "personProfileOverview" && from.name === "personProfileOverview" && disambiguationSavedState) {
        restoreInvestigationState(store)
    } else if (to.name === "personReviewProfileDocuments") {
        setIsInReviewProfile(store, true)
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
        await fetchCurrentUser(store)

        if (isSignedIn(store)) {
            next()
            return
        }

        next({ name: "sign-in", query: { redirect: to.fullPath} })
    } else {
        next()
    }
}))

export default router
