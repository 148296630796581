











import Component from "vue-class-component"
import Vue from "vue"
import {Prop} from "vue-property-decorator"
import {InvestigationCreationType} from "@/types/new-investigation"

@Component
export default class InvestigationSelector extends Vue {

    @Prop()
    private data!: InvestigationCreationType

    private gotoCreateInvestigation(type: string) {
        if (!this.isDisabled()) {
            this.$router.push({name: "createInvestigation", query: {type}})
        }
    }

    private isDisabled(): boolean {
        return this.data.type === "LocalDocuments"
    }

}
