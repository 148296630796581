import axios from "axios"
import {EnvironmentEntity} from "@/types/environment"


export default {

    async getEnvironment(): Promise<EnvironmentEntity> {
        const response = await axios.get("/api/about")
        return response.data as EnvironmentEntity
    }
}
