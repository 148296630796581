import Vue from "vue"
import Component from "vue-class-component"
import {isAdminUser, isFacultyWorksUser, isOmnipotentUser, isOmnisolUser} from "@/store/auth-module"

@Component
export class PermissionsMixin extends Vue {

    get isOmnipotentUser(): boolean {
        return isOmnipotentUser(this.$store)
    }

    get isOmnisolUser(): boolean {
        return isOmnisolUser(this.$store)
    }

    get isFacultyWorksUser(): boolean {
        return isFacultyWorksUser(this.$store)
    }

    get isAdminUser(): boolean {
        return isAdminUser(this.$store)
    }
}
