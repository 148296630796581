

































import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import {Watch} from "vue-property-decorator"
import {
    fetchAllData,
    fetchAllPersonData,
    getInvestigation,
    getIsInProfile,
    restoreInvestigationState,
    setDisambiguatePersonName,
    setPersonForRightPaneSummary,
    setDisplayedResultsStatus,
    setFilterByResearchers,
    setDocumentsSortBy,
    setFilterToDuplicates,
    setSelectedTops,
    clearFilters,
    getAvailableDocumentIds,
    getDisplayedResultsStatus,
    getFilterByResearchers,
    getFilterToDuplicates,
    getSelectedTops
} from "@/store/investigation/investigation-module"
import {setDisambiguationPersonId} from "@/store/investigation/person-filter-module"
import {isReportEnabled, isShowingTimeline} from "@/store/environment-module"
import investigationService from "../../api/investigation-service"
import {downloadBlob} from "@/utils/utils"
import moment from "moment"
import {PermissionsMixin} from "@/store/user-mixin"
import {SelectedTop, DocumentEntity, FacultyWorksWorkflowStage} from "@/types/investigations"
import {getDocuments} from "@/store/investigation/documents-module"

interface Tab {
    readonly title: string,
    readonly to: string
}

@Component
export default class InvestigationHeaderBar extends mixins(Vue, PermissionsMixin) {

    private sliderLeft: number = 0
    private sliderWidth: number = 0
    private workflowStage: FacultyWorksWorkflowStage | null = null
    private selectedTopFix: SelectedTop = {name: "predefined.Labels", selectedDisplayValue: "fix", selectedValue: "fix"}
    private selectedTopAccepted: SelectedTop = {name: "predefined.Labels", selectedDisplayValue: "accepted", selectedValue: "accepted"}

    private regularTabs: Tab[] = [
        {title: "Documents", to: "documents"},
        {title: "Author Graph", to: "author-graph"}
    ]

    private profileTabs: Tab[] = [
        {title: "Overview", to: "overview"},
        {title: "Publications", to: "documents"},
        {title: "Co-Authors", to: "author-graph"},
        {title: "Research Interests", to: "entity-graph"}
    ]

    private disambiguationTabs: Tab[] = [
        {title: "Disambiguate", to: "disambiguate"}
    ]

    private get isInTopicInvestigation() {
        return this.$route.path.includes("topic/")
    }

    private get isInPersonDocuments() {
        return !this.isInTopicInvestigation && this.$route.fullPath.includes("documents")
    }

    private get isInDisambiguate() {
        return this.$route.fullPath.includes("disambiguate")
    }

    private get isInProfile() {
        return getIsInProfile(this.$store)
    }

    private get showTimeline(): boolean {
        return isShowingTimeline(this.$store)
    }

    private get isReportEnabled(): boolean {
        return isReportEnabled(this.$store)
    }

    private get tabs() {
        if (this.isInDisambiguate) {
            return this.disambiguationTabs
        }
        if (this.isInProfile) {
            return this.profileTabs
        }
        return this.regularTabs
    }

    private mounted() {
        if (this.showTimeline && this.isInTopicInvestigation) {
            this.regularTabs.push({title: "Timeline", to: "timeline"})
        }
        this.regularTabs.push({title: "Entity Graph", to: "entity-graph"})
        if (this.isReportEnabled) {
            const title = this.isFacultyWorksUser ? "Configuration" : "Insight Report"
            this.regularTabs.push({title, to: "report-editor"})
        }
        this.routeChanged()
    }

    @Watch("tabs")
    @Watch("$route")
    private routeChanged() {
        Vue.nextTick(() => {
            const tabIndex = this.tabs.findIndex(tab => {
                return this.$route.path.endsWith(tab.to)
            })
            if (tabIndex !== -1) {
                this.tabChosen(tabIndex)
            }
        })
    }

    private tabChosen(index: number) {
        const tabContentElement = document.querySelector(`.tabs-container > .tab:nth-child(${index + 1}) > .tab-text`) as HTMLElement
        this.sliderLeft = tabContentElement.offsetLeft
        this.sliderWidth = tabContentElement.offsetWidth
    }

    private goBack() {
        setPersonForRightPaneSummary(this.$store, null)
        setDisambiguationPersonId(this.$store, null)
        setDisambiguatePersonName(this.$store, null)
        this.$router.back()
        restoreInvestigationState(this.$store)
        fetchAllPersonData(this.$store)
    }

    private showExportToWord(): boolean {
        return this.$router.currentRoute.name === "topicReportEditor" || this.$router.currentRoute.name === "personReportEditor"
    }

    private async exportInvestigation() {
        const investigation = getInvestigation(this.$store)
        const blob = await investigationService.exportInvestigationToWord(investigation!!.id, investigation!!.investigationType)
        const fileName = `${moment().format("YYYY_MM_DD_HH_mm_ss")} - Investigation Report - ${investigation!!.title!!}.docx`.replace(/[/\\?%*:|"<>]/g, "")
        downloadBlob(blob, fileName)
    }

    private getNumberOfDocumentsToMarkFix(): string {
        return this.getNumberOfDocumentsToReview("MarkFix", (d: DocumentEntity) => !d.touched)
    }

    private getNumberOfDuplicatesToReview(): string {
        const computedStr = this.getNumberOfDocumentsToReview("Dedup", (d: DocumentEntity) =>
            d.labels.includes("fix") && !d.labels.includes("dup-suspect cleared") && !d.labels.includes("duplicate"))
        const markedDuplicate = getDocuments(this.$store).filter((d: DocumentEntity) =>
            d.labels.includes("duplicate")).length
        return markedDuplicate === 0 ? computedStr : computedStr.replace(")", "*)")
    }

    private getNumberOfDocumentsToEnrich(): string {
        return this.getNumberOfDocumentsToReview("Enrich", (d: DocumentEntity) =>
            d.labels.includes("fix"))
    }

    private getNumberOfDocumentsToReview(workflowStage: FacultyWorksWorkflowStage,
                                         filter: (d: DocumentEntity) => boolean): string {
        if (this.workflowStage !== workflowStage || !this.validateWorkflowStageState(workflowStage)) {
            return ""
        }
        const documents = getDocuments(this.$store)
        const num = documents.filter(filter).length
        const withReservation = documents.length < getAvailableDocumentIds(this.$store).length
        return num === 0 && !withReservation
            ? "<span style='color: green; margin-inline-start: 0.2em;'>✔</span>︎"
            : ` (${num}${withReservation ? "+" : ""})`
    }

    private validateWorkflowStageState(workflowStage: FacultyWorksWorkflowStage): boolean {
        switch (workflowStage) {
            case "MarkFix":
                return getDisplayedResultsStatus(this.$store) === "Unread" && getFilterByResearchers(this.$store)
            case "Dedup":
                return getFilterToDuplicates(this.$store) &&
                    getSelectedTops(this.$store).includes(this.selectedTopFix) &&
                    getSelectedTops(this.$store).includes(this.selectedTopAccepted)
            case "Enrich":
                return getSelectedTops(this.$store).includes(this.selectedTopFix)
            default:
                return false
        }
    }

    private async setWorkflowStageFix() {
        clearFilters(this.$store)
        setDisplayedResultsStatus(this.$store, "Unread")
        setFilterByResearchers(this.$store, true)
        setDocumentsSortBy(this.$store, "publishDate")
        await fetchAllData(this.$store)
        this.workflowStage = "MarkFix"
    }

    private async setWorkflowStageDedup() {
        clearFilters(this.$store)
        setSelectedTops(this.$store, [this.selectedTopFix, this.selectedTopAccepted])
        setFilterToDuplicates(this.$store, true)
        setDocumentsSortBy(this.$store, "duplicateOrder")
        await fetchAllData(this.$store)
        this.workflowStage = "Dedup"
    }

    private async setWorkflowStageEnrich() {
        clearFilters(this.$store)
        setSelectedTops(this.$store, [this.selectedTopFix])
        setDocumentsSortBy(this.$store, "publishDate")
        await fetchAllData(this.$store)
        this.workflowStage = "Enrich"
    }
}
