import axios, {AxiosRequestConfig, AxiosResponse, CancelToken} from "axios"
import {
    DocumentEntity,
    DocumentOverwrites,
    DocumentStatusEntity,
    EntityRelationshipSummaryEntity,
    EntitySummaryEntity,
    GraphRequest,
    GraphType,
    InvestigationDataEntity,
    InvestigationEntity,
    InvestigationGraphDataEntity,
    InvestigationPersonDetails,
    InvestigationRequestSpec,
    InvestigationTermEntity,
    InvestigationTimelineDataEntity,
    InvestigationType,
    MainClusterFilterData,
    MentionedEntity,
    PersonSummaryEntity,
    Relevancy,
    SearchType,
    TimelineRequest
} from "@/types/investigations"
import moment from "moment"
import {ObservationsResponse} from "@/store/investigation/observation-module"

const settings = { headers: { "Content-Type": "application/json" } }

export default {

    async setDocumentRelevancy(investigationId: string, documentId: string, relevancy: Relevancy, investigationType: InvestigationType, terms: string[]): Promise<DocumentStatusEntity | undefined> {
        const url = `/api/investigations/${investigationType.toLowerCase()}/${investigationId}/documents/${documentId}/relevancy`
        const data = {
            relevancy: `${relevancy}`,
            terms
        }
        const response = await axios.patch(url, data, settings)

        if (response) {
            return response.data
        }
    },

    async setDocumentStarred(investigationId: string, documentId: string, starred: boolean, investigationType: InvestigationType): Promise<DocumentStatusEntity | undefined> {
        const url = `/api/investigations/${investigationType.toLowerCase()}/${investigationId}/documents/${documentId}/starred`
        const response = await axios.patch(url, starred, settings)

        if (response) {
            return response.data
        }
    },

    async setDocumentNote(investigationId: string, documentId: string, investigationType: InvestigationType, note?: string): Promise<DocumentStatusEntity | undefined> {
        const url = `/api/investigations/${investigationType.toLowerCase()}/${investigationId}/documents/${documentId}/note`
        const response = await axios.patch(url, note, settings)

        if (response) {
            return response.data
        }
    },

    async addDocumentLabel(investigationId: string, document: string, label: string, investigationType: InvestigationType): Promise<DocumentEntity | undefined> {
        const url = `/api/investigations/${investigationType.toLowerCase()}/${investigationId}/documents/${document}/label`
        const response = await axios.post(url, [label], settings)

        if (response) {
            return response.data
        }
    },

    async addLabelToSeveralDocuments(investigationId: string, documentIdList: string[], label: string, investigationType: InvestigationType): Promise<void> {
        const url = `/api/investigations/${investigationType.toLowerCase()}/${investigationId}/documents/label`
        const response = await axios.post(url, {documentIdList, label}, settings)
        if (response) {
            return response.data
        }
    },

    async removeDocumentLabel(investigationId: string, document: string, label: string, investigationType: InvestigationType): Promise<DocumentEntity | undefined> {
        const url = `/api/investigations/${investigationType.toLowerCase()}/${investigationId}/documents/${document}/label`
        const response = await axios.delete(url, {data: [label]})

        if (response) {
            return response.data
        }
    },

    async renameDocumentLabel(investigationId: string, payload: {oldLabel: string, newLabel: string}, investigationType: InvestigationType): Promise<boolean> {
        const url = `/api/investigations/${investigationType.toLowerCase()}/${investigationId}/documents/label`
        const response = await axios.patch(url, payload)

        return response.status === 204
    },

    async deleteDocumentLabel(investigationId: string, label: string, investigationType: InvestigationType): Promise<boolean> {
        const url = `/api/investigations/${investigationType.toLowerCase()}/${investigationId}/documents/label`
        const response = await axios.delete(url, {data: label})

        return response.status === 204
    },

    async addEntityLabel(investigationId: string, entityType: string, entity: string, label: string, investigationType: InvestigationType): Promise<MentionedEntity | undefined> {
        const entityTypeFullName = entityType === "o" ? "organizations" : "persons"
        const url = `/api/investigations/${investigationId}/entities/${entityTypeFullName}/${entity.toLowerCase()}/label`
        const response = await axios.post(url, [label], settings)

        if (response) {
            return response.data
        }
    },

    async removeEntityLabel(investigationId: string, entityType: string, entity: string, label: string, investigationType: InvestigationType): Promise<MentionedEntity | undefined> {
        const entityTypeFullName = entityType === "o" ? "organizations" : "persons"
        const url = `/api/investigations/${investigationId}/entities/${entityTypeFullName}/${entity.toLowerCase()}/label`
        const response = await axios.delete(url, {data: [label]})

        if (response) {
            return response.data
        }
    },

    async renameEntityLabel(investigationId: string, payload: {oldLabel: string, newLabel: string}, investigationType: InvestigationType): Promise<boolean> {
        const url = `/api/investigations/${investigationId}/entities/label`
        const response = await axios.patch(url, payload)

        return response.status === 204
    },

    async deleteMentionedEntityLabel(investigationId: string, label: string, investigationType: InvestigationType): Promise<boolean> {
        const url = `/api/investigations/${investigationId}/entities/label`
        const response = await axios.delete(url, {data: label})

        return response.status === 204
    },

    async bulkMarkRead(investigationId: string, investigationType: InvestigationType, documentIds: string[]): Promise<boolean> {
        const url = `/api/investigations/${investigationType.toLowerCase()}/${investigationId}/documents/bulkread`
        const response = await axios.post(url, documentIds)
        return response.status === 200
    },

    async bulkApplyDocumentLabel(investigationId: string, investigationType: InvestigationType, label: string, add: boolean, documentIds: string[]): Promise<boolean> {
        const url = `/api/investigations/${investigationType.toLowerCase()}/${investigationId}/documents/label/bulk`
        const response = await axios.post(url, { label, add, documentIds })
        return response.status === 200
    },

    async createDocument(investigationId: string, investigationType: InvestigationType, documentType: string, overwrites: DocumentOverwrites): Promise<string> {
        const url = `/api/investigations/${investigationType.toLowerCase()}/${investigationId}/documents`
        const response = await axios.post(url, { documentType, overwrites })
        if (response.status !== 201) {
            return Promise.reject("Failed to create document")
        }
        const location = response.headers.location
        return location.substr(location.lastIndexOf("/") + 1)
    },

    async updateDocument(investigationId: string, investigationType: InvestigationType, documentId: string, overwrites: DocumentOverwrites): Promise<boolean> {
        const url = `/api/investigations/${investigationType.toLowerCase()}/${investigationId}/documents/${documentId}`
        const response = await axios.patch(url, overwrites)

        return response.status === 204
    },

    async getInvestigation(id: string, investigationType: InvestigationType): Promise<InvestigationEntity | undefined> {
        const response = await axios.get(`/api/investigations/${investigationType.toLowerCase()}/${id}`)
        const entity = response.data

        if (entity) {
            return {...entity, createdOn: moment(entity.createdOn)} as InvestigationEntity
        }
    },

    async getPersonForSummary(id: string, investigationType: InvestigationType, personId: string, termsCount: number, personName?: string): Promise<PersonSummaryEntity | string> {
        try {
            const response = await axios.get(`/api/investigations/${investigationType.toLowerCase()}/${id}/person-summary/${personId}`, {params: {personName, termsCount}})
            return new PersonSummaryEntity(
                response.data.personName,
                response.data.terms,
                response.data.recentAffiliations,
                response.data.bibliographicDetails,
                response.data.documents,
                response.data.aliasNames,
                response.data.manuallyMerged,
                response.data.universityProfileUrl,
                !!response.data.lastReviewTime ? moment(response.data.lastReviewTime) : null)
        } catch (e) {
            return e.response.data as string
        }
    },

    async getEntityForSummary(id: string, investigationType: InvestigationType, entityName: string, entityType: string, spec: InvestigationRequestSpec): Promise<EntitySummaryEntity> {
        const response = await axios.post(`/api/investigations/${investigationType.toLowerCase()}/${id}/entity-summary`, {entityName, entityType, spec})
        return response.data as EntitySummaryEntity
    },

    async getEntityRelationshipForSummary(id: string, investigationType: InvestigationType, firstEntity: MentionedEntity, secondEntity: MentionedEntity): Promise<EntityRelationshipSummaryEntity> {
        const response = await axios.post(`/api/investigations/${investigationType.toLowerCase()}/${id}/entity-relationship-summary`, {firstEntity, secondEntity})
        const data = response.data as EntityRelationshipSummaryEntity
        const documents = data.documents.map(document => ({...document, snippet: null}))
        return {...data, documents} as EntityRelationshipSummaryEntity
    },

    async getAuthorsGraph(id: string, investigationType: InvestigationType, spec: InvestigationRequestSpec): Promise<AxiosResponse> {
        const config: AxiosRequestConfig = {
            url: `/api/investigations/${investigationType.toLowerCase()}/${id}/export-co-authors`,
            method: "POST",
            responseType: "blob",
            data: spec
        }
        return axios(config)
    },

    async getXmlTermsGraph(id: string, investigationType: InvestigationType, spec: InvestigationRequestSpec): Promise<AxiosResponse> {
        const config: AxiosRequestConfig = {
            url: `/api/investigations/${investigationType.toLowerCase()}/${id}/export-terms`,
            method: "POST",
            responseType: "blob",
            data: spec
        }
        return axios(config)
    },

    async getGraphData(id: string, investigationType: InvestigationType, graphType: GraphType, graphRequest: GraphRequest, cancelToken: CancelToken): Promise<InvestigationGraphDataEntity | undefined> {
        const response = await axios.post( `/api/investigations/${investigationType.toLowerCase()}/${id}/${graphType!.toLowerCase()}-graph`, graphRequest)
        if (response) {
            return response.data as InvestigationGraphDataEntity
        }
    },

    async getTimelineData(id: string, investigationType: InvestigationType, graphType: GraphType, timelineRequest: TimelineRequest): Promise<InvestigationTimelineDataEntity | undefined> {
        const response = await axios.post(`/api/investigations/${investigationType.toLowerCase()}/${id}/timeline-chart`, timelineRequest)
        if (response) {
            return response.data as InvestigationTimelineDataEntity
        }
    },

    async getDocumentMarkups(investigationId: string, spec: InvestigationRequestSpec, pageSize: number | null): Promise<DocumentEntity[] | undefined> {
        const url = `/api/investigations/topic/${investigationId}/document-markups`
        const response = await axios.post(url, spec, { params: { pageSize }})

        if (response) {
            return response.data.map((entity: any) => convertToDocument(entity))
        }
    },

    async getPersonProfile(investigationId: string, spec: InvestigationRequestSpec, investigationType: InvestigationType): Promise<InvestigationDataEntity | undefined> {
        const url = `/api/investigations/${investigationType.toLowerCase()}/${investigationId}/profile`
        const response = await axios.post(url, spec)
        if (response) {
            const documents = response.data.documents.map((entity: any) => convertToDocument(entity))
            return {...response.data, documents}
        }
    },

    async getPersonProfileExcel(investigationId: string, spec: InvestigationRequestSpec, investigationType: InvestigationType): Promise<Blob | undefined> {
        const url = `/api/investigations/${investigationType.toLowerCase()}/${investigationId}/profile/export`
        const response = await axios.post(url, spec, { responseType: "arraybuffer" })
        if (response) {
            return new Blob([response.data])
        }
    },

    async getPublicationsExcel(investigationId: string, spec: InvestigationRequestSpec, investigationType: InvestigationType): Promise<Blob | undefined> {
        const url = `/api/investigations/${investigationType.toLowerCase()}/${investigationId}/export-publications`
        const response = await axios.post(url, spec, { responseType: "arraybuffer" })
        if (response) {
            return new Blob([response.data])
        }
    },

    async getAllData(investigationId: string, spec: InvestigationRequestSpec, pageSize: number, investigationType: InvestigationType, cancelToken: CancelToken): Promise<InvestigationDataEntity | undefined> {
        const url = `/api/investigations/${investigationType.toLowerCase()}/${investigationId}/data`
        const response = await axios.post(url, spec, { params: { pageSize }, cancelToken })
        if (response && response.data) {
            const documents = response.data.documents.map((entity: any) => convertToDocument(entity))
            return {...response.data, documents}
        }
    },

    async getNextDocuments(investigationId: string, spec: InvestigationRequestSpec, investigationType: InvestigationType): Promise<DocumentEntity[] | undefined> {
        const url = `/api/investigations/${investigationType.toLowerCase()}/${investigationId}/next-documents`
        const response = await axios.post(url, spec)
        if (response) {
            return response.data.map((entity: any) => convertToDocument(entity))
        }
    },

    async getNextSuggestedTerms(investigationId: string, selectedSuggestedTerms: InvestigationTermEntity[], rejectedSuggestedTerms: string[], spec: InvestigationRequestSpec): Promise<string[] | undefined> {
        const response = await axios.post(`/api/investigations/topic/${investigationId}/next-suggested-terms`, { spec, selectedSuggestedTerms, rejectedSuggestedTerms })
        if (response) {
            return response.data
        }
    },

    async getObservations(investigationId: string, investigationType: InvestigationType): Promise<ObservationsResponse | undefined> {
        const response = await axios.get(`/api/investigations/${investigationType.toLowerCase()}/${investigationId}/observation`)
        if (response) {
            return response.data
        }
    },

    async createTopicHarvest(investigationId: string, investigationType: string, searchString: string,
                             urlHarvest: boolean, forceStringHarvest: boolean, forceNormalize: boolean = false
    ): Promise<void> {
        const urlPart = `${investigationType.toLowerCase()}/${investigationId}`
        await axios.post(`/api/investigations/${urlPart}/harvest/topic`,
          { searchString, urlHarvest, forceStringHarvest, forceNormalize })
    },

    async createHarvestByDocs(investigationId: string, investigationType: string, documentIds: string[]): Promise<void> {
        const urlPart = `${investigationType.toLowerCase()}/${investigationId}`
        await axios.post(`/api/investigations/${urlPart}/harvest/by-documents`, { documentIds })
    },

    async createHarvestByDoiList(investigationId: string, investigationType: string, doiList: string[]): Promise<void> {
        const urlPart = `${investigationType.toLowerCase()}/${investigationId}`
        await axios.post(`/api/investigations/${urlPart}/harvest/by-doi-list`, { doiList })
    },

    async createPersonHarvest(investigationId: string, investigationType: string, searchStrings: string[], searchType: SearchType): Promise<void> {
        const urlPart = `${investigationType.toLowerCase()}/${investigationId}`
        await axios.post(`/api/investigations/${urlPart}/harvest/person`, { searchStrings, searchType })
    },

    async merge(investigationId: string, investigationType: InvestigationType): Promise<void> {
        await axios.post(`/api/investigations/${investigationType.toLowerCase()}/${investigationId}/merge`)
    },

    async undoMerge(investigationId: string, investigationType: InvestigationType, personIds: string[]): Promise<void> {
        await axios.post(`/api/investigations/${investigationType.toLowerCase()}/${investigationId}/merge/undo`, personIds)
    },

    async disambiguate(investigationId: string, investigationType: InvestigationType,
                       irrelevantPersonIds: string[], relevantPersonIds: string[], neutralPersonIds: string[]): Promise<void> {
        await axios.post(`/api/investigations/${investigationType.toLowerCase()}/${investigationId}/disambiguate`,
          { irrelevantPersonIds, relevantPersonIds, neutralPersonIds })
    },

    async undelete(investigationId: string, investigationType: InvestigationType): Promise<void> {
        await axios.get(`/api/investigations/${investigationType.toLowerCase()}/${investigationId}/undelete`)
    },

    async getPersonDetails(investigationId: string, investigationType: InvestigationType, personId: string): Promise<InvestigationPersonDetails> {
        const response = await axios.get(`/api/investigations/${investigationType.toLowerCase()}/${investigationId}/person-details/${personId}`)
        return response.data
    },

    async updatePersonDetails(investigationId: string, investigationType: InvestigationType, details: InvestigationPersonDetails): Promise<void> {
        await axios.post(`/api/investigations/${investigationType.toLowerCase()}/${investigationId}/person-details/${details.id}`, details)
    },

    async preprocessPersonImage(investigationId: string, investigationType: InvestigationType, personId: string,
                                imageData: ImageCropData | null, imageUrl: string | null): Promise<string> {

        const formData = new FormData()
        if (imageData && imageData.file) {
            formData.set("file", imageData.file)
        } else if (imageUrl) {
            // proceed even if imageData is unavailable (i.e. cropper ran into cross-origin violation)
            formData.set("imageUrl", imageUrl)
        } else {
            // sending empty forms is apparently not allowed...
            formData.set("dummy", "")
        }
        const response = await axios.post(`/api/investigations/${investigationType.toLowerCase()}/${investigationId}/person-details/${personId}/preprocess-image`, formData)
        return response.data
    },

    async updatePersonImage(investigationId: string, investigationType: InvestigationType, personId: string,
                            imageData: ImageCropData | undefined, imageUrl: string | undefined): Promise<string> {
        const formData = new FormData()
        if (imageData) {
            if (imageData.file) {
                formData.set("file", imageData.file)
                formData.set("fileName", imageData.file.name)
            }
            formData.set("imageData", JSON.stringify(imageData))
        }
        if (imageUrl) {
            // proceed even if imageData is unavailable (i.e. cropper ran into cross-origin violation)
            formData.set("imageUrl", imageUrl)
        }
        const response = await axios.post(`/api/investigations/${investigationType.toLowerCase()}/${investigationId}/person-details/${personId}/upload-image`, formData)
        return response.data as string
    },

    async markPersonReviewed(investigationId: string, investigationType: InvestigationType, personId: string): Promise<void> {
        await axios.post(`/api/investigations/${investigationType.toLowerCase()}/${investigationId}/person-details/${personId}/reviewed`, {})
    },

    async clearPersonImage(investigationId: string, investigationType: InvestigationType, personId: string): Promise<void> {
        await axios.post(`/api/investigations/${investigationType.toLowerCase()}/${investigationId}/person-details/${personId}/clear-image`, {})
    },

    async exportInvestigationToWord(id: string, investigationType: InvestigationType): Promise<Blob> {
        const response = await axios.post(`/api/investigations/${investigationType.toLowerCase()}/${id}/observation/export-to-word`, {}, { responseType: "arraybuffer" })
        return new Blob([response.data])
    },

    async getMainClusterFilter(investigationId: string): Promise<MainClusterFilterData> {
        const response = await axios.get(`/api/investigations/person/${investigationId}/main-cluster-filter`)
        return response.data as MainClusterFilterData
    },

    async getLastFilterData(investigationId: string): Promise<InvestigationRequestSpec> {
        const response = await axios.get(`/api/investigations/${investigationId}/last-filter`)
        return response.data as InvestigationRequestSpec
    }

}

function convertToDocument(entity: any): DocumentEntity {
    return entity.date ? { ...entity, date: moment(entity.date) } : entity
}


export interface ImageCropData {
    file: File | null,
    width: number,
    height: number,
    crop: { top: number, left: number, width: number, height: number }
}
