



















import Vue from "vue"
import Component from "vue-class-component"
import GenericDialogNew, {createDialogMountingPoint} from "@/components/GenericDialogNew.vue"
import {Prop} from "vue-property-decorator"

export function openConfirmationDialog(options: ConfirmationDialogOptions) {
    new (Vue.extend(ConfirmationDialog))({propsData: {options}}).$mount(createDialogMountingPoint())
}

export interface ConfirmationDialogOptions {
    readonly title: string
    readonly message: string
    readonly okText: string
    readonly cancelText?: string
    readonly onOk?: () => void
    readonly showCancel: boolean
}

@Component({
    components: {GenericDialogNew}
})
export default class ConfirmationDialog extends Vue {

    @Prop()
    private options!: ConfirmationDialogOptions

    private ok() {
        this.close()
        if (this.options.onOk) {
            this.options.onOk()
        }
    }

    private close() {
        (this.$refs.genericDialog as GenericDialogNew).close()
    }
}
