import moment, {Moment} from "moment"
import Rollbar from "rollbar"

export enum SocketMessageType {
    Register = "Register",
    Unregister = "Unregister",
    KeepAlive = "KeepAlive",
    InvestigationState = "InvestigationState"
}


export class SocketMessage {
    constructor(public type: SocketMessageType, public content: string) {}
}


export type MessageHandler = (data?: string) => void


export interface WebSocketManager {
    registerInvestigation(investigationId: string, updateMessageHandler: MessageHandler, user: string, rollbarAccessToken: string): void
    unregisterInvestigation(): void
}


class WebSocketManagerImpl implements WebSocketManager {

    private socket?: WebSocket
    private isFirstKeepAlive: boolean = true
    private keepAliveInterval: any
    private investigationId?: string
    private messageHandlers: Map<SocketMessageType, MessageHandler> = new Map()
    private lastMessageSentTime?: Moment
    private lastMessageReceivedTime?: Moment
    private messageIntervalMillis: number = 10000
    private rollbar: Rollbar | null = null
    private rollbarAccessToken: string = ""
    private user: string = ""


    public registerInvestigation(investigationId: string, updateMessageHandler: MessageHandler, user: string, rollbarAccessToken: string) {
        // console.log("registerInvestigation", new Date().getTime())
        this.clearMetaData()
        this.user = user
        this.rollbarAccessToken = rollbarAccessToken
        this.messageHandlers.set(SocketMessageType.InvestigationState, updateMessageHandler)
        this.messageHandlers.set(SocketMessageType.KeepAlive, this.updateLastMessageReceivedTime.bind(this))
        this.investigationId = investigationId
        this.connectAndRun(() => {
            this.sendMessage(SocketMessageType.Register, investigationId)
            this.keepAliveInterval = setInterval(this.keepAlive.bind(this), this.messageIntervalMillis)
        })
    }

    public unregisterInvestigation() {
        // console.log("unregisterInvestigation")
        this.sendMessage(SocketMessageType.Unregister, this.investigationId!)
        this.clearMetaData()
    }


    private clearMetaData() {
        // console.log("clearMetaData")
        if (this.keepAliveInterval) {
            clearInterval(this.keepAliveInterval)
            this.keepAliveInterval = undefined
        }
        this.isFirstKeepAlive = true
        this.messageHandlers.delete(SocketMessageType.InvestigationState)
        this.messageHandlers.delete(SocketMessageType.KeepAlive)
        this.lastMessageSentTime = undefined
        this.lastMessageReceivedTime = undefined
        this.investigationId = undefined
    }


    private keepAlive() {
        // console.log("keepAlive", this.isFirstKeepAlive)
        if (!this.isFirstKeepAlive) {
            this.checkKeepAlive()
        }
        this.lastMessageSentTime = moment()
        this.sendMessage(SocketMessageType.KeepAlive, this.investigationId!)
        // console.log("keepAlive: isFirstKeepAlive", this.isFirstKeepAlive)
        this.isFirstKeepAlive = false
    }


    private updateLastMessageReceivedTime() {
        // console.log("updateLastMessageReceivedTime")
        this.lastMessageReceivedTime = moment()
    }


    private checkKeepAlive() {
        // console.log("checkKeepAlive")
        if (!this.isWithinSocketMessageInterval()) {
            this.resetConnection()
        }
    }


    private resetConnection() {
        // console.log("resetConnection")
        this.lastMessageReceivedTime = undefined
        this.closeConnection()
        if (this.investigationId) {
            this.registerInvestigation(
                this.investigationId,
                this.messageHandlers.get(SocketMessageType.InvestigationState)!,
                this.user,
                this.rollbarAccessToken)
        }
    }


    private sendMessage(messageType: SocketMessageType, content: string) {
        // if (!this.rollbar) {
        //     this.rollbar = new Rollbar({
        //         accessToken: this.rollbarAccessToken,
        //         captureUncaught: true,
        //         captureUnhandledRejections: true
        //     })
        // }
        // if (!content) {
        //     this.rollbar.error(`Socket message type ${messageType} is null, user: ${this.user}`)
        // } else {
        //     this.rollbar.debug(`Socket message type ${messageType} is ${content}, user: ${this.user}`)
        // }
        const socketMessage = new SocketMessage(messageType, content)
        // console.log("sendMessage", socketMessage)
        if (this.isConnectionOpen()) {
            this.socket!.send(JSON.stringify(socketMessage))
        }
    }


    private onMessage(event: MessageEvent) {
        const incomingMessage = JSON.parse(event.data) as SocketMessage
        const handler = this.messageHandlers.get(SocketMessageType[incomingMessage.type])
        if (handler) {
            handler(incomingMessage.content)
        }
    }

    private closeConnection() {
        if (this.socket !== undefined) {
            this.socket!.close()
        }
        this.socket = undefined
    }

    private connect(onMessageCallback: ({data}: any) => void) {
        // console.log("connect")
        if (!this.isConnectionOpen()) {
            const protocol = window.location.protocol.replace(/(http)(s)?/, "ws$2")
            const host = window.location.host.replace("4001", "4000")
            const websocketUrl = `${protocol}//${host}/api/web-socket`
            this.socket = new WebSocket(websocketUrl)
            this.socket.onmessage = onMessageCallback
            // tslint:disable-next-line:no-console
            this.socket.onerror = (ev: Event) => console.log(ev)
            // tslint:disable-next-line:no-console
            this.socket.onclose = (ev: Event) => console.log(ev)
        }
    }

    private connectAndRun(callback: () => void) {
        // console.log("connectAndRun")
        this.connect(event => this.onMessage(event))
        this.waitUntilConnectionOpen(callback)
    }

    private waitUntilConnectionOpen(callback: () => void) {
        // console.log("waitUntilConnectionOpen")
        if (!this.isConnectionOpen()) {
            setTimeout(() => {
                if (this.isConnectionOpen()) {
                    callback()
                } else {
                    setTimeout(this.resetConnection.bind(this), this.messageIntervalMillis)
                }
            }, 2000)
        } else {
            callback()
        }
    }

    private isConnectionOpen() {
        return this.socket && this.socket.readyState === WebSocket.OPEN
    }

    private isWithinSocketMessageInterval() {
        if (!this.lastMessageReceivedTime) {
            return false
        }
        // console.log("isWithinSocketMessageInterval", Math.abs(this.lastMessageReceivedTime!.diff(moment(this.lastMessageSentTime))))
        const maxSocketLatency = 1000
        return Math.abs(this.lastMessageReceivedTime!.diff(moment(this.lastMessageSentTime))) <= maxSocketLatency
    }
}


export const webSocketManager: WebSocketManager = new WebSocketManagerImpl()
