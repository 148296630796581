















import Vue from "vue"
import Component from "vue-class-component"
import {InvestigationCreationType, tenderInvestigationType} from "@/types/new-investigation"
import {createTenderInvestigation} from "@/store/investigations-module"
import {Watch} from "vue-property-decorator"
import {NewInvestigationDetails} from "@/views/investigations/NewInvestigationPage.vue"

@Component
export default class NewTenderInvestigationDetails extends Vue implements NewInvestigationDetails {

    private searchString: string = ""


    private get investigationType(): InvestigationCreationType {
        return tenderInvestigationType
    }


    public async createInvestigation() {
        const payload = { searchString: this.searchString! }
        const id = await createTenderInvestigation(this.$store, payload)

        this.$router.push({name: "topicInvestigation", params: {id}})
    }


    private mounted() {
        this.$nextTick(() => {
            const searchString = this.$refs.searchString as HTMLInputElement
            searchString.focus()
        })

        this.emitEnableNext()
    }


    @Watch("searchString")
    private emitEnableNext() {
        const enable = this.searchString.trim().length > 0
        this.$emit("enable-next", enable)
    }
}
