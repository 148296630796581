



















import Vue from "vue"
import Component from "vue-class-component"
import GenericDialog from "@/components/GenericDialog.vue"
import {updateAlertsEnabled} from "@/store/investigation/investigation-module"

@Component({
      components: { GenericDialog }
  })
  export default class AlertsDialog extends Vue {

      private isOpen = false

      public open() {
          this.isOpen = true
          document.addEventListener("keyup", this.handleKey)
      }


      private close() {
          this.isOpen = false
          document.removeEventListener("keyup", this.handleKey)
      }


      private async handleKey(e: KeyboardEvent) {
          switch (e.key) {
              case "Escape":
                  this.close()
                  break
              case "Enter":
                  await this.create()
                  break
          }
      }


      private async create() {
          this.close()
          await updateAlertsEnabled(this.$store, true)
      }
  }
