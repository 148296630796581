
















import Vue from "vue"
import Component from "vue-class-component"
import {Watch} from "vue-property-decorator"

@Component
export default class SplitPane extends Vue {

    private allowLeftPaneExpand: boolean = true
    private showLeftPane = false
    private showMiddlePane = true
    private showRightPane = true
    private layout = ""
    private leftPaneWidth = 0

    private mounted() {
        this.routeChange()
    }

    @Watch("$route")
    private routeChange() {
        this.layout = this.$route.meta?.layout || "three-pane"
        this.allowLeftPaneExpand = this.$route.meta?.allowLeftPaneExpand === undefined ? true : this.$route.meta.allowLeftPaneExpand
        this.showLeftPane = this.layout === "three-pane"
        this.showMiddlePane = true
        this.showRightPane = ["three-pane", "middle-pane-right-pane"].includes(this.layout)
    }

    private toggleLeftPane() {
        this.leftPaneWidth = this.leftPaneWidth === 25 ? 0 : 25
    }

    private get gridTemplateColumns(): string {
        if (this.layout === "three-pane") {
            if (this.allowLeftPaneExpand) {
                return `${this.leftPaneWidth}vw calc(${75 - this.leftPaneWidth}vw - 1.6rem - 1.6rem) 25vw`
            }
            return `25vw calc(50vw - 1.6rem - 1.6rem) 25vw`
        }

        if (this.layout === "middle-pane-right-pane") {
            return "0 calc(75vw - 1.6rem - 1.6rem) 25vw"
        }

        return "100vw"
    }

    private get expandCollapseTitle(): string {
        return (this.leftPaneWidth === 0) ? "Expand" : "Collapse"
    }
}
