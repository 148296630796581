import axios from "axios"
import {
    GenerateHtmlResponse,
    InvestigationEntity, InvestigationRequestSpec,
    InvestigationType,
    PersonSemanticAffinityEntity
} from "@/types/investigations"
import moment from "moment"

const settings = { headers: { "Content-Type": "application/json" } }

export default {

    async export(investigationId: string, spec: InvestigationRequestSpec): Promise<Blob> {
        const response = await axios.post(`/api/investigations/person/${investigationId}/export`, spec, { responseType: "arraybuffer" })
        return new Blob([response.data])
    },

    async createCorrelatedInvestigation(investigationId: string, personId: string, investigationType: InvestigationType): Promise<InvestigationEntity> {
        const response = await axios.post(`/api/investigations/${investigationType.toLowerCase()}/${investigationId}/correlated`, personId)
        const entity = response.data

        return { ...entity, createdOn: moment(entity.createdOn) } as InvestigationEntity
    },

    async getSemanticAffinity(investigationId: string, personId: string): Promise<PersonSemanticAffinityEntity[]> {
        const response = await axios.get(`/api/investigations/person/${investigationId}/semantic-affinity/${personId}`)
        return response.data.personSemanticAffinities
    },

    async exportHtml(investigationId: string, theme: string, filename: string): Promise<GenerateHtmlResponse> {
        const response = await axios.get(
            `/api/investigations/person/${investigationId}/html?theme=${theme}&filename=${filename}`)
        return response.data as GenerateHtmlResponse
    }

}
