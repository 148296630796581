import {LayoutName} from "@/keylines"

export interface HighlightFilter {
    filter: string,
    current: number | null
    list: string[]
}

export type LayoutPacking = "circle" | "rectangle" | "none"

export interface GraphLayout {
    readonly name: LayoutName,
    readonly packing: LayoutPacking
}

export const graphLayouts: {[key: string]: GraphLayout} = {
    circular: {
        name: "organic",
        packing: "circle"
    },
    organic: {
        name: "organic",
        packing: "rectangle"
    },
    structural: {
        name: "structural",
        packing: "rectangle"
    },
    lens: {
        name: "lens",
        packing: "rectangle"
    }
}
