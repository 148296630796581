

























import Vue from "vue"
import Component from "vue-class-component"
import {DocumentEntity, DocumentMarkupsEntity, InvestigationType} from "@/types/investigations"
import {Moment} from "moment"
import {Prop} from "vue-property-decorator"
import {getDocumentMarkups} from "@/store/investigation/documents-module"
import {getInvestigation} from "@/store/investigation/investigation-module"

interface DocumentDetail {
    html: string
    class: string,
    title: string
}

@Component
export default class OverviewDocumentItem extends Vue {

    @Prop()
    private document!: DocumentEntity

    @Prop()
    private documentIndex!: number


    private get documentMarkups(): DocumentMarkupsEntity {
        return getDocumentMarkups(this.$store).filter(documentMarkups => documentMarkups.id === this.document.id)[0]
    }

    private get documentDetails(): DocumentDetail[] {
        const details: DocumentDetail[] = []

        if (this.document.date) {
            const dateHtml = this.formatDate(this.document.date)
            details.push({class: "date", html: dateHtml, title: dateHtml})
        }

        if (this.document.citationNumber) {
            const citationHtml = `${this.document.citationNumber} citations`
            details.push({class: "citation-number", html: citationHtml, title: citationHtml})
        }

        if (this.document.journal) {
            details.push({class: "published-in", html: this.document.journal, title: this.document.journal})
        }

        if (this.document.type === "Web Page") {
            const urlParts = this.document.url.split("/")

            let boldUrl = urlParts[0] + "//<b>" + urlParts[2] + "</b>"
            for (let i = 3; i < urlParts.length; i++) {
                boldUrl += "/" + urlParts[i]
            }
            boldUrl = `<a href="${this.document.url}" target="_blank">${boldUrl}</a>`

            details.push({class: "web-page-url", html: boldUrl, title: this.document.url})
        }

        return details
    }

    private get classification(): string {
        return this.document.classification
    }

    private get originalLanguage(): string | undefined {
        if (this.document.originalLanguage === "English") {
            return undefined
        }
        return this.document.originalLanguage
    }

    private get investigationType(): InvestigationType {
        return getInvestigation(this.$store)!.investigationType
    }

    private classificationClassName(classification: string) {
        return "classification-" + classification.replace(" ", "-").toLowerCase()
    }

    private formatDate(date: Moment) {
        return date ? date.format("YYYY") : ""
    }

    private authorsCount() {
        const count = this.document.authors.length
        return count > 1 ? `${count} authors` : "1 author"
    }
}
