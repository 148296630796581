























import Vue from "vue"
import Component from "vue-class-component"
import {fetchAllData, fetchGraphData, getInvestigation, setFreeTextFilter} from "@/store/investigation/investigation-module"
import PopupMenu from "@/components/PopupMenu.vue"
import {Prop} from "vue-property-decorator"
import {SummaryDocument} from "@/types/investigations"
import {copyToClipboard} from "@/utils/utils"
import {getGraphType} from "@/store/investigation/graph-module"

@Component({
    components: {PopupMenu}
})
export default class SummaryDocumentsPane extends Vue {

    @Prop()
    private containingParentName!: string

    @Prop()
    private documents!: SummaryDocument[]

    @Prop({type: Boolean, default: true})
    private showHeader!: boolean

    private get investigationType() {
        return getInvestigation(this.$store)!.investigationType
    }

    private get containingPane() {
        return this.containingParentName
    }

    private openContextMenu(documentId: string, event: MouseEvent) {
        const topsContextButtons = this.$refs[`contextMenu` + documentId] as HTMLElement[]
        this.$emit("openContextMenu" + documentId, topsContextButtons[0])
    }

    private copyTitleToClipboard(document: SummaryDocument) {
        copyToClipboard(document.title)
    }

    private async addAsFilter(document: SummaryDocument) {
        await setFreeTextFilter(this.$store, document.title)
        getGraphType(this.$store) === null ? await fetchAllData(this.$store) : await fetchGraphData(this.$store)
    }
}
