















import Vue from "vue"
import Component from "vue-class-component"
import {createPersonInvestigation} from "@/store/investigations-module"
import {NewInvestigationDetails} from "@/views/investigations/NewInvestigationPage.vue"
import {Watch} from "vue-property-decorator"
import {InvestigationCreationType, InvestigationDataSource, personInvestigationType} from "@/types/new-investigation"


@Component
export default class NewPersonInvestigationDetails extends Vue implements NewInvestigationDetails {

    private researcherName: string = ""


    private get investigationType(): InvestigationCreationType {
        return personInvestigationType
    }


    public async createInvestigation() {
        const payload = { personName: this.researcherName!, sources: ["academic publications"] as InvestigationDataSource[]}
        const highlight = await createPersonInvestigation(this.$store, payload)

        this.$router.push({name: "investigations", query: {highlight}})
    }


    private mounted() {
        this.$nextTick(() => {
            const researcherName = this.$refs.researcherName as HTMLInputElement
            researcherName.focus()
        })

        this.emitEnableNext()
    }


    @Watch("researcherName")
    private emitEnableNext() {
        const enable = this.researcherName.trim().length > 0
        this.$emit("enable-next", enable)
    }
}
