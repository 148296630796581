




























































































import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import {
    getDocuments,
    getHighlightModes,
    getViewModeLevel,
    setDocumentNote,
    setDocumentRelevancy,
    setDocumentStarred,
    setDocumentRead,
    setHighlightModes,
    setViewModeLevel
} from "@/store/investigation/documents-module"
import {
    fetchAllData,
    fetchDocumentMarkups,
    fetchNextDocuments,
    getActivityStatus,
    getDocumentsSortBy,
    getIncludeOnlyUnlabeled,
    getIsBusy,
    getRelevancyStatus,
    getRelevantDocumentCount,
    getTotalDocumentCount,
    setDocumentsSortBy,
    setIncludeOnlyUnlabeled,
    setPersonForRightPaneSummary
} from "@/store/investigation/investigation-module"
import {DocumentEntity, Relevancy, RGB} from "@/types/investigations"
import DocumentItem from "@/views/investigation/panes/DocumentItem.vue"
import SuggestedTermsPane from "@/views/investigation/panes/SuggestedTermsPane.vue"
import PopupMenu from "@/components/PopupMenu.vue"
import RecentAffiliationsPane from "@/views/investigation/panes/RecentAffiliationsPane.vue"
import {getDisambiguationPersonId, getPersonId} from "@/store/investigation/person-filter-module"
import AuthorSummaryPane from "@/views/investigation/panes/AuthorSummaryPane.vue"
import {setGraphType} from "@/store/investigation/graph-module"
import Speedometer from "@/views/investigation/graphs/Speedometer.vue"
import {getDocumentsPageSize, isEntityDetectionEnabled} from "@/store/environment-module"
import {Watch} from "vue-property-decorator"
import {openCreateObservationDialog} from "@/views/investigation/dialogs/CreateObservationDialog.vue"
import NoResults from "@/components/NoResults.vue"
import SpinningLoader from "@/components/SpinningLoader.vue"
import {InvestigationMixin} from "@/store/investigation-mixin"
import {PermissionsMixin} from "@/store/user-mixin"

@Component({
    components: {DocumentItem, SuggestedTermsPane, PopupMenu, RecentAffiliationsPane, AuthorSummaryPane, Speedometer, NoResults, SpinningLoader}
})
export default class DocumentsPane extends mixins(Vue, InvestigationMixin, PermissionsMixin) {

    private harvestIndicatorInterval: any
    private harvestIndicatorRotation: number = 0
    private observer: IntersectionObserver | undefined
    private documentId: string | null = null
    private title: string | null = null
    private possibleHighlightNames: string[] = ["Highlight Search Terms", "Highlight Mentioned Entities"]

    public async mounted() {
        setGraphType(this.$store, null)
        setIncludeOnlyUnlabeled(this.$store, true)
        await this.refreshResults()
        this.harvestIndicatorInterval = setInterval(() => this.harvestIndicatorRotation = this.activityIndicator + (this.activityIndicator > 0 && this.activityIndicator <= 170 ? 10 * Math.random() : 0), 500)
    }

    public async refreshResults() {
        await fetchAllData(this.$store)
        this.$emit("documentsUpdated")
    }

    get documents(): DocumentEntity[] {
        return getDocuments(this.$store)
    }

    get loaded(): boolean {
        return !getIsBusy(this.$store)
    }

    private get entityDetectionEnabled() {
        return isEntityDetectionEnabled(this.$store)
    }

    private get hasDocuments(): boolean {
        return getTotalDocumentCount(this.$store) > 0
    }

    private get relevantPersonSelected() {
        return getPersonId(this.$store) || getDisambiguationPersonId(this.$store)
    }

    private get activityIndicator(): number {
        return getActivityStatus(this.$store)
    }

    private get resultsDifferenceColor() {
        const percentFade = Math.min(getRelevancyStatus(this.$store).relevancyScore / 100, 1)
        const startRed = 0
        const startGreen = 176
        const startBlue = 255
        const middleRed = 160
        const middleGreen = 109
        const middleBlue = 244
        const endRed = 233
        const endGreen = 30
        const endBlue = 99
        return this.colorGradient(
            percentFade,
            {red: startRed, green: startGreen, blue: startBlue},
            {red: middleRed, green: middleGreen, blue: middleBlue},
            {red: endRed, green: endGreen, blue: endBlue}
        )
    }

    private get documentCount(): string {
        const total = getTotalDocumentCount(this.$store)
        const relevant = getRelevantDocumentCount(this.$store)
        return total !== relevant ? `${relevant} of ${total}` : `${total}`
    }

    private get viewModeLevel(): number {
        return getViewModeLevel(this.$store)
    }

    private set viewModeLevel(level: number) {
        setViewModeLevel(this.$store, level)
    }

    private get highlightModes(): string[] {
        return [...getHighlightModes(this.$store)]
    }

    private set highlightModes(level: string[]) {
        setHighlightModes(this.$store, level)
    }

    private get shouldLoadMoreResults() {
        const availableResultsLeft = getRelevantDocumentCount(this.$store) - getDocuments(this.$store).length
        return availableResultsLeft > 0 && this.documents.length
    }

    private get showSuggestedTerms() {
        return this.isInTopicInvestigation
    }

    private get sortBy() {
        return getDocumentsSortBy(this.$store)
    }

    private async refreshMarkups() {
        await fetchDocumentMarkups(this.$store)
    }

    private createObservation(documentId: string) {
        this.documentId = documentId
        this.title = this.documents.find(document => document.id === documentId)!.title.text

        openCreateObservationDialog(this, {
            type: "Document",
            referencedEntity: this.documentId,
            observationTitle: this.title,
            allowImageUpload: true
        })
    }

    private colorGradient(fadeFraction: number, rgbColor1: RGB, rgbColor2: RGB, rgbColor3: RGB) {
        let color1: RGB = rgbColor1
        let color2: RGB = rgbColor2
        let fade: number = fadeFraction

        // Do we have 3 colors for the gradient? Need to adjust the params.
        if (rgbColor3) {
            fade = fade * 2

            // Find which interval to use and adjust the fade percentage
            if (fade >= 1) {
                fade -= 1
                color1 = rgbColor2
                color2 = rgbColor3
            }
        }

        const diffRed: number = color2.red - color1.red
        const diffGreen: number = color2.green - color1.green
        const diffBlue: number = color2.blue - color1.blue

        const gradient: RGB = {
            red: Math.floor(color1.red + (diffRed * fade)),
            green: Math.floor(color1.green + (diffGreen * fade)),
            blue: Math.floor(color1.blue + (diffBlue * fade))
        }

        return `rgb(${gradient.red},${gradient.green},${gradient.blue})`
    }

    private shouldShowResultsEnd(index: number) {
        return index === this.documents.length - (getDocumentsPageSize(this.$store) / 2)
    }

    private beforeDestroy() {
        setPersonForRightPaneSummary(this.$store, null)

        clearInterval(this.harvestIndicatorInterval)
        if (this.observer) {
            this.observer.disconnect()
        }
    }

    private async getNextDocuments() {
        await fetchNextDocuments(this.$store)
    }

    @Watch("documents")
    private observeEndOfResults() {
        const observerCallback: IntersectionObserverCallback = ([endResultsIndicator]) => {
            if (endResultsIndicator && endResultsIndicator.isIntersecting && this.shouldLoadMoreResults) {
                this.getNextDocuments()
            }
        }
        if (this.observer) {
            this.observer.disconnect()
        }
        this.observer = new IntersectionObserver(observerCallback)
        this.$nextTick(() => {
            const resultsEnd = document.querySelector("#resultsEnd")
            if (resultsEnd) {
                this.observer!.observe(resultsEnd)
            }
        })
    }

    private async updateDocumentRelevancy(doc: DocumentEntity, relevancy: Relevancy) {
        const investigationType = this.investigationType
        const mainTerms = doc.terms.map(term => term.text)
        await setDocumentRelevancy(this.$store, {id: doc.id, relevancy, investigationType, mainTerms})
    }

    private async updateDocumentStarred(doc: DocumentEntity, starred: boolean) {
        const investigationType = this.investigationType
        await setDocumentStarred(this.$store, {id: doc.id, starred, investigationType})
    }

    private async updateDocumentRead(id: string) {
        const investigationType = this.investigationType
        await setDocumentRead(this.$store, {id, investigationType})
    }

    private async updateDocumentNote(id: string, note: string) {
        const investigationType = this.investigationType
        await setDocumentNote(this.$store, {id, investigationType, note})
    }

    private openViewModeMenu() {
        const viewModeMenuOpenerButton = this.$refs.viewModeMenuOpener as HTMLElement
        this.$emit("openViewModeMenu", viewModeMenuOpenerButton)
    }

    private openHighlightModeMenu() {
        const highlightModeMenuOpenerButton = this.$refs.highlightModeMenuOpener as HTMLElement
        this.$emit("openHighlightModeMenu", highlightModeMenuOpenerButton)
    }

    private toggleHighlight(highlightName: string) {
        const isHighlighted = this.isHighlightChecked(highlightName)
        const newHighlights = this.highlightModes.filter(mode => mode !== highlightName)
        if (!isHighlighted) {
            newHighlights.push(highlightName)
        }
        this.highlightModes = newHighlights
        this.refreshResults()
    }

    private isHighlightChecked(highlightName: string) {
        return this.highlightModes.includes(highlightName)
    }

    private performSortBy() {
        const option = (this.$refs.sortBySelect as HTMLSelectElement).value as string
        setDocumentsSortBy(this.$store, option)
        this.refreshResults()
    }

    private async toggleFilterReviewedDocuments() {
        setIncludeOnlyUnlabeled(this.$store, !getIncludeOnlyUnlabeled(this.$store))
        await this.refreshResults()
    }

    private get includeOnlyUnlabeled() {
        return getIncludeOnlyUnlabeled(this.$store)
    }
}
