











import Vue from "vue"
import Component from "vue-class-component"
import { Model, Prop } from "vue-property-decorator"

@Component({})
export default class KendoEditorWrapper extends Vue {

    @Prop()
    @Model("value")
    private value!: string

    private get editorValue() {
        return this.value
    }

    private set editorValue(newValue: string) {
        this.$emit("value", newValue)
    }

    get pasteCleanup() {
        return {
            all: false,
            css: false,
            keepNewLines: true,
            msAllFormatting: false,
            msConvertLists: true,
            msTags: false,
            none: false,
            span: false
        }
    }
}
