import {MarkedUpText, TextMarkup} from "@/types/investigations"

export default class TextMarkerService {

    private termTags: MarkupTag[] = []
    private entityTags: MarkupTag[] = []



    public markText(markedUpText: MarkedUpText, classNames: string[]): string {
        this.fillTagsArrayWithZero(markedUpText.text.length)
        markedUpText.markups.forEach(markup => this.tagMarkup(markup))
        return this.wrapInSpans(markedUpText, classNames)
    }

    private fillTagsArrayWithZero(length: number) {
        this.termTags = []
        this.entityTags = []
        for (let i = 0; i < length; i++) {
            this.termTags.push({ priority: 0, mandatory: false })
            this.entityTags.push({ priority: 0, mandatory: false })
        }
    }

    private tagMarkup(markup: TextMarkup) {
        for (let tagIndex = markup.startIndex; tagIndex <= markup.endIndex; tagIndex++) {
            const tag = markup.entities ? this.entityTags[tagIndex] : this.termTags[tagIndex]
            tag.priority = tag.priority === 0 ? markup.priority : Math.min(tag.priority, markup.priority)
            tag.mandatory = markup.mandatory
        }
    }

    private wrapInSpans(markedUpText: MarkedUpText, classNames: string[]) {
        let previousTermTag: MarkupTag = {priority: 0, mandatory: false}
        let previousEntityTag: MarkupTag = {priority: 0, mandatory: false}
        let result: string = ""
        this.termTags.forEach((termTag, index) => {
            const entityTag = this.entityTags[index]
            const termMandatoryClass = termTag.mandatory ? " mandatory" : ""
            const entityMandatoryClass = entityTag.mandatory ? " mandatory" : ""
            const tagIsSameAsPrevious = termTag.priority === previousTermTag.priority && termTag.mandatory === previousTermTag.mandatory
            const entityTagIsSameAsPrevious = entityTag.priority === previousEntityTag.priority && entityTag.mandatory === previousEntityTag.mandatory
            if (!tagIsSameAsPrevious || !entityTagIsSameAsPrevious) {
                const termClassName = classNames[termTag.priority - 1] ? `term ${classNames[termTag.priority - 1]}${termMandatoryClass}` : ``
                const entityClassName = classNames[entityTag.priority - 1] ? ` entity ${classNames[entityTag.priority - 1]}${entityMandatoryClass}` : ``
                const newClassName = termClassName + entityClassName
                result += this.closeSpan(previousTermTag.priority, previousEntityTag.priority)
                result += this.openSpan(termTag.priority, entityTag.priority, newClassName.trim())
            }
            previousTermTag = termTag
            previousEntityTag = entityTag
            result += markedUpText.text[index]
        })
        result += this.closeSpan(previousTermTag.priority, previousEntityTag.priority)
        return result
    }

    private openSpan = (termTag: number, entityTag: number, className: string) => termTag === 0 && entityTag === 0 ? `` : `<span class='${className}'>`

    private closeSpan = (termTag: number, entityTag: number) => termTag === 0 && entityTag === 0 ? `` : `</span>`
}

interface MarkupTag {
    priority: number,
    mandatory: boolean
}
