




























import Vue from "vue"
import Component from "vue-class-component"
import {getSuggestedTerms, getTermGroups, getTerms, MAX_ALLOWED_TERMS, SuggestedTerm, updateSuggestedTerm} from "@/store/investigation/investigation-terms-module"
import {fetchNextSuggestedTerms, getInvestigation} from "@/store/investigation/investigation-module"
import CreateTermTemplate from "@/views/investigation/templates/CreateTermTemplate.vue"
import PopupMenu from "@/components/PopupMenu.vue"
import {TermModifier} from "@/types/investigations"
import {openMessageDialog} from "@/components/MessageDialog.vue"

@Component({components: {PopupMenu, CreateTermTemplate}})
export default class SuggestedTermsPane extends Vue {

    private updateSuggestedTerm = updateSuggestedTerm
    private isSendingTerms = false
    private newTerm: string = ""

    get suggestedTerms(): SuggestedTerm[] {
        return [...getSuggestedTerms(this.$store)]
    }

    private get terms() {
        return getTerms(this.$store)
    }

    private get hasGroups() {
        return getTermGroups(this.$store).length > 1
    }

    private get investigationSearchString(): string {
        return getInvestigation(this.$store)!.initialSearchString
    }

    private toggleSuggestedTerm(toggleTerm: SuggestedTerm, event: Event) {
        const currentTerm = this.suggestedTerms.find(suggestedTerm => suggestedTerm.term === toggleTerm.term)!
        const term = toggleTerm.term
        if (currentTerm.selected) {
            updateSuggestedTerm(this.$store, {term, selected: false, group: undefined, modifier: "Regular"})
            return
        }
        if (this.hasGroups) {
            this.newTerm = term
            this.$emit("openCreateTermMenu", event.target)
        } else {
            this.updateTermWithModifier(term, "Regular")
        }
    }

    private async updateTermWithModifier(term: string, requestedModifier: TermModifier) {
        const selectedTerm = this.suggestedTerms.find(suggestedTerm => suggestedTerm.term === term)!
        const group = getTermGroups(this.$store)[0].id
        const modifier: TermModifier = selectedTerm.modifier === requestedModifier ? "Regular" : requestedModifier
        updateSuggestedTerm(this.$store, {term, selected: true, group, modifier})
    }

    private async nextSuggestedTerms() {
        if (getTerms(this.$store).length + getSuggestedTerms(this.$store).filter(it => it.selected).length > MAX_ALLOWED_TERMS) {
            openMessageDialog({title: "Add Terms", message: `Cannot add more than ${MAX_ALLOWED_TERMS} terms`})
            return
        }

        const investigation = getInvestigation(this.$store)!
        if (!this.isSendingTerms) {
            this.isSendingTerms = true
            const wasMandatoryAdded = getSuggestedTerms(this.$store).some(suggestedTerm => suggestedTerm.selected && suggestedTerm.modifier === "Mandatory")
            await fetchNextSuggestedTerms(this.$store)
            this.isSendingTerms = false
            this.$emit(!wasMandatoryAdded ? "refresh-markups" : "refresh-documents")
        }
    }

    private async queryTermClicked(term: string) {
        window.open(`https://www.google.com/search?q=define+${term}`, "_blank")
    }
}
