

































import Vue from "vue"
import Component from "vue-class-component"
import SplitPane from "@/components/SplittedPane.vue"
import TopsPane from "@/views/investigation/panes/TopsPane.vue"
import AuthorDisambiguationPane from "@/views/investigation/panes/AuthorDisambiguationPane.vue"
import AuthorSummaryPane from "@/views/investigation/panes/AuthorSummaryPane.vue"
import {clearInvestigationStore, getEntityForSummary, getEntityRelationshipForSummary, getIsBusy, getIsInDisambiguate, getPersonForRightPaneSummary, setDisambiguatePersonName, setDisplayedResultsStatus, setIsInDisambiguate} from "@/store/investigation/investigation-module"
import ReportNavigationPane from "@/views/investigation/panes/ReportNavigationPane.vue"
import {webSocketManager} from "@/infrastructure/WebsocketManager"
import AuthorGraph from "@/views/investigation/graphs/AuthorGraph.vue"
import {getGraphType} from "@/store/investigation/graph-module"
import DocumentsPane from "@/views/investigation/panes/DocumentsPane.vue"
import EntitySummaryPane from "@/views/investigation/panes/EntitySummaryPane.vue"
import SpinningLoader from "@/components/SpinningLoader.vue"
import EntityRelationshipSummaryPane from "@/views/investigation/panes/EntityRelationshipSummaryPane.vue"

@Component({
    components: {EntityRelationshipSummaryPane, EntitySummaryPane, AuthorGraph, SplitPane, TopsPane, AuthorDisambiguationPane, AuthorSummaryPane, ReportNavigationPane, SpinningLoader}
})
export default class PersonInvestigation extends Vue {

    private scrolledToObservation: string | null = ""
    private scrolledToSection: string | null = ""

    private get isShowingTopsPane() {
        return !this.isAuthorSummaryInRightPane && !this.isEntitySummaryVisible && !this.isEntityRelationshipSummaryVisible
    }

    private get personForRightPaneSummary() {
        return getPersonForRightPaneSummary(this.$store)
    }

    private get isAuthorSummaryInRightPane(): boolean {
        return this.personForRightPaneSummary != null
    }

    private get entityForSummary() {
        return getEntityForSummary(this.$store)
    }

    private get entityRelationshipForSummary() {
        return getEntityRelationshipForSummary(this.$store)
    }

    private get isEntitySummaryVisible(): boolean {
        return this.entityForSummary != null
    }

    private get isEntityRelationshipSummaryVisible(): boolean {
        return this.entityRelationshipForSummary != null
    }

    private get isInReportEditor() {
        return this.$route.path.includes("report-editor")
    }

    private get isBusy(): boolean {
        return getIsBusy(this.$store)
    }

    private get showDisambiguationPane() {
        return getIsInDisambiguate(this.$store)
    }

    private get showingGraph() {
        return getGraphType(this.$store) != null
    }

    private async beforeCreate() {
        setDisplayedResultsStatus(this.$store, "All")
        if (this.$route.path.endsWith("disambiguate")) {
            setDisambiguatePersonName(this.$store, this.$route.query.name as string)
        } else {
            setIsInDisambiguate(this.$store, false)
        }
    }

    private refreshContent() {
        const documentsPane = this.$refs.content as DocumentsPane
        // @ts-ignore
        documentsPane.refreshResults()
    }

    private beforeRouteLeave(to: string, from: string, next: () => void) {
        webSocketManager.unregisterInvestigation()
        clearInvestigationStore(this.$store)
        next()
    }

    private documentsUpdated() {
        const documentContainer = this.$refs.documentsContainer as HTMLDivElement
        documentContainer.scrollTop = 0
    }

    private scrollToSection(sectionId: string) {
        const content = this.$refs.content as Vue
        this.scrolledToSection = sectionId
    }

    private scrollToObservation(observationId: string) {
        const content = this.$refs.content as Vue
        this.scrolledToObservation = observationId
    }

    private resetScrollVars() {
        this.scrolledToSection = null
        this.scrolledToObservation = null
    }
}
