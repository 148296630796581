

























































import Vue from "vue"
import Component from "vue-class-component"
import {deleteObservation, deleteObservationSection, getObservations, getObservationSections, getSectionObservationOrder, moveToSection, ObservationEntity, ObservationSectionEntity, setSectionObservationOrder} from "@/store/investigation/observation-module"
import {InvestigationType} from "@/types/investigations"
import PopupMenu from "@/components/PopupMenu.vue"
import CreateObservationSectionDialog from "@/views/investigation/dialogs/CreateObservationSectionDialog.vue"
import RenameObservationSectionDialog from "@/views/investigation/dialogs/RenameObservationSectionDialog.vue"
import ObservationSectionIndexDialog from "@/views/investigation/dialogs/ObservationSectionIndexDialog.vue"
import {getInvestigation} from "@/store/investigation/investigation-module"
import {Watch} from "vue-property-decorator"
import {VueDraggableOptions} from "vue-draggable/types/vue-draggable-options"
import {openConfirmationDialog} from "@/views/investigation/dialogs/ConfirmationDialog.vue"


@Component({
    components: {PopupMenu, CreateObservationSectionDialog, RenameObservationSectionDialog, ObservationSectionIndexDialog}
})
export default class ReportNavigationPane extends Vue {

    private newObservation: string = ""
    private isObservationTooltipShown = false
    private isInTransition = false
    private observationTooltipText = ""
    private observationPopupInterval: number = 0
    private observationContext: ObservationEntity | null = null
    private sectionContext: ObservationSectionEntity | null = null
    private expandedStates: { [key: string]: boolean } = {}

    get observationSections(): ObservationSectionEntity[] {
        return getObservationSections(this.$store)
    }

    private get observations(): ObservationEntity[] {
        return getObservations(this.$store)
    }

    private get investigationType(): InvestigationType {
        return getInvestigation(this.$store)!.investigationType
    }

    private get sectionObservationOrder() {
        return [...getSectionObservationOrder(this.$store)]
    }

    private set sectionObservationOrder(value: any) {
        setSectionObservationOrder(this.$store, value)
    }

    private get sections() {
        return [...getObservationSections(this.$store), {name: "", id: "0", ordinal: -1}]
    }

    private get dragAndDropOptions(): VueDraggableOptions {
        return {
            dropzoneSelector: ".drag-inner-list",
            draggableSelector: ".drag-item",
            multipleDropzonesItemsDraggingEnabled: true
        } as unknown as VueDraggableOptions
    }

    private mounted() {
        this.$on("closedOncloseSingleObservationMenu", this.singleObservationMenuClosed)
        this.$on("closedOncloseCreateObservationMenu", this.createObservationMenuClosed)
    }

    private toggleExpansion(sectionId: string) {
        this.expandedStates[sectionId] = !this.expandedStates[sectionId]
    }

    private observationsOf(section: ObservationSectionEntity): ObservationEntity[] {
        return this.observations
            .filter(observation => section.id === observation.section)
            .sort((a: ObservationEntity, b: ObservationEntity) => a.ordinal - b.ordinal)
    }

    @Watch("observationSections")
    private updateSectionExpansion() {
        this.observationSections.forEach(section => this.$set(
            this.expandedStates,
            section.id,
            this.expandedStates[section.id] !== undefined ? this.expandedStates[section.id] : true
        ))
    }

    private async moveToSection(observationSection: ObservationSectionEntity) {
        const observation = this.observationContext!.id!
        const section = observationSection.id
        await moveToSection(this.$store, {observation, section})
        this.observationContext = null
    }

    private singleObservationMenuClosed() {
        return null
    }

    private async createObservationMenuClosed() {
        this.newObservation = ""
    }

    private async deleteObservation(observation: ObservationEntity) {
        const observationId = observation.id!
        const investigationType = getInvestigation(this.$store)!.investigationType
        await deleteObservation(this.$store, {observationId, investigationType})
        await this.$emit("refresh-observations")
    }

    private handleTransition(el: HTMLElement, done: () => void) {
        this.isInTransition = true
        done()
        this.isInTransition = false
    }

    private openObservationMenu(button: HTMLElement, observation: ObservationEntity) {
        this.observationContext = observation
        this.$nextTick(() => this.$emit("openObservationMenu", button))
    }

    private openObservationSectionMenu(button: HTMLElement, observationSection: ObservationSectionEntity) {
        this.sectionContext = observationSection
        this.$nextTick(() => this.$emit("openObservationSectionMenu", button))
    }

    private openCreateSectionDialog() {
        // @ts-ignore
        (this.$refs.createObservationSectionDialog as CreateObservationSectionDialog).open()
    }

    private renameSection() {
        if (this.sectionContext != null && this.sectionContext.name !== "Default Section") {
            // @ts-ignore
            (this.$refs.renameObservationSectionDialog as RenameObservationSectionDialog).open({section: this.sectionContext})
        }
    }

    private setSectionIndex() {
        if (this.sectionContext != null && this.sectionContext.name !== "Default Section") {
            // @ts-ignore
            (this.$refs.observationSectionIndexDialog as ObservationSectionIndexDialog).open({section: this.sectionContext})
        }
    }

    private deleteSection() {
        if (this.sectionContext != null && this.sectionContext.name !== "Default Section") {
            openConfirmationDialog({
                message: `Are you sure you want to delete "${this.sectionContext.name}" section?`,
                title: "Delete Section",
                okText: "Delete",
                onOk: () => this.performDeleteSection(),
                showCancel: true
            })
        }
    }

    private async performDeleteSection() {
        const hasObservations = this.observationsOf(this.sectionContext!!).length > 0
        await deleteObservationSection(this.$store, this.sectionContext!!)
    }

    private scrollToSection(section: ObservationSectionEntity) {
        this.$emit("scroll-to-section", section.id)
    }

    private scrollToObservation(observation: ObservationEntity) {
        this.$emit("scroll-to-observation", observation.id)
    }
}
