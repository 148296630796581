




















import Vue from "vue"
import Component from "vue-class-component"
import {RecentAffiliation} from "@/types/investigations"
import {Prop} from "vue-property-decorator"
import {copyToClipboard} from "@/utils/utils"
import PopupMenu from "@/components/PopupMenu.vue"

@Component({
    components: {PopupMenu}
})
export default class RecentAffiliationsPane extends Vue {

    @Prop()
    private recentAffiliations!: RecentAffiliation[]

    private showingAll: boolean = false

    private get shownRecentAffiliations(): RecentAffiliation[] {
        if (this.showingAll) {
            return this.recentAffiliations
        }
        return this.recentAffiliations.slice(0, 5)
    }

    private affiliationText(affiliation: RecentAffiliation): string {
        return affiliation.title ? affiliation.title : affiliation.affiliation
    }

    private calcYearRange(recentAffiliation: RecentAffiliation) {
        if (recentAffiliation.fromYear === recentAffiliation.toYear) {
            return recentAffiliation.fromYear
        }

        return `${recentAffiliation.fromYear} - ${recentAffiliation.toYear}`
    }

    private toggleShowAll() {
        this.showingAll = !this.showingAll
    }

    private openMenu() {
        const menuOpenerButton = this.$refs.menuOpener as HTMLElement
        this.$emit("openMenu", menuOpenerButton)
    }

    private copyToClipboard() {
        const allAffiliations = this.recentAffiliations.slice().map(affiliation => this.calcYearRange(affiliation) + " " + this.affiliationText(affiliation)).join("\n")
        copyToClipboard(allAffiliations)
    }
}
