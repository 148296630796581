



































import Vue from "vue"
import Component from "vue-class-component"
import GenericDialog from "@/components/GenericDialog.vue"
import InvestigationService from "../../../api/investigation-service"
import {getInvestigation} from "@/store/investigation/investigation-module"
import {SearchType} from "@/types/investigations"

export interface ResearcherHarvestDialogDialogOptions {
    readonly title: string
    readonly searchStringLabel: string,
    readonly placeholder: string
}

@Component({
    components: {GenericDialog}
})
export default class ResearcherHarvestDialog extends Vue {

    private options?: ResearcherHarvestDialogDialogOptions
    private isOpen = false
    private errorMessage: string = ""
    private searchString: string = ""
    private searchType: SearchType = "LastYear"

    public open(options: ResearcherHarvestDialogDialogOptions) {
        this.options = options
        this.isOpen = true
        this.errorMessage = ""
        this.searchString = ""
        this.searchType = "LastYear"

        document.addEventListener("keyup", this.handleKey)

        Vue.nextTick(() => {
            const nameInput = this.$refs.searchString as HTMLInputElement
            nameInput.focus()
        })
    }


    private close() {
        this.isOpen = false
        document.removeEventListener("keyup", this.handleKey)
    }


    private handleKey(e: KeyboardEvent) {
        switch (e.key) {
            case "Escape":
                this.close()
                break
        }
    }

    private setSearchType() {
        this.searchType = (this.$refs.searchTypeSelection as HTMLSelectElement).value as SearchType
    }

    private startHarvest() {
        this.searchString = this.searchString.trim()
        if (this.searchString.length === 0) {
            this.errorMessage = "Please enter an author name"
            return
        }

        const investigation = getInvestigation(this.$store)!!

        this.errorMessage = ""
        InvestigationService.createPersonHarvest(
            investigation.id, investigation.investigationType,
            this.searchString.split("\n"), this.searchType)

        this.close()
    }
}
