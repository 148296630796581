import {InvestigationEntity} from "@/types/investigations"
import {RootState} from "@/store/index"
import adminService from "../api/admin-service"
import investigationsService from "../api/investigations-service"
import {ActionContext} from "vuex"
import {getStoreAccessors} from "vuex-typescript"
import {InvestigationDataSource} from "@/types/new-investigation"


export interface InvestigationsState {
    investigations: InvestigationEntity[],
    showAllInvestigations: boolean,
    busyFetchingAllInvestigations: boolean,
    freeTextFilter: string
}

const module = {
    namespaced: true,

    state(): InvestigationsState {
        return {
            investigations: [],
            showAllInvestigations: false,
            busyFetchingAllInvestigations: false,
            freeTextFilter: ""
        }
    },

    getters: {

        getInvestigations(state: InvestigationsState): InvestigationEntity[] {
            return state.investigations
        },

        isShowAllInvestigations(state: InvestigationsState): boolean {
            return state.showAllInvestigations
        },

        isBusyFetchingAllInvestigations(state: InvestigationsState): boolean {
            return state.busyFetchingAllInvestigations
        },

        getFreeTextFilter(state: InvestigationsState): string {
            return state.freeTextFilter
        }
    },

    mutations: {
        setInvestigations(state: InvestigationsState, investigations: InvestigationEntity[]) {
            state.investigations = investigations
        },


        toggleShowAllInvestigations(state: InvestigationsState) {
            state.showAllInvestigations = !state.showAllInvestigations
        },

        setBusyFetchingAllInvestigations(state: InvestigationsState, fetching: boolean) {
            state.busyFetchingAllInvestigations = fetching
        },

        setFreeTextFilter(state: InvestigationsState, text: string) {
            state.freeTextFilter = text
        }
    },

    actions: {
        async fetchAllInvestigations(context: ActionContext<InvestigationsState, RootState>, showBusy: boolean): Promise<void> {
            const interval = showBusy ? setTimeout(() => setBusyFetchingAllInvestigations(context, true), 250) : null

            const investigations = await investigationsService.getAll(context.state.showAllInvestigations)
            setInvestigations(context, investigations)

            if (showBusy) {
                setBusyFetchingAllInvestigations(context, false)
                clearTimeout(interval!)
            }
        },

        async createTopicInvestigation(context: ActionContext<InvestigationsState, RootState>, payload: {
            searchString: string, sources: InvestigationDataSource[], political: boolean
        }): Promise<string> {
            const {searchString, sources, political} = payload
            const topicInvestigation = await investigationsService.createTopic(
                searchString, sources.includes("academic publications"), sources.includes("web pages"), political
            )

            return topicInvestigation.id
        },

        async createTenderInvestigation(context: ActionContext<InvestigationsState, RootState>, payload: {
            searchString: string
        }): Promise<string> {
            const topicInvestigation = await investigationsService.createTender(payload.searchString)
            return topicInvestigation.id
        },

        async createCompanyInvestigation(context: ActionContext<InvestigationsState, RootState>, payload: {
            companyName: string, companyWebSite: string
        }): Promise<string> {
            const topicInvestigation = await investigationsService.createCompany(payload.companyName, payload.companyWebSite)
            return topicInvestigation.id
        },


        async createPersonInvestigation(context: ActionContext<InvestigationsState, RootState>, payload: {
            personName: string, sources: InvestigationDataSource[]
        }): Promise<string> {
            const {personName, sources} = payload
            const personInvestigation = await investigationsService.createPerson(personName, sources.includes("academic publications"), sources.includes("web pages"))
            return personInvestigation.id
        },

        async createPersonGroupInvestigation(context: ActionContext<InvestigationsState, RootState>, payload: {
            institutionName: string, facultyName: string, researcherNames: string, sources: InvestigationDataSource[]
        }): Promise<string> {
            const researchers = payload.researcherNames.split("\n").map(it => it.trim())
            const personInvestigation = await investigationsService.createPersonGroup(payload.institutionName, payload.facultyName, researchers, payload.sources.includes("academic publications"), payload.sources.includes("web pages"))
            return personInvestigation.id
        },

        async createUniversityInvestigation(context: ActionContext<InvestigationsState, RootState>, payload: {
            universityName: string, country: string, startYear: number, endYear: number, sources: InvestigationDataSource[]
        }): Promise<string> {
            const universityInvestigation = await investigationsService.createUniversity(payload.universityName, payload.country, payload.startYear, payload.endYear, payload.sources.includes("academic publications"), payload.sources.includes("web pages"))
            return universityInvestigation.id
        },

        async createWebsiteInvestigation(context: ActionContext<InvestigationsState, RootState>, payload: {
            investigationName: string, websiteUrl: string, sources: InvestigationDataSource[]
        }): Promise<string> {
            const websiteInvestigation = await investigationsService.createWebsite(payload.investigationName, payload.websiteUrl, payload.sources.includes("academic publications"), payload.sources.includes("web pages"))
            return websiteInvestigation.id
        },

        async createFacultyWorksInvestigation(context: ActionContext<InvestigationsState, RootState>, payload: {
            institutionName: string, facultyName: string, researcherNames: string, sources: InvestigationDataSource[]
        }): Promise<string> {
            const researchers = payload.researcherNames.split("\n").map(it => it.trim())
            const personInvestigation = await investigationsService.createFacultyWorks(
                payload.institutionName, payload.facultyName, researchers,
                payload.sources.includes("academic publications"), payload.sources.includes("web pages"))
            return personInvestigation.id
        },

        async createResearcherWorksInvestigation(context: ActionContext<InvestigationsState, RootState>, payload: {
            instituteName: string, facultyName: string, researcherName: string, sources: InvestigationDataSource[]
        }): Promise<string> {
            const personInvestigation = await investigationsService.createResearcherWorks(
                payload.instituteName, payload.facultyName, payload.researcherName,
                payload.sources.includes("academic publications"), payload.sources.includes("web pages"))
            return personInvestigation.id
        },

        async createBatchPersonInvestigations(context: ActionContext<InvestigationsState, RootState>, payload: File): Promise<void> {
            await investigationsService.createPersonBatch(payload)
        },

        async createBatchAccuracyReport(context: ActionContext<InvestigationsState, RootState>, payload: File): Promise<string> {
            return await adminService.createBatchAccuracyReport(payload)
        },

        async deleteAllPendingInvestigations(context: ActionContext<InvestigationsState, RootState>): Promise<void> {
            await investigationsService.deleteAllPendingInvestigations()
        },

        async restartFailedInvestigations(context: ActionContext<InvestigationsState, RootState>): Promise<void> {
            await investigationsService.restartFailedInvestigations()
        },

        async updateInvestigation(context: ActionContext<InvestigationsState, RootState>, { id, title, goals, alertsEnabled }: { id: string, title: string, goals?: string, alertsEnabled?: boolean }): Promise<void> {
            await investigationsService.update(id, title, goals, alertsEnabled)
            await fetchAllInvestigations(context, true)
        },

        async deleteInvestigation(context: ActionContext<InvestigationsState, RootState>, id: string): Promise<void> {
            await investigationsService.delete(id)
            await fetchAllInvestigations(context, true)
        },

        async duplicateInvestigation(context: ActionContext<InvestigationsState, RootState>, id: string): Promise<void> {
            await investigationsService.duplicate(id)
            await fetchAllInvestigations(context, true)
        }
    }
}

export default module


const { read, commit, dispatch } = getStoreAccessors<InvestigationsState, RootState>("investigations")

export const getInvestigations = read(module.getters.getInvestigations)
export const isShowAllInvestigations = read(module.getters.isShowAllInvestigations)
export const isBusyFetchingAllInvestigations = read(module.getters.isBusyFetchingAllInvestigations)
export const getFreeTextFilter = read(module.getters.getFreeTextFilter)

export const toggleShowAllInvestigations = commit(module.mutations.toggleShowAllInvestigations)
export const setFreeTextFilter = commit(module.mutations.setFreeTextFilter)

export const fetchAllInvestigations = dispatch(module.actions.fetchAllInvestigations)
export const createTopicInvestigation = dispatch(module.actions.createTopicInvestigation)
export const createPersonInvestigation = dispatch(module.actions.createPersonInvestigation)
export const createPersonGroupInvestigation = dispatch(module.actions.createPersonGroupInvestigation)
export const createUniversityInvestigation = dispatch(module.actions.createUniversityInvestigation)
export const createWebsiteInvestigation = dispatch(module.actions.createWebsiteInvestigation)
export const createTenderInvestigation = dispatch(module.actions.createTenderInvestigation)
export const createCompanyInvestigation = dispatch(module.actions.createCompanyInvestigation)
export const createFacultyWorksInvestigation = dispatch(module.actions.createFacultyWorksInvestigation)
export const createResearcherWorksInvestigation = dispatch(module.actions.createResearcherWorksInvestigation)
export const createBatchPersonInvestigations = dispatch(module.actions.createBatchPersonInvestigations)
export const createBatchAccuracyReport = dispatch(module.actions.createBatchAccuracyReport)
export const deleteAllPendingInvestigations = dispatch(module.actions.deleteAllPendingInvestigations)
export const restartFailedInvestigations = dispatch(module.actions.restartFailedInvestigations)
export const updateInvestigation = dispatch(module.actions.updateInvestigation)

export const deleteInvestigation = dispatch(module.actions.deleteInvestigation)
export const duplicateInvestigation = dispatch(module.actions.duplicateInvestigation)

const setInvestigations = commit(module.mutations.setInvestigations)
const setBusyFetchingAllInvestigations = commit(module.mutations.setBusyFetchingAllInvestigations)
