























import Vue from "vue"
import Component from "vue-class-component"
import GenericDialog from "@/components/GenericDialog.vue"
import {ObservationSectionEntity, renameObservationSection} from "@/store/investigation/observation-module"

@Component({
      components: {GenericDialog}
  })
  export default class RenameObservationSectionDialog extends Vue {

      private isOpen = false
      private section: ObservationSectionEntity | null = null


      public open(options: {section: ObservationSectionEntity}) {
          this.isOpen = true
          this.section = {...options.section}
          document.addEventListener("keyup", this.handleKey)

          Vue.nextTick(() => {
              const sectionNameText = this.$refs.sectionNameText as HTMLInputElement
              sectionNameText.focus()
          })
      }


      private close() {
          this.isOpen = false
          document.removeEventListener("keyup", this.handleKey)
      }


      private handleKey(e: KeyboardEvent) {
          switch (e.key) {
              case "Escape":
                  this.close()
                  break
              case "Enter":
                this.renameGroup()
                break
          }
      }

      private async renameGroup() {
          this.close()
          await renameObservationSection(this.$store, this.section!!)
      }
  }
