

















import Vue from "vue"
import Component from "vue-class-component"
import {getInvestigationCreationType, InvestigationCreationType} from "@/types/new-investigation"
import NewTopicInvestigationDetails from "@/views/investigations/NewTopicInvestigationDetails.vue"
import NewPersonInvestigationDetails from "@/views/investigations/NewPersonInvestigationDetails.vue"
import NewPersonGroupInvestigationDetails from "@/views/investigations/NewPersonGroupInvestigationDetails.vue"
import NewPoliticalInvestigationDetails from "@/views/investigations/NewPoliticalInvestigationDetails.vue"
import NewUniversityInvestigationDetails from "@/views/investigations/NewUniversityInvestigationDetails.vue"
import NewWebsiteInvestigationDetails from "@/views/investigations/NewWebsiteInvestigationDetails.vue"
import NewTenderInvestigationDetails from "@/views/investigations/NewTenderInvestigationDetails.vue"
import NewCompanyInvestigationDetails from "@/views/investigations/NewCompanyInvestigationDetails.vue"
import NewResearcherWorksInvestigationDetails from "@/views/investigations/NewResearcherWorksInvestigationDetails.vue"
import NewFacultyWorksInvestigationDetails from "@/views/investigations/NewFacultyWorksInvestigationDetails.vue"
import {InvestigationSubType} from "@/types/investigations"
import {isFacultyWorksUser} from "@/store/auth-module"


export interface NewInvestigationDetails {

    createInvestigation(): void
}


@Component({
    components: {
        NewTopicInvestigationDetails,
        NewPersonInvestigationDetails,
        NewPersonGroupInvestigationDetails,
        NewPoliticalInvestigationDetails,
        NewUniversityInvestigationDetails,
        NewWebsiteInvestigationDetails,
        NewTenderInvestigationDetails,
        NewCompanyInvestigationDetails,
        NewFacultyWorksInvestigationDetails,
        NewResearcherWorksInvestigationDetails
    }
})
export default class NewInvestigationPage extends Vue {

    private creationType: InvestigationCreationType | null = null
    private detailsComponent: string = ""
    private nextEnabled = false
    private buttonText = "Next"
    private isFacultyWorksUser = isFacultyWorksUser

    private beforeMount() {
        const investigationCreationType = this.$router.currentRoute.query.type as InvestigationSubType

        this.creationType = getInvestigationCreationType(investigationCreationType)!

        if (this.creationType.type === "Topic") {
            this.detailsComponent = "NewTopicInvestigationDetails"
        } else if (this.creationType.type === "Person") {
            this.detailsComponent = "NewPersonInvestigationDetails"
        } else if (this.creationType.type === "PersonGroup") {
            this.detailsComponent = "NewPersonGroupInvestigationDetails"
        } else if (this.creationType.type === "University") {
            this.detailsComponent = "NewUniversityInvestigationDetails"
        } else if (this.creationType.type === "Political") {
            this.detailsComponent = "NewPoliticalInvestigationDetails"
        } else if (this.creationType.type === "WebSite") {
            this.detailsComponent = "NewWebsiteInvestigationDetails"
        } else if (this.creationType.type === "Tender") {
            this.detailsComponent = "NewTenderInvestigationDetails"
        } else if (this.creationType.type === "Company") {
            this.detailsComponent = "NewCompanyInvestigationDetails"
        } else if (this.creationType.type === "FacultyWorks") {
            this.detailsComponent = "NewFacultyWorksInvestigationDetails"
        } else if (this.creationType.type === "ResearcherWorks") {
            this.detailsComponent = "NewResearcherWorksInvestigationDetails"
        }
    }


    private createInvestigation() {
        const details = this.$refs.details as unknown as NewInvestigationDetails
        this.buttonText = "Working..."
        this.nextEnabled = false
        details.createInvestigation()
    }


    private cancel() {
        this.$router.push({name: "investigations"})
    }


    private nextEvent(enable: boolean) {
        this.nextEnabled = enable
    }
}
