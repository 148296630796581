
























import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"
import AuthorSummaryPane from "@/views/investigation/panes/AuthorSummaryPane.vue"
import {DocumentEntity, DocumentObservationInfo, GraphObservationInfo, ObservationInfo, ObservationType, PersonObservationInfo, PersonSummaryEntity} from "@/types/investigations"
import {ObservationEntity} from "@/store/investigation/observation-module"
import DocumentItem from "@/views/investigation/panes/DocumentItem.vue"
import {getDocuments} from "@/store/investigation/documents-module"
import moment from "moment"
import GraphImage from "@/views/investigation/graphs/GraphImage.vue"

@Component({
    components: {GraphImage, DocumentItem, AuthorSummaryPane}
})
export default class ReferencedEntityPreview extends Vue {
    @Prop()
    // @ts-ignore
    private observation: ObservationEntity

    @Prop()
    // @ts-ignore
    private type: ObservationType

    @Prop()
    // @ts-ignore
    private referencedEntity: string

    @Prop()
    private scroll!: boolean

    private get imageId(): string | null {
        return this.info ? (this.info as PersonObservationInfo).imageId : null
    }

    private get summary(): PersonSummaryEntity | null {
        return this.info ? (this.info as PersonObservationInfo).summary : null
    }

    private get imageKey(): string | null {
        return this.info ? (this.info as GraphObservationInfo).imageKey : null
    }

    private get info(): ObservationInfo | undefined {
        if (!this.observation) {
            return undefined
        }
        return this.observation.info
    }

    private get document(): DocumentEntity {
        if (this.observation && this.observation.type === "Document") {
            const documentObservationInfo = {...this.observation.info!} as DocumentObservationInfo
            if (documentObservationInfo.date) {
                documentObservationInfo.date = moment(documentObservationInfo.date)
            }
            return documentObservationInfo as unknown as DocumentEntity
        }
        const documents = getDocuments(this.$store)
        return documents.find(document => document.id === this.referencedEntity)!
    }

    private get graphImage(): GraphObservationInfo | null {
        if (this.observation && this.observation.type === "Graph") {
            return this.observation.info as GraphObservationInfo
        }
        return null
    }


    private get previewStyle() {
        const {scroll} = this
        return {
            border: scroll ? "1px solid #E7E7E7" : "0",
            padding: scroll ? (this.type === "Graph" ? "0" : "1rem") : "0",
            "white-space": "pre-wrap"
        }
    }
}
