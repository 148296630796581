import axios from "axios"
import {InvestigationConfigurationEntity, BWJournal} from "@/types/investigations"

export const configurationService = {

    async getConfiguration(investigationId: string): Promise<InvestigationConfigurationEntity> {
        const response = await axios.get(`/api/investigations/${investigationId}/configuration`)
        return response.data as InvestigationConfigurationEntity
    },

    async setConfiguration(investigationId: string, configuration: InvestigationConfigurationEntity): Promise<string> {
        const response = await axios.post(`/api/investigations/${investigationId}/configuration`, configuration)
        return response.data as string
    },

    async setJournalBWList(investigationId: string, journalInfo: BWJournal): Promise<string> {
        const response = await axios.post(`/api/investigations/${investigationId}/configuration/bwjournal`, journalInfo)
        return response.data as string
    },

    async addActiveAuthor(investigationId: string, activeAuthor: string): Promise<string> {
        const response = await axios.post(`/api/investigations/${investigationId}/configuration/active-author`, activeAuthor)
        return response.data as string
    },

    async fetchEntireDictionary(dictionaryId: string): Promise<Blob> {
        const response = await axios.get(`/api/lexeme/${dictionaryId}`)
        return new Blob([response.data])
    }
}
