import Vue from "vue"
import Component from "vue-class-component"
import {getInvestigation, getIsInProfile, getIsInReviewProfile} from "@/store/investigation/investigation-module"
import {InvestigationEntity, InvestigationSubType, InvestigationType} from "@/types/investigations"

@Component
export class InvestigationMixin extends Vue {

    get isInInvestigationsPage(): boolean {
        return this.$route.name === "investigations"
    }

    get investigation(): InvestigationEntity | null {
        return getInvestigation(this.$store)
    }

    get investigationType(): InvestigationType {
        return this.investigation!.investigationType
    }

    get investigationSubType(): InvestigationSubType {
        return this.investigation!.investigationSubType
    }

    get isInTopicInvestigation(): boolean {
        return !!this.investigation && this.investigationType === "Topic"
    }

    get isInPersonInvestigation(): boolean {
        return !!this.investigation && this.investigationType === "Person"
    }

    get isInPersonProfile(): boolean {
        return getIsInProfile(this.$store)
    }

    get isInReviewProfile(): boolean {
        return getIsInReviewProfile(this.$store)
    }
}
