




























import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import SplitPane from "@/components/SplittedPane.vue"
import TopsPane from "@/views/investigation/panes/TopsPane.vue"
import AuthorSummaryPane from "@/views/investigation/panes/AuthorSummaryPane.vue"
import {getIsBusy, getIsInReviewProfile, getPersonForRightPaneSummary, setDisplayedResultsStatus, setIsInProfile} from "@/store/investigation/investigation-module"
import {setPersonId} from "@/store/investigation/person-filter-module"
import DocumentsPane from "@/views/investigation/panes/DocumentsPane.vue"
import {RawLocation, Route} from "vue-router"
import {InvestigationMixin} from "@/store/investigation-mixin"
import SpinningLoader from "@/components/SpinningLoader.vue"

@Component({
    components: {SplitPane, TopsPane, AuthorSummaryPane, SpinningLoader}
})
export default class PersonProfile extends mixins(Vue, InvestigationMixin) {

    private isShowLeftPane = false

    private get personForRightPaneSummary() {
        return getPersonForRightPaneSummary(this.$store)
    }

    private get isSummaryInRightPane(): boolean {
        return this.personForRightPaneSummary != null
    }

    private get personId(): string {
        return this.$route.params.personId
    }

    private beforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false | ((vm: PersonProfile) => any) | void) => void) {
        next(vm => {
            vm.isShowLeftPane = to.path.endsWith("documents") || getIsInReviewProfile(vm.$store)
        })
    }

    private beforeRouteUpdate(to: Route, from: Route, next: (to?: RawLocation | false | ((vm: PersonProfile) => any) | void) => void) {
        this.isShowLeftPane = to.path.endsWith("documents")
        next()
    }

    private async beforeCreate() {
        setDisplayedResultsStatus(this.$store, "All")
        setIsInProfile(this.$store, true)
        setPersonId(this.$store, this.$route.params.personId)
    }

    private documentsUpdated() {
        const documentContainer = this.$refs.documentsContainer as HTMLDivElement
        documentContainer.scrollTop = 0
    }

    private refreshContent() {
        const documentsPane = this.$refs.content as DocumentsPane
        documentsPane.refreshResults()
    }

    private get isBusy(): boolean {
        return getIsBusy(this.$store)
    }
}
