import {decreaseActiveRequests, increaseActiveRequests} from "@/store/investigation/investigation-module"
import {Store} from "vuex"
import {RootState} from "@/store"

export function copyToClipboard(text: string) {
    const textArea = document.createElement("textarea")
    textArea.value = text
    const body = document.querySelector("body")!
    body.appendChild(textArea)
    textArea.select()
    document.execCommand("copy")
    body.removeChild(textArea)
}


export function downloadBlob(blob: Blob | undefined, fileName: string) {
    if (blob) {
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = url

        link.setAttribute("download", fileName)
        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
    }
}


export function blobToFile(theBlob: Blob, fileName: string): File {
    const b: any = theBlob
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date()
    b.name = fileName
    return theBlob as File
}


export async function wrapActiveRequests(store: Store<RootState>, code: () => {}) {
    increaseActiveRequests(store)
    try {
        await code()
    } finally {
        decreaseActiveRequests(store)
    }
}


export function capitalize(text: string): string {
    return text.toLowerCase().replace(/^\w/, c => c.toUpperCase())
}
