import {Moment} from "moment"


export type InvestigationStatus = "PENDING" | "PROCESSING" | "READY" | "FAILED"


export interface InvestigationEntity {
    readonly id: string
    readonly uuid: string
    readonly title: string
    readonly initialSearchString: string
    readonly investigationType: InvestigationType
    readonly investigationSubType: InvestigationSubType
    readonly goals?: string
    readonly summary?: string
    readonly createdOn: Moment
    readonly age: number
    readonly createdBy: string
    readonly alertsEnabled: boolean
    readonly status: InvestigationStatus
    readonly lastUserInteractionTime?: Moment
    readonly configuration: InvestigationConfigurationEntity
    readonly email: string
    readonly totalDocuments: number
}

export class PersonSummaryEntity {
    constructor(
        public personName: string,
        public terms: MarkedUpText[],
        public recentAffiliations: RecentAffiliation[],
        public bibliographicDetails: BibliographicDetails,
        public documents: SummaryDocument[],
        public aliasNames: string[],
        public manuallyMerged: boolean,
        public universityProfileUrl: string,
        public lastReviewTime: Moment | null
    ) {}
}

export interface EntitySummaryEntity {
        readonly entity: string,
        readonly entityType: string,
        readonly documents: EntitySummaryDocument[]
}

export interface EntityRelationshipSummaryEntity {
        readonly firstEntity: MentionedEntity,
        readonly secondEntity: MentionedEntity,
        readonly documents: EntitySummaryDocument[]
}


export class ObservationInfo {}


export class PersonObservationInfo extends ObservationInfo {
    constructor(public summary: PersonSummaryEntity,
                public imageId: string) {
        super()
    }
}


export class DocumentObservationInfo extends ObservationInfo {
    constructor(public document: DocumentEntity,
                public id: string,
                public url: string,
                public fullTextUrl: string,
                public title: MarkedUpText,
                public abstract: MarkedUpText,
                public type: string,
                public classification: string,
                public authors: string[],
                public labels: string[],
                public touched: boolean,
                public citationNumber?: number,
                public originalLanguage?: string,
                public relevancy?: Relevancy,
                public starred?: boolean,
                public note?: string,
                public journal?: string,
                public date?: Moment) {
        super()
    }
}

export class GraphObservationInfo extends ObservationInfo {
    constructor(public dataUrl: string,
                public imageKey: string) {
        super()
    }
}


export interface DocumentStatusEntity {
    readonly id: string
    readonly relevancy?: Relevancy
    readonly starred?: boolean
    readonly note?: string
    readonly touched: boolean
}


export interface TopExpansion {
    readonly type: string
    readonly isExpanded: boolean
}


export interface GroupExpansion {
    readonly id: string
    readonly isExpanded: boolean
}


export interface TopSort {
    readonly type: string
    readonly isSortedByRelevant: boolean
}


export interface DocumentEntity extends DocumentStatusEntity {
    id: string
    url: string
    fullTextUrl: string | null
    title: MarkedUpText
    abstract: MarkedUpText
    readonly type: string
    classification: string
    volume: string | null
    issue: string | null
    firstPage: string | null
    lastPage: string | null
    publisher: string | null
    publicationType?: string
    harvesterType: HarvesterType
    doi: string | null
    date?: Moment | null
    datePrecision?: string
    terms: MarkedUpText[]
    authors: InvestigationAuthor[]
    keywords: MarkedUpText[]
    snippets: MarkedUpText
    journal?: string | null
    readonly citationNumber?: number
    readonly originalLanguage?: string
    labels: string[]
    journalImage: boolean
    coverImageId: string | null
    abstractImageId: string | null
    coverImageSourceUrl: string | null
    abstractImageSourceUrl: string | null
    readonly byTheResearchers?: boolean | null
}


export interface InvestigationAuthor {
    readonly id: string
    readonly name: string
    readonly affiliations: string[]
    readonly imageId: string | null
    readonly universityProfileUrl: string | null
    readonly lastReviewTime: Moment | null
}


export interface DocumentMarkupsEntity extends DocumentStatusEntity {
    readonly id: string
    readonly title: MarkedUpText
    readonly abstract: MarkedUpText
    readonly snippets: MarkedUpText
    readonly terms: MarkedUpText[]
    readonly keywords: MarkedUpText[]
}

export interface DocumentOverwrites {
    title?: string
    text?: string | null
    volume?: string | null
    issue?: string | null
    firstPage?: string | null
    lastPage?: string | null
    publisher?: string | null
    doi?: string | null
    fullTextUrl?: string | null
    publishedIn?: string | null
    publicationType?: string | null
    date?: string | null
    url?: string
    authors?: DocumentAuthorOverwrite[]
}


export interface DocumentAuthorOverwrite {
    readonly id: string,
    readonly name: string,
    readonly affiliations: string[],
    readonly imageId: string | null,
    readonly universityProfileUrl: string | null,
    readonly previousPosition?: number
    readonly lastReviewTime: Moment | null
}


export interface InvestigationDataEntity {
    readonly documents: DocumentEntity[]
    readonly tops: Tops
    readonly documentCounts: DocumentCounts
    readonly dateHistogram: DateHistogram
    readonly availableDocumentIds: string[]
    readonly labels: string[]
}


export interface TopicInvestigationDataEntity extends InvestigationDataEntity {
    readonly terms: { active: InvestigationTermEntity[], suggested: string[] }
    readonly termGroups: InvestigationTermGroupEntity[]
}


export interface InvestigationTermGroupEntity {
    readonly id: string
    readonly name: string
    readonly color: string
    readonly modifier: TermGroupModifier
}


export interface PersonInvestigationDataEntity extends InvestigationDataEntity {
    readonly persons: PersonEntity[]
    readonly personRelevantCounts: PersonRelevantCounts
    readonly matchingResearcherIds: MatchingResearcherIds
    readonly bwJournals: BWJournal[]
    readonly activeAuthorIds: string[]
}

export interface PersonRelevantCounts {
    [id: string]: number
}

export interface MatchingResearcherIds {
    [id: string]: string
}

export interface InvestigationGraphDataEntity {
    readonly graph: GraphEntity
    readonly tops: Tops
    readonly dateHistogram: DateHistogram
    readonly termGroups?: InvestigationTermGroupEntity[]
    readonly labels: string[]
    readonly terms: { active: InvestigationTermEntity[], suggested: string[] }
}

export interface DocumentCounts {
    readonly totalDocumentCount: number
    readonly relevantDocumentCount: number
}

export interface Tops {
    readonly topAuthors: ScoredAuthorEntity[]
    readonly topJournals: ScoredNameEntity[]
    readonly topConferences: ScoredNameEntity[]
    readonly topInstitutions: ScoredAffiliationEntity[]
    readonly topRegions: ScoredRegionEntity[]
    readonly topAffiliationGeographies: ScoredNameEntity[]
    readonly topEmails: ScoredNameEntity[]
    topClassifications: ScoredNameEntity[]
    readonly topPersons: ScoredNameEntity[]
    readonly topOrganizations: ScoredNameEntity[]
    readonly topDomains: ScoredNameEntity[]
    readonly topLanguages: ScoredNameEntity[]
    topLabels: ScoredNameEntity[]
    readonly topLexemes: TopLexemeEntity[]
    readonly topTerms: ScoredNameEntity[]
    readonly topLocations: ScoredNameEntity[]
    readonly topConcepts: ScoredNameEntity[]
}

export interface MarkedUpText {
    text: string,
    markups: TextMarkup[]
}

export interface TextMarkup {
    startIndex: number,
    endIndex: number,
    priority: number,
    mandatory: boolean,
    entities: boolean
}

export interface ScoredNameEntity {
    name: string
    totalScore: number
    relevantScore: number
    totalCount: number
    relevantCount: number
}

export interface SummaryDocument {
    readonly id: string
    readonly title: string
    readonly url: string
}

export interface EntitySummaryDocument {
    readonly id: string
    readonly url: string
    readonly title: string
    readonly markedUpTitle: MarkedUpText
    readonly excerpts: MarkedUpText[]
}


export interface ScoredAuthorEntity {
    readonly author: TopAuthorEntity
    readonly totalScore: number
    readonly relevantScore: number
    readonly totalCount: number
    readonly relevantCount: number
}


export interface ScoredAffiliationEntity {
    readonly affiliation: TopAffiliationEntity
    readonly totalScore: number
    readonly relevantScore: number
    readonly totalCount: number
    readonly relevantCount: number
}


export interface ScoredRegionEntity {
    readonly region: TopRegionEntity
    readonly totalScore: number
    readonly relevantScore: number
    readonly totalCount: number
    readonly relevantCount: number
}


export interface TopAuthorEntity {
    readonly id: string
    readonly name: string
}


export interface TopAffiliationEntity {
    readonly name: string
    readonly title: string
}


export interface TopRegionEntity {
    readonly name: string
    readonly code: number
}


export interface TopLexemeEntity {
    readonly name: string
    readonly displayName: string
    readonly lexemeScores: ScoredNameEntity[]
}


export interface InvestigationTermEntity {
    readonly term: string
    readonly modifier: TermModifier
    readonly group: string
}


export interface RGB {
    red: number,
    green: number,
    blue: number
}

export interface ColorCode { [key: number]: string}

export interface PersonEntity {
    id: string,
    name: string,
    relevancy: Relevancy,
    documentsCount: number
    filteredOut: boolean
}

export class MentionedEntity {
    constructor(public name: string, public type: string, public labels: string[]) {}
}

export interface PersonVote {
    id: string,
    relevancy: Relevancy
}


export interface RecentAffiliation {
    readonly affiliation: string
    readonly title?: string
    readonly fromYear: number
    readonly toYear: number
}


export interface BibliographicDetails {
    readonly articles: number,
    readonly authors: number,
    readonly books: number,
    readonly bookChapters: number
    readonly patents: number
}



export class DateHistogram {
    constructor(public bars: DateHistogramBar[] = []) {
    }
}


export class DateHistogramBar {
    constructor(public year: number, public totalCount: number, public relevantCount: number, public totalScore: number, public relevantScore: number) {
    }
}


export class BarGraphModel {
    constructor(public title: string, public type: string, public bars: BarModel[]) { }
}


export class BarModel {
    constructor(public caption: string,
                public totalPercentage: number,
                public relevantPercentage: number,
                public totalCount: number,
                public relevantCount: number,
                public title: string,
                public value: string) { }
}

export class RegionBarModel extends BarModel {
    constructor(public caption: string,
                public totalPercentage: number,
                public relevantPercentage: number,
                public totalCount: number,
                public relevantCount: number,
                public title: string,
                public code: number,
                public value: string) {
        super(caption, totalPercentage, relevantPercentage, totalCount, relevantCount, title, value)
    }
}

export interface InvestigationRequestSpec {
    dateFilter: DateFilter,
    displayedResultsStatus: DisplayedResultsStatus,
    selectedTops: SelectedTop[],
    includeOnlyUnlabeled: boolean,
    includeSingleAuthoredDocuments: boolean,
    personId?: string | null,
    personName?: string | null,
    freeText?: string | null,
    sortDocumentsBy: string,
    requestedDocumentIds: string[],
    highlightMode: string[],
    onlyInvestigationResearchers: boolean,
    onlyDuplicateDocuments: boolean,
    onlyUnaffiliated: boolean,
    onlyUnknownPublishers: boolean,
    onlyMissingVolume: boolean,
    onlyMissingDate: boolean,
    specificDocumentUrls: string[],
}


export interface DateFilter {
    startYear: number | null,
    endYear: number | null
}

export interface SelectedTop {
    name: string
    selectedDisplayValue: string
    selectedValue: any
}

export type Relevancy = "Relevant" | "Irrelevant" | "Neutral"
export type TermModifier = "Regular" | "Mandatory" | "Boost" | "Penalty" | "Ignored" | "Exclude"
export type TermGroupModifier = "Regular" | "Mandatory"
export type InvestigationType = "Topic" | "Person"
export type InvestigationSubType = "Topic" | "Person" | "PersonGroup" | "WebSite" | "University" | "Political"
    | "Tender" | "Company" | "LocalDocuments" | "ResearcherWorks" | "FacultyWorks"
export type InvestigationLabelType = "Document" | "Entity"
export type ObservationType = "Investigation" | "Person" | "Document" | "Graph"
export type GraphType = "Author" | "Unified" | "Timeline" | null
export type DisplayedResultsStatus = "All" | "Unread" | "Annotated" | "Relevant" | "Irrelevant"
export type MentionedEntityType = "ORGANIZATION" | "PERSON" | "LOCATION" | "CONCEPT" | "TERM"
export type SearchType = "All" | "LastYear" | "Last5Years"
export type FacultyWorksWorkflowStage = "MarkFix" | "Dedup" | "Enrich"
export type HarvesterType = "PUBLISHER" | "MICROSOFT" | "GENERIC" | "UNIVERSAL" | "GOOGLE" | "PDF" | "UNKNOWN"


export interface GraphRequest {
    spec: InvestigationRequestSpec,
    sizeBy?: string,
    colorBy?: string
}

export interface GraphNodeEntity {
    readonly id: string,
    readonly props: { [key: string]: any }
}

export interface GraphEdgeEntity {
    readonly id: string,
    readonly sourceId: string,
    readonly targetId: string,
    readonly props: { [key: string]: any }
}

export interface GraphEntity {
    readonly nodes: GraphNodeEntity[],
    readonly edges: GraphEdgeEntity[]
}

export interface TimelineRequest {
    spec: InvestigationRequestSpec,
}

export interface InvestigationTimelineDataEntity {
    readonly chart: TimelineChartEntity
    readonly tops: Tops
    readonly dateHistogram: DateHistogram,
    readonly terms: { active: InvestigationTermEntity[], suggested: string[] },
    readonly termGroups: InvestigationTermGroupEntity[]
    readonly externalSeries: string[]
}

export interface TimelineChartEntity {
    readonly dataItems: string[][]
    readonly columns: string[]
}

export class TimelineChartData {
    constructor(public chartEntity: TimelineChartEntity, public selectedTermGroups: string[], public selectedTerms: string[], public selectedExternalSeries: string | null) {
    }
}

export interface RelevancyStatusEntity {
    differentEnough: boolean
    relevancyScore: number
}

export interface JournalsEntity {
    blacklist: string[],
    whitelist: string[]
}

export interface BWJournal {
    journal: string
    list: "BLACKLIST" | "WHITELIST" | "NONE"
}

export interface InvestigationConfigurationEntity {
    readonly searchDefinition: InvestigationConfigurationSearchDefinitionEntity,
    readonly topsStatuses: InvestigationConfigurationTopStatus[],
    readonly translation: InvestigationConfigurationTranslationEntity
    readonly graphsSettings: { [key: string]: InvestigationGraphSettingsEntity }
    readonly includeAllDocuments: boolean
    readonly researchers: string[]
    readonly overrideDocumentNumberLimit: boolean
    readonly documentNumberLimit: number
    readonly startDateCutoff: Moment | null
    readonly startDateCutoffEnabled: boolean
    readonly journals: JournalsEntity
    readonly activeAffiliations: string[] | null
    readonly activeAuthors: string[] | null
}


export interface InvestigationConfigurationSearchDefinitionEntity {
    readonly includeWebPages: boolean,
    readonly overrideDefaultMaxResultsPerWebHarvest: boolean,
    readonly maxResultsPerWebHarvest: number,
    readonly webHarvestFromSites: string[],
    readonly includeArticles: boolean,
    readonly overrideDefaultMaxResultsPerArticleHarvest: boolean,
    readonly maxResultsPerArticleHarvest: number,
    readonly articleHarvestFromInstitutions: string[]
}


export interface InvestigationConfigurationTranslationEntity {
    readonly selectedLanguages: string[]
}

export interface InvestigationGraphSettingsEntity {
    readonly totalNumberOfNodes?: number,
    readonly edgeThreshold?: number
}


export interface InvestigationConfigurationTopStatus {
    readonly id: string,
    readonly displayName: string,
    readonly enabled: boolean
    readonly dictionary: boolean
}

export interface PersonSemanticAffinityEntity {
    readonly personId: string,
    readonly semanticAffinity: number
}

export interface AuthorGraphSettings {
    layout: string,
    sizeBy: GraphSetBy,
    colorBy: GraphSetBy,
    filter: string,
    showSnowflakes: boolean,
    personForRightPaneSummary: PersonEntity | null
}

export interface UnifiedGraphSettings {
    layout: string,
    filter: string
}

export interface GraphSetBy {
    option: string,
    personId?: string
}

export interface MainClusterFilterData {
    readonly personId: number,
    readonly displayName: string
}

export interface InvestigationPersonDetails {
    readonly id: string,
    readonly name: string
    readonly universityProfileUrl?: string,
    readonly authorImageId?: string
}

export interface PredefinedTermGroupEntity {
    readonly name: string,
    readonly terms: string[]
}

export interface GenerateHtmlResponse {
    readonly numOfDuplicates?: number,
    readonly failedTitle?: string,
    readonly base64File?: string | null,
}
