





























import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import GenericDialog from "@/components/GenericDialog.vue"
import {addBatchTerms, getTerms, MAX_ALLOWED_TERMS} from "@/store/investigation/investigation-terms-module"
import {getGraphType} from "@/store/investigation/graph-module"
import {PermissionsMixin} from "@/store/user-mixin"
import {InvestigationTermGroupEntity} from "@/types/investigations"

@Component({
    components: {GenericDialog}
})
export default class BatchTermsDialog extends mixins(Vue, PermissionsMixin) {

    private isOpen = false
    private termsText: string = ""
    private terms: string[] = []
    private termGroup: InvestigationTermGroupEntity | null = null
    private includeHarvests: boolean = false
    private error: string | null = null


    private get harvestOnlyTopTermsVisible() {
        return false // this.includeHarvests && this.terms.length > 15
    }

    public open(batchOptions: { termGroup: InvestigationTermGroupEntity }) {
        this.isOpen = true
        this.termsText = ""
        this.terms = []
        this.termGroup = batchOptions.termGroup
        this.includeHarvests = true
        document.addEventListener("keyup", this.handleKey)

        Vue.nextTick(() => {
            const termsText = this.$refs.terms as HTMLInputElement
            termsText.focus()
        })
    }


    private close() {
        this.isOpen = false
        document.removeEventListener("keyup", this.handleKey)
    }


    private handleKey(e: KeyboardEvent) {
        switch (e.key) {
            case "Escape":
                this.close()
                break
        }
    }

    private termsTextChanged() {
        this.terms = this.termsText.split(/[\n,]/).map(term => term.trim()).filter(term => term.length > 0).reverse()

        this.error = null
        if (this.terms.length + getTerms(this.$store).length > MAX_ALLOWED_TERMS) {
            this.error = `Cannot add more than ${MAX_ALLOWED_TERMS} terms`
        }
    }

    private async addTerms() {
        if (this.error) {
            return
        }

        this.close()
        await addBatchTerms(this.$store, {groups: [{name: this.termGroup!!.name, terms: this.terms}], includeHarvests: this.includeHarvests})
        if (getGraphType(this.$store) === null) {
            await this.$parent.$emit("refresh-documents")
        }
    }
}
