
































































































import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import ResearchInterestsPane from "@/views/investigation/panes/ResearchInterestsPane.vue"
import RecentAffiliationsPane from "@/views/investigation/panes/RecentAffiliationsPane.vue"
import SummaryDocumentsPane from "@/views/investigation/panes/SummaryDocumentsPane.vue"
import AliasNamesPane from "@/views/investigation/panes/AliasNamesPane.vue"
import {Prop, Watch} from "vue-property-decorator"
import {
    fetchAllData,
    getAuthorGraphSettings,
    getInvestigation,
    getIsInProfile,
    getPersonForRightPaneSummary,
    getSpecForStore,
    saveInvestigationState,
    setAuthorGraphSettings,
    setDisambiguatePersonName,
    setPersonForRightPaneSummary,
    setTitle,
    updatePersonImage,
    updateLastReviewTime
} from "@/store/investigation/investigation-module"
import {BibliographicDetails, InvestigationPersonDetails, MarkedUpText, PersonSummaryEntity, RecentAffiliation, SummaryDocument} from "@/types/investigations"
import investigationService, {ImageCropData} from "@/api/investigation-service"
import personInvestigationService from "@/api/person-investigation-service"
import {setDisambiguationPersonId} from "@/store/investigation/person-filter-module"
import PopupMenu from "@/components/PopupMenu.vue"
import moment, { Moment } from "moment"
import {copyToClipboard, downloadBlob} from "@/utils/utils"
import TopicInvestigationService from "@/api/topic-investigation-service"
import UpdateEntityImageDialog from "@/views/investigation/dialogs/UpdateEntityImageDialog.vue"
import {isReportEnabled} from "@/store/environment-module"
import {openConfirmationDialog} from "@/views/investigation/dialogs/ConfirmationDialog.vue"
import {PermissionsMixin} from "@/store/user-mixin"
import {openCreateObservationDialog} from "@/views/investigation/dialogs/CreateObservationDialog.vue"
import {openAuthorDetailsDialog} from "@/views/investigation/dialogs/AuthorDetailsDialog.vue"
import {undoMergeMessage} from "@/utils/common-messages"

@Component({
    components: {
        ResearchInterestsPane,
        RecentAffiliationsPane,
        SummaryDocumentsPane,
        AliasNamesPane,
        PopupMenu,
        UpdateEntityImageDialog
    }
})
export default class AuthorSummaryPane extends mixins(Vue, PermissionsMixin) {

    @Prop()
    private personId!: string

    @Prop()
    // @ts-ignore
    private imageId: string

    @Prop()
    // @ts-ignore
    private observationSummary: PersonSummaryEntity

    @Prop()
    private containingParentName!: string

    @Prop()
    private passedPersonName?: string

    @Prop({type: Boolean, default: true})
    private showResearchInterests!: boolean

    @Prop({type: Boolean, default: false})
    private showDocuments!: boolean

    @Prop({type: Boolean, default: true})
    private showAliasNames!: boolean

    @Prop({type: Boolean, default: false})
    private allowImageUpdate!: boolean

    private personName?: string = this.passedPersonName

    private isDataFetched = false
    private shouldShowError = false
    private terms?: MarkedUpText[]
    private documents?: SummaryDocument[]
    private recentAffiliations?: RecentAffiliation[]
    private aliasNames?: string[]
    private bibliographicDetails?: BibliographicDetails
    private imageLoaded: boolean = false
    private personImageTimestamp: number = new Date().getTime()
    private manuallyMerged: boolean = false
    private universityProfileUrl?: string
    private lastReviewed: Moment | null = null

    get isInPersonProfile(): boolean {
        return getIsInProfile(this.$store)
    }

    private get currentId() {
        return this.personId
    }

    private get isInRightPane() {
        return getPersonForRightPaneSummary(this.$store) != null
    }

    private get containingPane() {
        return this.containingParentName
    }

    private get shouldShowViewProfile() {
        return this.currentId &&
            this.containingParentName !== "documents-pane" &&
            !this.isInPersonProfile && !this.shouldShowError &&
            this.containingParentName !== "observation-preview" &&
            !this.isFacultyWorksUser
    }

    private get shouldShowActionMenu() {
        return !this.isInPersonProfile && !this.shouldShowError
    }

    private get shouldShowHeader() {
        return this.containingParentName === "right-pane"
    }

    private get shouldShowDisambiguate() {
        const investigation = getInvestigation(this.$store)!
        return this.containingParentName !== "documents-pane" && !this.isInPersonProfile && investigation.investigationType === "Person" && !this.shouldShowError
    }

    private get shouldShowWebEnrichment() {
        if (this.shouldShowError) {
            return false
        }
        const investigation = getInvestigation(this.$store)!
        return !this.isInPersonProfile
    }

    private get personImageUrl() {
        const investigation = getInvestigation(this.$store)!
        if (this.observationSummary) {
            return this.imageId ? `/api/images?imageId=${this.imageId}` : ""
        }
        return `/api/investigations/${investigation.investigationType.toLowerCase()}/${investigation.id}/person-details/${this.personId}/image?time=${this.personImageTimestamp}`
    }

    private get isReportEnabled(): boolean {
        return isReportEnabled(this.$store)
    }

    private async mounted() {
        if (getIsInProfile(this.$store) && !this.isInRightPane) {
            setTitle(this.$store, ``)
        }
        if (this.observationSummary) {
            this.personName = this.observationSummary.personName
            this.terms = this.observationSummary.terms
            this.recentAffiliations = this.observationSummary.recentAffiliations
            this.bibliographicDetails = this.observationSummary.bibliographicDetails
            this.documents = this.observationSummary.documents
            this.aliasNames = this.observationSummary.aliasNames
            this.isDataFetched = true
            this.manuallyMerged = this.observationSummary.manuallyMerged
        } else {
            await this.personIdChanged()
        }
    }

    @Watch("currentId")
    private async personIdChanged() {
        const investigation = getInvestigation(this.$store)!
        this.personName = this.passedPersonName
        this.isDataFetched = false
        this.shouldShowError = false
        const termsCount = this.containingParentName === "documents-pane" ? 15 : 25

        const personSummary = await investigationService.getPersonForSummary(investigation.id, investigation.investigationType, this.personId, termsCount, this.personName)
        this.isDataFetched = true
        if (personSummary instanceof PersonSummaryEntity) {
            this.$parent.$emit("personName", personSummary.personName)
            this.personName = personSummary.personName
            this.terms = personSummary.terms
            this.recentAffiliations = personSummary.recentAffiliations
            this.bibliographicDetails = personSummary.bibliographicDetails
            this.documents = personSummary.documents
            this.aliasNames = personSummary.aliasNames
            this.manuallyMerged = personSummary.manuallyMerged
            this.universityProfileUrl = personSummary.universityProfileUrl
            this.lastReviewed = personSummary.lastReviewTime
        } else {
            this.shouldShowError = true
            this.personName = personSummary
            this.manuallyMerged = false
        }
        if (getIsInProfile(this.$store) && !this.isInRightPane) {
            setTitle(this.$store, this.personName)
        }
    }

    private close() {
        setPersonForRightPaneSummary(this.$store, null)
        setAuthorGraphSettings(this.$store, {...getAuthorGraphSettings(this.$store), personForRightPaneSummary: null})
    }

    private async viewProfile() {
        const slashIndex = this.$route.path.lastIndexOf("/")
        window.open(this.$route.fullPath.substring(0, slashIndex) + "/profile/" + this.currentId + "/overview")
    }

    private async createCorrelatedInvestigation() {
        const investigation = getInvestigation(this.$store)!!
        const newInvestigation = await personInvestigationService.createCorrelatedInvestigation(getInvestigation(this.$store)!.id, this.currentId, investigation.investigationType)
        const href = this.$router.resolve({name: "topicInvestigation", params: {id: newInvestigation.id}}).href
        openConfirmationDialog({
            message: `A new web investigation was launched successfully.<br>Click <a href="${href}" target="_blank">here</a> to open it now.`,
            title: "New Web Investigation",
            okText: "Ok",
            onOk: () => {
                return
            },
            showCancel: false
        })
    }

    private async disambiguate() {
        setPersonForRightPaneSummary(this.$store, null)
        setDisambiguationPersonId(this.$store, this.personId)
        setDisambiguatePersonName(this.$store, this.personName!)
        saveInvestigationState(this.$store)
        const inDocuments = this.$route.path.endsWith("/documents")
        this.$router.push({path: "disambiguate"})
        if (inDocuments) {
            await fetchAllData(this.$store)
        }
    }

    private async markReviewed() {
        this.lastReviewed = moment()
        await updateLastReviewTime(this.$store,
            {personId: this.personId, lastReviewTime: this.lastReviewed })
    }

    private openMenu() {
        const menuOpenerButton = this.$refs.menuOpener as HTMLElement
        this.$emit("openMenu", menuOpenerButton)
    }

    private enrich() {
        const investigation = getInvestigation(this.$store)!
        TopicInvestigationService.createPersonHarvests(investigation.id, [this.personName!])
    }

    private async exportToExcel() {
        const investigation = getInvestigation(this.$store)!
        const spec = {...getSpecForStore(this.$store), personId: this.currentId}
        const blob = await investigationService.getPersonProfileExcel(investigation.id, spec, investigation.investigationType)
        const fileName = `${moment().format("YYYY_MM_DD_HH_mm_ss")} - ${this.personName}.xlsx`
        downloadBlob(blob, fileName)
    }

    private formattedLastReview() {
        return !this.lastReviewed ? "---" : this.lastReviewed.format("YYYY-MM-DD HH:mm:ss")
    }

    private reloadImage() {
        const image = document.querySelector(".person-image > img") as HTMLImageElement
        const src = image.src
        Vue.nextTick(() => {
            image.src = src
        })
    }

    private createObservation() {
        openCreateObservationDialog(this, {
            type: "Person",
            referencedEntity: this.personId
        })
    }

    private copyAuthorName() {
        copyToClipboard(this.personName!)
    }

    private async editAuthorDetails() {
        openAuthorDetailsDialog(this, {
            personId: this.currentId,
            personName: this.personName!,
            onUpdateCallback: async (details: InvestigationPersonDetails, imageModified: boolean,
                                     imageData?: ImageCropData, imageUrl?: string) => {
                const personNameModified = this.personName !== details.name
                const investigation = getInvestigation(this.$store)!
                await investigationService.updatePersonDetails(investigation.id, investigation.investigationType, details)
                this.universityProfileUrl = details.universityProfileUrl
                this.personName = details.name
                this.lastReviewed = moment()

                if (imageModified) {
                    await updatePersonImage(this.$store, {personId: this.personId, imageData, imageUrl })
                }

                this.resetImageTimestamp()
                if (personNameModified) {
                    await fetchAllData(this.$store)
                }
            }
        })
    }

    @Watch("personId")
    private resetImageTimestamp() {
        this.personImageTimestamp = new Date().getTime()
    }

    private undoMerge() {
        const performUndoMerge = async () => {
            const investigation = getInvestigation(this.$store)!

            setPersonForRightPaneSummary(this.$store, null)
            setDisambiguationPersonId(this.$store, null)
            await investigationService.undoMerge(investigation.id, investigation.investigationType, [this.personId])
        }

        openConfirmationDialog({
            message: undoMergeMessage(this.personName!),
            title: "Undo Manual Merge",
            okText: "Undo Merge",
            onOk: performUndoMerge,
            showCancel: true
        })
    }

    private openAuthorPages() {
        window.open(`https://www.google.com/search?q=\"${this.personName}\"`, "_blank")

        if (this.recentAffiliations && this.recentAffiliations.length > 0) {
            window.open(`https://www.google.com/search?q=\"${this.personName}\" ${this.recentAffiliations[0].affiliation}`, "_blank")
        }
        window.open(`https://www.google.com/search?q=\"${this.personName}\" linkedin`, "_blank")
        window.open(`https://scholar.google.com/scholar?hl=en&as_sdt=0%2C5&q=\"${this.personName}\"`, "_blank")
    }
}
