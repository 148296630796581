























import Vue from "vue"
import Component from "vue-class-component"
import GenericDialog from "@/components/GenericDialog.vue"
import {ObservationSectionEntity, setObservationSectionIndex, getObservationSections} from "@/store/investigation/observation-module"

@Component({
    components: {GenericDialog}
})
export default class ObservationSectionIndexDialog extends Vue {

    private isOpen = false
    private index: number = -1
    private section: ObservationSectionEntity | null = null

    public open(options: {section: ObservationSectionEntity}) {
        const sections = getObservationSections(this.$store)
        this.isOpen = true
        this.section = options.section
        this.index = sections.findIndex(x => x.id === options.section.id)
        document.addEventListener("keyup", this.handleKey)

        Vue.nextTick(() => {
            const sectionNameText = this.$refs.sectionNameText as HTMLInputElement
            sectionNameText.focus()
        })
    }

    private close() {
        this.isOpen = false
        document.removeEventListener("keyup", this.handleKey)
    }

    private handleKey(e: KeyboardEvent) {
        switch (e.key) {
            case "Escape":
                this.close()
                break
            case "Enter":
                this.setIndex()
                break
        }
    }

    private async setIndex() {
        this.close()
        await setObservationSectionIndex(this.$store, {sectionId: this.section!!.id, newIndex: this.index})
    }
}
