









































































































































































































































import Vue from "vue"
import Component from "vue-class-component"
import KendoEditorWrapper from "@/components/KendoEditorWrapper.vue"
import {Prop} from "vue-property-decorator"
import ReferencedEntityPreview from "@/views/investigation/previews/ReferencedEntityPreview.vue"
import {DocumentAuthorOverwrite, DocumentEntity, DocumentOverwrites} from "@/types/investigations"
import moment from "moment"
import GenericDialogNew, {createDialogMountingPoint} from "@/components/GenericDialogNew.vue"
import {blobToFile} from "@/utils/utils"

export function openEditDocumentDialog(options: EditDocumentDialogOptions) {
    new (Vue.extend(EditDocumentDialog))({propsData: {options}}).$mount(createDialogMountingPoint())
}

export interface EditDocumentDialogOptions {
    readonly doc: DocumentEntity | null
    readonly callback: (
        documentType: string,
        overwrites: DocumentOverwrites | null,
        fullTextFile: File | null,
        coverImage: { modified: boolean, file: File | null, journalImage: boolean },
        abstractImage: { modified: boolean, file: File | null }
    ) => void
}

class DialogPage {
    public title: string = ""
    public enabled: boolean = true
}

let nextFictivePersonId = -1

@Component({
    components: {GenericDialogNew, KendoEditorWrapper, ReferencedEntityPreview}
})
export default class EditDocumentDialog extends Vue {

    private pages: DialogPage[] = [{
        title: "Document Type",
        enabled: true
    }, {
        title: "Details",
        enabled: true
    }, {
        title: "Authors",
        enabled: true
    }, {
        title: "Authors in Bulk",
        enabled: true
    }, {
        title: "Cover Image",
        enabled: true
    }, {
        title: "Abstract Image",
        enabled: true
    }, {
        title: "Debug",
        enabled: true
    }]
    private currentPage: DialogPage = this.pages[0]
    private publicationTypes: string[] = [
        "Journal Article", "Conference Paper", "Preprint", "Book Chapter", "Book", "Edited Volume",
        "Review Article", "Book Review", "PhD Dissertation", "Discussion Paper", "Journal Article (Translation)",
        "Poster", "Presentation", "Video Presentation", "Keynote Lecture", "Webinar",
        "Book Chapter (Reprint)", "Book (Reprint)", "Edited Volume (Reprint)",
        "Custom..."]
    private actualsQuery: string = ""
    private docType: string = "Article"
    private publicationType: string = ""
    private customPublicationType: string = ""
    private title: string = ""
    private abstract: string = ""
    private doi: string | null = null
    private volume: string | null = null
    private issue: string | null = null
    private firstPage: string | null = null
    private lastPage: string | null = null
    private publisher: string | null = null
    private fullTextUrl: string | null = null
    private fullTextUrlFile: File | null = null
    private url: string = ""
    private publishedIn: string | null = null
    private date: string | null = null
    private authors: DocumentAuthorOverwrite[] = []
    private bulkAuthorList: string = ""

    private coverImageId: string | null = null
    private coverImageUrl: string | null = null
    private coverImageFile: File | null = null

    private abstractImageId: string | null = null
    private abstractImageUrl: string | null = null
    private abstractImageFile: File | null = null

    private dragOver = false
    private showingCoverImage = false
    private showingAbstractImage = false
    private coverImageModified = false
    private abstractImageModified = false
    private journalImage: boolean = false
    private errorMessage = ""

    @Prop()
    private options!: EditDocumentDialogOptions

    private customPubTypeOption(): string {
        return this.publicationTypes[this.publicationTypes.length - 1]
    }

    get isBook(): boolean {
        return this.publicationType === "Book" || this.docType === "Book"
    }

    get isOldBook(): boolean {
        return this.docType === "Book"
    }

    get isOldBookChapter(): boolean {
        return this.docType === "Book Chapter"
    }

    get unionType(): boolean {
        return this.docType === "Article"
    }

    get webPage(): boolean {
        return this.docType === "Web Page"
    }

    private mounted() {
        this.coverImageFile = null
        this.abstractImageFile = null
        this.showingCoverImage = false
        this.showingAbstractImage = false
        this.coverImageModified = false
        this.abstractImageModified = false

        if (this.options.doc) {
            this.pages.splice(0, 1)
            this.currentPage = this.pages[0]
            this.selectDocType(this.options.doc.type)

            this.title = this.options.doc.title.text
            this.abstract = this.options.doc.abstract.text || ""
            this.doi = this.options.doc.doi || null
            this.publisher = this.options.doc.publisher || null
            this.volume = this.options.doc.volume || null
            this.issue = this.options.doc.issue || null
            this.firstPage = this.options.doc.firstPage || null
            this.lastPage = this.options.doc.lastPage || null
            this.url = this.options.doc.url
            this.fullTextUrl = this.options.doc.fullTextUrl || null
            this.publishedIn = this.originalPublishedIn() || null
            this.date = this.formattedDocumentDate() || null
            this.authors = this.originalAuthors()
            this.bulkAuthorList = this.authors.map(author => author.name).join("\n")
            this.journalImage = this.options.doc.journalImage
            this.coverImageId = this.options.doc.coverImageId
            this.abstractImageId = this.options.doc.abstractImageId
            if (!this.coverImageId && this.options.doc.coverImageSourceUrl) {
                this.coverImageUrl = this.options.doc.coverImageSourceUrl
            }
            if (!this.abstractImageId && this.options.doc.abstractImageSourceUrl) {
                this.abstractImageUrl = this.options.doc.abstractImageSourceUrl
            }
            // noinspection SqlResolve
            this.actualsQuery = `${this.title}
${this.url}

select * from sne_document where id = ${this.options.doc.id};
select * from sne_actual_document where document_id = ${this.options.doc.id};
select * from sne_document_author where document_id = ${this.options.doc.id};
`

            const givenPubType = this.options.doc.publicationType
            if (givenPubType) {
                if (givenPubType === this.customPubTypeOption()) {
                    // why would anyone do that?
                } else if (this.publicationTypes.includes(givenPubType)) {
                    this.publicationType = givenPubType
                } else {
                    this.publicationType = this.customPubTypeOption()
                    this.customPublicationType = givenPubType
                }
            }

            this.$nextTick(() => {
                if (this.coverImageId) {
                    const image = this.$refs.coverImage as HTMLImageElement
                    image.src = `/api/images?imageId=${this.coverImageId}`
                    this.showingCoverImage = true
                }
                if (this.abstractImageId) {
                    const image = this.$refs.abstractImage as HTMLImageElement
                    image.src = `/api/images?imageId=${this.abstractImageId}`
                    this.showingAbstractImage = true
                }
            })
        } else {
            this.selectDocType("Article")
            this.currentPage = this.pages[0]
        }

        this.attachImagePersistenceHandler(this.$refs.coverImage as HTMLImageElement, blob => {
            this.coverImageFile = blobToFile(blob!!, "cover-image")
        })

        this.attachImagePersistenceHandler(this.$refs.abstractImage as HTMLImageElement, blob => {
            this.abstractImageFile = blobToFile(blob!!, "abstract-image")
        })
    }

    private attachImagePersistenceHandler(img: HTMLImageElement, saveFn: BlobCallback) {
        const c = document.createElement("canvas")
        const ctx = c.getContext("2d")!!
        img.onload = () => {
            c.width = img.naturalWidth
            c.height = img.naturalHeight
            ctx.drawImage(img, 0, 0)
            c.toBlob(saveFn)
        }
        img.crossOrigin = "Anonymous"
    }

    private dialogTitle(): string {
        return this.options.doc ? "Edit" : "Create"
    }

    private page(name: string) {
        return this.pages.find(x => x.title === name)!
    }

    private selectCurrentPage(page: DialogPage) {
        if (page.enabled) {
            this.currentPage = page
        }
    }

    private selectDocType(docType: string) {
        this.docType = docType
        this.publicationType = this.publicationTypes[0]
        const docIsWebPage = docType === "Web Page"
        this.page("Cover Image").enabled = !docIsWebPage
    }

    private close() {
        (this.$refs.genericDialog as GenericDialogNew).close()
    }

    private originalPublishedIn() {
        if (this.options.doc) {
            return this.options.doc.journal
        }
        return ""
    }

    private originalAuthors() {
        if (this.options.doc) {
            return this.options.doc.authors.map((author, previousPosition) => ({
                id: author.id, name: author.name, affiliations: [...author.affiliations], imageId: author.imageId,
                universityProfileUrl: author.universityProfileUrl, lastReviewTime: author.lastReviewTime,
                previousPosition
            }))
        }
        return []
    }

    private validateForm(): string {
        if (this.title.length === 0) {
            return "Title should not be empty"
        }

        if (this.url.length === 0) {
            return "Website URL should not be empty"
        }

        const urlRegex = RegExp("^https?://\\S+$", "i")

        if (!this.url.match(urlRegex)) {
            return "Website URL doesn't conform to a valid URL pattern"
        }

        if (this.unionType && this.fullTextUrl) {
            if (this.fullTextUrlFile == null && this.fullTextUrl.length > 0 && (!this.fullTextUrl.match(urlRegex))) {
                return "Full text URL doesn't conform to a valid URL pattern"
            }
        }

        if (this.date && this.date.length > 0 && !moment(this.date).isValid()) {
            return "Date is not valid"
        }

        if (this.publicationType === this.customPubTypeOption() && this.customPublicationType.trim().length === 0) {
            return "Please fill in the custom publication type"
        }

        if (this.unionType) {
            if (this.authors.some(author => author.name.length === 0)) {
                return "Authors must not have an empty name"
            }
        }

        return ""
    }

    private fetchCoverImageOnPaste(e: ClipboardEvent) {
        if (e.clipboardData) {
            this.fetchCoverImage(e.clipboardData.getData("text/plain"))
        }
    }

    private fetchAbstractImageOnPaste(e: ClipboardEvent) {
        if (e.clipboardData) {
            this.fetchAbstractImage(e.clipboardData.getData("text/plain"))
        }
    }

    private fetchCoverImageClick() {
        this.fetchCoverImage(this.coverImageUrl || "")
    }

    private fetchAbstractImageClick() {
        this.fetchAbstractImage(this.abstractImageUrl || "")
    }

    private fetchCoverImage(url: string) {
        Vue.nextTick(() => {
            this.coverImageFile = null
            const image = this.$refs.coverImage as HTMLImageElement
            image.src = url
            this.showingCoverImage = true
            this.coverImageModified = true
        })
    }

    private fetchAbstractImage(url: string) {
        Vue.nextTick(() => {
            this.abstractImageFile = null
            const image = this.$refs.abstractImage as HTMLImageElement
            image.src = url
            this.showingAbstractImage = true
            this.abstractImageModified = true
        })
    }

    private setAffiliation(event: Event, author: DocumentAuthorOverwrite, aff: string, affIdx: number) {
        author.affiliations[affIdx] = (event.target as HTMLInputElement).value
    }

    private bulkAddAuthors() {
        this.errorMessage = ""
        if (this.bulkAuthorList.length === 0) {
            return
        }
        const stubbedText = `\n${this.bulkAuthorList}\n`
        if (!this.authors.every(author => stubbedText.includes(`\n${author.name}\n`))) {
            this.errorMessage = "As a precaution, bulk authors must contain all names from the authors tab"
            return
        }
        const list = this.bulkAuthorList.split("\n")
        this.authors = list.map((name, idx) => this.nameToAuthor(name, -1 - idx))
        this.selectCurrentPage(this.page("Authors"))
    }

    private save() {
        this.authors.forEach(author => {
            // remove empty affiliations
            const affiliations = author.affiliations.filter(aff => aff.trim().length > 0)
            author.affiliations.splice(0, author.affiliations.length, ...affiliations)
        })

        this.errorMessage = this.validateForm()
        if (this.errorMessage !== "") {
            return
        }

        const overwrites: DocumentOverwrites = {}

        if (!this.options.doc || this.different(this.title, this.options.doc.title.text)) {
            overwrites.title = this.title
        }
        if (!this.options.doc || this.different(this.abstract, this.options.doc.abstract.text)) {
            overwrites.text = this.abstract
        }
        if (!this.options.doc || this.different(this.volume, this.options.doc.volume)) {
            overwrites.volume = this.volume
        }
        if (!this.options.doc || this.different(this.issue, this.options.doc.issue)) {
            overwrites.issue = this.issue
        }
        if (!this.options.doc || this.different(this.firstPage, this.options.doc.firstPage)) {
            overwrites.firstPage = this.firstPage
        }
        if (!this.options.doc || this.different(this.lastPage, this.options.doc.lastPage)) {
            overwrites.lastPage = this.lastPage
        }
        if (!this.options.doc || this.different(this.doi, this.options.doc.doi)) {
            overwrites.doi = this.doi
        }
        if (!this.options.doc || this.different(this.publisher, this.options.doc.publisher)) {
            overwrites.publisher = this.publisher
        }
        if (this.unionType) {
            if (this.publicationType !== this.customPubTypeOption()) {
                if (!this.options.doc || this.different(this.publicationType, this.options.doc.publicationType)) {
                    overwrites.publicationType = this.publicationType
                }
            } else {
                if (!this.options.doc || this.different(this.customPublicationType, this.options.doc.publicationType)) {
                    overwrites.publicationType = this.customPublicationType
                }
            }

            if (this.fullTextUrlFile == null) {
                if (!this.options.doc || this.different(this.fullTextUrl, this.options.doc.fullTextUrl)) {
                    overwrites.fullTextUrl = this.fullTextUrl
                }
            }
        }
        if (!this.options.doc || this.different(this.date, this.formattedDocumentDate())) {
            overwrites.date = this.date
        }
        if (!this.options.doc || this.different(this.url, this.options.doc.url)) {
            overwrites.url = this.url
        }
        if (!this.webPage && this.different(JSON.stringify(this.authors), JSON.stringify(this.originalAuthors()))) {
            overwrites.authors = this.authors
        }
        if (!this.options.doc || this.different(this.publishedIn, this.originalPublishedIn())) {
            overwrites.publishedIn = this.publishedIn
        }

        this.close()

        this.options.callback!(
            this.docType,
            Object.keys(overwrites).length > 0 ? overwrites : null,
            this.fullTextUrlFile,
            { modified: this.coverImageModified, file: this.coverImageFile, journalImage: this.journalImage },
            { modified: this.abstractImageModified, file: this.abstractImageFile }
        )
    }

    private nameToAuthor(name: string, id: number): DocumentAuthorOverwrite {
        const existing = this.authors.filter(author => author.name === name)
        return existing.length === 0
            ? {id: id.toString(), name, affiliations: [], imageId: null, universityProfileUrl: null, lastReviewTime: null}
            : existing.pop() as DocumentAuthorOverwrite
    }

    private different(first: string | null | undefined, second: string | null | undefined): boolean {
        if (!first && !second) {
            return false
        }

        return first !== second
    }

    private formattedDocumentDate(): string {
        if (this.options.doc && this.options.doc.date) {
            if (this.options.doc.datePrecision === "MonthYear") {
                return this.options.doc.date.format("YYYY-MM")
            }

            if (this.options.doc.datePrecision === "DayMonthYear") {
                return this.options.doc.date.format("YYYY-MM-DD")
            }

            return this.options.doc.date.format("YYYY")
        }

        return ""
    }

    private coverFileSelected(e: Event) {
        const input = e.target as HTMLInputElement
        if (input.files) {
            const file = input.files[0]
            this.setFileAsCoverImage(file)
        }
    }

    private abstractFileSelected(e: Event) {
        const input = e.target as HTMLInputElement
        if (input.files) {
            const file = input.files[0]
            this.setFileAsAbstractImage(file)
        }
    }

    private fullTextUrlFileSelected(e: Event) {
        const input = e.target as HTMLInputElement
        if (input.files) {
            const file = input.files[0]
            this.fullTextUrlFile = file
            this.fullTextUrl = file.name
        }
    }

    private dragCoverFile(e: DragEvent) {
        const droppedFiles = e.dataTransfer!.files
        if (!droppedFiles) {
            return
        }
        ([...droppedFiles]).forEach(file => {
            this.setFileAsCoverImage(file)
        })
    }

    private dragAbstractFile(e: DragEvent) {
        const droppedFiles = e.dataTransfer!.files
        if (!droppedFiles) {
            return
        }
        ([...droppedFiles]).forEach(file => {
            this.setFileAsAbstractImage(file)
        })
    }

    private setFileAsCoverImage(file: File) {
        this.coverImageFile = file
        Vue.nextTick(() => {
            const image = this.$refs.coverImage as HTMLImageElement
            image.src = URL.createObjectURL(file)
            this.showingCoverImage = true
            this.coverImageModified = true
        })
    }

    private setFileAsAbstractImage(file: File) {
        this.abstractImageFile = file
        Vue.nextTick(() => {
            const image = this.$refs.abstractImage as HTMLImageElement
            image.src = URL.createObjectURL(file)
            this.showingAbstractImage = true
            this.abstractImageModified = true
        })
    }

    private clearCoverImage() {
        this.coverImageFile = null
        this.showingCoverImage = false
        this.coverImageModified = true
    }

    private clearAbstractImage() {
        this.abstractImageFile = null
        this.showingAbstractImage = false
        this.abstractImageModified = true
    }

    private addAuthor() {
        this.authors.push({
            id: (nextFictivePersonId--).toString(), name: "", affiliations: [], imageId: null,
            universityProfileUrl: null, lastReviewTime: null
        })
        const button = this.$refs.addAuthorButton as HTMLButtonElement
        this.$nextTick(() => {
            const lastAuthor = document.querySelector(".field-input.author:last-child")
            const lastAuthorInput = lastAuthor!!.querySelector("input") as HTMLInputElement
            lastAuthorInput.focus()
            button.scrollIntoView({ behavior: "smooth", block: "center" })
        })
    }

    private deleteAuthor(index: number) {
        this.authors.splice(index, 1)
    }


    private moveAuthor(index: number, direction: number) {
        if ((direction === -1 && index === 0) || (direction === 1 && index === this.authors.length - 1)) {
            return
        }

        const author = this.authors.splice(index, 1)[0]
        this.authors.splice(index + direction, 0, author)
    }


    private wrapTitleInSuperscript() {
        this.title = this.wrapWithTag(this.$refs.title as HTMLTextAreaElement, this.title, "<sup>", "</sup>")
    }


    private wrapTitleInSubscript() {
        this.title = this.wrapWithTag(this.$refs.title as HTMLTextAreaElement, this.title, "<sub>", "</sub>")
    }


    private wrapAbstractInSuperscript() {
        this.abstract = this.wrapWithTag(this.$refs.abstract as HTMLTextAreaElement, this.abstract || "", "<sup>", "</sup>")
    }


    private wrapAbstractInSubscript() {
        this.abstract = this.wrapWithTag(this.$refs.abstract as HTMLTextAreaElement, this.abstract || "", "<sub>", "</sub>")
    }


    private wrapWithTag(inputTag: HTMLTextAreaElement, text: string, openingTag: string, closingTag: string): string {
        const selectionStart = inputTag.selectionStart
        const selectionEnd = inputTag.selectionEnd
        if (selectionStart !== null && selectionEnd !== null) {
            text = [text.slice(0, selectionEnd), closingTag, text.slice(selectionEnd)].join("")
            text = [text.slice(0, selectionStart), openingTag, text.slice(selectionStart)].join("")
            return text
        }
        return text
    }
}
