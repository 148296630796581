























import Vue from "vue"
import Component from "vue-class-component"
import {getInvestigation} from "@/store/investigation/investigation-module"
import {showTermPopupMenu} from "@/store/investigation/dialogs/term-popup-menu-module"
import {copyToClipboard} from "@/utils/utils"
import PopupMenu from "@/components/PopupMenu.vue"
import {Prop} from "vue-property-decorator"
import {MarkedUpText} from "@/types/investigations"

@Component({
        components: {PopupMenu}
    })
    export default class ResearchInterestsPane extends Vue {

        @Prop()
        private containingParentName!: string

        @Prop()
        private terms!: MarkedUpText[]

        @Prop({type: Boolean, default: true})
        private showHeader!: boolean

        private get investigationType() {
            return getInvestigation(this.$store)!.investigationType
        }

        private get mainTerms() {
            return this.terms
        }

        private get containingPane() {
          return this.containingParentName
        }

        private async termClicked(term: string) {
            const termElements = this.$refs.terms as HTMLElement[]
            const termElement = termElements.filter(element => element.innerText === term)[0]
            const {isInObservation} = this
            await showTermPopupMenu(this.$store, { term, termElement, showAddTerm: false, showAddPenalizedTerm: false, investigationType: this.investigationType, centered: false, isInObservation, showAddObservation: true })
        }

        private openMenu() {
            const menuOpenerButton = this.$refs.menuOpener as HTMLElement
            this.$emit("openMenu", menuOpenerButton)
        }

        private copyToClipboard() {
            const allTerms = this.mainTerms.slice().map(term => term.text).join("\n")
            copyToClipboard(allTerms)
        }

        private get isInObservation() {
          return this.containingParentName === "observation-preview"
        }

    }
