import {DateHistogram} from "@/types/investigations"
import {RootState} from ".."
import {getStoreAccessors} from "vuex-typescript"


export interface DateFilterState {
    dateHistogram: DateHistogram
    dateFilterStart: number | null
    dateFilterEnd: number | null
}

const module = {
    getters: {
        getDateHistogram(state: DateFilterState): DateHistogram {
            return state.dateHistogram
        },

        getDateFilterStart(state: DateFilterState): number | null {
            return state.dateFilterStart
        },

        getDateFilterEnd(state: DateFilterState): number | null {
            return state.dateFilterEnd
        }
    },

    mutations: {
        setDateHistogram(state: DateFilterState, dateHistogram: DateHistogram) {
            state.dateHistogram = dateHistogram
        },

        setDateFilterYears(state: DateFilterState, years: { start: number | null, end: number | null }) {
            state.dateFilterStart = years.start
            state.dateFilterEnd = years.end
        }
    }
}

export default module

const { read, commit } = getStoreAccessors<DateFilterState, RootState>("investigation")

export const getDateHistogram = read(module.getters.getDateHistogram)
export const getDateFilterStart = read(module.getters.getDateFilterStart)
export const getDateFilterEnd = read(module.getters.getDateFilterEnd)

export const setDateHistogram = commit(module.mutations.setDateHistogram)
export const setDateFilterYears = commit(module.mutations.setDateFilterYears)
