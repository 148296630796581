































import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"
import {getInvestigation} from "@/store/investigation/investigation-module"
import GenericDialogNew, {createDialogMountingPoint} from "@/components/GenericDialogNew.vue"

export function openUploadDialog(options: UploadFileDialogOptions) {
    const dialogOptions = {title: "Upload File", ...options}
    new (Vue.extend(UploadFileDialog))({propsData: {options: dialogOptions}}).$mount(createDialogMountingPoint())
}

export interface UploadFileDialogOptions {
    readonly title?: string
    readonly handler: (file: File) => void
}

@Component({
    components: {GenericDialogNew}
})
export default class UploadFileDialog extends Vue {

    @Prop()
    private options!: UploadFileDialogOptions

    private dragOver: boolean = false
    private file: File | null = null

    public mount() {
        this.file = null
    }

    private get investigation() {
        return getInvestigation(this.$store)!
    }

    private get okButtonExists() {
        return this.file
    }

    private get fileName() {
        return this.file!.name
    }

    private async ok() {
        if (this.file) {
            this.options.handler(this.file)
        }

        this.close()
    }

    private close() {
        (this.$refs.genericDialog as GenericDialogNew).close()
    }

    private clear() {
        this.file = null
    }

    private fileSelected(e: Event) {
        const input = e.target as HTMLInputElement
        if (input.files) {
            const file = input.files[0]
            this.setFile(file)
        }
    }

    private dragFile(e: DragEvent) {
        const droppedFiles = e.dataTransfer!.files
        if (!droppedFiles) {
            return
        }
        ([...droppedFiles]).forEach(file => {
            this.setFile(file)
        })
    }

    private setFile(file: File) {
        this.file = file
    }
}
