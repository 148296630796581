import { render, staticRenderFns } from "./DocumentExcerpts.vue?vue&type=template&id=9c496386&scoped=true&"
import script from "./DocumentExcerpts.vue?vue&type=script&lang=ts&"
export * from "./DocumentExcerpts.vue?vue&type=script&lang=ts&"
import style0 from "./DocumentExcerpts.vue?vue&type=style&index=0&id=9c496386&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c496386",
  null
  
)

export default component.exports