





























































































































import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import {
    DocumentEntity,
    DocumentMarkupsEntity,
    InvestigationAuthor,
    MarkedUpText,
    PersonEntity
} from "@/types/investigations"
import {Moment} from "moment"
import {Prop, Watch} from "vue-property-decorator"
import TextSelection from "@/directives/TextSelection"
import {showTermPopupMenu} from "@/store/investigation/dialogs/term-popup-menu-module"
import {Optional} from "@/types"
import TextMarkerService from "@/views/investigation/services/TextMarkerService"
import {
    addDocumentLabel,
    deleteDocumentLabel,
    getDocumentMarkups,
    getDocuments,
    getViewModeLevel,
    removeDocumentLabel
} from "@/store/investigation/documents-module"
import {
    fetchTopLabels,
    getActiveAuthorIds,
    getBWJournals,
    getDocumentLabels,
    getMatchingResearcherIds,
    getSelectedTops,
    setPersonForRightPaneSummary,
    setSelectedTops,
    updateBWJournal
} from "@/store/investigation/investigation-module"
import PopupMenu from "@/components/PopupMenu.vue"
import RenameLabelDialog from "@/views/investigation/dialogs/RenameLabelDialog.vue"
import UpdateEntityImageDialog from "@/views/investigation/dialogs/UpdateEntityImageDialog.vue"
import {openConfirmationDialog} from "@/views/investigation/dialogs/ConfirmationDialog.vue"
import {PermissionsMixin} from "@/store/user-mixin"
import DocumentItemHeader from "@/views/investigation/panes/DocumentItemHeader.vue"
import {InvestigationMixin} from "@/store/investigation-mixin"
import DocumentItemReviewHeader from "@/views/investigation/panes/DocumentItemReviewHeader.vue"
import {getSentence} from "@/utils/text-utils"

interface DocumentDetail {
    html: string
    class: string,
    title: string
}

@Component({
    directives: {TextSelection},
    components: {DocumentItemReviewHeader, DocumentItemHeader, PopupMenu, RenameLabelDialog, UpdateEntityImageDialog}
})
export default class DocumentItem extends mixins(Vue, PermissionsMixin, InvestigationMixin) {

    @Prop()
    private document!: DocumentEntity

    @Prop()
    private isEditable!: boolean

    private disableShowLess: boolean = false
    private isAbstractClamped: boolean = false
    private expanded: boolean = false
    private newLabel: string = ""
    private labelContext: string = ""
    private isShowingAllAuthors: boolean = false
    private maxAuthorsToShow: number = 8

    private mounted() {

        if (this.isFacultyWorksUser) {
            this.maxAuthorsToShow = 22
        }

        this.$store.watch(state => getViewModeLevel(this.$store), (newValue, oldValue) => {
            this.expanded = false
        })

        this.$nextTick(() => {
            const abstractSection = this.$refs.abstract as HTMLInputElement
            this.isAbstractClamped = abstractSection ? abstractSection.offsetHeight < abstractSection.scrollHeight : false

            if (this.document.abstract.text === "" && this.document.keywords && this.document.keywords.length > 0) {
                this.disableShowLess = true
            }
        })
    }

    private isActive(author: InvestigationAuthor) {
        return getActiveAuthorIds(this.$store).indexOf(author.id) >= 0
    }

    private getAuthorNameTooltip(author: InvestigationAuthor) {
        let nameFromResearcherList = null
        if (this.isFacultyWorksUser) {
            const personIds = getMatchingResearcherIds(this.$store)
            nameFromResearcherList = personIds[author.id]
        }
        if (!nameFromResearcherList) {
            return author.affiliations.join("; ")
        }
        const matches = nameFromResearcherList === author.name
            ? `Author name is exact` : `Compatible author name: ${nameFromResearcherList}`

        return author.affiliations.length > 0 && matches
            ? `${author.affiliations.join("; ")} ${matches}`
            : author.affiliations.join("; ") || matches
    }

    private getAuthorNameHighlightClass(author: InvestigationAuthor) {
        if (!this.shouldDisplayDocumentAndAuthorImages) {
            return ""
        }
        const cssClasses = []

        if (author.affiliations.length === 0 && this.isFacultyWorksUser) {
            cssClasses.push("affiliation-missing")
        } else if (author.universityProfileUrl || author.imageId) {
            cssClasses.push("profile-url-exists")
        } else if (!!author.lastReviewTime && this.isFacultyWorksUser) {
            cssClasses.push("no-internet-presence")
        }

        if (this.isFacultyWorksUser) {
            const personIds = getMatchingResearcherIds(this.$store)
            if (personIds[author.id]) {
                cssClasses.push("compatible-author")
            }

            if (!this.isActive(author)) {
                cssClasses.push("inactive-author")
            }
        }

        return cssClasses.join(" ")
    }

    get shouldDisplayDocumentAndAuthorImages(): boolean {
        return this.investigationSubType === "Person" || this.investigationSubType === "PersonGroup" ||
            this.investigationSubType === "FacultyWorks" || this.investigationSubType === "ResearcherWorks"
    }

    get shouldDisplayShowMoreButton(): boolean {
        return this.document.authors.length > this.maxAuthorsToShow + 2
    }

    get visibleAuthors(): InvestigationAuthor[] {
        if (!this.shouldDisplayShowMoreButton || this.isShowingAllAuthors ) {
            return this.document.authors
        }
        return this.document.authors.slice(0, this.maxAuthorsToShow)
    }

    get authorsWithImages(): InvestigationAuthor[] {
        return this.document.authors.filter(it => it.imageId)
    }

    get showMoreText(): string {
        return this.isShowingAllAuthors ? "View Less" : `View All ${this.document.authors.length} Authors`
    }

    private get filteredInvestigationLabels(): string[] {
        const allLabels = [...getDocumentLabels(this.$store)]
        allLabels.sort()
        const userLabel = this.newLabel.toLocaleLowerCase()
        const hasUserTypedLabel = userLabel.length > 0
        return allLabels
            .filter(label => hasUserTypedLabel ? label.toLocaleLowerCase().includes(userLabel) : true)
            .sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()))
    }

    private get documentMarkups(): DocumentMarkupsEntity | undefined {
        return getDocumentMarkups(this.$store).find(documentMarkups => documentMarkups.id === this.document.id)
    }

    private get viewModeLevel(): number {
        return this.expanded ? 3 : (this.isEditable ? getViewModeLevel(this.$store) : 1)
    }

    private get displayKeywords() {
        return this.document.keywords.map(this.markText).join(", ")
    }

    private get documentDetails(): DocumentDetail[] {
        const details: DocumentDetail[] = []

        if (this.document.date) {
            const dateHtml = this.formatDate(this.document.date, this.document.datePrecision)
            details.push({class: "date no-wrap", html: dateHtml, title: dateHtml})
        }

        if (this.document.citationNumber && !this.isFacultyWorksUser) {
            const citationHtml = `${this.document.citationNumber} citations`
            details.push({class: "citation-number no-wrap", html: citationHtml, title: citationHtml})
        }

        if (this.document.journal) {
            const lst = getBWJournals(this.$store).get(this.document.journal)
            const bwClass = lst === "WHITELIST" ? "journal-whitelisted" : lst === "BLACKLIST" ? "journal-blacklisted" : "journal-unlisted"
            details.push({class: `published-in ${bwClass}`, html: this.document.journal, title: this.document.journal})
        }

        if (this.isFacultyWorksUser) {
            const volume = this.document.volume || ""
            const issue = !this.document.issue ? "" : `(${this.document.issue})`
            const pages = this.document.firstPage || this.document.lastPage ? `pp. ${this.document.firstPage || ""}-${this.document.lastPage || ""}` : ""
            const doi = !this.document.doi ? "" : `<a href="https://doi.org/${this.document.doi}" target="_blank">DOI</a>`
            const pagesSeparator = (volume || issue) && pages ? ", " : ""
            const doiSeparator = (volume || issue || pages) && doi ? ", " : ""
            const html = `${volume}${issue}${pagesSeparator}${pages}${doiSeparator}${doi}`
            if (html) {
                details.push({html, class: "", title: ""})
            }
        }

        if (this.isFacultyWorksUser && this.viewModeLevel >= 4) {
            const publisher = this.document.publisher || "<missing>"
            details.push({class: this.getPublisherColor(), html: publisher, title: `publisher: ${publisher}
harvester: ${this.document.harvesterType}`})
        }

        if (this.document.type === "Web Page") {
            const urlParts = this.document.url.split("/")

            let boldUrl = urlParts[0] + "//<b>" + urlParts[2] + "</b>"
            for (let i = 3; i < urlParts.length; i++) {
                boldUrl += "/" + urlParts[i]
            }
            boldUrl = `<a href="${this.document.url}" target="_blank">${boldUrl}</a>`

            details.push({class: "web-page-url", html: boldUrl, title: this.document.url})
        }

        return details
    }

    private getPublisherColor(): string {
        switch (this.document.harvesterType) {
            case "PUBLISHER": return "entities-25-text"
            case "GENERIC": return "entities-26-text"
            case "MICROSOFT": return "entities-23-text"
            case "GOOGLE": return "entities-24-text"
            default: return ""
        }
    }

    // ugly and slow, but was quick to implement
    private async toggleAcceptedLabel() {
        const {document, investigationType} = this
        if (document.labels.includes("accepted")) {
            await removeDocumentLabel(this.$store, {document, label: "accepted", investigationType})
        } else {
            const markedFix = document.labels.includes("fix")
            if (markedFix) {
                document.labels = document.labels.filter(x => x !== "fix")
            }
            await addDocumentLabel(this.$store, {document, label: "accepted", investigationType})
            if (markedFix) {
                const success = await removeDocumentLabel(this.$store, {document, label: "fix", investigationType})
                if (!success) {
                    document.labels.push("fix")
                }
            }
        }
        await fetchTopLabels(this.$store)
        this.removeLabelFilter("fix")
        this.removeLabelFilter("accepted")
    }

    private async toggleLabel(label: string) {
        const {document, investigationType} = this
        document.labels.includes(label) ?
            await removeDocumentLabel(this.$store, {document, label, investigationType}) :
            await addDocumentLabel(this.$store, {document, label, investigationType})
        await fetchTopLabels(this.$store)
        this.removeLabelFilter(label)
    }

    private removeLabelFilter(label: string) {
        const documents = getDocuments(this.$store)
        const toLowerCase = (text: string) => text.toLowerCase()
        const documentHasLabel = (document: DocumentEntity) => document.labels.map(toLowerCase).includes(label.toLowerCase())
        const hasAnyDocumentWithLabel = documents.some(documentHasLabel)

        const selectedTops = getSelectedTops(this.$store)
        const hasLabelFilter = selectedTops.some(top => top.name === "predefined.Labels" && top.selectedValue === label)

        if (!hasAnyDocumentWithLabel && hasLabelFilter) {
            this.closeFilter(label)
        }
    }

    private closeFilter(label: string) {
        const newSelectedTops = getSelectedTops(this.$store).filter(activeFilter => activeFilter.selectedValue !== label)
        setSelectedTops(this.$store, newSelectedTops)
        this.$emit("closeLabelsMenu")
        this.$emit("refresh-documents")
    }

    private listJournalAs(list: "WHITELIST" | "BLACKLIST" | "NONE") {
        updateBWJournal(this.$store, {journal: this.document.journal!!, list})
    }

    private async addDocumentLabel() {
        const label = this.newLabel.trim()
        if (label) {
            this.newLabel = ""
            const {document, investigationType} = this
            this.$emit("closeLabelsMenu")
            await addDocumentLabel(this.$store, {document, label, investigationType})
            await fetchTopLabels(this.$store)
        }
    }

    private openLabelsMenu(labelsMenuOpenerButton: HTMLElement) {
        if (this.isEditable) {
            this.$emit("openLabelsMenu", labelsMenuOpenerButton)
            Vue.nextTick(() => {
                const labelText = this.$refs.labelText as HTMLInputElement
                labelText.focus()
            })
        }
    }

    private openJournalColorMenu(button: HTMLElement) {
        this.$nextTick(() => this.$emit("openJournalColorMenu", button))
    }

    private openLabelMenu(button: HTMLElement, label: string) {
        this.labelContext = label
        this.$nextTick(() => this.$emit("openLabelMenu", button))
    }

    private renameLabel() {
        if (this.labelContext !== "") {
            // @ts-ignore
            (this.$refs.renameLabelDialog as RenameLabelDialog).open({
                label: this.labelContext,
                investigationType: this.investigationType,
                labelType: "Document"
            })
            this.$emit("closeLabelMenu")
        }
    }

    private deleteLabel() {
        if (this.labelContext !== "") {
            openConfirmationDialog({
                message: `Are you sure you want to delete label "${this.labelContext}"?<br>This label will be removed from any document it was associated with.`,
                title: "Delete Label",
                okText: "Delete Label",
                onOk: () => this.performDeleteLabel(),
                showCancel: true
            })
            this.$emit("closeLabelMenu")
        }
    }

    private async performDeleteLabel() {
        const label = this.labelContext!!
        const {investigationType} = this
        await deleteDocumentLabel(this.$store, {label, investigationType})
        this.closeFilter(label)
    }

    @Watch("documentMarkups")
    private updateMarkups() {
        if (this.isEditable) {
            const markups = this.documentMarkups
            if (markups) {
                this.document.abstract = markups.abstract
                this.document.title = markups.title
                this.document.keywords = markups.keywords
                this.document.terms = markups.terms
                this.document.snippets = markups.snippets
            }
        }
    }

    private toggleExpansion() {
        if (this.viewModeLevel !== 4) {
            this.expanded = !this.expanded
        }
    }

    private toggleShowAllAuthors() {
        this.isShowingAllAuthors = !this.isShowingAllAuthors
    }

    private setDocumentTouched() {
        if (!this.document.touched && !this.isInPersonProfile) {
            this.$emit("touched-changed")
        }
    }

    private formatDate(date?: Moment, precision?: string): string {
        if (date) {
            if (!precision || precision === "Year") {
                return date.format("YYYY")
            }
            if (precision === "MonthYear") {
                return date.format("MMM YYYY")
            }
            if (precision === "DayMonthYear") {
                return date.format("DD MMM YYYY")
            }

            return date.format("YYYY")
        }

        return ""
    }

    private async textSelected(text: Optional<string>, boundingRect: DOMRect, element: HTMLElement, selection: Selection) {
        if (!this.isInPersonProfile) {
            const documentId = this.document.id
            const sentence = getSentence(element, selection)
            await showTermPopupMenu(this.$store, {term: text, termElement: element, boundingRect, showAddTerm: true, showAddPenalizedTerm: true, investigationType: this.investigationType, documentId, sentence, showAddObservation: true})
        }
    }

    private async termClicked(term: string) {
        const termElements = this.$refs.terms as HTMLElement[]
        const termElement = termElements.filter(element => element.innerText === term)[0]
        const documentId = this.document.id
        await showTermPopupMenu(this.$store, {term, termElement, showAddTerm: true, showAddPenalizedTerm: true, investigationType: this.investigationType, documentId, showAddObservation: true})
    }

    private markText(text: MarkedUpText): string {
        const marker = new TextMarkerService()
        const groupNames = Array.from(Array(20).keys(), i => this.isInPersonProfile ? "" : `group-${i + 1}-text`)
        const entitiesNames = Array.from(Array(10).keys(), i => this.isInPersonProfile ? "" : `entities-${i + 22}-text`)
        return marker.markText(text, ["filter-highlighted-text", ...groupNames, ...entitiesNames])
    }

    private removeNewlines(text: string): string {
        // leave the newlines only in texts that don't contain HTML tags
        return (text.indexOf("<p>") < 0) ? text : text
            .replace("\n", " ")
            .replace(RegExp("(</\\S+?>)\\s+?(<\\S+?>)", "g"), "$1$2")
    }

    private selectAuthor(author: InvestigationAuthor) {
        const person: PersonEntity = {id: author.id, name: author.name, relevancy: "Neutral", documentsCount: 0, filteredOut: false}
        setPersonForRightPaneSummary(this.$store, person)
    }
}
