





















import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"

let veilZIndex = 9000

@Component
export default class GenericDialog extends Vue {

    @Prop(Boolean)
    private modal!: boolean

    @Prop(Boolean)
    private visible!: boolean

    @Prop(Boolean)
    private maximized!: boolean

    @Prop(Boolean)
    private embedded!: boolean

    private componentVeilZIndex: number = veilZIndex += 2

    private onVailClicked() {
        if (!this.modal) {
            this.onClose()
        }
    }


    private onClose() {
        this.$emit("onClose")
    }
}
