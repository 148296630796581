










































































import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import {Watch} from "vue-property-decorator"
import GenericDialog from "@/components/GenericDialog.vue"
import "keylines"
import "./CustomKeylinesChart"
import {Chart, ChartData, ChartOptions, Link, Node, NodeProperties} from "@/keylines"
import {fetchGraphData, getActivityStatus, getInvestigation, getRelevancyStatus, getSelectedNodes, getSelectedTops, getShortestPath, getUnifiedGraphSettings, setEntityForSummary, setEntityRelationshipForSummary, setIsRenderingGraph, setPersonForRightPaneSummary, setSelectedNodes, setSelectedTops, setShortestPath, setUnifiedGraphSettings} from "@/store/investigation/investigation-module"
import SpinningLoader from "@/components/SpinningLoader.vue"
import PopupMenu from "@/components/PopupMenu.vue"
import {capitalize, copyToClipboard} from "@/utils/utils"
import {getGraph, setGraphType} from "@/store/investigation/graph-module"
import {getTermGroups, getTerms, MAX_ALLOWED_TERMS} from "@/store/investigation/investigation-terms-module"
import {graphLayouts, HighlightFilter} from "@/views/investigation/graphs/graph-tools"
import {InvestigationTermEntity, MentionedEntity, MentionedEntityType, RGB, TermModifier} from "@/types/investigations"
import Speedometer from "@/views/investigation/graphs/Speedometer.vue"
import CreateTermTemplate from "@/views/investigation/templates/CreateTermTemplate.vue"
import {configurationService} from "@/api/investigation-configuration-service"
import {getGroupColor} from "@/utils/color-utils"
import NoResults from "@/components/NoResults.vue"
import {openCreateObservationDialog} from "@/views/investigation/dialogs/CreateObservationDialog.vue"
import {openMessageDialog} from "@/components/MessageDialog.vue"
import {openConfirmationDialog} from "@/views/investigation/dialogs/ConfirmationDialog.vue"
import {PermissionsMixin} from "@/store/user-mixin"
import investigationEntityService from "@/api/investigation-entity-service"
import {InvestigationMixin} from "@/store/investigation-mixin"


class VisibleLayer {
    constructor(public name: string, public type: MentionedEntityType, public visible: boolean, public color: string, public cssColor: string | null) {
    }
}

@Component({
    components: {
        NoResults,
        SpinningLoader,
        GenericDialog,
        PopupMenu,
        Speedometer,
        CreateTermTemplate
    }
})
export default class UnifiedGraph extends mixins(Vue, PermissionsMixin, InvestigationMixin) {

    private chart!: Chart
    private chartData: ChartData = {type: "LinkChart", items: []}
    private selectedId: number = 0
    private originalProperties: any[] = []
    private isRenderedByNumberOfDocuments = true
    private harvestIndicatorInterval: any
    private harvestIndicatorRotation: number = 0
    private menuPosition: { left: number, top: number } = {left: 0, top: 0}
    private numberOfNodes: number = 0
    private isHiding: boolean = false
    private isContextMenuOpen: boolean = false
    private mergeEntityMenuTitle: string = ""
    private removeEntityMenuTitle: string = ""
    private isInFullScreen = false
    private imageDataUrl: string = ""
    private newTerm: string = ""
    private disableAddTerm: boolean = false
    private maxEdgeFactor: number = 0
    private minEdgeFactor: number = 0
    private visibleLayers: VisibleLayer[] = []
    private termsListener!: () => void
    private termGroupListener!: () => void
    private disableMerge: boolean = true
    private showAddFilter: boolean = false
    private disableFindShortestPath: boolean = false


    private highlightFilter: HighlightFilter = {
        filter: "",
        current: null,
        list: []
    }

    private get graphData() {
        return getGraph(this.$store)
    }

    private get getTerms(): InvestigationTermEntity[] {
        return getTerms(this.$store)
    }

    private get getTermGroups() {
        return getTermGroups(this.$store)
    }

    private get settings() {
        return getUnifiedGraphSettings(this.$store)
    }

    private get activityIndicator(): number {
        return getActivityStatus(this.$store)
    }

    private get fullScreenText() {
        return this.isInFullScreen ? "Exit Full Screen" : "Enter Full Screen"
    }

    private get resultsDifferenceColor() {
        const percentFade = Math.min(getRelevancyStatus(this.$store).relevancyScore / 100, 1)
        const startRed = 0
        const startGreen = 176
        const startBlue = 255
        const middleRed = 160
        const middleGreen = 109
        const middleBlue = 244
        const endRed = 233
        const endGreen = 30
        const endBlue = 99
        return this.colorGradient(
            percentFade,
            {red: startRed, green: startGreen, blue: startBlue},
            {red: middleRed, green: middleGreen, blue: middleBlue},
            {red: endRed, green: endGreen, blue: endBlue}
        )
    }

    public async mounted() {
        setGraphType(this.$store, "Unified")
        this.clearHighlight()
        this.highlightFilter.filter = this.settings.filter
        await this.fetchData()

        this.$on("closedOncloseGraphContextMenu", this.contextMenuClosed)

        this.harvestIndicatorInterval = setInterval(() => this.harvestIndicatorRotation = this.activityIndicator + (this.activityIndicator > 0 && this.activityIndicator <= 170 ? 10 * Math.random() : 0), 500)

        this.termGroupListener = this.$store.watch(state => getTermGroups(this.$store), (newValue, oldValue) => {
            this.updateTermGroups()
        })
    }

    private beforeDestroy() {
        setEntityForSummary(this.$store, null)
        setEntityRelationshipForSummary(this.$store, null)

        this.isRenderedByNumberOfDocuments = true
        setIsRenderingGraph(this.$store, false)

        if (this.termGroupListener) {
            this.termGroupListener()
        }

        if (this.termsListener) {
            this.termsListener()
        }
    }

    private contextMenuClosed() {
        setEntityForSummary(this.$store, null)
        setEntityRelationshipForSummary(this.$store, null)
        this.isContextMenuOpen = false
    }

    private openViewModeMenu() {
        const viewModeMenuOpenerButton = this.$refs.viewModeMenuOpener as HTMLElement
        this.$emit("openViewModeMenu", viewModeMenuOpenerButton)
    }

    private async showHideItemsBasedOnConfiguration() {
        const investigation = getInvestigation(this.$store)!!
        const configuration = await configurationService.getConfiguration(investigation.id)
        const totalNumberOfNodes = configuration.graphsSettings[`term`].totalNumberOfNodes
        const edgeThreshold = configuration.graphsSettings[`term`].edgeThreshold

        const visibleItemIds: string[] = []
        const invisibleItemIds: string[] = []
        const allNodes: Node[] = []

        this.chartData.items.forEach(item => {
            if (item.type === "node") {
                allNodes.push(item)
            } else if (item.type === "link") {
                if (edgeThreshold == null || (item.w && item.w >= edgeThreshold)) {
                    visibleItemIds.push(item.id)
                } else {
                    invisibleItemIds.push(item.id)
                }
            }
        })

        if (totalNumberOfNodes != null) {
            const allNodesIds: string[] = allNodes.sort((n1, n2) => n2.e! - n1.e!).map(node => node.id)
            allNodesIds.slice(0, totalNumberOfNodes).forEach(node => visibleItemIds.push(node))
            allNodesIds.slice(totalNumberOfNodes, allNodes.length).forEach(node => invisibleItemIds.push(node))
        } else {
            allNodes.map(node => node.id).forEach(node => visibleItemIds.push(node))
        }

        await this.chart.show(visibleItemIds)
        await this.chart.hide(invisibleItemIds)
    }

    private async fetchData() {
        await fetchGraphData(this.$store)
    }

    @Watch("visibleLayers", {deep: true})
    private updateLayers() {
        const visibleTypes = new Set(this.visibleLayers.filter(layer => layer.visible).map(layer => layer.type))

        this.chart.selection([])
        this.chart.foreground(item => true)
        this.chart.filter(item => {
            return visibleTypes.has(item.d.props.type)
        }, {type: "node", time: 200})

        this.handleSelectionChange()
    }


    @Watch("graphData")
    private resetGraph() {
        this.chartData = {type: "LinkChart", items: []}
        this.chart.load(this.chartData)
        this.chartData = this.getChartData()

        this.updateTermGroups()

        if (this.graphData.nodes.length !== 0) {
            this.$nextTick(async () => {
                await this.chart.load(this.chartData)
                await this.showHideItemsBasedOnConfiguration()
                this.calcNumberOfNodes()
                await this.performLayout(this.settings.layout)
                this.chart.selection(getShortestPath(this.$store).length ? getShortestPath(this.$store) : getSelectedNodes(this.$store))

                await this.chart.zoom("fit", {animate: true})
                if (getShortestPath(this.$store).length) {
                    this.drawConnectingPath()
                }

                setIsRenderingGraph(this.$store, false)
            })
        } else {
            setIsRenderingGraph(this.$store, false)
        }
    }

    private toggleFullScreen() {
        const graphContent = this.$refs.graphContent as HTMLElement
        // @ts-ignore
        KeyLines.toggleFullScreen(graphContent, isInFullScreen => this.isInFullScreen = isInFullScreen)
    }

    private getChartData(): ChartData {
        const documentProjectionMin = 5.0
        const documentProjectionMax = 20.0

        if (this.graphData !== null) {
            const maxWeight = Math.max(...this.graphData.nodes.map(node => node.props.weight, 1))

            const keyLinesNodes = this.graphData.nodes
                .map(node => {
                    const splittedName = node.props.term.replace(" ", "\n") as string
                    const longest = Math.max(...splittedName.split("\n").map(t => t.length))
                    const fontSize = longest < 10 ? 10 : Math.max(Math.round(-0.43 * longest + 12.8), 5)

                    let color = "transparent"
                    switch (node.props.type as MentionedEntityType) {
                        case "PERSON":
                            color = "#FFD540"
                            break

                        case "ORGANIZATION":
                            color = "#F67DA7"
                            break

                        case "LOCATION":
                            color = "#9df67d"
                            break

                        case "CONCEPT":
                            color = "#56D0E5"
                            break

                        case "TERM":
                            color = "#AEC7CE"
                            break
                    }

                    return {
                        id: node.id,
                        type: "node",
                        sh: "circle",
                        ff: "Roboto",
                        t: splittedName,
                        c: color,
                        fs: fontSize,
                        fbc: "transparent",
                        e: node.props.weight / maxWeight * (documentProjectionMax - documentProjectionMin) + documentProjectionMin,
                        d: {
                            props: node.props
                        }
                    } as unknown as Node
                })

            const keyLinesLinks = this.graphData.edges
                .map(edge => {
                    return {
                        id: edge.id,
                        type: "link",
                        bg: false,
                        id1: edge.sourceId,
                        id2: edge.targetId,
                        c: "rgba(135, 135, 135, 0.4)",
                        w: 1 + Math.log10(edge.props.weight),
                        d: {
                            props: edge.props
                        }
                    } as Link
                })

            let items: Array<Node | Link> = []
            items = items.concat(keyLinesNodes).concat(keyLinesLinks)

            this.maxEdgeFactor = Math.round(Math.max(...keyLinesNodes.map(node => node.e as number)) * 100) / 100
            this.minEdgeFactor = Math.round(Math.min(...keyLinesNodes.map(node => node.e as number)) * 100) / 100

            return {
                type: "LinkChart",
                items
            } as ChartData
        }
        return {type: "LinkChart", items: []}
    }

    private getSelectedNode(): Node {
        return this.chartData.items.filter(item => item.type === "node" && item.id === this.selectedId.toString())[0] as unknown as Node
    }

    private getSelectedNodes(): Node[] {
        if (this.chart) {
            const selectedIds = this.chart.selection()
            return this.chartData.items
                .filter(item => item.type === "node")
                .filter(item => selectedIds.find(id => id === item.id)) as Node[]
        }
        return []
    }

    private getSelectedNodesIds(): string[] {
        return this.getSelectedNodes().map(node => node.id)
    }

    private getUnselectedNodesIds(): string[] {
        const selectedNodesIds = this.getSelectedNodesIds()
        return this.chartData.items
            .filter(item => item.type === "node")
            .map(item => item.id)
            .filter(item => !selectedNodesIds.find(selected => selected === item))
    }

    private openPopupMenu(id: number | null, x: number, y: number) {
        if (id !== null) {
            this.showAddFilter = this.calculateShowAddFilter()

            const selectedTypes = new Set(this.getSelectedNodes().map(node => node.d.props.type as MentionedEntityType))
            this.disableMerge = this.getSelectedNodesIds().length <= 1 || selectedTypes.size > 1 || selectedTypes.has("TERM")

            this.selectedId = id
            const menuRef = this.$refs.menuRef as HTMLElement
            const chartElement = this.$refs.chart as HTMLElement
            const boundingClientRect = chartElement.getBoundingClientRect()
            this.menuPosition = {left: x + boundingClientRect.left, top: y + boundingClientRect.top}
            this.$emit("openGraphContextMenu", menuRef)
            this.isContextMenuOpen = true
        }
    }

    private xor(a: boolean, b: boolean): boolean {
        return a || b && !(a && b)
    }

    private clickedGraph(id: string | null) {
        const nodes = this.chartData.items
            .filter(item => item.type === "node" && item.id === id)
        if (nodes.length === 0) {
            setEntityForSummary(this.$store, null)
            setEntityRelationshipForSummary(this.$store, null)
            return
        }

        if (!this.isContextMenuOpen) {
            if (!!id && this.canDisplayRelationshipSummary()) {
                this.viewRelationshipSummary()
            } else if (!!id) {
                this.viewSummary(parseInt(id, 10))
            } else {
                setEntityForSummary(this.$store, null)
                setEntityRelationshipForSummary(this.$store, null)
            }
        }
    }

    private canDisplayRelationshipSummary() {
        return this.getSelectedNodesIds().length === 2 && this.selectedNodesAreConnected()
    }

    private selectedNodesAreConnected(): boolean {
        const firstNode = this.getSelectedNodesIds()[0]
        const secondNode = this.getSelectedNodesIds()[1]
        return this.graphData.edges.some(edge => edge.sourceId === firstNode && edge.targetId === secondNode ||
            edge.sourceId === secondNode && edge.targetId === firstNode
        )
    }

    private viewRelationshipSummary() {
        setEntityForSummary(this.$store, null)
        const nodeA = this.getSelectedNodes()[0]
        const nodeB = this.getSelectedNodes()[1]
        const entityA = new MentionedEntity(nodeA.d.props.term, nodeA.d.props.type, [])
        const entityB = new MentionedEntity(nodeB.d.props.term, nodeB.d.props.type, [])
        setEntityRelationshipForSummary(this.$store, [entityA, entityB])
    }

    private viewSummary(selectedId: number) {
        setEntityRelationshipForSummary(this.$store, null)
        this.selectedId = selectedId
        const name = this.getSelectedNode().d.props.term
        const type = this.getSelectedNode().d.props.type
        setEntityForSummary(this.$store, new MentionedEntity(name, type, []))
    }

    private async refresh() {
        await this.fetchData()
    }

    private async klReady(chart: Chart) {
        this.chart = chart
        const options: ChartOptions = {
            minZoom: 0.001,
            handMode: true,
            zoom: {
                adaptiveStyling: true
            },
            linkEnds: {
                avoidLabels: false,
                spacing: "tight"
            },
            selectionColour: "#33b8ff",
            drag: {
                links: false,
                panAtBoundary: true
            },
            selectedNode: {
                b: "#0055B3",
                fb: true,
                fbc: "transparent"
            },
            selectedLink: {},
            marqueeLinkSelection: "off",
            overview: {
                icon: false,
                shown: false
            },
            navigation: {
                x: 20,
                y: 10
            }
        }
        this.chart.options(options)
        this.chart.bind("selectionchange", (() => {
            this.handleSelectionChange()
        }))
        this.chart.bind("delete", (() => {
            this.removeSelectedEntities()
            return true
        }))
    }

    private handleSelectionChange() {
        if (!this.isHiding) {
            this.chart.foreground(item => true)

            const selectedNodeIds = this.getSelectedNodesIds()
            this.chart.setProperties(this.originalProperties)

            this.originalProperties = []

            this.buildContextMenu(selectedNodeIds)

            this.selectNeighbouringNodes(1)
            this.chart.selection(selectedNodeIds)

            if (selectedNodeIds.length === 0) {
                this.chart.foreground(item => true)
            }
        }
    }

    private buildContextMenu(selectedNodeIds: string[]) {
        if (selectedNodeIds.length === 1) {
            const termName = this.getNode(selectedNodeIds[0]).d.props.term.toLowerCase()
            const term = this.getTerms.find(it => it.term.toLowerCase() === termName)
            this.removeEntityMenuTitle = "Remove Selected Entity"
            this.disableAddTerm = !!term
        } else {
            this.removeEntityMenuTitle = `Remove ${selectedNodeIds.length} Selected Entities`
            this.disableAddTerm = true
        }

        this.mergeEntityMenuTitle = `Merge ${selectedNodeIds.length} Selected Entities`

        this.disableFindShortestPath = selectedNodeIds.length !== 2 || !this.hasConnectingPath(selectedNodeIds)
    }

    private hasConnectingPath(selectedNodes: string[]): boolean {
        const paths = this.chart!.graph().shortestPaths(selectedNodes[0], selectedNodes[1], {direction: "any"})
        const connectedNodeIds = paths.items.filter(itemId => this.chart!.getItem(itemId)!!.type === "node")
        return connectedNodeIds.length > 0
    }

    private drawConnectingPath() {
        this.chart!.setProperties(this.originalProperties)

        const chart = this.chart!
        if (this.getSelectedNodesIds()[0] && this.getSelectedNodesIds()[1]) {
            setShortestPath(this.$store, this.getSelectedNodesIds())
            const paths = chart.graph().shortestPaths(this.getSelectedNodesIds()[0], this.getSelectedNodesIds()[1], {direction: "any"})

            const connectedNodeIds = paths.items.filter(itemId => chart.getItem(itemId)!!.type === "node")
            const unconnectedNodeIds = this.chartData.items
                .filter(item => item.type === "node")
                .map(item => item.id)
                .filter(item => !connectedNodeIds.find(connected => connected === item))

            const connectedEdgeIds = paths.items.filter(itemId => chart.getItem(itemId)!!.type === "link")
            const unconnectedEdgeIds = this.chartData.items
                .filter(item => item.type === "link")
                .map(item => item.id)
                .filter(item => !connectedEdgeIds.find(connected => connected === item))

            const unconnectedNodes: Node[] = unconnectedNodeIds.map(unconnectedNodeId => chart.getItem(unconnectedNodeId)!!) as unknown as Node[]
            const unconnectedEdges: Link[] = unconnectedEdgeIds.map(unconnectedEdgeId => chart.getItem(unconnectedEdgeId)!!) as unknown as Link[]
            const connectedEdges: Link[] = connectedEdgeIds.map(connectedEdgeId => chart.getItem(connectedEdgeId)!!) as unknown as Link[]

            this.originalProperties = []
            this.originalProperties.push(...[
                ...unconnectedNodes.map(node => ({id: node.id, bg: node.bg})),
                ...[...unconnectedEdges, ...connectedEdges].map(edge => ({id: edge.id, bg: edge.bg, w: edge.w, c: edge.c}))
            ])
            chart.setProperties([
                ...[...unconnectedNodes, ...unconnectedEdges].map(item => ({id: item.id, bg: true})),
                ...connectedEdges.map(edge => ({id: edge.id, c: "#0055B3", w: 25, bg: false}))
            ])
            chart.selection(connectedNodeIds)
            setSelectedNodes(this.$store, connectedNodeIds)
        }
    }

    private async performLayout(layoutName: string) {
        setUnifiedGraphSettings(this.$store, {...this.settings, layout: layoutName})
        const layout = graphLayouts[layoutName]
        await this.chart.layout(layout.name, {packing: layout.packing})
    }

    private async selectNeighbouringNodes(hops: number) {
        const result = this.chart.graph().neighbours(this.chart.selection(), {hops, all: false})
        result.nodes.push(...this.chart.selection())
        await this.chart.selection(result.nodes)

        result.links!!.forEach((itemId, index) => {
            const item = this.chart.getItem(itemId)
            this.originalProperties.push({id: itemId, c: "rgba(135, 135, 135, 0.4)", w: (item!! as Link).w, bg: true})
        })

        if (result.nodes.length !== 0 || result.links!.length !== 0) {
            await this.chart.foreground(item => {
                if (item.type === "node" && !result.nodes.includes(item.id)) {
                    return false
                }
                if (item.type === "link" && !result.links!.includes(item.id)) {
                    return false
                }
                return true
            }, {type: "all"})
        }
        await this.chart.setProperties(result.links!!.map(link => ({id: link, c: "rgba(0,102,215,0.53)", bg: false})))
    }

    private invertSelection() {
        this.chart.selection(this.getUnselectedNodesIds())
        this.handleSelectionChange()
    }

    private getNode(id: string): Node {
        return this.chartData.items.filter(item => item.type === "node" && item.id === id)[0] as Node
    }

    private googleIt() {
        const entity = this.getSelectedNode().d.props.term!
        window.open(`https://www.google.com/search?q=${entity}`, "_blank")
    }

    private async createGraphObservation() {
        this.imageDataUrl = await this.chart!.toDataURL(undefined, undefined, {fit: "exact", selection: true})

        openCreateObservationDialog(this, {
            type: "Graph",
            referencedEntity: this.imageDataUrl,
            observationTitle: this.getObservationTitle()
        })
    }

    private async addTerm() {
        if (this.disableAddTerm) {
            return
        }

        if (this.getTerms.length + 1 > MAX_ALLOWED_TERMS) {
            openMessageDialog({title: "Add Terms", message: `Cannot add more than ${MAX_ALLOWED_TERMS} terms`})
            return
        }

        this.newTerm = this.getSelectedNode().d.props.term!
        const modifier: TermModifier = "Regular"
        this.$emit("openCreateTermMenu", this.$refs.menuRef)
        this.disableAddTerm = true
    }

    private copyNodeText() {
        copyToClipboard(this.getSelectedNode().d.props.term!)
    }

    private hide(persons: string[]) {
        this.isHiding = true
        this.chart.hide(persons)
        setPersonForRightPaneSummary(this.$store, null)
        this.isHiding = false
        this.calcNumberOfNodes()
    }

    private removeSelectedEntities() {
        const selectedNodes = this.getSelectedNodesIds()
        const plural = selectedNodes.length > 1 ? "ies" : "y"
        // @ts-ignore
        openConfirmationDialog({
            message: `You are about to remove ${selectedNodes.length} entit${plural} from the graph. Are you sure?`,
            title: `Remove Selected Entit${plural}`,
            okText: "Remove",
            onOk: () => this.performRemoveEntities(selectedNodes),
            showCancel: true
        })
    }


    private performRemoveEntities(selectedNodes: string[]) {
        const entities = this.graphData.nodes
            .filter(node => selectedNodes.includes(node.id))
            .reduce((obj, node) => ({ ...obj, [node.props.term as string]: node.props.type as MentionedEntityType}), {})

        const investigation = this.investigation!
        investigationEntityService.removeEntities(investigation.id, entities)

        this.hide(selectedNodes)
        this.chart.foreground(item => true)
    }


    private colorGradient(fadeFraction: number, rgbColor1: RGB, rgbColor2: RGB, rgbColor3: RGB) {
        let color1: RGB = rgbColor1
        let color2: RGB = rgbColor2
        let fade: number = fadeFraction

        // Do we have 3 colors for the gradient? Need to adjust the params.
        if (rgbColor3) {
            fade = fade * 2

            // Find which interval to use and adjust the fade percentage
            if (fade >= 1) {
                fade -= 1
                color1 = rgbColor2
                color2 = rgbColor3
            }
        }

        const diffRed: number = color2.red - color1.red
        const diffGreen: number = color2.green - color1.green
        const diffBlue: number = color2.blue - color1.blue

        const gradient: RGB = {
            red: Math.floor(color1.red + (diffRed * fade)),
            green: Math.floor(color1.green + (diffGreen * fade)),
            blue: Math.floor(color1.blue + (diffBlue * fade))
        }

        return `rgb(${gradient.red},${gradient.green},${gradient.blue})`
    }

    private addHalosToNodes() {
        const names = this.highlightFilter.filter.substr(1)
        if (names.length === 0) {
            this.chart.setProperties({id: "."}, true)
        } else {
            names.split(",").forEach(name => {
                name = name.trim()
                this.chart.each({type: "node"}, node => {
                    if (node.d.props.term.includes(name)) {
                        this.chart.setProperties({
                            id: node.id,
                            ha0: {
                                c: "rgb(158,161,255)",
                                r: 35,
                                w: 10
                            }
                        })
                    }
                })
            })
        }
    }

    private calcNumberOfNodes() {
        let count = 0
        if (this.chart) {
            this.chart.each({type: "node"}, node => {
                if (!node.hi) {
                    count++
                }
            })
        }
        this.numberOfNodes = count
    }

    private filter() {
        setUnifiedGraphSettings(this.$store, {...this.settings, filter: this.highlightFilter.filter})
        if (this.highlightFilter.filter.startsWith("!")) {
            this.addHalosToNodes()
        }

        this.highlightFilter.current = null
        this.highlightFilter.list = []

        if (this.highlightFilter.filter !== "") {
            const lowerCaseFilterName = this.highlightFilter.filter.toLowerCase()
            const filteredNodeIds = this.chartData.items
                .filter(item => item.type === "node")
                .map(item => item as NodeProperties)
                .filter(node => {
                    return this.highlightFilter.filter === "" || node.d.props.term.toLowerCase().includes(lowerCaseFilterName)
                })
                .map(node => node.id)
            this.chart.ping(filteredNodeIds, {
                c: "#ff0000",
                repeat: 10,
                r: 120,
                w: 100,
                time: 800
            })
            this.chart.zoom("fit", {ids: filteredNodeIds, animate: true})
            this.highlightFilter.current = null
            this.highlightFilter.list = filteredNodeIds
        }
    }

    private calcHighlightFilterCounts() {
        if (this.highlightFilter.list.length === 0) {
            return ""
        }
        if (this.highlightFilter.current != null) {
            return `${this.highlightFilter.current + 1}/${this.highlightFilter.list.length}`
        }

        return `${this.highlightFilter.list.length}`
    }

    private highlightPrevious() {
        this.highlightJump(-1)
    }

    private highlightNext() {
        this.highlightJump(1)
    }

    private async highlightJump(direction: number) {
        if (this.highlightFilter.list === []) {
            return
        }
        if (this.highlightFilter.current === null) {
            this.highlightFilter.current = direction === 1 ? 0 : this.highlightFilter.list.length - 1
        } else {
            this.highlightFilter.current += direction
            if (this.highlightFilter.current === -1 || this.highlightFilter.current === this.highlightFilter.list.length) {
                this.highlightFilter.current = null
            }
        }

        const nodeIds = this.highlightFilter.current === null ? this.highlightFilter.list : this.highlightFilter.list[this.highlightFilter.current]

        this.chart.ping(nodeIds, {
            c: "#ff0000",
            repeat: 3,
            r: 120,
            w: 100,
            time: 800
        })
        await this.chart.zoom("fit", {ids: nodeIds, animate: true, time: 500})
    }

    private clearHighlight() {
        this.highlightFilter = {
            filter: "",
            current: null,
            list: []
        }
    }

    private updateTermGroups() {
        const nodeTypes = this.chartData.items
            .filter(it => it.type === "node")
            .map(it => it.d.props.type as MentionedEntityType)
        const visibleNodeTypes = new Set(nodeTypes)

        const oldVisibleLayers = this.visibleLayers

        this.visibleLayers = []
        if (visibleNodeTypes.has("PERSON")) {
            const peopleLayer = oldVisibleLayers.find(it => it.name === "People")
            const visible = peopleLayer ? peopleLayer.visible : true
            this.visibleLayers.push({name: "People", type: "PERSON", visible, color: "", cssColor: "#FFD540"})
        }

        if (visibleNodeTypes.has("ORGANIZATION")) {
            const organizationLayer = oldVisibleLayers.find(it => it.name === "Organizations")
            const visible = organizationLayer ? organizationLayer.visible : true
            this.visibleLayers.push({name: "Organizations", type: "ORGANIZATION", visible, color: "", cssColor: "#F67DA7"})
        }

        if (visibleNodeTypes.has("LOCATION")) {
            const locationLayer = oldVisibleLayers.find(it => it.name === "Locations")
            const visible = locationLayer ? locationLayer.visible : true
            this.visibleLayers.push({name: "Locations", type: "LOCATION", visible, color: "", cssColor: "#9df67d"})
        }

        if (visibleNodeTypes.has("CONCEPT")) {
            const conceptLayer = oldVisibleLayers.find(it => it.name === "Concepts")
            const visible = conceptLayer ? conceptLayer.visible : true
            this.visibleLayers.push({name: "Concepts", type: "CONCEPT", visible, color: "", cssColor: "#56D0E5"})
        }

        if (visibleNodeTypes.has("TERM")) {
            const termLayer = oldVisibleLayers.find(it => it.name === "Terms")
            const visible = termLayer ? termLayer.visible : true
            this.visibleLayers.push({name: "Terms", type: "TERM", visible, color: "", cssColor: getGroupColor("default", true)})
        }
    }


    private layerCheckboxClass(layer: VisibleLayer) {
        return `${layer.visible ? "checked" : ""} group-${layer.color}`
    }


    private toggleLayer(layer: VisibleLayer) {
        layer.visible = !layer.visible
        if (this.visibleLayers.filter(visibleLayer => visibleLayer.visible).length === 0) {
            layer.visible = !layer.visible
        }
    }


    private getObservationTitle(): string {
        const nodes = this.getSelectedNodes()
        if (nodes.length === 1) {
            return nodes[0].d.props.term
        }

        if (nodes.length === 2) {
            return nodes[0].d.props.term + " & " + nodes[1].d.props.term
        }

        return "Entity Graph Insight"
    }


    private calculateShowAddFilter(): boolean {
        return this.getSelectedNodes()
            .every(node =>
                !this.selectedTops.some(top =>
                    top.name === entityTypeName(node) && top.selectedValue === node.d.props.term
                )
            )
    }


    private async addAsFilter() {
        if (this.showAddFilter) {
            this.getSelectedNodes().forEach(node => {
                const name = entityTypeName(node)
                const value = node.d.props.term
                this.selectedTops.push({name, selectedDisplayValue: value, selectedValue: value})
            })

            await setSelectedTops(this.$store, this.selectedTops)
            await fetchGraphData(this.$store)
        }
    }

    private get selectedTops() {
        return getSelectedTops(this.$store)
    }

    private mergeSelectedEntities() {
        if (!this.disableMerge) {
            const selectedNodeIds = this.getSelectedNodesIds()
            const selectedNodes = this.graphData.nodes.filter(node => selectedNodeIds.includes(node.id))
            const entityNames = selectedNodes.map(node => node.props.term as string)
            const entityType = selectedNodes[0].props.type as MentionedEntityType

            openConfirmationDialog({
                message: `You are about to merge ${entityNames.length} entities:<br><ul>${entityNames.map(name => `<li>${name}</li>`).join("")}</ul>Are you sure?<br>This operation cannot be undone.`,
                title: "Merge Entities",
                okText: "Merge",
                onOk: () => this.performMergeEntities(entityNames, entityType),
                showCancel: true
            })
        }
    }

    private async performMergeEntities(entityNames: string[], entityType: MentionedEntityType) {
        const investigation = this.investigation!
        await investigationEntityService.mergeEntities(investigation.id, entityType, entityNames)

        await fetchGraphData(this.$store)
    }
}

function entityTypeName(node: KeyLines.Node) {
  return `${node.d.props.type === "TERM" ? "predefined" : "entity"}.Mentioned ${capitalize(node.d.props.type)}s`
}
