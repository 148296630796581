

















































import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import BarGraph from "@/components/BarGraph.vue"
import DateFilter from "@/components/filter/DateFilter.vue"
import DocumentDisplayedResultsFilter from "@/components/filter/DocumentDisplayedResultsFilter.vue"
import {BarGraphModel, BarModel, RegionBarModel, ScoredAffiliationEntity, ScoredAuthorEntity, ScoredNameEntity, ScoredRegionEntity, SelectedTop} from "@/types/investigations"
import {
    fetchGraphData,
    getDisplayedResultsStatus,
    getFilterByResearchers,
    getFilterToUnaffiliated,
    getFilterToDuplicates,
    getFreeTextFilter,
    getIsLoadingInvestigation,
    getJournals,
    getSelectedTops,
    getTopAffiliationGeographies,
    getTopAuthors,
    getTopClassifications,
    getTopConcepts,
    getTopConferences,
    getTopDomains,
    getTopEmails,
    getTopInstitutions,
    getTopLabels,
    getTopLanguages,
    getTopLexemes,
    getTopLocations,
    getTopOrganizations,
    getTopPersons,
    getTopRegions,
    getTopTerms,
    getTotalDocumentCount,
    setDisplayedResultsStatus,
    setFilterByResearchers,
    setFilterToDuplicates,
    setFilterToUnaffiliated,
    setFreeTextFilter,
    setSelectedTops,
    getFilterToUnknownPublishers,
    setFilterToUnknownPublishers,
    clearFilters,
    getFilterToSpecificUrls,
    setFilterToSpecificUrls,
    getFilterToMissingVolume,
    getFilterToMissingDate,
    setFilterToMissingVolume,
    setFilterToMissingDate
} from "@/store/investigation/investigation-module"
import {getDateFilterEnd, getDateFilterStart, getDateHistogram, setDateFilterYears} from "@/store/investigation/date-filter-module"
import {getGraphType} from "@/store/investigation/graph-module"
import {getLabelText} from "@/components/filter/DocumentLabelFilterUtil"
import PopupMenu from "@/components/PopupMenu.vue"
import CreateTermTemplate from "@/views/investigation/templates/CreateTermTemplate.vue"
import {InvestigationMixin} from "@/store/investigation-mixin"

@Component({
    components: {BarGraph, DateFilter, DocumentLabelFilter: DocumentDisplayedResultsFilter, PopupMenu, CreateTermTemplate}
})
export default class TopsPane extends mixins(Vue, InvestigationMixin) {

    private localFreeText: string = ""
    private newTerm: string = ""

    get showDateHistogram(): boolean {
        return getDateHistogram(this.$store).bars.length > 0 && !getIsLoadingInvestigation(this.$store)
    }

    private get displayTopsPane() {
        return getGraphType(this.$store) != null || ((this.hasDocuments || this.hasFiltersApplied))
    }

    private get hasDocuments(): boolean {
        return getTotalDocumentCount(this.$store) > 0
    }

    private get hasFiltersApplied(): boolean {
        return this.hasFreeTextFilter || this.hasDisplayStatusFilter || this.hasTopFilter || this.hasDateFilter ||
            this.hasFilterByResearchers || this.hasFilterToUnknownPublishers || this.hasFilterToDuplicates ||
            this.hasFilterToDuplicates || this.hasSpecificUrlsFilter || this.hasFilterToMissingVolume ||
            this.hasFilterToMissingDate
    }

    private get shouldShowFreeTextSearchButton() {
        return this.freeText === null || this.freeText !== this.localFreeText
    }

    private get freeText(): string | null {
        return getFreeTextFilter(this.$store)
    }

    private set freeText(value: string | null) {
        if (value === "") {
            value = null
        }
        setFreeTextFilter(this.$store, value)
    }

    private get hasFreeTextFilter() {
        return this.freeText !== null
    }

    private get hasFilterByResearchers() {
        return getFilterByResearchers(this.$store)
    }

    private get hasFilterToDuplicates() {
        return getFilterToDuplicates(this.$store)
    }

    private get hasFilterToUnaffiliated() {
        return getFilterToUnaffiliated(this.$store)
    }

    private get hasFilterToUnknownPublishers() {
        return getFilterToUnknownPublishers(this.$store)
    }

    private get hasFilterToMissingVolume() {
        return getFilterToMissingVolume(this.$store)
    }

    private get hasFilterToMissingDate() {
        return getFilterToMissingDate(this.$store)
    }

    private get hasSpecificUrlsFilter() {
        return getFilterToSpecificUrls(this.$store).length > 0
    }

    private get hasDisplayStatusFilter() {
        return getDisplayedResultsStatus(this.$store) !== "All"
    }

    private get labelFilterText() {
        return getLabelText(getDisplayedResultsStatus(this.$store)!!)
    }

    private get hasDateFilter() {
        return getDateFilterStart(this.$store) != null || getDateFilterEnd(this.$store) != null
    }

    private get dateFilterText() {
        const bars = getDateHistogram(this.$store).bars
        const start = getDateFilterStart(this.$store) != null ? getDateFilterStart(this.$store) : (bars.length > 0 ? bars[0].year : "")
        const end = getDateFilterEnd(this.$store) != null ? getDateFilterEnd(this.$store) : (bars.length > 0 ? bars[bars.length - 1].year : "")
        return start === end ? end : `${start} - ${end}`
    }

    private get hasTopFilter() {
        return this.selectedTops.length > 0
    }

    private get selectedTops() {
        return getSelectedTops(this.$store)
    }

    private get graphs(): BarGraphModel[] {
        const graphs: BarGraphModel[] = []

        if (!this.isInReviewProfile) {
            this.addGraph(graphs, "Labels", "predefined.Labels", getTopLabels(this.$store))
        }
        this.addLanguageGraph(graphs, "Languages", "predefined.Languages", getTopLanguages(this.$store))
        this.addGraph(graphs, "Classifications", "predefined.Classifications", getTopClassifications(this.$store))
        if (!this.isInPersonProfile) {
            this.addGraph(graphs, "Domains", "predefined.Domains", getTopDomains(this.$store))
        }
        this.addAuthorGraph(graphs, "Authors", "predefined.Persons", getTopAuthors(this.$store))
        this.addGraph(graphs, "Journals", "predefined.Journals", getJournals(this.$store))
        this.addGraph(graphs, "Conference Proceedings", "predefined.Conferences", getTopConferences(this.$store))
        this.addAffiliationGraph(graphs, "Institutes of Authors", "predefined.Institutions", getTopInstitutions(this.$store))
        if (!this.isInPersonProfile) {
            this.addRegionGraph(graphs, "Regions of Authors", "predefined.Regions", getTopRegions(this.$store))
        }
        this.addGraph(graphs, "Countries of Author Institutes", "predefined.Affiliation Geographies", getTopAffiliationGeographies(this.$store))
        this.addGraph(graphs, "Emails", "predefined.Email", getTopEmails(this.$store))
        this.addGraph(graphs, "Mentioned Terms", "predefined.Mentioned Terms", getTopTerms(this.$store))
        this.addGraph(graphs, "Mentioned People", "entity.Mentioned Persons", getTopPersons(this.$store))
        this.addGraph(graphs, "Mentioned Organizations", "entity.Mentioned Organizations", getTopOrganizations(this.$store))
        this.addGraph(graphs, "Mentioned Locations", "entity.Mentioned Locations", getTopLocations(this.$store))
        this.addGraph(graphs, "Mentioned Concepts", "entity.Mentioned Concepts", getTopConcepts(this.$store))

        getTopLexemes(this.$store).forEach(topLexeme => {
            this.addGraph(graphs, topLexeme.displayName, topLexeme.name, topLexeme.lexemeScores)
        })

        return graphs
    }

    private async getAllData() {
        getGraphType(this.$store) === null ? await this.$emit("refresh-documents") : await fetchGraphData(this.$store)
    }

    private setNewTerm(term: string) {
        this.newTerm = term
    }

    private async clearFilters() {
        clearFilters(this.$store)
        this.localFreeText = ""
        this.getAllData()
    }

    private searchFreeText() {
        this.freeText = this.localFreeText = this.localFreeText.trim()
        this.getAllData()
    }

    private clearFreeTextSearch() {
        this.freeText = null
        this.localFreeText = ""
        this.getAllData()
    }

    private clearResearchersFilter() {
        setFilterByResearchers(this.$store, false)
        this.getAllData()
    }

    private clearDuplicatesFilter() {
        setFilterToDuplicates(this.$store, false)
        this.getAllData()
    }

    private clearUnaffiliatedFilter() {
        setFilterToUnaffiliated(this.$store, false)
        this.getAllData()
    }

    private clearUnknownPublishersFilter() {
        setFilterToUnknownPublishers(this.$store, false)
        this.getAllData()
    }

    private clearMissingVolumeFilter() {
        setFilterToMissingVolume(this.$store, false)
        this.getAllData()
    }

    private clearMissingDateFilter() {
        setFilterToMissingDate(this.$store, false)
        this.getAllData()
    }

    private clearSpecificUrlsFilter() {
        setFilterToSpecificUrls(this.$store, [])
        this.getAllData()
    }

    private async closeLabelFilter() {
        setDisplayedResultsStatus(this.$store, "All")
        this.getAllData()
    }

    private async closeDateFilter() {
        setDateFilterYears(this.$store, {start: null, end: null})
        this.getAllData()
    }

    private async closeFilter(filter: SelectedTop) {
        setSelectedTops(this.$store, this.selectedTops.filter(activeFilter => activeFilter.name !== filter.name || activeFilter.selectedValue !== filter.selectedValue))
        this.getAllData()
    }

    private topName(top: SelectedTop) {
        const topGraph = this.graphs.find(graph => graph.type === top.name)
        return topGraph ? topGraph.title : top.name
    }

    private addGraph(graphs: BarGraphModel[], title: string, type: string, tops: ScoredNameEntity[]) {
        if (tops.length > 0) {
            graphs.push(new BarGraphModel(title, type, tops.map(top => new BarModel(top.name, top.totalScore, top.relevantScore, top.totalCount, top.relevantCount, "", top.name))))
        }
    }

    private addLanguageGraph(graphs: BarGraphModel[], title: string, type: string, tops: ScoredNameEntity[]) {
        if (tops.length > 1 || (tops.length === 1 && tops[0].name !== "English")) {
            graphs.push(new BarGraphModel(title, type, tops.map(top => new BarModel(top.name, top.totalScore, top.relevantScore, top.totalCount, top.relevantCount, "", top.name))))
        }
    }

    private addAuthorGraph(graphs: BarGraphModel[], title: string, type: string, tops: ScoredAuthorEntity[]) {
        if (tops.length > 0) {
            graphs.push(new BarGraphModel(title, type, tops.map(top => new BarModel(top.author.name, top.totalScore, top.relevantScore, top.totalCount, top.relevantCount, top.author.id, top.author.id))))
        }
    }

    private addAffiliationGraph(graphs: BarGraphModel[], title: string, type: string, tops: ScoredAffiliationEntity[]) {
        if (tops.length > 0) {
            graphs.push(new BarGraphModel(title, type, tops.map(top => new BarModel(top.affiliation.name, top.totalScore, top.relevantScore, top.totalCount, top.relevantCount, top.affiliation.title, top.affiliation.name))))
        }
    }

    private addRegionGraph(graphs: BarGraphModel[], title: string, type: string, tops: ScoredRegionEntity[]) {
        if (tops.length > 0) {
            graphs.push(new BarGraphModel(title, type, tops.map(top => new RegionBarModel(top.region.name, top.totalScore, top.relevantScore, top.totalCount, top.relevantCount, "", top.region.code, top.region.name))))
        }
    }
}
