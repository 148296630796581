import { getStoreAccessors } from "vuex-typescript"
import { ActionContext } from "vuex"
import { Optional } from "@/types"
import { RootState } from "@/store"


export class NoteDialogData {
    constructor(public documentId: string, public note?: string) {}
}


export interface NoteDialogState {
    noteDialogData: Optional<NoteDialogData>
}

const module = {

    getters: {
        getNoteDialogData(state: NoteDialogState): Optional<NoteDialogData> {
            return state.noteDialogData
        }
    },

    mutations: {
        setNoteDialogData(state: NoteDialogState, data: Optional<NoteDialogData>) {
            state.noteDialogData = data
        }
    },

    actions: {
        async showNoteDialog(context: ActionContext<NoteDialogState, RootState>, {documentId, note}: {documentId: string, note?: string}): Promise<void> {
            setNoteDialogData(context, {documentId, note})
        },

        async hideNoteDialog(context: ActionContext<NoteDialogState, RootState>): Promise<void> {
            setNoteDialogData(context, undefined)
        }
    }
}

export default module

const { read, commit, dispatch } = getStoreAccessors<NoteDialogState, RootState>("investigation")

export const getNoteDialogData = read(module.getters.getNoteDialogData)

const setNoteDialogData = commit(module.mutations.setNoteDialogData)

export const showNoteDialog = dispatch(module.actions.showNoteDialog)
export const hideNoteDialog = dispatch(module.actions.hideNoteDialog)
