import {RootState} from ".."
import {getStoreAccessors} from "vuex-typescript"
import {getMentionedEntityLabels, InvestigationState, setMentionedEntityLabels} from "@/store/investigation/investigation-module"
import {GraphEntity, GraphNodeEntity, GraphType, InvestigationTermEntity, InvestigationTermGroupEntity, InvestigationType, MentionedEntity, TimelineChartData} from "@/types/investigations"
import {ActionContext} from "vuex"
import investigationService from "@/api/investigation-service"
import {getInvestigationId} from "@/store/investigation/get-investigation-id"


const module = {
    getters: {
        getGraph(state: InvestigationState): GraphEntity {
            return state.graph
        },

        getTimeline(state: InvestigationState): TimelineChartData {
            return state.timeline
        },

        getExternalSeries(state: InvestigationState): string[] {
            return state.externalSeries
        },

        getGraphType(state: InvestigationState): GraphType {
            return state.graphType
        }
    },

    mutations: {
        setGraph(state: InvestigationState, graph: GraphEntity) {
            state.graph = graph
        },

        setTimeline(state: InvestigationState, timeline: TimelineChartData) {
            state.timeline = timeline
        },

        setExternalSeries(state: InvestigationState, externalSeries: string[]) {
            state.externalSeries = externalSeries
        },

        setGraphType(state: InvestigationState, graphType: GraphType) {
            state.graphType = graphType
        },

        updateMentionedEntityLabelInInvestigation(state: InvestigationState, payload: {oldLabel: string, newLabel: string}) {
            const {oldLabel, newLabel} = payload
            const labelIndex = state.entityLabels.findIndex(currentLabel => oldLabel.toLocaleLowerCase() === currentLabel.toLocaleLowerCase())

            if (labelIndex > -1) {
                state.entityLabels.splice(labelIndex, 1, newLabel)
            }
        },

        deleteMentionedEntityLabelFromInvestigation(state: InvestigationState, label: string) {
            const labelIndex = state.entityLabels.findIndex(currentLabel => label.toLocaleLowerCase() === currentLabel.toLocaleLowerCase())

            if (labelIndex > -1) {
                state.entityLabels.splice(labelIndex, 1)
            }
        },

        removeGraphNodes(state: InvestigationState, nodeIds: string[]) {
            state.graph = {
                ...state.graph,
                nodes: state.graph.nodes.filter(node => !nodeIds.includes(node.id))
            }
        }
    },

    actions: {
        async toggleTimelineTermGroup(context: ActionContext<InvestigationState, RootState>, termGroup: InvestigationTermGroupEntity): Promise<void> {
            const timeline = getTimeline(context)
            const newTermGroups = addOrRemoveItemFromList(termGroup.name, timeline.selectedTermGroups)

            setTimeline(context, {chartEntity: timeline.chartEntity, selectedTermGroups: newTermGroups, selectedTerms: timeline.selectedTerms, selectedExternalSeries: timeline.selectedExternalSeries})
        },

        async toggleTimelineTerm(context: ActionContext<InvestigationState, RootState>, term: InvestigationTermEntity): Promise<void> {
            const timeline = getTimeline(context)
            const newTerms = addOrRemoveItemFromList(term.term, timeline.selectedTerms)
            setTimeline(context, {chartEntity: timeline.chartEntity, selectedTermGroups: timeline.selectedTermGroups, selectedTerms: newTerms, selectedExternalSeries: timeline.selectedExternalSeries})
        },

        async toggleTimelineExternalSeries(context: ActionContext<InvestigationState, RootState>, externalSeries: string): Promise<void> {
            const timeline = getTimeline(context)
            const newExternalSeries = timeline.selectedExternalSeries === externalSeries ? null : externalSeries
            setTimeline(context, {chartEntity: timeline.chartEntity, selectedTermGroups: timeline.selectedTermGroups, selectedTerms: timeline.selectedTerms, selectedExternalSeries: newExternalSeries})
        },

        async addMentionedEntityLabel(context: ActionContext<InvestigationState, RootState>, payload: {label: string, entity: MentionedEntity, investigationType: InvestigationType}): Promise<void> {
            const {entity, label, investigationType} = payload
            const oldEntity = {...entity}
            const oldLabels = [...getMentionedEntityLabels(context)]
            if (!entity.labels.some(entityLabel => entityLabel.toLocaleLowerCase() === label.toLocaleLowerCase())) {
                entity.labels.push(label)
            }
            if (!oldLabels.some(oldLabel => oldLabel.toLocaleLowerCase() === label.toLocaleLowerCase())) {
                const newLabels = [...oldLabels, label]
                setMentionedEntityLabels(context, newLabels)
            }

            const result = await investigationService.addEntityLabel(getInvestigationId(context, "add entity label"), entity.type, entity.name, label, investigationType)
            if (!result) {
                setMentionedEntityLabels(context, oldLabels)
            }
        },

        async removeMentionedEntityLabel(context: ActionContext<InvestigationState, RootState>, payload: {label: string, entity: MentionedEntity, investigationType: InvestigationType}): Promise<void> {
            const {entity, label, investigationType} = payload
            const oldEntity = {...entity}
            const labelIndex = entity.labels.findIndex(entityLabel => entityLabel.toLocaleLowerCase() === label.toLocaleLowerCase())
            if (labelIndex > -1) {
                entity.labels.splice(labelIndex, 1)
            }

            const result = await investigationService.removeEntityLabel(getInvestigationId(context, "remove entity label"), entity.type, entity.name, label, investigationType)
        },

        async renameMentionedEntityLabel(context: ActionContext<InvestigationState, RootState>, payload: {oldLabel: string, newLabel: string, investigationType: InvestigationType}): Promise<void> {
            const {oldLabel, newLabel, investigationType} = payload
            const oldLabels = [...getMentionedEntityLabels(context)]
            updateMentionedEntityLabelInInvestigation(context, {oldLabel, newLabel})

            const nodes = getGraph(context).nodes

            function changeNodeLabel(currentLabel: string, replacedLabel: string) {
                return (newNode: GraphNodeEntity) => {
                    const {name, type, labels} = newNode.props
                    const labelIndex = labels.findIndex((nodeLabel: string) => currentLabel.toLocaleLowerCase() === nodeLabel.toLocaleLowerCase())
                    if (labelIndex > -1) {
                        labels.splice(labelIndex, 1, replacedLabel)
                    }
                }
            }

            nodes.forEach(changeNodeLabel(oldLabel, newLabel))

            const result = await investigationService.renameEntityLabel(getInvestigationId(context, "rename entity label"), {oldLabel, newLabel}, investigationType)
            if (!result) {
                setMentionedEntityLabels(context, oldLabels)
                nodes.forEach(changeNodeLabel(newLabel, oldLabel))
            }
        },

        async deleteMentionedEntityLabel(context: ActionContext<InvestigationState, RootState>, payload: {label: string, investigationType: InvestigationType}): Promise<void> {
            const {label, investigationType} = payload
            const oldLabels = [...getMentionedEntityLabels(context)]
            deleteMentionedEntityLabelFromInvestigation(context, label)

            const newNodes = getGraph(context).nodes
            newNodes.forEach(newNode => {
                const {name, type, labels} = newNode.props
                const labelIndex = labels.findIndex((nodeLabel: string) => label.toLocaleLowerCase() === nodeLabel.toLocaleLowerCase())
                if (labelIndex > -1) {
                    labels.splice(labelIndex, 1)
                }
            })

            const result = await investigationService.deleteMentionedEntityLabel(getInvestigationId(context, "delete entity label"), label, investigationType)
            if (!result) {
                setMentionedEntityLabels(context, oldLabels)
            }
        }
    }
}

function addOrRemoveItemFromList(item: string, list: string[]): string[] {
    const newList = list.filter(it => it !== item)
    if (newList.length === list.length) {
        newList.push(item)
    }
    return newList
}

export default module

const {read, commit, dispatch} = getStoreAccessors<InvestigationState, RootState>("investigation")

export const getGraph = read(module.getters.getGraph)
export const getGraphType = read(module.getters.getGraphType)
export const setGraph = commit(module.mutations.setGraph)
export const setGraphType = commit(module.mutations.setGraphType)
export const removeGraphNodes = commit(module.mutations.removeGraphNodes)

export const getTimeline = read(module.getters.getTimeline)
export const setTimeline = commit(module.mutations.setTimeline)
export const toggleTimelineTermGroup = dispatch(module.actions.toggleTimelineTermGroup)
export const toggleTimelineTerm = dispatch(module.actions.toggleTimelineTerm)
export const toggleExternalSeries = dispatch(module.actions.toggleTimelineExternalSeries)
export const getExternalSeries = read(module.getters.getExternalSeries)
export const setExternalSeries = commit(module.mutations.setExternalSeries)

export const addMentionedEntityLabel = dispatch(module.actions.addMentionedEntityLabel)
export const removeMentionedEntityLabel = dispatch(module.actions.removeMentionedEntityLabel)
export const renameMentionedEntityLabel = dispatch(module.actions.renameMentionedEntityLabel)
export const deleteMentionedEntityLabel = dispatch(module.actions.deleteMentionedEntityLabel)
const updateMentionedEntityLabelInInvestigation = commit(module.mutations.updateMentionedEntityLabelInInvestigation)
const deleteMentionedEntityLabelFromInvestigation = commit(module.mutations.deleteMentionedEntityLabelFromInvestigation)
