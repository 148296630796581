import axios from "axios"
import {ObservationEntity, ObservationSectionEntity} from "@/store/investigation/observation-module"
import {InvestigationType, ObservationType} from "@/types/investigations"
import {ImageCropData} from "@/api/investigation-service"

export default {

    async addInvestigationObservation(investigationId: string, observation: ObservationEntity, investigationType: InvestigationType, observationType: ObservationType): Promise<ObservationEntity> {
        const response = await axios.post(`/api/investigations/${investigationType.toLowerCase()}/${investigationId}/observation/${observationType.toLowerCase()}`, observation)
        return response.data as ObservationEntity
    },

    async setInvestigationImage(investigationId: string, investigationType: InvestigationType, observationId: string, imageCropData: ImageCropData): Promise<string | null> {
        const formData = new FormData()
        if (imageCropData.file) {
            formData.set("file", imageCropData.file)
            formData.set("fileName", imageCropData.file.name)
        }
        formData.set("imageData", JSON.stringify(imageCropData))

        const response = await axios.post(`/api/investigations/${investigationType.toLowerCase()}/${investigationId}/observation/${observationId}/image`, formData)
        return response.data as (string | null)
    },

    async clearObservationImage(investigationId: string, investigationType: InvestigationType, observationId: string): Promise<void> {
        await axios.post(`/api/investigations/${investigationType.toLowerCase()}/${investigationId}/observation/${observationId}/clear-image`, {})
    },

    async editInvestigationObservation(investigationId: string, observation: ObservationEntity, investigationType: InvestigationType): Promise<boolean> {
        const response = await axios.patch(`/api/investigations/${investigationType.toLowerCase()}/${investigationId}/observation/${observation.id}`, observation)
        return response && (response.status === 200 || response.status === 204)
    },

    async deleteInvestigationObservation(investigationId: string, observationId: string, investigationType: InvestigationType): Promise<boolean> {
        const response = await axios.delete(`/api/investigations/${investigationType.toLowerCase()}/${investigationId}/observation/${observationId}`)
        return response && (response.status === 200 || response.status === 204)
    },

    async createSection(id: string, name: string, ordinal: number): Promise<ObservationSectionEntity[] | undefined> {
        const response = await axios.post(`/api/investigations/topic/${id}/observation/section`, {id: -1, name, ordinal})
        if (response) {
            return response.data as ObservationSectionEntity[]
        }
    },

    async renameSection(id: string, section: ObservationSectionEntity): Promise<ObservationSectionEntity[] | undefined> {
        const response = await axios.patch(`/api/investigations/topic/${id}/observation/section/rename`, section)
        if (response) {
            return response.data as ObservationSectionEntity[]
        }
    },

    async deleteSection(id: string, sectionId: string): Promise<ObservationSectionEntity[] | undefined> {
        const response = await axios.delete(`/api/investigations/topic/${id}/observation/section/`, {data: sectionId})
        if (response) {
            return response.data as ObservationSectionEntity[]
        }
    },

    async reorderSections(id: string, sectionIds: string[]): Promise<ObservationSectionEntity[] | undefined> {
        const response = await axios.patch(`/api/investigations/topic/${id}/observation/section/reorder`, {sectionIds})
        if (response) {
            return response.data as ObservationSectionEntity[]
        }
    },

    async moveObservationToGroup(id: string, observation: string, section: string): Promise<ObservationEntity[] | undefined> {
        const response = await axios.patch(`/api/investigations/topic/${id}/observation/move-to-section`, { observation, section })
        if (response) {
            return response.data as ObservationEntity[]
        }
    },

    async reorderObservations(id: string, observations: ObservationEntity[]): Promise<boolean | undefined> {
        const response = await axios.patch(`/api/investigations/topic/${id}/observation/reorder`, observations)
        return response && response.status === 204
    }
}
