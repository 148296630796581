












import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"

@Component
export default class Speedometer extends Vue {
    @Prop()
    private rotation!: number

    get gaugeStyle() {
        return "transform: rotate(" + this.rotation + "deg);"
    }
}
