




















import GenericDialogNew, {createDialogMountingPoint} from "@/components/GenericDialogNew.vue"
import Component, {mixins} from "vue-class-component"
import Vue from "vue"
import {fetchAllData, setFilterToSpecificUrls} from "@/store/investigation/investigation-module"

export function createFilterByUrlsDialog(parent: Vue) {
    const params = {parent, store: parent.$store, router: parent.$router, propsData: {}}
    new (Vue.extend(FilterByUrlsDialog))(params).$mount(createDialogMountingPoint())
}

@Component({ components: {GenericDialogNew} })
export default class FilterByUrlsDialog extends mixins(Vue) {
    private urls: string = ""

    private close() {
        (this.$refs.genericDialog as GenericDialogNew).close()
    }

    private apply() {
        const urlList = this.urls.split('\n').map(x => x.trim())
        setFilterToSpecificUrls(this.$store, urlList)
        this.close()
        fetchAllData(this.$store)
    }
}

