import {RootState} from "@/store/index"
import {Credentials, UserEntityOptional} from "@/types/user"
import authService from "../api/auth-service"
import userService from "../api/user-service"
import {ActionContext} from "vuex"
import {getStoreAccessors} from "vuex-typescript"


export interface AuthState {
    currentUser: UserEntityOptional
    isTokenValidating: boolean
}


const module = {
    namespaced: true,

    state(): AuthState {
        return {
            currentUser: null,
            isTokenValidating: false
        }
    },

    getters: {
        getCurrentUser(state: AuthState): UserEntityOptional {
            return state.currentUser
        },

        isOmnipotentUser(state: AuthState): boolean {
            const user = state.currentUser
            return user != null && (user.omnisol === true || user.facultyWorks === true)
        },

        isOmnisolUser(state: AuthState): boolean {
            const user = state.currentUser
            return user != null && user.omnisol === true
        },

        isFacultyWorksUser(state: AuthState): boolean {
            const user = state.currentUser
            return user != null && user.facultyWorks === true
        },

        isAdminUser(state: AuthState): boolean {
            const user = state.currentUser
            return user != null && user.admin === true
        },

        isSignedIn(state: AuthState): boolean {
            return state.currentUser != null
        },

        isTokenValidating(state: AuthState): boolean {
            return state.isTokenValidating
        }
    },

    mutations: {
        setCurrentUser(state: AuthState, user: UserEntityOptional) {
            state.currentUser = user
        },

        setTokenValidating(state: AuthState, validating: boolean) {
            state.isTokenValidating = validating
        }
    },

    actions: {
        async fetchCurrentUser(context: ActionContext<AuthState, RootState>): Promise<void> {
            if (context.state.currentUser == null) {
                try {
                    const user = await userService.getCurrentUser()
                    setCurrentUser(context, user)
                } catch (error) {
                    // handled by router
                }
            }
        },

        async signIn(context: ActionContext<AuthState, RootState>, { email, password }: Credentials): Promise<void> {
            try {
                await authService.signIn(email, password)
                await fetchCurrentUser(context)
            } catch (error) {
                setCurrentUser(context, null)
                throw error
            }
        },

        async signOut(context: ActionContext<AuthState, RootState>): Promise<void> {
            if (context.state.currentUser != null) {
                try {
                    await authService.signOut()
                } catch (error) {
                    // handled by router
                } finally {
                    setCurrentUser(context, null)
                }
            }
        },

        async createUserAndSignIn(context: ActionContext<AuthState, RootState>, token: string): Promise<void> {
            try {
                await userService.createAndSignIn(token)
                await fetchCurrentUser(context)
            } catch (error) {
                setCurrentUser(context, null)
                throw error
            }
        },

        async validateToken(context: ActionContext<AuthState, RootState>, token: string): Promise<void> {
            setTokenValidating(context, true)

            try {
                await userService.validateToken(token)
            } finally {
                setTokenValidating(context, false)
            }
        }
    }
}

export default module


const { read, commit, dispatch } = getStoreAccessors<AuthState, RootState>("auth")

export const getCurrentUser = read(module.getters.getCurrentUser)
export const isAdminUser = read(module.getters.isAdminUser)
export const isOmnipotentUser = read(module.getters.isOmnipotentUser)
export const isOmnisolUser = read(module.getters.isOmnisolUser)
export const isFacultyWorksUser = read(module.getters.isFacultyWorksUser)
export const isSignedIn = read(module.getters.isSignedIn)
export const isTokenValidating = read(module.getters.isTokenValidating)
export const fetchCurrentUser = dispatch(module.actions.fetchCurrentUser)
export const signIn = dispatch(module.actions.signIn)
export const signOut = dispatch(module.actions.signOut)
export const createUserAndSignIn = dispatch(module.actions.createUserAndSignIn)
export const validateToken = dispatch(module.actions.validateToken)

const setCurrentUser = commit(module.mutations.setCurrentUser)
const setTokenValidating = commit(module.mutations.setTokenValidating)
