


























import Vue from "vue"
import Component from "vue-class-component"
import GenericDialogNew, {createDialogMountingPoint} from "@/components/GenericDialogNew.vue"
import {Prop} from "vue-property-decorator"

export function openMessageDialog(options: MessageDialogOptions) {
    new (Vue.extend(MessageDialog))({propsData: {options}}).$mount(createDialogMountingPoint())
}

export interface MessageDialogOptions {
    readonly title: string
    readonly message: string
    readonly multipleMessages?: boolean
    readonly asHtml?: boolean
}

@Component({
    components: {GenericDialogNew}
})
export default class MessageDialog extends Vue {

    @Prop()
    private options!: MessageDialogOptions

    private close() {
        (this.$refs.genericDialog as GenericDialogNew).close()
    }

    private get message(): string | string[] {
        if (this.options.multipleMessages) {
            return this.options.message.split("\n")
        }
        return this.options.message
    }
}
