























import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"
import {EntitySummaryDocument} from "@/types/investigations"
import DocumentExcerpts from "@/views/investigation/panes/DocumentExcerpts.vue"
import PopupMenu from "@/components/PopupMenu.vue"
import {openAddLabelToDocumentsDialog} from "@/views/investigation/dialogs/AddLabelToDocumentsDialog.vue"

@Component({
    components: {DocumentExcerpts, PopupMenu}
})
export default class DocumentExcerptsPane extends Vue {

    @Prop()
    private documents!: EntitySummaryDocument[]

    private showingAll: boolean = false
    private showDocumentCount = 10


    private get shownDocuments(): EntitySummaryDocument[] {
        if (this.showingAll) {
            return this.documents
        }
        return this.documents.slice(0, this.showDocumentCount)
    }

    private toggleShowAll() {
        this.showingAll = !this.showingAll
    }

    private openContextMenu() {
        const contextMenuOpenerButton = this.$refs.contextMenuOpener as HTMLElement
        this.$emit(`openContextMenu`, contextMenuOpenerButton)
    }

    private addLabelToAll() {
        openAddLabelToDocumentsDialog(this, { documents: this.documents.map(it => it.id) })
    }
}
