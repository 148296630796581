









































import Vue from "vue"
import Component from "vue-class-component"
import {signIn} from "@/store/auth-module"
import AuthPageContainer from "@/components/AuthPageContainer.vue"
import {Prop} from "vue-property-decorator"
import {isShowingSignUp} from "@/store/environment-module"
import {openMessageDialog} from "@/components/MessageDialog.vue"

@Component({
        components: { AuthPageContainer }
    })
    export default class SignInPage extends Vue {

        @Prop()
        private base!: string

        private email: string = ""
        private password: string = ""
        private error: boolean = false
        private emailHasFocus: boolean = false
        private passwordHasFocus: boolean = false
        private emailError = false
        private passwordError = false
        private showProgress = false

        private get showSignUp() {
            return isShowingSignUp(this.$store)
        }


        private async signIn() {
            if (this.showProgress) {
                return
            }

            this.error = false
            this.emailError = this.email === ""
            this.passwordError = this.password === ""

            if (this.emailError || this.passwordError) {
                return
            }

            this.showProgress = true
            try {
                await signIn(this.$store, { email: this.email, password: this.password })
                if (this.$route.query.redirect) {
                    this.$router.push(this.$route.query.redirect as string)
                    return
                }
                this.$router.push("/")

            } catch (error) {
                if (error.response && error.response.data === "suspended") {
                    openMessageDialog({title: "Suspended Account", message: "You have exceeded your evaluation period.<br style=\"height: 1em; display: block; content: ''''\">In order to reactivate your account, please contact info@socrates-insights.com", asHtml: true})
                }

                this.error = true
                this.showProgress = false
            }
        }
    }
