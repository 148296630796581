























import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"
import {InvestigationTermEntity} from "@/types/investigations"
import GenericDialogNew, {createDialogMountingPoint} from "@/components/GenericDialogNew.vue"

export function openRenameTermDialog(parent: Vue, options: RenameTermDialogOptions) {
    new (Vue.extend(RenameTermDialog))({
        parent,
        store: parent.$store,
        router: parent.$router,
        propsData: {options}
    }).$mount(createDialogMountingPoint())
}

export interface RenameTermDialogOptions {
    readonly term: InvestigationTermEntity
    readonly onRename: (newName: string) => void
}

@Component({
    components: { GenericDialogNew }
})
export default class RenameTermDialog extends Vue {

    @Prop()
    private options!: RenameTermDialogOptions

    private newName: string = ""


    public mounted() {
        Vue.nextTick(() => {
            this.newName = this.options.term.term

            const termNameText = this.$refs.termNameText as HTMLInputElement
            termNameText.focus()
        })
    }

    private dialogAppeared() {
        const textInput = this.$refs.termNameText as HTMLInputElement
        textInput.select()
        textInput.focus()
    }

    private close() {
        (this.$refs.genericDialog as GenericDialogNew).close()
    }

    private async rename() {
        this.close()
        this.options.onRename(this.newName)
    }
}
