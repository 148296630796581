
























import Vue from "vue"
import Component from "vue-class-component"
import moment, {Moment} from "moment"
import {getInvestigation} from "@/store/investigation/investigation-module"

@Component
  export default class ParticleLoadingScreen extends Vue {

      private interval: any
      private investigationCreationMoment: Moment = moment()
      private shouldShowLongerThanExpectedMessage: boolean = false

      private mounted() {
        const investigation = getInvestigation(this.$store)!
        this.investigationCreationMoment = moment(new Date()).subtract(investigation.age, "seconds")
        this.setShowMessage()
        if (!this.shouldShowLongerThanExpectedMessage) {
          this.interval = setInterval(this.setShowMessage, 1000)
        }
      }

      private setShowMessage() {
        this.shouldShowLongerThanExpectedMessage = this.calculateShouldShowLongerThanExpectedMessage()
        if (this.shouldShowLongerThanExpectedMessage) {
          clearInterval(this.interval)
        }
      }

      private calculateShouldShowLongerThanExpectedMessage() {
        return moment(new Date()).diff(this.investigationCreationMoment, "seconds") > 45
      }

      private beforeDestroy() {
        clearInterval(this.interval)
      }
  }

