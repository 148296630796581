import {RootState} from ".."
import {getStoreAccessors} from "vuex-typescript"


export interface PersonFilterState {
    personId: string | null
    disambiguationPersonId: string | null
}

const module = {
    getters: {
        getPersonId(state: PersonFilterState): string | null {
            return state.personId
        },

        getDisambiguationPersonId(state: PersonFilterState): string | null {
            return state.disambiguationPersonId
        }
    },

    mutations: {
        setPersonId(state: PersonFilterState, personId: string | null) {
            state.personId = personId
        },

        setDisambiguationPersonId(state: PersonFilterState, disambiguationPersonId: string | null) {
            state.disambiguationPersonId = disambiguationPersonId
        }
    }
}

export default module

const { read, commit } = getStoreAccessors<PersonFilterState, RootState>("investigation")

export const getPersonId = read(module.getters.getPersonId)
export const setPersonId = commit(module.mutations.setPersonId)
export const getDisambiguationPersonId = read(module.getters.getDisambiguationPersonId)
export const setDisambiguationPersonId = commit(module.mutations.setDisambiguationPersonId)
