


























import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import GenericDialogNew, {createDialogMountingPoint} from "@/components/GenericDialogNew.vue"
import {Prop} from "vue-property-decorator"
import {getDocumentLabels} from "@/store/investigation/investigation-module"
import investigationService from "@/api/investigation-service"
import {InvestigationMixin} from "@/store/investigation-mixin"

export function openAddLabelToDocumentsDialog(parent: Vue, options: AddLabelToDocumentsDialogOptions) {
    new (Vue.extend(AddLabelToDocumentsDialog))({parent, store: parent.$store, propsData: {options}}).$mount(createDialogMountingPoint())
}

export interface AddLabelToDocumentsDialogOptions {
    readonly documents: string[]
}


@Component({
    components: {GenericDialogNew}
})
export default class AddLabelToDocumentsDialog extends mixins(Vue, InvestigationMixin) {

    private newLabel: string = ""
    private existingLabels: string[] = []

    @Prop()
    private options!: AddLabelToDocumentsDialogOptions


    private mounted() {
        this.existingLabels = getDocumentLabels(this.$store)
    }


    private dialogAppeared() {
        const labelText = this.$refs.labelText as HTMLInputElement
        labelText.focus()
    }


    private close() {
        (this.$refs.genericDialog as GenericDialogNew).close()
    }


    private async addLabel() {
        if (this.newLabel.trim().length === 0) {
            return
        }

        this.close()

        const newLabel = this.newLabel.trim()
        await investigationService.addLabelToSeveralDocuments(this.investigation!!.id, this.options.documents, newLabel, this.investigationType)
    }
}
