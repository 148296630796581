
































































































































import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import {InvestigationEntity, InvestigationType} from "@/types/investigations"
import {deleteInvestigation, duplicateInvestigation, fetchAllInvestigations, getFreeTextFilter, getInvestigations, isBusyFetchingAllInvestigations, setFreeTextFilter} from "@/store/investigations-module"
import {Moment} from "moment"
import {setInvestigation} from "@/store/investigation/investigation-module"
import SpinningLoader from "@/components/SpinningLoader.vue"
import InvestigationSelector from "@/components/InvestigationSelector.vue"
import PopupMenu from "@/components/PopupMenu.vue"
import {
    companyInvestigationType, facultyWorksInvestigationType,
    getInvestigationCreationType,
    InvestigationCreationType,
    localDocumentsInvestigationType,
    personGroupInvestigationType,
    personInvestigationType,
    politicalInvestigationType,
    researcherWorksInvestigationType,
    tenderInvestigationType,
    topicInvestigationType,
    universityInvestigationType,
    websiteInvestigationType
} from "@/types/new-investigation"
import {isAllowDuplicateInvestigation, isSupportAcademicResearcherGroupInvestigations, isSupportCompanyInvestigations, isSupportLocalDocumentsInvestigations, isSupportPoliticalInvestigations, isSupportTenderInvestigations, isSupportUniversityInvestigations, isSupportWebsiteInvestigations} from "@/store/environment-module"
import {openConfirmationDialog} from "@/views/investigation/dialogs/ConfirmationDialog.vue"
import {PermissionsMixin} from "@/store/user-mixin"
import {Watch} from "vue-property-decorator"
import FacultyWorksSelector from "@/components/FacultyWorksSelector.vue"

@Component({
    components: {FacultyWorksSelector, PopupMenu, InvestigationSelector, SpinningLoader}
})
export default class InvestigationsPage extends mixins(Vue, PermissionsMixin) {

    private getInvestigations = this.$wrap(getInvestigations)
    private fetchAllInvestigations = this.$wrap(fetchAllInvestigations)
    private pollingTimeout?: number
    private loadedInvestigations: boolean = false
    private freeText: string = ""
    private menuPosition: { left: number, top: number } = {left: 0, top: 0}
    private highlightedInvestigation: boolean = false
    private freeTextListener!: () => void

    private investigationTypes(): InvestigationCreationType[] {
        if (this.isFacultyWorksUser) {
            return [facultyWorksInvestigationType, researcherWorksInvestigationType]
        }

        const investigationTypes: InvestigationCreationType[] = [topicInvestigationType]

        if (isSupportCompanyInvestigations(this.$store)) {
            investigationTypes.push(companyInvestigationType)
        }

        if (isSupportTenderInvestigations(this.$store)) {
            investigationTypes.push(tenderInvestigationType)
        }

        investigationTypes.push(personInvestigationType)

        if (isSupportAcademicResearcherGroupInvestigations(this.$store)) {
            investigationTypes.push(personGroupInvestigationType)
        }

        if (isSupportUniversityInvestigations(this.$store)) {
            investigationTypes.push(universityInvestigationType)
        }

        if (isSupportWebsiteInvestigations(this.$store)) {
            investigationTypes.push(websiteInvestigationType)
        }

        if (isSupportPoliticalInvestigations(this.$store)) {
            investigationTypes.push(politicalInvestigationType)
        }

        if (isSupportLocalDocumentsInvestigations(this.$store)) {
            investigationTypes.push(localDocumentsInvestigationType)
        }

        return investigationTypes
    }

    get investigations(): InvestigationEntity[] {
        const investigations = this.getInvestigations().slice().sort((a, b): number =>
            b.createdOn.valueOf() - a.createdOn.valueOf() || (b.id as any) - (a.id as any)
        )
        return this.freeText ?
            investigations.filter(investigation =>
                investigation.id.includes(this.freeText!) ||
                this.investigationSubType(investigation).toLowerCase().includes(this.freeText.toLowerCase()) ||
                investigation.uuid.includes(this.freeText!) ||
                investigation.createdBy.toLowerCase().includes(this.freeText!.toLowerCase()) ||
                (investigation.createdOn.format("DD-MMM-YYYY").includes(this.freeText!) && this.isOmnipotentUser) ||
                (investigation.lastUserInteractionTime && investigation.lastUserInteractionTime.format("DD-MMM-YYYY").includes(this.freeText!)) ||
                investigation.title.toLowerCase().includes(this.freeText!.toLowerCase()) ||
                investigation.email.toLowerCase().includes(this.freeText!.toLowerCase())
            ) :
            investigations
    }

    private investigationSubType(investigation: InvestigationEntity): string {
        return getInvestigationCreationType(investigation.investigationSubType)!!.tag
    }

    private investigationSubTypeColor(investigation: InvestigationEntity): string {
        return getInvestigationCreationType(investigation.investigationSubType)!!.color
    }

    get isBusyFetchingAllInvestigations(): boolean {
        return isBusyFetchingAllInvestigations(this.$store)
    }

  private get isAllowDuplicateInvestigation(): boolean {
    return isAllowDuplicateInvestigation(this.$store)
  }

  get showInvestigations(): boolean {
        return this.investigations.length > 0 && !this.isBusyFetchingAllInvestigations
    }

    get showNoInvestigations(): boolean {
        return this.loadedInvestigations && !this.isBusyFetchingAllInvestigations
    }

    private formatDate(date: Moment) {
        return date.format("DD-MMM-YYYY HH:mm")
    }

    private mounted() {
        setInvestigation(this.$store, null)
        this.freeText = getFreeTextFilter(this.$store)
        this.pollInvestigations(this.investigations.length === 0)

        this.freeTextListener = this.$store.watch(state => getFreeTextFilter(this.$store), (newValue, oldValue) => {
            this.freeText = newValue
        })
    }

    private beforeDestroy() {
        clearTimeout(this.pollingTimeout)

        if (this.freeTextListener) {
            this.freeTextListener()
        }

    }

    private async pollInvestigations(showSpinner: boolean) {
        clearTimeout(this.pollingTimeout)

        await this.fetchAllInvestigations(showSpinner)
        this.highlightInvestigation()
        this.loadedInvestigations = true

        this.pollingTimeout = setTimeout(() => this.pollInvestigations(false), 5000)

        this.$nextTick(() => {
            if (this.$route.fullPath.endsWith("#investigation-table")) {
                this.$router.push({name: "investigations"})
                this.scrollToInvestigations()
            }
        })
    }

    private goToInvestigation(investigation: InvestigationEntity) {
        const {id, investigationType} = investigation
        const name = `${investigationType.toLowerCase()}Investigation`
        const tab = this.isFacultyWorksUser || investigationType === "Topic" ? "documents" : "author-graph"
        this.$router.push({name, params: {id, tab}})
    }

    private async askDeleteInvestigation(event: MouseEvent, investigation: InvestigationEntity) {
        openConfirmationDialog({
            message: "You are about to permanently delete this entire investigation",
            title: "Delete Investigation",
            okText: "Delete",
            onOk: () => this.deleteInvestigation(investigation),
            showCancel: true
        })
    }

    private async askDuplicateInvestigation(event: MouseEvent, investigation: InvestigationEntity) {
        openConfirmationDialog({
            message: "You are about to duplicate this investigation",
            title: "Duplicate Investigation",
            okText: "Duplicate",
            onOk: () => this.duplicateInvestigation(investigation),
            showCancel: true
        })
    }

    private async deleteInvestigation(investigation: InvestigationEntity) {
        await deleteInvestigation(this.$store, investigation.id)
    }

    private async duplicateInvestigation(investigation: InvestigationEntity) {
        await duplicateInvestigation(this.$store, investigation.id)
    }

    private investigationTypeClass(type: InvestigationType) {
        return {topic: type === "Topic", person: type === "Person"}
    }

    private showPending(investigation: InvestigationEntity): boolean {
        return (investigation.investigationType === "Person" || investigation.investigationSubType === "University") && investigation.status === "PENDING"
    }

    private showProcessing(investigation: InvestigationEntity): boolean {
        return (investigation.investigationType === "Person" || investigation.investigationSubType === "University") && investigation.status === "PROCESSING"
    }

    private showFailed(investigation: InvestigationEntity): boolean {
        return (investigation.investigationType === "Person" || investigation.investigationSubType === "University") && investigation.status === "FAILED"
    }

    private showReady(investigation: InvestigationEntity): boolean {
        return (investigation.investigationType === "Person" || investigation.investigationSubType === "University") && !investigation.lastUserInteractionTime && investigation.status === "READY"
    }

    private get shouldShowClearFreeTextSearchButton() {
        return this.freeText !== null && this.freeText !== ""
    }

    private highlightInvestigation() {
        const investigationId = this.$route.query.highlight
        if (investigationId && !this.highlightedInvestigation) {
            this.highlightedInvestigation = true
            const refs = this.$refs[`investigation-${investigationId}`] as HTMLElement[]
            if (refs.length === 0) {  // tomer: I don't know why this sometimes happens
                return
            }
            const investigation = refs[0] as HTMLElement
            investigation.scrollIntoView({block: "center"})
            if (!this.isFacultyWorksUser) {
                const menuRef = this.$refs.menuRef as HTMLElement
                const boundingClientRect = investigation.getBoundingClientRect()
                this.menuPosition = {left: boundingClientRect.left + 40, top: boundingClientRect.top - 170}
                setTimeout(() => this.$emit("openUserMenu", menuRef, true), 0)
            }
        }
    }

    private scrollToInvestigations() {
        const investigations = this.$refs.investigations as HTMLElement
        investigations.scrollIntoView({behavior: "smooth", block: "start"})
    }

    @Watch("freeText")
    private freeTextChanged(event: Event) {
        setFreeTextFilter(this.$store, this.freeText)
    }
}
