







import {Component, Vue} from "vue-property-decorator"
import {onResponseError} from "@/api/base-service"
import {signOut} from "@/store/auth-module"
import axios from "axios"
import {getGroupColor} from "@/utils/color-utils"
import {openErrorMessageDialog} from "@/components/ErrorMessageDialog.vue"

Component.registerHooks([
    "beforeRouteEnter",
    "beforeRouteLeave",
    "beforeRouteUpdate"
])
@Component
export default class App extends Vue {

    public async created() {
        axios.defaults.headers.post["Client-Id"] = this.generateUuid()
        onResponseError(async error => {
            if (!axios.isCancel(error)) {
                if (error.response.status === 401) {
                    await signOut(this.$store)
                    const invalidUserData = error.response.data === "Invalid user data"
                    const isInSignIn = this.$router.currentRoute.name === "sign-in"
                    if (invalidUserData || isInSignIn) {
                        throw error
                    } else {
                        this.$router.push({name: "sign-in"})
                    }
                } else if (error.response.status === 400 && ["Invalid registration token", "Duplicate", "Email error"].includes(error.response.data)) {
                    throw error
                } else if (error.response.status === 410) {
                    throw error
                } else {
                    openErrorMessageDialog()
                }
            }
        })

        this.initializeCommonStyles()
    }

    private generateUuid() {
        // @ts-ignore
        // tslint:disable-next-line
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))
    }


    private initializeCommonStyles() {
        const style = document.createElement("style")
        style.type = "text/css"
        let innerHtml = ""
        for (let i = 1; i <= 10; i++) {
            innerHtml += `.group-${i}-text { background-color: ${getGroupColor(i.toString(), false)}; }\n`
        }
        style.innerHTML = innerHtml
        document.getElementsByTagName("head")[0].appendChild(style)
    }
}
