





















import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"

let veilZIndex = 9000

export function createDialogMountingPoint(): HTMLDivElement {
    const mountingElement = document.createElement("div")
    let container = document.getElementById("modal-container")
    if (!container) {
        container = document.body
    }
    container!.appendChild(mountingElement)
    return mountingElement
}

@Component
export default class GenericDialogNew extends Vue {

    @Prop(Boolean)
    private modal!: boolean

    @Prop(Boolean)
    private maximized!: boolean

    @Prop(Boolean)
    private embedded!: boolean

    @Prop({type: Boolean, default: true})
    private escapeCloses!: boolean

    private componentVeilZIndex: number = veilZIndex += 2
    private visible = false

    public mounted() {
        document.addEventListener("keyup", this.handleKey)

        this.$nextTick(() => {
            this.visible = true
        })
    }

    public close() {
        this.visible = false
    }

    private remove() {
        document.removeEventListener("keyup", this.handleKey)

        this.$el.parentElement!.removeChild(this.$el)
        this.$destroy()
    }

    private handleKey(e: KeyboardEvent) {
        switch (e.key) {
            case "Escape":
                if (this.escapeCloses) {
                    this.close()
                }
                break

            case "Enter":
                this.$emit("enterPressed")
                break
        }
    }

    private onVeilClicked() {
        if (!this.modal) {
            this.close()
        }
    }

    private afterEnter() {
        this.$emit("dialogAppeared")
    }
}
