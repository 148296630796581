


























import Vue from "vue"
import Component from "vue-class-component"
import GenericDialog from "@/components/GenericDialog.vue"
import InvestigationService from "../../../api/investigation-service"
import {getInvestigation} from "@/store/investigation/investigation-module"

@Component({
        components: {GenericDialog}
    })
    export default class HarvestByDoiDialog extends Vue {

        private isOpen = false
        private errorMessage: string = ""
        private text: string = ""


        public open() {
            this.isOpen = true
            this.errorMessage = ""
            document.addEventListener("keyup", this.handleKey)

            Vue.nextTick(() => {
                const nameInput = this.$refs.text as HTMLInputElement
                nameInput.focus()
            })
        }

        private close() {
            this.isOpen = false
            document.removeEventListener("keyup", this.handleKey)
        }


        private handleKey(e: KeyboardEvent) {
            switch (e.key) {
                case "Escape":
                    this.close()
                    break
            }
        }


        private startHarvest() {
            if (this.text.length === 0) {
                this.errorMessage = "Please enter a DOI list"
                return
            }

            let doiListToSend = []
            if (this.text[0] === "[") {
                try {
                    doiListToSend = JSON.parse(this.text)
                } catch (e) {
                    this.errorMessage = "DOI list is not a valid JSON"
                    return
                }
            } else {
                doiListToSend = this.text.split("\n")
            }

            const investigation = getInvestigation(this.$store)!!

            this.errorMessage = ""
            InvestigationService.createHarvestByDoiList(investigation.id, investigation.investigationType, doiListToSend)
            this.text = ""
            this.close()
        }
    }
