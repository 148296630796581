import axios from "axios"
import { UserEntity } from "@/types/user"
import { followRedirect } from "@/api/redirect"


export default {
    async signUp(nickname: string, email: string, password: string) {
        await followRedirect(() => axios.post("/api/users/sign-up", { nickname, email, password }))
    },

    async reset(email: string) {
        await followRedirect(() => axios.post(`/api/users/reset`, { email }))
    },

    async createAndSignIn(token: string) {
        await followRedirect(() => axios.post("/api/users/create-sign-in", token))
    },

    async createUser(nickname: string, email: string, password: string) {
        await followRedirect(() => axios.post("/api/users", { nickname, email, password }))
    },

    async getCurrentUser(): Promise<UserEntity> {
        const response = await axios.get(`/api/users/current`)
        return response.data as UserEntity
    },

    async validateToken(token: string) {
        await followRedirect(() => axios.post(`/api/users/validate-reset`, token))
    },

    async changePasswordAndSignIn(token: string, password: string) {
        await axios.post("/api/users/change-password-sign-in", {token, password})
    }
}
