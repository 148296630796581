












import Vue from "vue"
import Component from "vue-class-component"
import { getCurrentUser, signOut } from "@/store/auth-module"
import { UserEntityOptional } from "@/types/user"
import PopupMenu from "@/components/PopupMenu.vue"

@Component({
    components: {PopupMenu}
})
export default class UserMenu extends Vue {

    private opened: boolean = false

    private openUserMenu() {
        const userButton = this.$refs.userButton as HTMLElement
        this.$emit("openUserMenu", userButton)
    }

    private async signOut() {
        await signOut(this.$store)
        this.$router.push({ name: "sign-in" })
    }

    private get user(): UserEntityOptional {
        return getCurrentUser(this.$store)
    }
}
