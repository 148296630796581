import { render, staticRenderFns } from "./ObservationItem.vue?vue&type=template&id=fbcd8d06&scoped=true&"
import script from "./ObservationItem.vue?vue&type=script&lang=ts&"
export * from "./ObservationItem.vue?vue&type=script&lang=ts&"
import style0 from "./ObservationItem.vue?vue&type=style&index=0&id=fbcd8d06&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbcd8d06",
  null
  
)

export default component.exports