










import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"

@Component
export default class GraphImage extends Vue {

    @Prop({default: null})
    private dataUrl!: string | null

    @Prop({default: null})
    private imageId!: string | null

    private imageLoaded: boolean = false

    private get imageUrl() {
        return `/api/images?imageId=${this.imageId}`
    }
}

