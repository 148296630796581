import axios from "axios"
import { followRedirect } from "@/api/redirect"


export default {
    async signIn(email: string, password: string) {
        await followRedirect(() => axios.post("/api/auth/sign-in", { email, password }))
    },

    async signOut() {
        await axios.post("/api/auth/sign-out")
    }
}
