























import Vue from "vue"
import Component from "vue-class-component"
import GenericDialog from "@/components/GenericDialog.vue"
import {InvestigationLabelType, InvestigationType} from "@/types/investigations"
import {renameDocumentLabel} from "@/store/investigation/documents-module"
import {renameMentionedEntityLabel} from "@/store/investigation/graph-module"

@Component({
        components: {GenericDialog}
    })
    export default class RenameLabelDialog extends Vue {

        private isOpen = false
        private oldLabel: string = ""
        private newLabel: string = ""
        private investigationType: InvestigationType = "Topic"
        private labelType: InvestigationLabelType = "Entity"


        public open(options: {label: string, investigationType: InvestigationType, labelType: InvestigationLabelType}) {
            this.isOpen = true
            this.newLabel = this.oldLabel = options.label
            this.investigationType = options.investigationType
            this.labelType = options.labelType
            document.addEventListener("keyup", this.handleKey)

            Vue.nextTick(() => {
                const labelNameText = this.$refs.labelNameText as HTMLInputElement
                labelNameText.focus()
            })
        }


        private close() {
            this.isOpen = false
            document.removeEventListener("keyup", this.handleKey)
        }


        private handleKey(e: KeyboardEvent) {
            switch (e.key) {
                case "Escape":
                    this.close()
                    break
                case "Enter":
                  this.renameLabel()
                  break
            }
        }

        private async renameLabel() {
            this.close()
            const {oldLabel, newLabel, investigationType} = this
            this.labelType === "Document" ?
                await renameDocumentLabel(this.$store, {oldLabel, newLabel, investigationType}) :
                await renameMentionedEntityLabel(this.$store, {oldLabel, newLabel, investigationType})
        }
    }
