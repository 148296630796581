import axios from "axios"
import {InvestigationEntity} from "@/types/investigations"
import moment from "moment"


export default {

    async getAll(forceAll: boolean): Promise<InvestigationEntity[]> {
        const response = await axios.get("/api/investigations", { params: { forceAll } })
        return response.data.map((entity: any) => toInvestigation(entity))
    },


    async createTopic(searchString: string, includePublications: boolean, includeWebPages: boolean, political: boolean): Promise<InvestigationEntity> {
        const response = await axios.post("/api/investigations/topic", {searchString, includePublications, includeWebPages, political})
        return toInvestigation(response.data)
    },


    async createTender(searchString: string): Promise<InvestigationEntity> {
        const response = await axios.post("/api/investigations/topic/tender", {searchString})
        return toInvestigation(response.data)
    },


    async createCompany(companyName: string, companyWebSite: string): Promise<InvestigationEntity> {
        const response = await axios.post("/api/investigations/topic/company", {companyName, companyWebSite})
        return toInvestigation(response.data)
    },


    async createPerson(personName: string, includePublications: boolean, includeWebPages: boolean): Promise<InvestigationEntity> {
        const response = await axios.post("/api/investigations/person", {personName, includePublications, includeWebPages})
        return toInvestigation(response.data)
    },


    async createPersonGroup(instituteName: string, facultyName: string, researcherNames: string[], includePublications: boolean, includeWebPages: boolean): Promise<InvestigationEntity> {
        const response = await axios.post("/api/investigations/person/group", {instituteName, facultyName, researcherNames, includePublications, includeWebPages})
        return toInvestigation(response.data)
    },


    async createUniversity(universityName: string, country: string, startYear: number, endYear: number, includePublications: boolean, includeWebPages: boolean): Promise<InvestigationEntity> {
        const response = await axios.post("/api/investigations/topic/university", {universityName, country, startYear, endYear, includePublications, includeWebPages})
        return toInvestigation(response.data)
    },


    async createWebsite(investigationName: string, websiteUrl: string, includePublications: boolean, includeWebPages: boolean): Promise<InvestigationEntity> {
        const response = await axios.post("/api/investigations/topic/website", {investigationName, websiteUrl, includePublications, includeWebPages})
        return toInvestigation(response.data)
    },

    async createFacultyWorks(instituteName: string, facultyName: string, researcherNames: string[],
                             includePublications: boolean, includeWebPages: boolean): Promise<InvestigationEntity> {
        const response = await axios.post(
            "/api/investigations/person/facultyworks",
            {instituteName, facultyName, researcherNames, includePublications, includeWebPages})
        return toInvestigation(response.data)
    },

    async createResearcherWorks(instituteName: string, facultyName: string, researcherName: string,
                                includePublications: boolean, includeWebPages: boolean): Promise<InvestigationEntity> {
        const researcherNames = [researcherName]
        const response = await axios.post(
            "/api/investigations/person/researcherworks",
            {instituteName, facultyName, researcherNames, includePublications, includeWebPages})
        return toInvestigation(response.data)
    },

    async getMagAffiliations(): Promise<string[]> {
        const response = await axios.get(`/api/investigations/topic/mag-affiliations`)
        return response.data as string[]
    },


    async createPersonBatch(file: File): Promise<void> {
        const formData = new FormData()
        formData.set("file", file)

        await axios.post("/api/investigations/person/batch", formData)
    },

    async createInvestigationsWithDefinitions(file: File): Promise<void> {
        const formData = new FormData()
        formData.set("fileName", file.name)
        formData.set("file", file)

        const response = await axios.post("/api/investigation_definition/create", formData)
        if (response.data) {
            throw response.data
        }
    },

    async deleteAllPendingInvestigations(): Promise<void> {
        await axios.delete("/api/investigations/person/pending")
    },

    async restartFailedInvestigations(): Promise<void> {
        await axios.post("/api/investigations/person/restart-failed")
    },

    async update(investigationId: string, title: string, goals?: string, alertsEnabled?: boolean): Promise<InvestigationEntity> {
        const response = await axios.patch(`/api/investigations/${investigationId}`, {title, goals, alertsEnabled})
        return response.data
    },

    async duplicate(investigationId: string): Promise<InvestigationEntity> {
        const response = await axios.post(`/api/investigations/${investigationId}/duplicate`, investigationId)
        return response.data
    },

    async delete(investigationId: string): Promise<void> {
        await axios.delete(`/api/investigations/${investigationId}`)
    }
}


function toInvestigation(entity: InvestigationEntity): InvestigationEntity {
    return {
        ...entity,
        createdOn: moment(entity.createdOn),
        lastUserInteractionTime: entity.lastUserInteractionTime ? moment(entity.lastUserInteractionTime) : undefined
    }
}
