import {
    DocumentEntity,
    DocumentMarkupsEntity,
    DocumentOverwrites,
    DocumentStatusEntity,
    InvestigationAuthor,
    InvestigationType,
    Relevancy,
    TopExpansion,
    TopSort
} from "@/types/investigations"
import {RootState} from ".."
import {getInvestigationId} from "./get-investigation-id"
import investigationService from "@/api/investigation-service"
import InvestigationService from "@/api/investigation-service"
import {ActionContext} from "vuex"
import {getStoreAccessors} from "vuex-typescript"
import {
    getAvailableDocumentIds,
    getDocumentLabels,
    getTopLabels,
    InvestigationState,
    setDocumentLabels,
    setTopLabels
} from "@/store/investigation/investigation-module"
import moment from "moment"
import investigationDocumentService from "@/api/investigation-document-service"


const module = {
    getters: {
        getDocumentMarkups(state: InvestigationState): DocumentMarkupsEntity[] {
            return state.documentMarkups
        },

        getDocuments(state: InvestigationState): DocumentEntity[] {
            return state.documents
        },

        getViewModeLevel(state: InvestigationState): number {
            return state.viewModeLevel
        },

        getHighlightModes(state: InvestigationState): string[] {
            return state.highlightModes
        },

        getTopsExpansion(state: InvestigationState): {[key: string]: boolean} {
            return state.topsExpansion
        },

        getGroupsExpansion(state: InvestigationState): {[key: string]: boolean} {
            return state.groupsExpansion
        },

        getTopsSorting(state: InvestigationState): {[key: string]: boolean | undefined} {
            return state.topsSorting
        }
    },

    mutations: {
        setDocuments(state: InvestigationState, documents: DocumentEntity[]) {
            state.documents = documents
        },

        setDocumentMarkups(state: InvestigationState, documentMarkups: DocumentMarkupsEntity[]) {
            state.documentMarkups = documentMarkups
        },

        setDocumentStatus(state: InvestigationState, documentStatus: DocumentStatusEntity) {
            const index = state.documents.findIndex(it => it.id === documentStatus.id)

            if (index >= 0) {
                const originalDocument = state.documents[index]
                state.documents.splice(index, 1, { ...originalDocument, ...documentStatus })
            }
        },

        setDocument(state: InvestigationState, document: DocumentEntity) {
            const index = state.documents.findIndex(it => it.id === document.id)

            if (index >= 0) {
                state.documents.splice(index, 1, document)
            }
        },

        updateDocumentId(state: InvestigationState, payload: {oldId: string, newId: string}) {
            const document = state.documents.find(it => it.id === payload.oldId)

            if (document) {
                document.id = payload.newId
            }
        },

        setViewModeLevel(state: InvestigationState, level: number) {
            state.viewModeLevel = level
            localStorage.setItem("viewModeLevel", level.toString())
        },

        setHighlightModes(state: InvestigationState, level: string[]) {
            state.highlightModes = level
        },

        setTopExpanded(state: InvestigationState, expanded: TopExpansion) {
            const {type, isExpanded} = expanded
            const newTopsExpansion = {...state.topsExpansion}
            newTopsExpansion[type] = isExpanded
            state.topsExpansion = newTopsExpansion
        },

        setGroupsExpansion(state: InvestigationState, expansion: {[key: string]: boolean}) {
            state.groupsExpansion = expansion
        },

        setTopSorting(state: InvestigationState, sortedByRelevant: TopSort) {
            const {type, isSortedByRelevant} = sortedByRelevant
            state.topsSorting = {...state.topsSorting}
            state.topsSorting[type] = isSortedByRelevant
        },

        clearTopsSorting(state: InvestigationState) {
            state.topsSorting = {}
        },

        clearTopsExpansion(state: InvestigationState) {
            state.topsExpansion = {}
        },

        clearGroupsExpansion(state: InvestigationState) {
            state.groupsExpansion = {}
        },

        setDocumentImageId(state: InvestigationState, sortedByRelevant: TopSort) {
            const {type, isSortedByRelevant} = sortedByRelevant
            state.topsSorting = {...state.topsSorting}
            state.topsSorting[type] = isSortedByRelevant
        },

        updateDocumentLabelInInvestigation(state: InvestigationState, payload: {oldLabel: string, newLabel: string}) {
            const {oldLabel, newLabel} = payload
            const labelIndex = state.documentLabels.findIndex(currentLabel => oldLabel.toLocaleLowerCase() === currentLabel.toLocaleLowerCase())

            if (labelIndex > -1) {
                state.documentLabels.splice(labelIndex, 1, newLabel)
            }
        },

        deleteDocumentLabelFromInvestigation(state: InvestigationState, label: string) {
            const labelIndex = state.documentLabels.findIndex(currentLabel => label.toLocaleLowerCase() === currentLabel.toLocaleLowerCase())

            if (labelIndex > -1) {
                state.documentLabels.splice(labelIndex, 1)
            }
        }
    },

    actions: {
        async setDocumentRelevancy(context: ActionContext<InvestigationState, RootState>, { id, relevancy, investigationType, mainTerms }: { id: string, relevancy: Relevancy, investigationType: InvestigationType, mainTerms: string[] }): Promise<void> {
            const original = {...getDocument(context, id)}
            setDocumentStatus(context, { id, relevancy, touched: true, starred: original.starred })

            try {
                const updatedStatus = await investigationService.setDocumentRelevancy(getInvestigationId(context, "set document starred"), id, relevancy, investigationType, mainTerms)
                if (updatedStatus) {
                    setDocumentStatus(context, updatedStatus)
                }

            } catch (e) {
                setDocumentStatus(context, { id, relevancy: original.relevancy, touched: original.touched, starred: original.starred })
            }
        },

        async setDocumentStarred(context: ActionContext<InvestigationState, RootState>, { id, starred, investigationType }: { id: string, starred: boolean, investigationType: InvestigationType }): Promise<void> {
            const original = {...getDocument(context, id)}
            setDocumentStatus(context, { id, relevancy: original.relevancy, touched: true, starred })

            try {
                const updatedStatus = await investigationService.setDocumentStarred(getInvestigationId(context, "set document starred"), id, starred, investigationType)
                if (updatedStatus) {
                    setDocumentStatus(context, updatedStatus)
                }

            } catch (e) {
                setDocumentStatus(context, { id, relevancy: original.relevancy, touched: original.touched, starred })
            }
        },

        async setDocumentRead(context: ActionContext<InvestigationState, RootState>, {id, investigationType}: { id: string, investigationType: InvestigationType }): Promise<void> {
            const original = {...getDocument(context, id)}
            setDocumentStatus(context, { id, relevancy: "Neutral", touched: true })

            try {
                const updatedStatus = await investigationService.setDocumentRelevancy(getInvestigationId(context, "set document visited"), id, "Neutral", investigationType, [])
                if (updatedStatus) {
                    setDocumentStatus(context, updatedStatus)
                }

            } catch (e) {
                setDocumentStatus(context, { id, relevancy: original.relevancy, touched: original.touched })
            }
        },

        async setDocumentNote(context: ActionContext<InvestigationState, RootState>, { id, note, investigationType }: { id: string, note?: string, investigationType: InvestigationType }): Promise<void> {
            const original = {...getDocument(context, id)}
            setDocumentStatus(context, { id, note, touched: true })

            try {
                const updatedStatus = await investigationService.setDocumentNote(getInvestigationId(context, "set document note"), id, investigationType, note)
                if (updatedStatus) {
                    setDocumentStatus(context, updatedStatus)
                }

            } catch (e) {
                setDocumentStatus(context, { id, note: original.note, touched: original.touched })
            }
        },

        async addDocumentLabel(context: ActionContext<InvestigationState, RootState>, payload: {label: string, document: DocumentEntity, investigationType: InvestigationType}): Promise<void> {
            const {document, label, investigationType} = payload
            const oldDocument = {...document}
            const oldLabels = [...getDocumentLabels(context)]
            if (document.labels.find(documentLabel => documentLabel.toLocaleLowerCase() === label.toLocaleLowerCase()) === undefined) {
                document.labels.push(label)
            }
            if (oldLabels.find(oldLabel => oldLabel.toLocaleLowerCase() === label.toLocaleLowerCase()) === undefined) {
                const newLabels = [...oldLabels, label]
                setDocumentLabels(context, newLabels)
            }
            setDocument(context, document)

            const result = await investigationService.addDocumentLabel(getInvestigationId(context, "add label"), document.id, label, investigationType)
            if (!result) {
                setDocument(context, oldDocument)
                setDocumentLabels(context, oldLabels)
            }
        },

        async removeDocumentLabel(context: ActionContext<InvestigationState, RootState>, payload: {label: string, document: DocumentEntity, investigationType: InvestigationType}): Promise<boolean> {
            const {document, label, investigationType} = payload
            const oldDocument = {...document}
            const labelIndex = document.labels.findIndex(documentLabel => documentLabel.toLocaleLowerCase() === label.toLocaleLowerCase())
            if (labelIndex > -1) {
                document.labels.splice(labelIndex, 1)
            }
            setDocument(context, document)

            const result = await investigationService.removeDocumentLabel(getInvestigationId(context, "remove label"), document.id, label, investigationType)
            if (!result) {
                setDocument(context, oldDocument)
            }
            return !!result
        },

        async renameDocumentLabel(context: ActionContext<InvestigationState, RootState>, payload: {oldLabel: string, newLabel: string, investigationType: InvestigationType}): Promise<void> {
            const {oldLabel, newLabel, investigationType} = payload
            const oldLabels = [...getDocumentLabels(context)]
            updateDocumentLabelInInvestigation(context, {oldLabel, newLabel})
            const oldTopLabels = [...getTopLabels(context)]
            const newTopLabels = [...oldTopLabels]
            const topLabel = newTopLabels.find(newTopLabel => newTopLabel.name.toLocaleLowerCase() === oldLabel.toLocaleLowerCase())
            if (topLabel) { topLabel.name = newLabel }
            setTopLabels(context, newTopLabels)
            const oldDocuments = [...getDocuments(context)]
            const newDocuments = [...oldDocuments]
            newDocuments.forEach(newDocument => {
                const labelIndex = newDocument.labels.findIndex(documentLabel => oldLabel.toLocaleLowerCase() === documentLabel.toLocaleLowerCase())

                if (labelIndex > -1) {
                    newDocument.labels.splice(labelIndex, 1, newLabel)
                }
            })
            setDocuments(context, newDocuments)

            const result = await investigationService.renameDocumentLabel(getInvestigationId(context, "rename label"), {oldLabel, newLabel}, investigationType)
            if (!result) {
                setDocumentLabels(context, oldLabels)
                setTopLabels(context, oldTopLabels)
                setDocuments(context, oldDocuments)
            }
        },

        async deleteDocumentLabel(context: ActionContext<InvestigationState, RootState>, payload: {label: string, investigationType: InvestigationType}): Promise<void> {
            const {label, investigationType} = payload
            const oldLabels = [...getDocumentLabels(context)]
            deleteDocumentLabelFromInvestigation(context, label)

            const oldDocuments = [...getDocuments(context)]
            const newDocuments = [...oldDocuments]
            newDocuments.forEach(document => {
                const labelIndex = document.labels.findIndex(currentLabel => label.toLocaleLowerCase() === currentLabel.toLocaleLowerCase())

                if (labelIndex > -1) {
                    document.labels.splice(labelIndex, 1)
                }
            })
            setDocuments(context, newDocuments)

            const oldTopLabels = [...getTopLabels(context)]
            const newTopLabels = [...oldTopLabels]
            const topLabelIndex = newTopLabels.findIndex(topLabel => label.toLocaleLowerCase() === topLabel.name.toLocaleLowerCase())

            if (topLabelIndex > -1) {
                newTopLabels.splice(topLabelIndex, 1)
            }
            setTopLabels(context, newTopLabels)

            const result = await investigationService.deleteDocumentLabel(getInvestigationId(context, "delete label"), label, investigationType)
            if (!result) {
                setDocumentLabels(context, oldLabels)
                setTopLabels(context, oldTopLabels)
                setDocuments(context, oldDocuments)
            }
        },

        async bulkMarkRead(context: ActionContext<InvestigationState, RootState>, investigationType: InvestigationType): Promise<void> {
            const investigationId = getInvestigationId(context, "bulk-mark-read")
            const docIds = getAvailableDocumentIds(context)
            await investigationService.bulkMarkRead(investigationId, investigationType, docIds)
        },

        async bulkApplyDocumentLabel(context: ActionContext<InvestigationState, RootState>, payload: {label: string, add: boolean, investigationType: InvestigationType}): Promise<void> {
            const { label, add, investigationType } = payload
            const investigationId = getInvestigationId(context, "bulk-apply-label")
            const docIds = getAvailableDocumentIds(context)
            await investigationService.bulkApplyDocumentLabel(investigationId, investigationType, label, add, docIds)
        },

        async updateDocumentCoverImage(context: ActionContext<InvestigationState, RootState>,
                                       payload: {id: string, file: File | null, journalImage: boolean}): Promise<void> {
            const doc = {...getDocument(context, payload.id)}
            const investigation = context.rootState.investigation.investigation!!

            if (payload.file) {
                doc.coverImageId = await investigationDocumentService.updateDocumentImage(
                    investigation.id, payload.id, "cover", payload.file, payload.journalImage)

                doc.journalImage = payload.journalImage
            } else {
                await investigationDocumentService.clearDocumentImage(investigation.id, payload.id, "cover")
                doc.coverImageId = null
                doc.journalImage = false
            }

            setDocument(context, doc)
        },

        async updateDocumentAbstractImage(context: ActionContext<InvestigationState, RootState>,
                                          payload: {id: string, file: File | null}): Promise<void> {
            const doc = {...getDocument(context, payload.id)}
            const investigation = context.rootState.investigation.investigation!!

            if (payload.file) {
                doc.abstractImageId = await investigationDocumentService.updateDocumentImage(
                    investigation.id, payload.id, "abstract", payload.file, false)
            } else {
                await investigationDocumentService.clearDocumentImage(investigation.id, payload.id, "abstract")
                doc.abstractImageId = null
            }

            setDocument(context, doc)
        },

        async updateFullTextFile(context: ActionContext<InvestigationState, RootState>, payload: { id: string, file: File }): Promise<void> {
            const doc = {...getDocument(context, payload.id)}
            const investigation = context.rootState.investigation.investigation!!

            doc.fullTextUrl = await investigationDocumentService.updateFullTextFile(investigation.id, payload.id, payload.file)
            setDocument(context, doc)
        },

        async createDocument(context: ActionContext<InvestigationState, RootState>, payload: { documentType: string, overwrites: DocumentOverwrites }): Promise<string> {
            const pubType = payload.documentType === "Article" ?
              payload.overwrites.publicationType : payload.documentType
            const doc = {
                id: "-1",
                url: payload.overwrites.url,
                fullTextUrl: payload.overwrites.fullTextUrl,
                title: {text: payload.overwrites.title, markups: []},
                abstract: {text: payload.overwrites.text, markups: []},
                type: payload.documentType,
                publicationType: pubType,
                classification: pubType,
                terms: [],
                authors: payload.overwrites.authors ? payload.overwrites.authors.map(author => ({
                    id: author.id, name: author.name, affiliations: [], imageId: author.imageId,
                    universityProfileUrl: author.universityProfileUrl, lastReviewTime: null
                }) as InvestigationAuthor) : [],
                keywords: [],
                snippets: {text: "", markups: []},
                labels: [],
                journal: payload.overwrites.publishedIn === "" ? null : payload.overwrites.publishedIn
            } as unknown as DocumentEntity

            if (payload.overwrites.date === "") {
                doc.date = undefined
                doc.datePrecision = undefined
            } else if (payload.overwrites.date) {
                doc.date = moment(payload.overwrites.date)
                doc.datePrecision = datePrecisionBasedOnTextDate(payload.overwrites.date)
            }

            setDocuments(context, [doc, ...getDocuments(context)])

            const investigation = context.rootState.investigation.investigation!!
            const newDocumentId = await InvestigationService.createDocument(investigation.id, investigation.investigationType, payload.documentType, payload.overwrites)

            doc.id = newDocumentId
            updateDocumentId(context, { oldId: doc.id, newId: newDocumentId })

            return newDocumentId
        },

        async updateDocument(context: ActionContext<InvestigationState, RootState>, payload: { id: string, overwrites: DocumentOverwrites }): Promise<void> {
            const doc = { ...getDocument(context, payload.id) }
            const ow = payload.overwrites
            doc.title = { text: ow.title || doc.title.text, markups: [] }
            doc.abstract = { text: ow.text === "" ? "" : (ow.text || doc.abstract.text), markups: [] }
            doc.volume = ow.volume === "" ? null : (ow.volume || doc.volume)
            doc.issue = ow.issue === "" ? null : (ow.issue || doc.issue)
            doc.firstPage = ow.firstPage === "" ? null : (ow.firstPage || doc.firstPage)
            doc.lastPage = ow.lastPage === "" ? null : (ow.lastPage || doc.lastPage)
            doc.publisher = ow.publisher === "" ? null : (ow.publisher || doc.publisher)
            doc.publicationType = ow.publicationType || doc.publicationType
            if (doc.type === "Article") {
                doc.classification = doc.publicationType!!
            }
            doc.doi = ow.doi === "" ? null : (ow.doi || doc.doi)
            doc.fullTextUrl = ow.fullTextUrl === "" ? null : (ow.fullTextUrl || doc.fullTextUrl)
            doc.url = ow.url || doc.url
            doc.authors = ow.authors ?
                ow.authors.map(it => ({
                    id: it.id, name: it.name, affiliations: it.affiliations, imageId: it.imageId,
                    universityProfileUrl: it.universityProfileUrl, lastReviewTime: it.lastReviewTime
                })) : doc.authors
            doc.journal = ow.publishedIn === "" ? null : (ow.publishedIn || doc.journal)

            if (ow.date === "") {
                doc.date = undefined
                doc.datePrecision = undefined
            } else if (ow.date) {
                doc.date = moment(ow.date)
                doc.datePrecision = datePrecisionBasedOnTextDate(ow.date)
            }

            setDocument(context, doc)

            const investigation = context.rootState.investigation.investigation!!
            await InvestigationService.updateDocument(investigation.id, investigation.investigationType, payload.id, payload.overwrites)
        }
    }
}

export default module

const {read, commit, dispatch} = getStoreAccessors<InvestigationState, RootState>("investigation")

export const getDocumentMarkups = read(module.getters.getDocumentMarkups)
export const getDocuments = read(module.getters.getDocuments)
export const getViewModeLevel = read(module.getters.getViewModeLevel)
export const getHighlightModes = read(module.getters.getHighlightModes)
export const getTopsExpansion = read(module.getters.getTopsExpansion)
export const getTopsSorting = read(module.getters.getTopsSorting)

const setDocument = commit(module.mutations.setDocument)
const updateDocumentId = commit(module.mutations.updateDocumentId)
export const setDocuments = commit(module.mutations.setDocuments)
export const setDocumentMarkups = commit(module.mutations.setDocumentMarkups)
export const setDocumentStatus = commit(module.mutations.setDocumentStatus)
export const setViewModeLevel = commit(module.mutations.setViewModeLevel)
export const setHighlightModes = commit(module.mutations.setHighlightModes)
export const setTopExpanded = commit(module.mutations.setTopExpanded)
export const setTopSorting = commit(module.mutations.setTopSorting)
export const clearTopsExpansion = commit(module.mutations.clearTopsExpansion)
export const clearTopsSorting = commit(module.mutations.clearTopsSorting)
export const getGroupsExpansion = read(module.getters.getGroupsExpansion)
export const setGroupsExpansion = commit(module.mutations.setGroupsExpansion)
export const clearGroupsExpansion = commit(module.mutations.clearGroupsExpansion)


export const setDocumentRelevancy = dispatch(module.actions.setDocumentRelevancy)
export const setDocumentStarred = dispatch(module.actions.setDocumentStarred)
export const setDocumentRead = dispatch(module.actions.setDocumentRead)
export const setDocumentNote = dispatch(module.actions.setDocumentNote)
export const addDocumentLabel = dispatch(module.actions.addDocumentLabel)
export const removeDocumentLabel = dispatch(module.actions.removeDocumentLabel)
export const renameDocumentLabel = dispatch(module.actions.renameDocumentLabel)
export const deleteDocumentLabel = dispatch(module.actions.deleteDocumentLabel)
export const bulkApplyDocumentLabel = dispatch(module.actions.bulkApplyDocumentLabel)
export const bulkMarkRead = dispatch(module.actions.bulkMarkRead)
export const updateDocumentCoverImage = dispatch(module.actions.updateDocumentCoverImage)
export const updateDocumentAbstractImage = dispatch(module.actions.updateDocumentAbstractImage)
export const updateFullTextFile = dispatch(module.actions.updateFullTextFile)
export const createDocument = dispatch(module.actions.createDocument)
export const updateDocument = dispatch(module.actions.updateDocument)
const updateDocumentLabelInInvestigation = commit(module.mutations.updateDocumentLabelInInvestigation)
const deleteDocumentLabelFromInvestigation = commit(module.mutations.deleteDocumentLabelFromInvestigation)

export function getDocument(context: ActionContext<InvestigationState, RootState>, id: string): DocumentEntity {
    const documents = context.rootState.investigation.documents
    return documents.filter((document: DocumentEntity) => document.id === id)[0]
}

function datePrecisionBasedOnTextDate(date?: string): string | undefined {
    if (!date) {
        return undefined
    }

    switch (date.split("-").length) {
        case 2:
            return "MonthYear"

        case 3:
            return "DayMonthYear"
    }

    return "Year"
}
