



















































import Vue from "vue"
import Component from "vue-class-component"
import GenericDialog from "@/components/GenericDialog.vue"
import {getInvestigation} from "@/store/investigation/investigation-module"
import {InvestigationConfigurationEntity} from "@/types/investigations"
import {configurationService} from "@/api/investigation-configuration-service"

export interface GraphSettingsDialogOptions {
  graphType: string,
  maxEdgeFactor: number,
  minEdgeFactor: number,
  maxNodeCount: number,
  readonly onOk: () => void
}

@Component({
  components: {GenericDialog}
})
export default class GraphSettingsDialog extends Vue {
  private options!: GraphSettingsDialogOptions
  private isOpen = false
  private pages: string[] = []
  private currentPage: string = ""
  private saving: boolean = false
  private errorMessage: string | null = null
  private configuration!: InvestigationConfigurationEntity

  private totalNumberOfNodes: string = ""
  private edgeThreshold: string = ""

  public async open(options: GraphSettingsDialogOptions) {
    this.options = options
    document.addEventListener("keyup", this.handleKey)

    this.saving = false
    this.pages = ["Counts And Thresholds"]
    this.currentPage = this.pages[0]

    const investigation = getInvestigation(this.$store)!!
    this.configuration = await configurationService.getConfiguration(investigation.id)
    this.totalNumberOfNodes = this.optionalNumberToString(this.configuration.graphsSettings[this.options.graphType]!.totalNumberOfNodes)
    this.edgeThreshold = this.optionalNumberToString(this.configuration.graphsSettings[this.options.graphType]!.edgeThreshold)

    this.errorMessage = ""
    this.isOpen = true
  }


  private close() {
    this.isOpen = false
    document.removeEventListener("keyup", this.handleKey)
  }


  private async handleKey(e: KeyboardEvent) {
    switch (e.key) {
      case "Escape":
        this.close()
        break
      case "Enter":
        await this.save()
        break
    }
  }


  private validate(): boolean {
    this.errorMessage = null

    if (this.nullToEmpty(this.totalNumberOfNodes) !== "") {

      const totalNumberOfNodes = Number(this.totalNumberOfNodes)
      if (Number.isNaN(totalNumberOfNodes) || !Number.isInteger(totalNumberOfNodes) || totalNumberOfNodes < 0 || totalNumberOfNodes > this.options.maxNodeCount) {
        this.errorMessage = `Total number of nodes should be a whole number between 0 and ${this.options.maxNodeCount}`
        return false
      }
    }

    if (this.nullToEmpty(this.edgeThreshold) !== "") {
      const edgeThreshold = Number(this.edgeThreshold)
      if (Number.isNaN(edgeThreshold) || edgeThreshold < this.options.minEdgeFactor || edgeThreshold > this.options.maxEdgeFactor) {
        this.errorMessage = `Edge threshold should be a number between ${this.options.minEdgeFactor} and ${this.options.maxEdgeFactor}`
        return false
      }
    }

    return true
  }


  private async save() {
    if (!this.validate()) {
      return
    }

    this.saving = true
    const investigation = getInvestigation(this.$store)!!

    const newConfiguration: InvestigationConfigurationEntity = {
      ...this.configuration
    }

    newConfiguration.graphsSettings[this.options.graphType] = {
      totalNumberOfNodes: this.totalNumberOfNodes.length === 0 ? undefined : Number.parseInt(this.totalNumberOfNodes, 10),
      edgeThreshold: this.edgeThreshold.length === 0 ? undefined : Number.parseFloat(this.edgeThreshold)
    }

    this.errorMessage = await configurationService.setConfiguration(investigation.id, newConfiguration)
    this.saving = false
    if (!this.errorMessage) {
      this.close()

      this.options!!.onOk()
    }
  }


  private nullToEmpty(str?: string): string {
    return str != null ? str : ""
  }


  private optionalNumberToString(num?: number): string {
    return num == null ? "" : num.toString(10)
  }
}
