






















import Vue from "vue"
import Component from "vue-class-component"
import {companyInvestigationType, InvestigationCreationType} from "@/types/new-investigation"
import {createCompanyInvestigation} from "@/store/investigations-module"
import {Watch} from "vue-property-decorator"
import {NewInvestigationDetails} from "@/views/investigations/NewInvestigationPage.vue"

@Component
export default class NewCompanyInvestigationDetails extends Vue implements NewInvestigationDetails {

    private companyName: string = ""
    private websiteUrl: string = ""


    private get investigationType(): InvestigationCreationType {
        return companyInvestigationType
    }


    public async createInvestigation() {
        const payload = { companyName: this.companyName, companyWebSite: this.websiteUrl }
        const id = await createCompanyInvestigation(this.$store, payload)

        this.$router.push({name: "topicInvestigation", params: {id}})
    }


    private mounted() {
        this.$nextTick(() => {
            const searchString = this.$refs.searchString as HTMLInputElement
            searchString.focus()
        })

        this.emitEnableNext()
    }


    @Watch("companyName")
    @Watch("websiteUrl")
    private emitEnableNext() {
        let enable = this.companyName.trim().length > 0 && this.websiteUrl.trim().length > 0
        try {
            const _ = new URL(this.websiteUrl)
        } catch (e) {
            enable = false
        }
        this.$emit("enable-next", enable)
    }
}
