





















import Vue from "vue"
import Component from "vue-class-component"
import GenericDialog from "@/components/GenericDialog.vue"
import KendoEditorWrapper from "@/components/KendoEditorWrapper.vue"
import {getNoteDialogData, NoteDialogData} from "@/store/investigation/dialogs/note-dialog-module"
import {Optional} from "@/types"
import {Watch} from "vue-property-decorator"
import {setDocumentNote} from "@/store/investigation/documents-module"
import {getInvestigation} from "@/store/investigation/investigation-module"
import {InvestigationType} from "@/types/investigations"

@Component({
      components: {GenericDialog, KendoEditorWrapper}
  })
  export default class NoteDialog extends Vue {

      private isOpen = false
      private documentId: string = ""
      private note?: string

      get getData(): Optional<NoteDialogData> {
          return getNoteDialogData(this.$store)
      }


      @Watch("getData")
      private async updateDialog() {
          const data: Optional<NoteDialogData> = await getNoteDialogData(this.$store)

          if (data) {
              this.documentId = data.documentId
              this.note = data.note
              this.isOpen = true

              document.addEventListener("keyup", this.handleKey)
          } else {
              this.close()
          }
      }


      private close() {
          this.isOpen = false
          document.removeEventListener("keyup", this.handleKey)
      }


      private async handleKey(e: KeyboardEvent) {
          switch (e.key) {
              case "Escape":
                  this.close()
                  break
              case "Enter":
                  await this.save()
                  break
          }
      }


      private async save() {
          const investigationType: InvestigationType = getInvestigation(this.$store)!!.investigationType
          await setDocumentNote(this.$store, {id: this.documentId, note: this.note, investigationType})
          this.close()
      }
  }
