
























import Vue from "vue"
import Component from "vue-class-component"
import {addTerm, createTermGroup, getTermGroups} from "@/store/investigation/investigation-terms-module"
import GenericDialogNew, {createDialogMountingPoint} from "@/components/GenericDialogNew.vue"
import CheckBox from "@/components/CheckBox.vue"

export function openCreateTermGroupDialog(parent: Vue) {
    new (Vue.extend(CreateTermGroupDialog))({parent, store: parent.$store, router: parent.$router}).$mount(createDialogMountingPoint())
}

@Component({
    components: {CheckBox, GenericDialogNew }
})
export default class CreateTermGroupDialog extends Vue {

    private groupName: string = ""

    private addGroupAsTerm: boolean = false

    public open() {
        this.groupName = ""

        Vue.nextTick(() => {
            const groupNameText = this.$refs.groupNameText as HTMLInputElement
            groupNameText.focus()
        })
    }

    private dialogAppeared() {
        const textInput = this.$refs.groupNameText as HTMLInputElement
        textInput.focus()
    }

    private close() {
        (this.$refs.genericDialog as GenericDialogNew).close()
    }

    private async createGroup() {
        this.close()
        await createTermGroup(this.$store, this.groupName)
        if (this.addGroupAsTerm) {
            const newGroup = getTermGroups(this.$store).find(it => it.name === this.groupName)
            if (newGroup) {
                await addTerm(this.$store, {term: this.groupName, modifier: "Regular", group: newGroup.id})
            }
        }
    }
}
