












































































































































import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import {
    areAlertsEnabled,
    getUserGuideUrl,
    getVersion,
    isReportEnabled,
    isShowingHebrewTutorials,
    isSupportBatchInvestigations
} from "@/store/environment-module"
import {
    fetchAllData,
    fetchGraphData,
    fetchInvestigationHtml,
    fetchPersonInvestigationExport,
    fetchTopicInvestigationExport,
    getAlertsEnabled,
    getAvailableDocumentIds,
    getDisambiguatePersonName,
    getInvestigation,
    getSpecForStore,
    setFilterByResearchers,
    setFilterToDuplicates, setFilterToMissingDate,
    setFilterToMissingVolume,
    setFilterToUnaffiliated,
    setFilterToUnknownPublishers,
    setFreeTextFilter,
    setSelectedTops,
    showOnlyMyPublications,
    updateAlertsEnabled,
    updateTitle
} from "@/store/investigation/investigation-module"
import UserMenu from "@/components/UserMenu.vue"
import {Watch} from "vue-property-decorator"
import AlertsDialog from "@/views/investigation/dialogs/AlertsDialog.vue"
import PopupMenu from "@/components/PopupMenu.vue"
import HarvestDialog from "@/views/investigation/dialogs/HarvestDialog.vue"
import HarvestByDoiDialog from "@/views/investigation/dialogs/HarvestByDoiDialog.vue"
import ResearcherHarvestDialog from "@/views/investigation/dialogs/ResearcherHarvestDialog.vue"
import {
    createBatchAccuracyReport,
    createBatchPersonInvestigations,
    deleteAllPendingInvestigations,
    fetchAllInvestigations,
    isShowAllInvestigations,
    restartFailedInvestigations,
    toggleShowAllInvestigations
} from "@/store/investigations-module"
import {InvestigationEntity, SelectedTop} from "@/types/investigations"
import moment from "moment"
import investigationService from "../../api/investigation-service"
import InvestigationConfigurationDialog from "@/views/investigation/dialogs/InvestigationConfigurationDialog.vue"
import {downloadBlob} from "@/utils/utils"
import {openConfirmationDialog} from "@/views/investigation/dialogs/ConfirmationDialog.vue"
import {getGraphType} from "@/store/investigation/graph-module"
import {openCreateObservationDialog} from "@/views/investigation/dialogs/CreateObservationDialog.vue"
import investigationEntityService from "../../api/investigation-entity-service"
import adminService from "@/api/admin-service"
import {openMessageDialog} from "@/components/MessageDialog.vue"
import {openUploadDialog} from "@/views/investigation/dialogs/UploadFileDialog.vue"
import {openEditDocumentDialog} from "@/views/investigation/dialogs/EditDocumentDialog.vue"
import {
    createDocument,
    updateDocumentAbstractImage,
    updateDocumentCoverImage,
    updateFullTextFile
} from "@/store/investigation/documents-module"
import {PermissionsMixin} from "@/store/user-mixin"
import {InvestigationMixin} from "@/store/investigation-mixin"
import investigationsService from "@/api/investigations-service"
import {setDateFilterYears} from "@/store/investigation/date-filter-module"
import {getInvestigationCreationType} from "@/types/new-investigation"
import {createFilterByUrlsDialog} from '@/views/investigation/dialogs/FilterByUrlsDialog.vue'


@Component({
    components: {
        InvestigationConfigurationDialog,
        PopupMenu,
        UserMenu,
        AlertsDialog,
        HarvestDialog,
        HarvestByDoiDialog,
        ResearcherHarvestDialog
    }
})
export default class HeaderBar extends mixins(Vue, PermissionsMixin, InvestigationMixin) {

    private static convertBase64ToBlob(base64: string) {
        const decodedData = window.atob(base64)
        const uInt8Array = new Uint8Array(decodedData.length)
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i)
        }
        return new Blob([uInt8Array])
    }

    private title: string = ""
    private oldTitle: string = ""
    private isEditing: boolean = false
    private supportBatchInvestigations = this.$wrap(isSupportBatchInvestigations)

    private mounted() {
        if (this.isInReviewProfile) {
            this.$nextTick(() => {
                const menuRef = this.$refs.menuRef as HTMLElement
                setTimeout(() => this.$emit("openReviewProfileMenu", menuRef, false, false), 1000)
            })
        }
    }

    get showHebrewTutorials() {
        return isShowingHebrewTutorials(this.$store)
    }

    get isInInvestigationCreationPage(): boolean {
        return this.$route.name === "createInvestigation"
    }

    get isInvestigating(): boolean {
        return (this.isInTopicInvestigation || this.isInPersonInvestigation)
    }

    get headerClass() {
        return { profile: this.isInPersonProfile, facultyworks: this.isFacultyWorksUser }
    }

    get selectInvestigationsText() {
        return this.isShowingAllInvestigations() ? "Show Only My Investigations" : "Show All Investigations"
    }

    private get isInDisambiguate(): boolean {
        return this.$route.fullPath !== undefined && this.$route.fullPath.includes("/disambiguate")
    }

    private get isReportEnabled(): boolean {
        return isReportEnabled(this.$store)
    }

    private get isInAdminPage(): boolean {
        if (!this.$route.path) {
            return false
        }
        return this.$route.path.includes("/admin")
    }

    private get investigationTitle(): string {
        if (this.isInDisambiguate) {
            return getDisambiguatePersonName(this.$store)!!
        }

        if ((this.isInvestigating && this.investigation !== null) || this.isInPersonProfile) {
            return (this.investigation as unknown as InvestigationEntity).title
        }

        return ""
    }


    private get version(): string {
        return getVersion(this.$store)
    }

    private get userGuideUrl(): string {
        return getUserGuideUrl(this.$store)
    }

    private get followText() {
        return this.alertsEnabled ? "Cancel Alerts" : "Alerts"
    }

    private get followTitle() {
        return this.alertsEnabled ? "Cancel alerts for investigation" : "Create alerts for investigation"
    }

    private get alertsEnabled() {
        return getAlertsEnabled(this.$store)
    }

    private get alertsFeatureIsOn() {
        return areAlertsEnabled(this.$store)
    }

    private async mergePersons() {
        const investigation = this.investigation!!
        await investigationService.merge(investigation.id, investigation.investigationType)
    }

    private async undeleteAuthors() {
        openConfirmationDialog({
            message: `You are about to bring back all removed authors. Are you sure?`,
            title: "Bring Back All Removed Authors",
            okText: "Bring Back",
            onOk: () => this.performAuthorUndelete(),
            showCancel: true
        })
    }


    private async restoreEntities() {
        // @ts-ignore
        openConfirmationDialog({
            message: `You are about to bring back all removed entities. Are you sure?`,
            title: "Bring Back All Removed Entities",
            okText: "Bring Back",
            onOk: () => this.performRestoreEntities(),
            showCancel: true
        })
    }

    private async performAuthorUndelete() {
        const investigation = this.investigation!
        await investigationService.undelete(investigation.id, investigation.investigationType)
        getGraphType(this.$store) === null ? await fetchAllData(this.$store) : await fetchGraphData(this.$store)
    }


    private async performRestoreEntities() {
        const investigation = this.investigation!
        await investigationEntityService.restoreAll(investigation.id)
        getGraphType(this.$store) === null ? await fetchAllData(this.$store) : await fetchGraphData(this.$store)
    }

    private async authorsGraph() {
        const id = this.investigation!.id
        const xml = await investigationService.getAuthorsGraph(id, this.investigation!.investigationType, getSpecForStore(this.$store))
        const url = window.URL.createObjectURL(new Blob([xml.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `cytoscape-export-${id}.xml`)
        document.body.appendChild(link)
        link.click()
    }

    private async termsGraph() {
        const id = this.investigation!.id
        const xml = await investigationService.getXmlTermsGraph(id, this.investigation!.investigationType, getSpecForStore(this.$store))
        const url = window.URL.createObjectURL(new Blob([xml.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `cytoscape-export-${id}.xml`)
        document.body.appendChild(link)
        link.click()
    }

    @Watch("investigationTitle")
    private updateTitle() {
        this.title = this.investigationTitle
        this.$nextTick(() => {
            if (!this.isInDisambiguate) {
                document.title = this.title ? this.title : "Socrates"
            }
        })
    }

    private editTitle() {
        if (!this.isInInvestigationsPage && !this.isInPersonProfile && !this.isInDisambiguate && !this.supportBatchInvestigations()) {
            this.oldTitle = this.title
            this.isEditing = true
            this.$nextTick(() => {
                const editor = this.$refs.titleEditor as HTMLElement
                editor.focus()
            })
        }
    }

    private endEditTitle() {
        this.isEditing = false
        if (!this.isInInvestigationsPage) {
            updateTitle(this.$store, this.title)
        }
    }

    private cancelEditTitle() {
        this.title = this.oldTitle
        this.endEditTitle()
    }

    private openHeaderProcessMenu() {
        const processButton = this.$refs.process as HTMLElement
        this.$emit("openHeaderProcessMenu", processButton)
    }

    private openFacultyworksMenu() {
        const facultyworksMenu = this.$refs.facultyworksMenu as HTMLElement
        this.$emit("openFacultyworksMenu", facultyworksMenu)
    }

    private async toggleAlerts() {
        if (!this.alertsEnabled) {
            this.openAlertsDialog()
        } else {
            await updateAlertsEnabled(this.$store, false)
        }
    }

    private async openAlertsDialog() {
        // @ts-ignore
        (this.$refs.alertsDialog as AlertsDialog).open()
    }

    private openHarvestDialog() {
        // @ts-ignore
        (this.$refs.harvestDialog as HarvestDialog).open()
    }

    private openHarvestAuthorsDialog() {
        (this.$refs.researcherHarvestDialog as ResearcherHarvestDialog).open({
            title: "Add Authors to Investigation",
            searchStringLabel: "Author Names:",
            placeholder: "Author names, separated by newlines"
        })
    }

    private harvestResultSetUrls() {
        const investigation = getInvestigation(this.$store)!!
        openConfirmationDialog({
            message: `Are you sure you want to re-harvest the current set of documents?`,
            title: "Re-Harvest Documents",
            okText: "Yep",
            onOk: () => investigationService.createHarvestByDocs(
                investigation.id, investigation.investigationType, getAvailableDocumentIds(this.$store)),
            showCancel: true
        })
    }

    private openHarvestByDoiDialog() {
        // @ts-ignore
        (this.$refs.harvestByDoiDialog as HarvestByDoiDialog).open()
    }

    private openInvestigationConfigurationDialog() {
        // @ts-ignore
        (this.$refs.investigationConfigurationDialog as InvestigationConfigurationDialog).open()
    }

    private async exportToExcel() {
        const investigation = this.investigation!
        const blob = await investigationService.getPersonProfileExcel(investigation.id, getSpecForStore(this.$store), investigation.investigationType)
        const fileName = `${moment().format("YYYY_MM_DD_HH_mm_ss")} - ${this.title}.xlsx`
        downloadBlob(blob, fileName)
    }

    private async exportPublications() {
        const investigation = this.investigation!
        const blob = await investigationService.getPublicationsExcel(investigation.id, getSpecForStore(this.$store), investigation.investigationType)
        const fileName = `${moment().format("YYYYMMDDTHHmmss")} - ${this.title}.xlsx`
        downloadBlob(blob, fileName)
    }

    private isShowingAllInvestigations() {
        return isShowAllInvestigations(this.$store)
    }

    private async toggleShowAllInvestigations() {
        toggleShowAllInvestigations(this.$store)
        await fetchAllInvestigations(this.$store, true)
    }

    private isShowProcessMenu() {
        if (this.isInInvestigationCreationPage || this.isInReviewProfile) {
            return false
        }

        if (!this.isInInvestigationsPage) {
            return true
        }

        return this.isAdminUser || this.supportBatchInvestigations()
    }

    private createObservation() {
        openCreateObservationDialog(this, {
            type: "Investigation",
            allowImageUpload: true
        })
    }

    private async downloadTopicExport() {
        openMessageDialog({title: "Export to Excel", message: "Export to Excel started, this might take a short while."})

        const blob = await fetchTopicInvestigationExport(this.$store, this.investigation!.id)
        const fileName = `${moment().format("YYYY_MM_DD_HH_mm_ss")} - ${this.investigationTitle}.xlsx`
        downloadBlob(blob, fileName)
    }

    private async downloadPersonExport() {
        openMessageDialog({title: "Export to Excel", message: "Export to Excel started, this might take a short while."})

        const blob = await fetchPersonInvestigationExport(this.$store, this.investigation!.id)
        const fileName = `${moment().format("YYYYMMDDTHHmmss")} - ${this.investigationTitle}.xlsx`
        downloadBlob(blob, fileName)
    }

    private async downloadHtml(themeName: string) {
        let mainPart = this.investigationTitle.replace(RegExp("(.*)\,.*"), "$1")
        if (mainPart === this.investigationTitle) {
            // backward compatibility (names starting with the institute and faculty.works)
            mainPart = this.investigationTitle.replace(RegExp(".*?\\s*[/-]\\s*(.*)"), "$1")
        }
        const fname = mainPart.replace(RegExp("\\W+", "g"), ".").toLowerCase()
        const data = await fetchInvestigationHtml(this.$store,
            {id: this.investigation!.id, theme: themeName, filename: fname})
        if (data.base64File !== null) {
            const longFilename = `${moment().format("YYYYMMDDTHHmmss")} ${fname}.zip`
            downloadBlob(HeaderBar.convertBase64ToBlob(data.base64File!), longFilename)
        } else if (data.numOfDuplicates != null) {
            openConfirmationDialog({
                message: `Found ${data.numOfDuplicates} duplicates; They must be resolved for generate to succeed.`,
                title: "Page Generation Halted",
                okText: "Show Duplicates",
                cancelText: "Close",
                onOk: () => this.filterToAcceptedDuplicates(),
                showCancel: true
            })
        } else {
            openMessageDialog({
                title: "Page Generation Failed",
                message: data.failedTitle || "No message provided"
            })
        }
    }

    private openHeaderHelpMenu() {
        const helpButton = this.$refs.help as HTMLElement
        this.$emit("openHeaderHelpMenu", helpButton)
    }

    private openUserGuide() {
        window.open(this.userGuideUrl)
    }

    private openVideoTutorialHebrew() {
        window.open("https://www.youtube.com/playlist?list=PLevDEGwGyhV8pKXwqrRQ8I6aNhIFtQX_s")
    }

    private openVideoTutorialEnglish() {
        window.open("https://www.youtube.com/playlist?list=PLevDEGwGyhV8x-qodzxUBN8sMYT7cH2wM")
    }

    private uploadAuthorsFile() {
        openUploadDialog({title: "Upload Authors File", handler: (file: File) => {
                createBatchPersonInvestigations(this.$store, file)
            }})
    }

    private uploadInvestigationDefinitions() {
        openUploadDialog({title: "Upload Predefined Investigations File (XLSX / ZIP)", handler: async (file: File) => {
            try {
                await investigationsService.createInvestigationsWithDefinitions(file)
                openMessageDialog({title: "Upload Predefined Investigations", message: "Investigation(s) created successfully"})
            } catch (e) {
                openMessageDialog({title: "Error Parsing File", message: e, multipleMessages: true})
            }
        }})
    }

    private deleteAllPending() {
        openConfirmationDialog({
            message: `You are about to delete all pending investigations. Are you sure?`,
            title: "Delete All Pending Investigations",
            okText: "Delete",
            onOk: () => deleteAllPendingInvestigations(this.$store),
            showCancel: true
        })
    }

    private restartFailed() {
        restartFailedInvestigations(this.$store)
    }

    private performClusterDiff() {
        const investigation = this.investigation!
        this.$router.push({path: `/admin/cluster-report/${investigation.id}`, query: {}})
    }

    private evaluateAccuracyUsingCvs() {
        openUploadDialog({title: "Upload Curated Author CV ZIP", handler: async (file: File) => {
                const s3Key = await createBatchAccuracyReport(this.$store, file)
                openMessageDialog({title: "Accuracy Report", message: `Accuracy report will be available in S3 at ${s3Key}`})
            }})
    }

    private async generateQualityEstimationReport() {
        const s3Key = await adminService.generateQualityEstimationReport()
        openMessageDialog({title: "Estimation Report", message: `Quality estimation report will be available in S3 at ${s3Key}`})
    }

    private async generateAuthorFeedbackReport() {
        const s3Key = await adminService.generateAuthorFeedbackReport()
        openMessageDialog({title: "Author Feedback Report", message: `Author Feedback report will be available in S3 at ${s3Key}`})
    }

    private async showOnlyMyPublications() {
        await showOnlyMyPublications(this.$store)
    }

    private async openReviewProfile() {
        const investigation = this.investigation!
        const mainClusterFilter = await investigationService.getMainClusterFilter(investigation.id)

        const slashIndex = this.$route.path.lastIndexOf("/")
        window.open(this.$route.fullPath.substring(0, slashIndex) + "/review_profile/" + mainClusterFilter.personId, "_blank")
    }

    private createDocument() {
        openEditDocumentDialog({
            doc: null,
            callback: async (documentType, overwrites, fullTextFile,
                             coverImage: { modified: boolean, file: File | null, journalImage: boolean },
                             abstractImage: { modified: boolean, file: File | null }
            ) => {
                if (overwrites) {
                    const newDocId = await createDocument(this.$store, {documentType, overwrites})

                    if (coverImage.modified) {
                        await updateDocumentCoverImage(this.$store, {id: newDocId, file: coverImage.file, journalImage: coverImage.journalImage})
                    }
                    if (abstractImage.modified) {
                        await updateDocumentAbstractImage(this.$store, {id: newDocId, file: abstractImage.file})
                    }
                    if (fullTextFile) {
                        await updateFullTextFile(this.$store, { id: newDocId, file: fullTextFile })
                    }
                }
            }
        })
    }

    private openReviewProfileTutorial() {
        window.open("https://docs.google.com/presentation/d/e/2PACX-1vSITw8hW3i05tuAnvr3wHrgVO9RvW-lVdJFIfJrKArnv9vz7nVhYpvaZE4NZ3UYCyS_h5kRDQFrBydZ/pub", "_blank")
    }

    private async filterByResearchers() {
        setDateFilterYears(this.$store, { start: moment().year() - 1, end: moment().year() })
        setFilterByResearchers(this.$store, true)
        getGraphType(this.$store) === null ? await fetchAllData(this.$store) : await fetchGraphData(this.$store)
    }

    private async filterToUnaffiliated() {
        setFilterToUnaffiliated(this.$store, true)
        getGraphType(this.$store) === null ? await fetchAllData(this.$store) : await fetchGraphData(this.$store)
    }

    private async filterToUnknownPublishers() {
        setFilterToUnknownPublishers(this.$store, true)
        getGraphType(this.$store) === null ? await fetchAllData(this.$store) : await fetchGraphData(this.$store)
    }

    private async filterToMissingVolume() {
        setFilterToMissingVolume(this.$store, true)
        getGraphType(this.$store) === null ? await fetchAllData(this.$store) : await fetchGraphData(this.$store)
    }

    private async filterToMissingDate() {
        setFilterToMissingDate(this.$store, true)
        getGraphType(this.$store) === null ? await fetchAllData(this.$store) : await fetchGraphData(this.$store)
    }

    private async openFilterByUrlsDialog() {
        createFilterByUrlsDialog(this)
    }

    private async filterToDuplicates() {
        setFilterToDuplicates(this.$store, true)
        getGraphType(this.$store) === null ? await fetchAllData(this.$store) : await fetchGraphData(this.$store)
    }

    private async filterToAcceptedDuplicates() {
        // clear existing filters
        setDateFilterYears(this.$store, {start: null, end: null})
        setFreeTextFilter(this.$store, null)
        setFilterByResearchers(this.$store, false)
        setFilterToUnaffiliated(this.$store, false)
        setFilterToUnknownPublishers(this.$store, false)

        // set the "accepted" label and special filter
        const tops = [{name: "predefined.Labels", selectedDisplayValue: "accepted", selectedValue: "accepted"}] as SelectedTop[]
        setSelectedTops(this.$store, tops)
        await this.filterToDuplicates()
    }

    private get investigationSubTypeText() {
        return "Type: " + (getInvestigationCreationType(this.investigationSubType))!!.title
    }

    private get investigationSubTypeColor() {
        return getInvestigationCreationType(this.investigationSubType)!!.color
    }
}
