import {
    InvestigationTermEntity,
    InvestigationTermGroupEntity,
    TermGroupModifier,
    TermModifier
} from "@/types/investigations"
import {RootState} from ".."
import termsService, {BatchTermsRequest} from "../../api/investigation/terms-service"
import {getInvestigationId} from "./get-investigation-id"
import {ActionContext} from "vuex"
import {getStoreAccessors} from "vuex-typescript"

export const MAX_ALLOWED_TERMS = 350

export class SuggestedTerm {
    constructor(public term: string, public selected: boolean, public modifier: TermModifier, public group?: string) {}
}

export interface TermsState {
    terms: InvestigationTermEntity[],
    termGroups: InvestigationTermGroupEntity[],
    suggestedTerms: SuggestedTerm[]
}

const module = {
    getters: {
        getTerms(state: TermsState): InvestigationTermEntity[] {
            return state.terms
        },

        getTermGroups(state: TermsState): InvestigationTermGroupEntity[] {
            return state.termGroups
        },

        getSuggestedTerms(state: TermsState): SuggestedTerm[] {
            return state.suggestedTerms
        }
    },

    mutations: {
        setTerms(state: TermsState, terms: InvestigationTermEntity[]) {
            state.terms = terms
        },

        setTermGroups(state: TermsState, termGroups: InvestigationTermGroupEntity[]) {
            state.termGroups = termGroups
        },

        insertTerm(state: TermsState, term: InvestigationTermEntity) {
            state.terms.push(term)
        },

        updateTerm(state: TermsState, term: InvestigationTermEntity) {
            const termIndex = state.terms.findIndex(currentTerm => currentTerm.term === term.term)

            if (termIndex >= 0) {
                const newTerms = [...state.terms]
                newTerms.splice(termIndex, 1, term)
                state.terms = newTerms
            }
        },

        updateTermGroup(state: TermsState, group: InvestigationTermGroupEntity) {
            const groupIndex = state.termGroups.findIndex(currentGroup => currentGroup.id === group.id)

            if (groupIndex >= 0) {
                state.termGroups.splice(groupIndex, 1, group)
            }
        },

        deleteTerm(state: TermsState, term: string) {
            const termIndex = state.terms.findIndex(currentTerm => currentTerm.term === term)

            if (termIndex >= 0) {
                state.terms.splice(termIndex, 1)
            }
        },

        setSuggestedTerms(state: TermsState, suggestedTerms: string[]) {
            state.suggestedTerms = suggestedTerms.map(term => new SuggestedTerm(term, false, "Regular", undefined))
        },

        removeSuggestedTerm(state: TermsState, suggestedTerm: string) {
            const termIndex = state.suggestedTerms.findIndex(term => term.term === suggestedTerm)
            if (termIndex > -1) {
                state.suggestedTerms.splice(termIndex, 1)
            }
        },

        updateSuggestedTerm(state: TermsState, payload: {term: string, selected: boolean, group?: string, modifier: TermModifier}) {
            const {term, selected, group, modifier} = payload
            const selectedTerm = state.suggestedTerms.find(suggestedTerm => suggestedTerm.term === term)
            if (selectedTerm) {
                if (selected !== undefined) { selectedTerm.selected = selected }
                selectedTerm.group = group
                selectedTerm.modifier = modifier
            }
        }
    },

    actions: {
        async addTerm(context: ActionContext<TermsState, RootState>, term: {term: string, modifier: TermModifier, group: string}): Promise<void> {
            const oldTerms = getTerms(context)
            const newTerm: InvestigationTermEntity = {...term}
            const newTerms = oldTerms.concat(newTerm).sort((a, b) => a.term.localeCompare(b.term))
            setTerms(context, newTerms)
            const result = await termsService.addTerm(getInvestigationId(context, "add term"), term)
            if (result) {
                removeSuggestedTerm(context, term.term)
            } else {
                setTerms(context, oldTerms)
            }
        },

        async addTermFromText(context: ActionContext<TermsState, RootState>, payload: {term: string, modifier: TermModifier, group: string}): Promise<void> {
            await addTerm(context, payload)
        },


        async addBatchTerms(context: ActionContext<TermsState, RootState>, payload: BatchTermsRequest): Promise<void> {
            const oldGroups = getTermGroups(context)
            const oldGroupsLowerCase = oldGroups.map(it => it.name.toLowerCase())
            const oldTerms = getTerms(context)
            const oldTermsLowerCase = oldTerms.map(term => term.term.toLowerCase())

            let lastGroupColor = parseInt(oldGroups[oldGroups.length - 1].color, 10)
            const batchGroups: InvestigationTermGroupEntity[] = payload.groups
                .filter(group => !oldGroupsLowerCase.includes(group.name.toLowerCase()))
                .map(group => {
                    lastGroupColor = lastGroupColor % 10 + 1
                    return {id: group.name, name: group.name, color: lastGroupColor.toString(), modifier: "Regular"} as InvestigationTermGroupEntity
                })
            const newGroups = oldGroups.concat(batchGroups)

            const batchTerms: InvestigationTermEntity[] = payload.groups
                .flatMap(group => {
                    return group.terms.map(term => {
                        return ({term, modifier: "Regular", group: newGroups.find(it => it.name === group.name)!!.id} as InvestigationTermEntity)
                    })
                })
                .filter(term => !oldTermsLowerCase.includes(term.term.toLowerCase()))

            const newTerms = oldTerms.concat(batchTerms).sort((a, b) => a.term.localeCompare(b.term))

            setTermGroups(context, newGroups)
            setTerms(context, newTerms)

            const result = await termsService.addBatchTerms(getInvestigationId(context, "add term"), payload)
            if (!result) {
                setTerms(context, oldTerms)
                setTermGroups(context, oldGroups)
            }
        },

        async createTermGroup(context: ActionContext<TermsState, RootState>, name: string): Promise<void> {
            const oldGroups = getTermGroups(context)
            const lastGroupColor = oldGroups[oldGroups.length - 1].color
            const color = lastGroupColor === "10" ? "1" : (parseInt(lastGroupColor, 10) + 1).toString()
            const modifier: TermGroupModifier = "Regular"
            const createdGroup: InvestigationTermGroupEntity = {id: "", name, color, modifier}
            setTermGroups(context, [...oldGroups, createdGroup])
            const result = await termsService.createGroup(getInvestigationId(context, "create group"), name)
            setTermGroups(context, result ? result : oldGroups)
        },

        async renameTermGroup(context: ActionContext<TermsState, RootState>, group: InvestigationTermGroupEntity): Promise<void> {
            const oldGroups = getTermGroups(context)
            updateTermGroup(context, group)
            const result = await termsService.renameGroup(getInvestigationId(context, "rename group"), group)
            if (!result) {
                setTermGroups(context, oldGroups)
            }
        },

        async deleteTermGroup(context: ActionContext<TermsState, RootState>, group: InvestigationTermGroupEntity): Promise<void> {
            const terms = getTerms(context).filter(term => group.id === term.group)

            const result = await termsService.deleteGroup(getInvestigationId(context, "delete group"), group.id)
            if (result) {
                setTermGroups(context, result)
                terms.forEach(term => deleteTerm(context, term.term))
            }
        },

        async modifyTermGroup(context: ActionContext<TermsState, RootState>, group: InvestigationTermGroupEntity): Promise<void> {
            const investigationId = getInvestigationId(context, "modify term group")
            const oldTermGroup = getTermGroups(context).find(currentTermGroup => currentTermGroup.id === group.id)!
            updateTermGroup(context, group)
            const result = await termsService.modifyTermGroup(investigationId, group)
            if (!result) {
                updateTermGroup(context, oldTermGroup)
            }
        },

        async moveToGroup(context: ActionContext<TermsState, RootState>, payload: {term: string, group: string}): Promise<void> {
            const {term, group} = payload
            const investigationId = getInvestigationId(context, "move term to group")
            const oldTerm = getTerms(context).find(currentTerm => currentTerm.term === term)!
            const {modifier} = oldTerm
            const newTerm: InvestigationTermEntity = {term, modifier, group}
            updateTerm(context, newTerm)
            const result = await termsService.moveTermToGroup(investigationId, term, group)
            if (!result) {
                updateTerm(context, oldTerm)
            }
        },

        async modifyTerm(context: ActionContext<TermsState, RootState>, { term, modifier, group }: InvestigationTermEntity): Promise<void> {
            const investigationId = getInvestigationId(context, "modify term")
            const oldTerm = getTerms(context).find(currentTerm => currentTerm.term === term)!
            const newTerm: InvestigationTermEntity = {term, modifier, group}
            updateTerm(context, newTerm)
            const result = await termsService.modifyTerm(investigationId, term, modifier)
            if (!result) {
                updateTerm(context, oldTerm)
            }
        },

        async renameTerm(context: ActionContext<TermsState, RootState>, payload: { oldTerm: InvestigationTermEntity, newName: string }): Promise<void> {
            const { oldTerm, newName } = payload
            const investigationId = getInvestigationId(context, "rename term")
            const oldTerms = getTerms(context)
            const oldTermIdx = oldTerms.indexOf(oldTerm)
            const newTermObj: InvestigationTermEntity = {term: newName, modifier: oldTerm.modifier, group: oldTerm.group}

            const newTerms = [...oldTerms]
            newTerms.splice(oldTermIdx, 1, newTermObj)
            setTerms(context, newTerms)

            const result = await termsService.renameTerm(investigationId, oldTerm.term, newName)
            if (!result) {
                setTerms(context, oldTerms)
            }
        },

        async removeTerm(context: ActionContext<TermsState, RootState>, term: string): Promise<void> {
            const oldTerms = getTerms(context)
            deleteTerm(context, term)
            const result = await termsService.removeTerm(getInvestigationId(context, "delete term"), term)
            if (!result) {
                setTerms(context, oldTerms)
            }
        }
    }
}


export default module


const { read, commit, dispatch } = getStoreAccessors<TermsState, RootState>("investigation")

export const getTerms = read(module.getters.getTerms)
export const getTermGroups = read(module.getters.getTermGroups)
export const getSuggestedTerms = read(module.getters.getSuggestedTerms)
export const addTerm = dispatch(module.actions.addTerm)
export const addTermFromText = dispatch(module.actions.addTermFromText)
export const modifyTerm = dispatch(module.actions.modifyTerm)
export const renameTerm = dispatch(module.actions.renameTerm)
export const moveToGroup = dispatch(module.actions.moveToGroup)
export const removeTerm = dispatch(module.actions.removeTerm)
export const addBatchTerms = dispatch(module.actions.addBatchTerms)

export const createTermGroup = dispatch(module.actions.createTermGroup)
export const renameTermGroup = dispatch(module.actions.renameTermGroup)
export const deleteTermGroup = dispatch(module.actions.deleteTermGroup)
export const modifyTermGroup = dispatch(module.actions.modifyTermGroup)

export const setTerms = commit(module.mutations.setTerms)
export const setTermGroups = commit(module.mutations.setTermGroups)
const removeSuggestedTerm = commit(module.mutations.removeSuggestedTerm)
export const setSuggestedTerms = commit(module.mutations.setSuggestedTerms)
export const updateSuggestedTerm = commit(module.mutations.updateSuggestedTerm)
const deleteTerm = commit(module.mutations.deleteTerm)
const updateTerm = commit(module.mutations.updateTerm)
const updateTermGroup = commit(module.mutations.updateTermGroup)
