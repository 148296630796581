



































import Vue from "vue"
import Component from "vue-class-component"
import {InvestigationEntity, PersonEntity, PersonVote, Relevancy} from "@/types/investigations"
import {fetchAllPersonData, getInvestigation, getPersonRelevantCounts, getPersons, getRelevantDocumentCount, getTotalDocumentCount, setPersons} from "@/store/investigation/investigation-module"
import {getDisambiguationPersonId, setDisambiguationPersonId} from "@/store/investigation/person-filter-module"
import InvestigationService from "@/api/investigation-service"
import {wrapActiveRequests} from "@/utils/utils"
import investigationService from "../../../api/investigation-service"
import {openConfirmationDialog} from "@/views/investigation/dialogs/ConfirmationDialog.vue"

@Component
export default class AuthorDisambiguationPane extends Vue {

    private personVotes: PersonVote[] = []
    private sortByRelevant: boolean = false

    private getInvestigation = this.$wrap(getInvestigation)

    private get persons(): PersonEntity[] {
        return getPersons(this.$store)
    }

    private get sortedPersons(): PersonEntity[] {
        return this.sortByRelevant && this.hasFilters ? this.persons.slice().sort(this.relevantDocumentSort) : this.persons
    }

    private relevantDocumentSort(first: PersonEntity, second: PersonEntity) {
        const sortByRelevantDocuments = (a: PersonEntity, b: PersonEntity) => this.relevantCount(b) - this.relevantCount(a)
        const defaultSort = (a: PersonEntity, b: PersonEntity) => this.persons.indexOf(a) - this.persons.indexOf(b)

        return sortByRelevantDocuments(first, second) || defaultSort(first, second)
    }

    private get hasFilters(): boolean {
        return getTotalDocumentCount(this.$store) !== getRelevantDocumentCount(this.$store)
    }

    private get shouldShowApplyButton() {
        const relevantPersons = this.personVotes.filter(vote => vote.relevancy === "Relevant")
        if (relevantPersons.length === 1) {
            return false
        }
        return !!this.personVotes.length
    }

    private get personId(): string | null {
        return getDisambiguationPersonId(this.$store)
    }

    private mounted() {
        setPersons(this.$store, [])
    }

    private async selectPerson(id: string) {
        setDisambiguationPersonId(this.$store, this.personId !== id ? id : null)
        await fetchAllPersonData(this.$store)
        this.$emit("documentsUpdated")
    }

    private isVoted(person: PersonEntity, relevancy?: Relevancy): boolean {
        if (relevancy) {
            return !!this.personVotes.find(vote => vote.id === person.id && vote.relevancy === relevancy)
        }
        return !!this.personVotes.find(vote => vote.id === person.id)
    }

    private isRelevantTurnedOn(person: PersonEntity): boolean {
        return this.isVoted(person, 'Relevant')
    }

    private isIrrelevantTurnedOn(person: PersonEntity): boolean {
        return this.isVoted(person, 'Irrelevant') ||
            (person.relevancy === 'Irrelevant' && !this.isVoted(person))
    }

    private async vote(person: PersonEntity, relevancy: Relevancy) {
        // const isAnyButtonOn = this.isIrrelevantTurnedOn(person) || this.isRelevantTurnedOn(person)
        const isMyButtonOn = (relevancy === "Relevant" && this.isRelevantTurnedOn(person)) ||
            (relevancy === "Irrelevant" && this.isIrrelevantTurnedOn(person))

        this.clearVote(person)

        if (isMyButtonOn) {
            if (person.relevancy === "Irrelevant") {
                this.addVote(person, "Neutral")
            }
        } else {
            if (relevancy !== "Irrelevant" || person.relevancy !== "Irrelevant") {
                this.addVote(person, relevancy)
            }
        }
    }

    private clearVote(person: PersonEntity) {
        this.personVotes = this.personVotes.filter(vote => vote.id !== person.id)
    }

    private addVote(person: PersonEntity, relevancy: Relevancy) {
        this.personVotes.push({id: person.id, relevancy})
    }

    private async disambiguate() {
        const investigation = this.getInvestigation()!
        const neutralPersons = this.personVotes.filter(vote => vote.relevancy === "Neutral").map(vote => vote.id)
        const relevantPersons = this.personVotes.filter(vote => vote.relevancy === "Relevant").map(vote => vote.id)
        const irrelevantPersons = this.personVotes.filter(vote => vote.relevancy === "Irrelevant").map(vote => vote.id)
        const authorCountMessage = []
        if (relevantPersons.length > 1) {
            authorCountMessage.push(`merge ${relevantPersons.length} authors`)
        }
        if (irrelevantPersons.length) {
            if (authorCountMessage.length) {
                authorCountMessage.push(neutralPersons.length ? ", " : " and ")
                authorCountMessage.push(`remove ${irrelevantPersons.length}`)
            } else {
                authorCountMessage.push(`remove ${irrelevantPersons.length} author${irrelevantPersons.length > 1 ? "s" : ""}`)
            }
        }
        if (neutralPersons.length) {
            if (authorCountMessage.length) {
                authorCountMessage.push(` and restore ${neutralPersons.length}`)
            } else {
                authorCountMessage.push(`restore ${neutralPersons.length} author${neutralPersons.length > 1 ? "s" : ""}`)
            }
        }
        const note = relevantPersons.length ? "Note that merging cannot be undone." : ""
        const message = `You are about to ${authorCountMessage.join("")}.<br>Are you sure?<br>${note}`
        const title = "Merge and Remove Authors"
        const onOk = () => this.performDisambiguation(investigation, irrelevantPersons, relevantPersons, neutralPersons)
        const disambiguationDialog = {message, title, okText: "I'm sure", onOk, showCancel: true}
        openConfirmationDialog(disambiguationDialog)
    }

    private async performDisambiguation(investigation: InvestigationEntity,
                                        irrelevantPersons: string[], relevantPersons: string[], neutralPersons: string[]) {
        await wrapActiveRequests(this.$store, async () => {
            await InvestigationService.disambiguate(
                investigation.id, investigation.investigationType, irrelevantPersons, relevantPersons, neutralPersons)
            await investigationService.merge(investigation.id, investigation.investigationType)
            this.unselectPerson(relevantPersons)
            await fetchAllPersonData(this.$store)
            this.personVotes = []
        })
    }

    private unselectPerson(disappearingIds: string[]) {
        if (!!disappearingIds.find(personId => personId === this.personId)) {
            setDisambiguationPersonId(this.$store, null)
        }
    }

    private relevantCount(person: PersonEntity): number {
        return getPersonRelevantCounts(this.$store)[person.id] || 0
    }
}
