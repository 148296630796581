




































import Vue from "vue"
import Component from "vue-class-component"
import {NewInvestigationDetails} from "@/views/investigations/NewInvestigationPage.vue"
import {Watch} from "vue-property-decorator"
import {createUniversityInvestigation} from "@/store/investigations-module"
import {InvestigationCreationType, InvestigationDataSource, universityInvestigationType} from "@/types/new-investigation"
import investigationsService from "@/api/investigations-service"

interface Affiliation {
    readonly original: string,
    readonly lower: string
}

@Component
export default class NewUniversityInvestigationDetails extends Vue implements NewInvestigationDetails {

    private magAffiliations: Affiliation[] = []
    private universityName: string = ""
    private country: string = ""
    private startYear: string = (new Date().getFullYear() - 2).toString()
    private endYear: string = new Date().getFullYear().toString()


    private get investigationType(): InvestigationCreationType {
        return universityInvestigationType
    }


    public async createInvestigation() {
        const startYear = Number.parseInt(this.startYear, 10)
        const endYear = Number.parseInt(this.endYear, 10)
        const payload = { universityName: this.universityName, country: this.country, startYear, endYear, sources: ["academic publications"] as InvestigationDataSource[]}
        const highlight = await createUniversityInvestigation(this.$store, payload)

        this.$router.push({name: "investigations", query: {highlight}})
    }


    private async mounted() {
        this.magAffiliations = (await investigationsService.getMagAffiliations()).map(it => {
            return { original: it, lower: it.toLowerCase() }
        })

        this.$nextTick(() => {
            const universityName = this.$refs.universityName as HTMLInputElement
            universityName.focus()
        })

        this.emitEnableNext()
    }


    private get filteredMagAffiliation(): Affiliation[] {
        // return this.magAffiliations
        const name = this.universityName.trim().toLowerCase()
        let list: Affiliation[]
        if (name.length === 0) {
            list = this.magAffiliations
        } else {
            list = this.magAffiliations.filter(it => it.lower.includes(name))
        }
        if (list.length > 40) {
            list = list.slice(0, 40)
        }
        return list
    }


    @Watch("universityName")
    @Watch("startYear")
    @Watch("endYear")
    private emitEnableNext() {
        let enable =
            this.universityName.trim().length > 0 &&
            this.startYear.trim().length > 0 &&
            this.endYear.trim().length > 0

        if (enable) {
            enable = isNumericAndValidYear(this.startYear) && isNumericAndValidYear(this.endYear)
        }

        this.$emit("enable-next", enable)
    }
}


function isNumericAndValidYear(yearStr: string): boolean {
    if (/^\d{4}$/.test(yearStr)) {
        const year = parseInt(yearStr, 10)
        return year >= 1950 && year <= new Date().getFullYear()
    }
    return false
}

