import { ActionContext } from "vuex"
import { RootState } from "@/store"


export function getInvestigationId(context: ActionContext<any, RootState>, item: string): string {
    const investigation = context.rootState.investigation.investigation

    if (investigation == null) {
        throw Error(`Cannot fetch ${item} - no investigation selected`)
    }

    return investigation.id
}
