











import Vue from "vue"
import Component from "vue-class-component"
import {Watch} from "vue-property-decorator"
import {getTermPopupMenuData, hideTermPopupMenu, TermPopupMenuData} from "@/store/investigation/dialogs/term-popup-menu-module"
import {Optional} from "@/types"
import CreateTermTemplate from "@/views/investigation/templates/CreateTermTemplate.vue"
import {getTerms} from "@/store/investigation/investigation-terms-module"
import {openCreateObservationDialog} from "@/views/investigation/dialogs/CreateObservationDialog.vue"

@Component({components: {CreateTermTemplate}})
export default class TermPopupMenu extends Vue {

    private menuTopPosition: string = "0px"
    private menuLeftPosition: string = "0px"

    private term: string | null = null
    private sentence: string | null = null
    private termExists: boolean = false
    private visible: boolean = false
    private boundingRect?: DOMRect
    private centered!: boolean
    private showAddTerm!: boolean
    private showAddPenalizedTerm!: boolean
    private isInObservation: boolean = false
    private showAddObservation: boolean = false

    private clickDebounce: boolean = false
    private element?: HTMLElement
    private documentId: string | null = null


    private get menuData(): Optional<TermPopupMenuData> {
        return getTermPopupMenuData(this.$store)
    }

    private get shouldDisplayAddTerm() {
        const data: Optional<TermPopupMenuData> = getTermPopupMenuData(this.$store)
        return data && data.term && data.term.length < 60
    }

    private get shouldDisplayMenu() {
        const data: Optional<TermPopupMenuData> = getTermPopupMenuData(this.$store)
        return data && data.term && data.term.length > 0
    }

    private get observationBody() {
        return `From the Article:<br />"${this.sentence || this.term}"`
    }

    private get showingAddObservation() {
        return this.showAddObservation && !this.isInObservation
    }

    @Watch("menuData")
    private async updatePopupMenu() {
        const data: Optional<TermPopupMenuData> = await getTermPopupMenuData(this.$store)

        if (data) {
            this.clickDebounce = false
            this.visible = true
            this.centered = data.centered !== undefined ? data.centered : true
            this.element = data.termElement
            this.boundingRect = data.boundingRect
            this.term = data.term ? data.term : null
            this.sentence = data.sentence ? data.sentence : null
            this.showAddTerm = !!data.showAddTerm && data.investigationType === "Topic"
            this.showAddPenalizedTerm = !!data.showAddPenalizedTerm && data.investigationType === "Topic"
            const terms = getTerms(this.$store).map(term => term.term.toLocaleLowerCase())
            this.termExists = this.term ? terms.includes(this.term.toLocaleLowerCase()) : false

            const boundingRect = data.boundingRect ? data.boundingRect : data.termElement.getBoundingClientRect()
            this.menuTopPosition = Math.round(window.innerHeight - boundingRect.top + 10) + "px"
            this.menuLeftPosition = Math.round((this.centered ? boundingRect.width / 2 : 0) + boundingRect.left) + "px"

            this.documentId = data.documentId ? data.documentId : null
            this.isInObservation = data.isInObservation ? data.isInObservation : false
            this.showAddObservation = data.showAddObservation ? data.showAddObservation : false

            document.body.addEventListener("click", this.onClickOutside!, true)
            document.body.addEventListener("scroll", this.onClickOutside!, true)
        }
    }

    private async onClickOutside(event?: Event) {
        if (event && this.element === event.target) {
            // receiving a boundingRect means that the popup was triggered by text selection - which means that a click event will
            // follow the mouse-up event of the selection --> we need to disregard the first click event otherwise the popup will
            // immediately close
            if (!this.clickDebounce) {
                this.clickDebounce = true
                return
            }
        }

        document.body.removeEventListener("click", this.onClickOutside!, true)
        document.body.removeEventListener("scroll", this.onClickOutside!, true)

        await hideTermPopupMenu(this.$store)
    }

    private async openGoogleDefinition() {
        await this.onClickOutside()
        window.open(`https://www.google.com/search?q=define+${this.term}`, "_blank")
    }

    private openObservationDialog() {
        openCreateObservationDialog(this, {
            referencedEntity: this.documentId!!,
            observationTitle: this.term!!,
            observationBody: this.observationBody,
            type: "Document",
            allowImageUpload: true
        })
    }
}
