import { DirectiveOptions, VNodeDirective } from "vue"

function handleMouseDown() {
    // @ts-ignore
    this.removeEventListener("mouseup", handleMouseUp)
    // @ts-ignore
    this.addEventListener("mouseup", handleMouseUp, { once: true })
}


function handleMouseUp() {
    setTimeout(() => {
        // @ts-ignore
        const callback = this._onTextSelection.callback

        const selection = window.getSelection()
        if (selection) {
            const text = selection.toString().trim()

            if (text.length > 0) {
                const boundingRect = selection.getRangeAt(0).getClientRects()[0]

                // @ts-ignore
                callback.apply(this, [text, boundingRect, this, selection])
                return
            }
        }

        // @ts-ignore
        callback.apply(this, [null, null, this])
    }, 10)
}


function inserted(el: HTMLElement, binding: VNodeDirective) {
    // @ts-ignore
    el._onTextSelection = {
        callback: binding.value
    }

    el.addEventListener("mousedown", handleMouseDown, true)
}


function unbind(el: HTMLElement) {
    // @ts-ignore
    if (!el._onTextSelection) {
        return
    }

    el.removeEventListener("mousedown", handleMouseDown, true)
    el.removeEventListener("mouseup", handleMouseUp, true)

    // @ts-ignore
    delete el._onTextSelection
}


export default {
    inserted,
    unbind
} as DirectiveOptions
