

















import Vue from "vue"
import Component from "vue-class-component"
import adminService from "../../api/admin-service"
import {openConfirmationDialog} from "@/views/investigation/dialogs/ConfirmationDialog.vue"
import {openMessageDialog} from "@/components/MessageDialog.vue"

@Component
export default class AdminMergePage extends Vue {

    private investigationId: string = ""
    private timerId: number | null = null
    private mergeHealthChecks: string = ""
    private normalizationHealthChecks: string = ""

    private async mounted() {
        this.timerId = setTimeout(this.getHealthChecks, 1000)
    }

    private beforeDestroy() {
        if (this.timerId) {
            clearTimeout(this.timerId)
        }
    }

    private async getHealthChecks() {
        const healthChecks = await adminService.getHealthChecks()

        this.mergeHealthChecks = JSON.stringify(healthChecks.merge, null, 2)
        this.normalizationHealthChecks = JSON.stringify(healthChecks.normalization, null, 2)

        this.timerId = setTimeout(this.getHealthChecks, 1000)
    }

    private redoMerge() {
        if (this.investigationId.length === 0) {
            openMessageDialog({title: "Error", message: "Please fill in the investigation ID"})
            return
        }

        const onOk = async () => {
            const error = await adminService.merge(this.investigationId)
            if (error.length !== 0) {
                openMessageDialog({title: "Error", message: error})
            }
        }

        openConfirmationDialog({
            message: `You are about to redo merge for investigation ${this.investigationId}.<br><br>    Are you sure?`,
            title: "Redo Merge",
            okText: "Redo Merge",
            onOk,
            showCancel: true
        })
    }

    private redoMergeAll() {
        const onOk = async () => {
            const error = await adminService.mergeAll()

            if (error.length !== 0) {
                openMessageDialog({title: "Error", message: error})
            }
        }

        openConfirmationDialog({
            message: `You are about to redo merge for all investigations.<br><br>    Are you sure?`,
            title: "Redo Merge",
            okText: "Redo Merge",
            onOk,
            showCancel: true
        })
    }
}
