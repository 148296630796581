import Vue from "vue"
import {
    CommitAccessorNoPayload,
    CommitAccessorWithPayload,
    DispatchAccessorNoPayload,
    DispatchAccessorWithPayload,
    GetAccessor
} from "vuex-typescript"
import { Store } from "vuex"


export function VuexTypescriptPlugin(vue: typeof Vue) {
    vue.prototype.$wrap = function<T>(handler: (store: Store<T>, payload?: any) => any) {
        return (payload?: any) => handler(this.$store, payload)
    }
}


declare module "vue/types/vue" {
    interface Vue {
        $wrap<TModuleState, TRootState, TResult>(getter: GetAccessor<TModuleState, TRootState, TResult>): () => TResult
        $wrap<TModuleState, TRootState>(commit: CommitAccessorNoPayload<TModuleState, TRootState>): () => void
        $wrap<TModuleState, TRootState, TPayload>(commit: CommitAccessorWithPayload<TModuleState, TRootState, TPayload>): (payload: TPayload) => void
        $wrap<TModuleState, TRootState, TResult>(action: DispatchAccessorNoPayload<TModuleState, TRootState, TResult>): () => Promise<TResult>
        $wrap<TModuleState, TRootState, TPayload, TResult>(action: DispatchAccessorWithPayload<TModuleState, TRootState, TPayload, TResult>): (payload: TPayload) => Promise<TResult>
    }
}
