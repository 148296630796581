

































































































import Vue from "vue"
import Component from "vue-class-component"
import AuthPageContainer from "@/components/AuthPageContainer.vue"
import userService from "../api/user-service"
import {isTokenValidating, validateToken} from "@/store/auth-module"
import {isShowingSignUp} from "@/store/environment-module"

@Component({
    components: { AuthPageContainer }
})
export default class ResetPage extends Vue {

    private validateToken = this.$wrap(validateToken)

    private email: string = ""
    private emailHasFocus: boolean = false
    private emailError: boolean = false
    private password: string = ""
    private passwordHasFocus: boolean = false
    private confirmPassword: string = ""
    private confirmPasswordHasFocus: boolean = false
    private passwordError: boolean = false
    private confirmPasswordError: boolean = false
    private sending: boolean = false
    private sent: boolean = false
    private error: boolean = false

    private get isTokenValidating(): boolean {
        return isTokenValidating(this.$store)
    }

    private get showSignUp() {
        return isShowingSignUp(this.$store)
    }

    private get tokenExists(): boolean {
        return !!(this.$route.query[`token`] as string)
    }

    private async mounted() {
        if (this.tokenExists) {
            try {
                await this.validateToken(this.$route.query[`token`] as string)
            } catch (e) {
                this.error = true
            }
        }
    }

    private async create() {
        this.emailError = this.email === ""

        if (this.emailError) {
            return
        }

        this.sending = true
        try {
            await userService.reset(this.email)
            this.sent = true

        } catch (error) {
            this.error = true
            this.sending = false
        }
    }

    private async changePassword() {
        this.passwordError = this.password === ""
        this.confirmPasswordError = this.password !== this.confirmPassword

        if (this.passwordError || this.confirmPasswordError) {
            return
        }

        this.sending = true
        try {
            await userService.changePasswordAndSignIn(this.$route.query[`token`] as string, this.password)
            this.$router.push("/")

        } catch (error) {
            this.error = true
            this.sending = false
        }
    }
}
