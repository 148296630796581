




















import Vue from "vue"
import Component from "vue-class-component"
import FusionCharts from "fusioncharts"
import {getTimeline, setGraphType} from "@/store/investigation/graph-module"
import {fetchTimelineData, setIsRenderingGraph} from "@/store/investigation/investigation-module"
import {Watch} from "vue-property-decorator"
import {TimelineChartData} from "@/types/investigations"


@Component
export default class TimelineGraph extends Vue {
    private type = "timeseries" // The chart type
    private width = "100%" // Width of the chart
    private height = "calc(100% - 3.88em)" // Height of the chart
    private dataFormat = "json" // Data type
    private dataSource: any = null
    private noData: boolean = true

    private async mounted() {
        setGraphType(this.$store, "Timeline")
        await fetchTimelineData(this.$store)
    }

    private get chartData(): TimelineChartData {
        return getTimeline(this.$store)
    }

    @Watch("chartData")
    private resetChart() {

        if (this.chartData.selectedTermGroups.length === 0 && this.chartData.selectedTerms.length === 0 && !this.chartData.selectedExternalSeries) {
            this.noData = true
        } else {
            this.noData = false

            const schema = [{
                name: "Time",
                type: "date",
                format: "%Y-%m-%d"
            }]
            this.chartData.chartEntity.columns.slice(1).forEach(it => schema.push(
                {
                    name: it,
                    type: "number",
                    format: ""
                }
            ))

            const fusionTable = new FusionCharts.DataStore().createDataTable(this.chartData.chartEntity.dataItems, schema)

            const leftYAxisPlots: any[] = []
            const rightYAxisPlots: any[] = []
            this.chartData.selectedTermGroups.forEach(it => leftYAxisPlots.push({
                    value: it,
                    connectnulldata: true,
                    type: "smooth-line"
                }
            ))
            this.chartData.selectedTerms.forEach(it => leftYAxisPlots.push({
                    value: it,
                    connectnulldata: true,
                    type: "smooth-line"
                }
            ))
            if (this.chartData.selectedExternalSeries) {
                rightYAxisPlots.push({
                    value: this.chartData.selectedExternalSeries,
                    connectnulldata: true,
                    type: "smooth-line"
                })
            }
            const plots = [{
                plot: leftYAxisPlots,
                title: "Document Count"
            }]
            if (this.chartData.selectedExternalSeries) {
                const externalSeries = this.chartData.selectedExternalSeries
                plots.push({
                    plot: [{
                        value: externalSeries,
                        connectnulldata: true,
                        type: "smooth-line"
                    }],
                    title: externalSeries
                })
            }

            this.dataSource = {
                chart: {
                    multiCanvas: false
                },
                series: "Term",
                yaxis: plots,
                data: fusionTable
            }
        }

        setIsRenderingGraph(this.$store, false)
    }
}

