




























































import Vue from "vue"
import Component from "vue-class-component"
import {fetchAllObservations, getInvestigation, getIsBusy, getTotalDocumentCount} from "@/store/investigation/investigation-module"
import {deleteObservationSection, getObservations, getObservationSections, getObservationViewModeLevel, getSectionObservationOrder, ObservationEntity, ObservationSectionEntity, reorderObservations, setObservationViewModeLevel, setSectionObservationOrder} from "@/store/investigation/observation-module"
import ObservationItem from "@/views/investigation/panes/ObservationItem.vue"
import {isReportEnabled} from "@/store/environment-module"
import PopupMenu from "@/components/PopupMenu.vue"
import {Prop, Watch} from "vue-property-decorator"
import {VueDraggableOptions} from "vue-draggable/types/vue-draggable-options"
import RenameObservationSectionDialog from "@/views/investigation/dialogs/RenameObservationSectionDialog.vue"
import ObservationSectionIndexDialog from "@/views/investigation/dialogs/ObservationSectionIndexDialog.vue"
import {openConfirmationDialog} from "@/views/investigation/dialogs/ConfirmationDialog.vue"
import {openCreateObservationDialog} from "@/views/investigation/dialogs/CreateObservationDialog.vue"


@Component({
    components: {ObservationItem, PopupMenu, RenameObservationSectionDialog, ObservationSectionIndexDialog}
})
export default class ReportEditor extends Vue {

    @Prop()
    private scrolledToObservation!: string

    @Prop()
    private scrolledToSection!: string

    private getInvestigation = this.$wrap(getInvestigation)

    private isCreatingOrder: boolean = true
    private isReordering: boolean = false
    private expandedStates: { [key: string]: boolean } = {}
    private sectionContext: ObservationSectionEntity | null = null

    get observationSections(): ObservationSectionEntity[] {
        return getObservationSections(this.$store)
    }

    get loaded(): boolean {
        return !getIsBusy(this.$store)
    }

    private get sectionObservationOrder() {
        return [...getSectionObservationOrder(this.$store)]
    }

    private set sectionObservationOrder(value: any) {
        setSectionObservationOrder(this.$store, value)
    }

    private get isReportEnabled(): boolean {
        return isReportEnabled(this.$store)
    }

    private get hasDocuments(): boolean {
        return getTotalDocumentCount(this.$store) > 0
    }

    private get investigationType() {
        return getInvestigation(this.$store)!.investigationType
    }

    private get observations(): ObservationEntity[] {
        return [...getObservations(this.$store)].sort((a: ObservationEntity, b: ObservationEntity) => {
            const sectionScore = Number(a.section || 0) - Number(b.section || 0)
            const ordinalScore = a.ordinal - b.ordinal
            return sectionScore * 2 + ordinalScore
        })
    }

    private get viewModeLevel(): number {
        return getObservationViewModeLevel(this.$store)
    }

    private set viewModeLevel(level: number) {
        setObservationViewModeLevel(this.$store, level)
    }

    private get dragAndDropOptions(): VueDraggableOptions {
        return {
            dropzoneSelector: ".drag-inner-list",
            draggableSelector: ".drag-item",
            multipleDropzonesItemsDraggingEnabled: true
        } as unknown as VueDraggableOptions
    }

    private get sections() {
        return [...getObservationSections(this.$store), {name: "", id: "0", ordinal: -1}]
    }

    private get observationsWithoutSection(): ObservationEntity[] {
        return this.observations
            .filter(observation => observation.section === null)
            .sort((a: ObservationEntity, b: ObservationEntity) => a.ordinal - b.ordinal)
    }

    private async mounted() {
        if (this.isReportEnabled) {
            await fetchAllObservations(this.$store)
            // window.document.getElementsByClassName("observation-item")[0].scrollIntoView()
        } else {
            this.$router.push("documents")
        }
    }

    @Watch("observations")
    @Watch("sections")
    private createSectionObservationOrder() {
        this.isCreatingOrder = true
        const newSectionObservationOrder: any[] = []

        this.sections.forEach(section => {
            const observationsObjects = section.name ? this.observationsOf(section) : this.observationsWithoutSection
            const items = observationsObjects.map(observation => ({
                id: Number(observation.id!),
                groupId: observation.section,
                name: observation.title,
                observation: {...observation}
            }))
            newSectionObservationOrder.push({
                id: section.id,
                name: section.name,
                ordinal: section.ordinal,
                items
            })
        })
        this.sectionObservationOrder = newSectionObservationOrder
        this.isReordering = false
    }

    @Watch("observationSections")
    private updateSectionExpansion() {
        this.observationSections.forEach(section => this.$set(
            this.expandedStates,
            section.id,
            this.expandedStates[section.id] !== undefined ? this.expandedStates[section.id] : true
        ))
    }

    private imageId(observation: ObservationEntity): string | null {
        if (observation.type === "Investigation") {
            return null
        }
        return observation.referencedObject!
    }

    private openViewModeMenu() {
        const viewModeMenuOpenerButton = this.$refs.viewModeMenuOpener as HTMLElement
        this.$emit("openViewModeMenu", viewModeMenuOpenerButton)
    }

    @Watch("scrolledToSection")
    private scrollToSection() {
        if (this.scrolledToSection) {
            const section = this.$refs[this.scrolledToSection] as Vue[]
            const element = section[0] as unknown as HTMLElement
            element.scrollIntoView()
            window.document.getElementsByClassName("middle")[0].scrollTop -= 60
            this.$emit("scrolled")
        }
    }

    @Watch("scrolledToObservation")
    private scrollToObservation() {

        if (this.scrolledToObservation) {
            const section = this.observations.find(observation => observation.id === this.scrolledToObservation)!.section
            if (section && !this.expandedStates[section]) {
                this.expandedStates[section] = true
                setTimeout(this.performScrollToObservation, 100)
            } else {
                this.performScrollToObservation()
            }
        }
    }

    private performScrollToObservation() {
        const container = window.document.getElementsByClassName("middle")[0]
        const observation = this.$refs[this.scrolledToObservation] as Vue[]
        const target = observation[0].$el.getBoundingClientRect()
        observation[0].$el.scrollIntoView()
        window.document.getElementsByClassName("middle")[0].scrollTop -= 60
        this.$emit("scrolled")
    }

    @Watch("sectionObservationOrder", {deep: true})
    private changedSectionObservationOrder() {
        this.isReordering = true
        if (!this.isCreatingOrder) {
            const newObservations: ObservationEntity[] = []
            this.sectionObservationOrder.forEach(
                (section: any) => section.items
                    .map((item: any) => item.observation)
                    .forEach((observation: ObservationEntity, index: number) => {
                            observation.section = section.id === "0" ? null : section.id.toString()
                            observation.ordinal = index
                            newObservations.push(observation)
                        }
                    ))

            reorderObservations(this.$store, newObservations)
        }
        this.isCreatingOrder = false
    }

    private observationsOf(section: ObservationSectionEntity): ObservationEntity[] {
        return this.observations
            .filter(observation => section.id === observation.section || (observation.section === null && section.id === "0"))
            .sort((a: ObservationEntity, b: ObservationEntity) => a.ordinal - b.ordinal)
    }

    private toggleExpansion(sectionId: string) {
        this.expandedStates[sectionId] = !this.expandedStates[sectionId]
    }

    private openObservationSectionMenu(button: HTMLElement, observationSection: ObservationSectionEntity) {
        this.sectionContext = observationSection
        this.$nextTick(() => this.$emit("openObservationSectionMenu", button))
    }

    private renameSection() {
        if (this.sectionContext != null && this.sectionContext.name !== "Default Section") {
            // @ts-ignore
            (this.$refs.renameObservationSectionDialog as RenameObservationSectionDialog).open({section: this.sectionContext})
        }
    }

    private setSectionIndex() {
        if (this.sectionContext != null && this.sectionContext.name !== "Default Section") {
            // @ts-ignore
            (this.$refs.observationSectionIndexDialog as ObservationSectionIndexDialog).open({section: this.sectionContext})
        }
    }

    private deleteSection() {
        if (this.sectionContext != null && this.sectionContext.name !== "Default Section") {
            // @ts-ignore
            openConfirmationDialog({
                message: `Are you sure you want to delete "${this.sectionContext.name}" section?`,
                title: "Delete Section",
                okText: "Delete",
                onOk: () => this.performDeleteSection(),
                showCancel: true
            })
        }
    }

    private async performDeleteSection() {
        const hasObservations = this.observationsOf(this.sectionContext!!).length > 0
        await deleteObservationSection(this.$store, this.sectionContext!!)
    }

    private createObservation() {
        openCreateObservationDialog(this, {
            type: "Investigation",
            allowImageUpload: true
        })
    }
}
