
export function getGroupColor(groupId: string, isGraphNode: boolean): string {
    switch (groupId) {
        case "1": return isGraphNode ? "#FFEF3A" : "#FFF9B7"
        case "2": return isGraphNode ? "#34D5FF" : "#D3F6FF"
        case "3": return isGraphNode ? "#F94844" : "#FFD4D3"
        case "4": return isGraphNode ? "#B454FC" : "#ECD3FF"
        case "5": return isGraphNode ? "#B0FB2D" : "#E5FFB7"
        case "6": return isGraphNode ? "#FFCC53" : "#F0DBAA"
        case "7": return isGraphNode ? "#395AFD" : "#C2CCF9"
        case "8": return isGraphNode ? "#F845D8" : "#FFD3F7"
        case "9": return isGraphNode ? "#FB9446" : "#FFE6D3"
        case "10": return isGraphNode ? "#37FB73" : "#B7FFCD"
    }

    return isGraphNode ? "#AEC7CE" : "#DEE6E9"
}

