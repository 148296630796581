import {RootState} from "@/store/index"
import environmentService from "../api/environment-service"
import {ActionContext} from "vuex"
import {getStoreAccessors} from "vuex-typescript"
import {EnvironmentEntity} from "@/types/environment"


export interface EnvironmentState {
    version: string
    showHebrewTutorials: boolean
    showHiddenFeatures: boolean
    showDocumentLabels: boolean
    showTranslations: boolean
    showSignUp: boolean
    directSignUp: boolean
    reportEnabled: boolean
    entityDetectionEnabled: boolean
    alertsEnabled: boolean
    showTimeline: boolean
    documentsPageSize: number
    userGuideUrl: string
    rollbarAccessToken: string
    supportBatchInvestigations: boolean
    supportPoliticalInvestigations: boolean
    supportAcademicResearcherGroupInvestigations: boolean
    supportUniversityInvestigations: boolean
    supportWebsiteInvestigations: boolean
    supportTenderInvestigations: boolean
    supportCompanyInvestigations: boolean
    supportLocalDocumentsInvestigations: boolean
    showPredefinedTermGroups: boolean
    allowDuplicateInvestigation: boolean
}


const module = {
    namespaced: true,

    state(): EnvironmentState {
        return {
            version: "",
            showHebrewTutorials: false,
            showHiddenFeatures: false,
            showDocumentLabels: false,
            showTranslations: false,
            showSignUp: false,
            directSignUp: false,
            reportEnabled: false,
            entityDetectionEnabled: false,
            alertsEnabled: false,
            showTimeline: false,
            documentsPageSize: 50,
            userGuideUrl: "",
            rollbarAccessToken: "",
            supportBatchInvestigations: false,
            supportPoliticalInvestigations: false,
            supportAcademicResearcherGroupInvestigations: false,
            supportUniversityInvestigations: false,
            supportWebsiteInvestigations: false,
            supportTenderInvestigations: false,
            supportCompanyInvestigations: false,
            supportLocalDocumentsInvestigations: false,
            showPredefinedTermGroups: false,
            allowDuplicateInvestigation: false
        }
    },

    getters: {
        getVersion(state: EnvironmentState): string {
            return state.version
        },

        isShowingHebrewTutorials(state: EnvironmentState): boolean {
            return state.showHebrewTutorials
        },

        isShowingHiddenFeatures(state: EnvironmentState): boolean {
            return state.showHiddenFeatures
        },

        isShowingDocumentLabels(state: EnvironmentState): boolean {
            return state.showDocumentLabels
        },

        isShowingTranslations(state: EnvironmentState): boolean {
            return state.showTranslations
        },

        getDocumentsPageSize(state: EnvironmentState): number {
            return state.documentsPageSize
        },

        isShowingSignUp(state: EnvironmentState): boolean {
            return state.showSignUp
        },

        isDirectSignUp(state: EnvironmentState): boolean {
            return state.directSignUp
        },

        isReportEnabled(state: EnvironmentState): boolean {
            return state.reportEnabled
        },

        isEntityDetectionEnabled(state: EnvironmentState): boolean {
            return state.entityDetectionEnabled
        },

        areAlertsEnabled(state: EnvironmentState): boolean {
            return state.alertsEnabled
        },

        isShowingTimeline(state: EnvironmentState): boolean {
            return state.showTimeline
        },

        getuserGuideUrl(state: EnvironmentState): string {
            return state.userGuideUrl
        },

        getRollbarAccessToken(state: EnvironmentState): string {
            return state.rollbarAccessToken
        },

        isSupportBatchInvestigations(state: EnvironmentState): boolean {
            return state.supportBatchInvestigations
        },

        isSupportPoliticalInvestigations(state: EnvironmentState): boolean {
            return state.supportPoliticalInvestigations
        },

        isSupportAcademicResearcherGroupInvestigations(state: EnvironmentState): boolean {
            return state.supportAcademicResearcherGroupInvestigations
        },

        isSupportUniversityInvestigations(state: EnvironmentState): boolean {
            return state.supportUniversityInvestigations
        },

        isSupportWebsiteInvestigations(state: EnvironmentState): boolean {
            return state.supportWebsiteInvestigations
        },

        isSupportTenderInvestigations(state: EnvironmentState): boolean {
            return state.supportTenderInvestigations
        },

        isSupportCompanyInvestigations(state: EnvironmentState): boolean {
            return state.supportCompanyInvestigations
        },

        isSupportLocalDocumentsInvestigations(state: EnvironmentState): boolean {
            return state.supportLocalDocumentsInvestigations
        },

        isShowPredefinedTermGroups(state: EnvironmentState): boolean {
            return state.showPredefinedTermGroups
        },

        isAllowDuplicateInvestigation(state: EnvironmentState): boolean {
            return state.allowDuplicateInvestigation
        }
    },

    mutations: {
        setEnvironment(state: EnvironmentState, {
            version,
            showHebrewTutorials = false,
            showHiddenFeatures = false,
            showDocumentLabels = false,
            showTranslations = false,
            showSignUp = false,
            directSignUp = false,
            reportEnabled = false,
            entityDetectionEnabled = false,
            alertsEnabled = false,
            showEntityGraph = false,
            showTimeline = false,
            userGuideUrl,
            rollbarAccessToken = "",
            supportBatchInvestigations = false,
            supportPoliticalInvestigations = false,
            supportAcademicResearcherGroupInvestigations = false,
            supportUniversityInvestigations = false,
            supportWebsiteInvestigations = false,
            supportTenderInvestigations = false,
            supportCompanyInvestigations = false,
            supportLocalDocumentsInvestigations = false,
            showPredefinedTermGroups = false,
            allowDuplicateInvestigation  = false
        }: EnvironmentEntity) {
            state.version = version
            state.showHebrewTutorials = showHebrewTutorials
            state.showHiddenFeatures = showHiddenFeatures
            state.showDocumentLabels = showDocumentLabels
            state.showTranslations = showTranslations
            state.showSignUp = showSignUp
            state.directSignUp = directSignUp
            state.reportEnabled = reportEnabled
            state.entityDetectionEnabled = entityDetectionEnabled
            state.alertsEnabled = alertsEnabled
            state.showTimeline = showTimeline
            state.userGuideUrl = userGuideUrl
            state.rollbarAccessToken = rollbarAccessToken
            state.supportBatchInvestigations = supportBatchInvestigations
            state.supportPoliticalInvestigations = supportPoliticalInvestigations
            state.supportAcademicResearcherGroupInvestigations = supportAcademicResearcherGroupInvestigations
            state.supportUniversityInvestigations = supportUniversityInvestigations
            state.supportWebsiteInvestigations = supportWebsiteInvestigations
            state.supportTenderInvestigations = supportTenderInvestigations
            state.supportCompanyInvestigations = supportCompanyInvestigations
            state.supportLocalDocumentsInvestigations = supportLocalDocumentsInvestigations
            state.showPredefinedTermGroups = showPredefinedTermGroups
            state.allowDuplicateInvestigation = allowDuplicateInvestigation
        }
    },

    actions: {
        async fetchEnvironment(context: ActionContext<EnvironmentState, RootState>): Promise<void> {
            const environment = await environmentService.getEnvironment()
            setEnvironment(context, environment)
        }
    }
}

export default module


const { read, commit, dispatch } = getStoreAccessors<EnvironmentState, RootState>("environment")

export const getVersion = read(module.getters.getVersion)
export const isShowingSignUp = read(module.getters.isShowingSignUp)
export const isDirectSignUp = read(module.getters.isDirectSignUp)
export const isShowingHebrewTutorials = read(module.getters.isShowingHebrewTutorials)
export const isShowingHiddenFeatures = read(module.getters.isShowingHiddenFeatures)
export const isShowingDocumentLabels = read(module.getters.isShowingDocumentLabels)
export const isShowingTranslations = read(module.getters.isShowingTranslations)
export const isShowingTimeline = read(module.getters.isShowingTimeline)
export const isReportEnabled = read(module.getters.isReportEnabled)
export const isEntityDetectionEnabled = read(module.getters.isEntityDetectionEnabled)
export const areAlertsEnabled = read(module.getters.areAlertsEnabled)
export const getDocumentsPageSize = read(module.getters.getDocumentsPageSize)
export const fetchEnvironment = dispatch(module.actions.fetchEnvironment)
export const getUserGuideUrl = read(module.getters.getuserGuideUrl)
export const getRollbarAccessToken = read(module.getters.getRollbarAccessToken)
export const isSupportBatchInvestigations = read(module.getters.isSupportBatchInvestigations)
export const isSupportPoliticalInvestigations = read(module.getters.isSupportPoliticalInvestigations)
export const isSupportAcademicResearcherGroupInvestigations = read(module.getters.isSupportAcademicResearcherGroupInvestigations)
export const isSupportUniversityInvestigations = read(module.getters.isSupportUniversityInvestigations)
export const isSupportWebsiteInvestigations = read(module.getters.isSupportWebsiteInvestigations)
export const isSupportTenderInvestigations = read(module.getters.isSupportTenderInvestigations)
export const isSupportCompanyInvestigations = read(module.getters.isSupportCompanyInvestigations)
export const isSupportLocalDocumentsInvestigations = read(module.getters.isSupportLocalDocumentsInvestigations)
export const isShowPredefinedTermGroups = read(module.getters.isShowPredefinedTermGroups)
export const isAllowDuplicateInvestigation = read(module.getters.isAllowDuplicateInvestigation)

const setEnvironment = commit(module.mutations.setEnvironment)
