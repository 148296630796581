



































import Vue from "vue"
import Component from "vue-class-component"
import GenericDialog from "@/components/GenericDialog.vue"

export interface UpdateEntityImageDialogOptions {
    readonly title: string
    readonly initialImageUrlOrFile: string | File | null,
    readonly onUpdateCallback: (file: File | null) => void
    readonly widthAspectRatio: number
    readonly heightAspectRatio: number
    readonly forceAspectRatio: boolean
    readonly roundedImage: boolean
}

@Component({
    components: {GenericDialog}
})
export default class UpdateEntityImageDialog extends Vue {

    private options?: UpdateEntityImageDialogOptions
    private isOpen = false
    private dragOver: boolean = false
    private file: File | null = null
    private showingImage: boolean = false
    private modified: boolean = false


    public open(options: UpdateEntityImageDialogOptions) {
        this.options = options
        this.isOpen = true
        this.file = null
        this.showingImage = false

        this.$nextTick(() => {
            if (!!options.initialImageUrlOrFile) {
                const image = this.$refs.image as HTMLImageElement
                if (typeof(options.initialImageUrlOrFile) === "object") {
                    this.setFileAsImage(options.initialImageUrlOrFile)
                } else {
                    image.src = options.initialImageUrlOrFile
                }
                this.showingImage = true
            }
        })

        document.addEventListener("keyup", this.handleKey)
    }

    private handleKey(e: KeyboardEvent) {
        switch (e.key) {
            case "Escape":
                this.close()
                break
            case "Enter":
                this.ok()
                break
        }
    }

    private async ok() {
        if (this.modified) {
            this.options!!.onUpdateCallback(this.file)
        }
        this.close()
    }

    private close() {
        this.isOpen = false
        document.removeEventListener("keyup", this.handleKey)
    }

    private clear() {
        this.file = null
        this.showingImage = false
        this.modified = true
    }

    private fileSelected(e: Event) {
        const input = e.target as HTMLInputElement
        if (input.files) {
            const file = input.files[0]
            this.setFileAsImage(file)
        }
    }

    private dragFile(e: DragEvent) {
        const droppedFiles = e.dataTransfer!.files
        if (!droppedFiles) {
            return
        }
        ([...droppedFiles]).forEach(file => {
            this.setFileAsImage(file)
        })
    }

    private setFileAsImage(file: File) {
        this.file = file
        Vue.nextTick(() => {
            const image = this.$refs.image as HTMLImageElement
            image.src = URL.createObjectURL(file)
            this.showingImage = true
            this.modified = true
        })
    }
}
