



























import Vue from "vue"
import Component from "vue-class-component"
import {addTermFromText, createTermGroup, getTermGroups, getTerms, MAX_ALLOWED_TERMS} from "@/store/investigation/investigation-terms-module"
import {InvestigationTermGroupEntity, TermModifier} from "@/types/investigations"
import {Prop} from "vue-property-decorator"
import PopupMenu from "@/components/PopupMenu.vue"
import {Store} from "vuex"
import {getGroupColor} from "@/utils/color-utils"
import {openMessageDialog} from "@/components/MessageDialog.vue"
import {openRenameTermGroupDialog} from "@/views/investigation/dialogs/RenameTermGroupDialog.vue"
import {fetchAllData, fetchDocumentMarkups} from "@/store/investigation/investigation-module"
import {getGraphType} from "@/store/investigation/graph-module"

@Component({
    components: {PopupMenu}
})
export default class CreateTermTemplate extends Vue {

    @Prop({default: true})
    private enableCreateGroup!: boolean

    @Prop()
    private term!: string

    @Prop()
    private action!: (store: Store<any>, payload: any) => void

    get termGroups(): InvestigationTermGroupEntity[] {
        return getTermGroups(this.$store)
    }

    private async addTerm(groupEntity: InvestigationTermGroupEntity | null, modifier: TermModifier) {
        if (getTerms(this.$store).length + 1 > MAX_ALLOWED_TERMS) {
            openMessageDialog({title: "Add Terms", message: `Cannot add more than ${MAX_ALLOWED_TERMS} terms`})
            return
        }

        const newGroupName = this.chooseNewGroupName()

        const creatingNewGroup = !groupEntity
        if (creatingNewGroup) {
            await createTermGroup(this.$store, newGroupName)
            groupEntity = getTermGroups(this.$store).find(it => it.name === newGroupName)!!
        }

        const {term} = this
        const group = groupEntity!!.id
        this.$parent.$emit("addedTerm", {term, groupEntity, modifier})
        const action: (store: Store<any>, payload: any) => void = this.action === undefined ? addTermFromText : this.action
        await action(this.$store, {term, selected: true, modifier, group})

        if (creatingNewGroup) {
            openRenameTermGroupDialog(this, {group: groupEntity!!})
        }

        if (getGraphType(this.$store) === null) {
            if (action === addTermFromText) {
                modifier === "Mandatory" ? await fetchAllData(this.$store) : await fetchDocumentMarkups(this.$store)
            }
        }
    }

    private getGroupColor(group: string): string {
        return getGroupColor(group, false)
    }

    private chooseNewGroupName() {
        const termGroupNames = getTermGroups(this.$store).map(it => it.name)
        let index = 0
        while (true) {
            const newGroupName = `<New Group${index === 0 ? "" : " " + index}>`
            if (!termGroupNames.find(it => it === newGroupName)) {
                return newGroupName
            }
            index++
        }
    }
}
