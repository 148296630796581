import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import {KendoEditorPlugin} from "./plugins/kendo-editor"
import {VuexTypescriptPlugin} from "@/plugins/vuex-typescript-plugin"
import Vuetify from "vuetify"
// @ts-ignore
import VueParticles from "vue-particles"
import VueDraggable from "vue-draggable"
import "keylines"
// import "cropper/index.js"
import "./externals/vue-keylines"
// @ts-ignore
import VueFusionCharts from "vue-fusioncharts"
import FusionCharts from "fusioncharts"
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion"
import TimeSeries from "fusioncharts/fusioncharts.timeseries"

Vue.use(KendoEditorPlugin)
Vue.use(VuexTypescriptPlugin)
Vue.use(Vuetify)
Vue.use(VueParticles)
Vue.use(VueDraggable)
Vue.use(VueFusionCharts, FusionCharts, FusionTheme, TimeSeries)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app")
