




























import Vue from "vue"
import Component from "vue-class-component"
import {
  getEntityForSummary,
  getInvestigation,
  getSpecForStore,
  setEntityForSummary
} from "@/store/investigation/investigation-module"
import investigationService from "@/api/investigation-service"
import {Prop, Watch} from "vue-property-decorator"
import {EntitySummaryEntity, MentionedEntity} from "@/types/investigations"
import DocumentExcerptsPane from "@/views/investigation/panes/DocumentExcerptsPane.vue"

@Component({
    components: {
        DocumentExcerptsPane
    }
})
export default class EntitySummaryPane extends Vue {

    @Prop()
    private entity!: MentionedEntity

    private isDataFetched = true
    private entitySummary: EntitySummaryEntity | null = null

    private async mounted() {
        await this.entityChanged()
    }

    @Watch("entity")
    private async entityChanged() {
        const investigation = getInvestigation(this.$store)!
        const spec = getSpecForStore(this.$store)
        this.isDataFetched = false
        this.entitySummary = await investigationService.getEntityForSummary(investigation.id, investigation.investigationType, this.entity.name, this.entity.type, spec)
        this.isDataFetched = true
    }

    private get isInRightPane() {
        return getEntityForSummary(this.$store) != null
    }


    private close() {
        setEntityForSummary(this.$store, null)
    }
}
