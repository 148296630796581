












































import Vue from "vue"
import Component from "vue-class-component"
import {getDocuments} from "@/store/investigation/documents-module"
import {fetchAllData, getIsInProfile} from "@/store/investigation/investigation-module"
import {DocumentEntity} from "@/types/investigations"
import AuthorSummaryPane from "@/views/investigation/panes/AuthorSummaryPane.vue"
import AuthorGraph from "@/views/investigation/graphs/AuthorGraph.vue"
import DateFilter from "@/components/filter/DateFilter.vue"
import OverviewDocumentItem from "@/views/investigation/panes/OverviewDocumentItem.vue"

@Component({
    components: {AuthorGraph, DateFilter, AuthorSummaryPane, OverviewDocumentItem}
})
export default class OverviewPane extends Vue {

    get documents(): DocumentEntity[] {
        return getDocuments(this.$store).slice(0, 3)
    }

    get isInPersonProfile(): boolean {
        return getIsInProfile(this.$store)
    }

    private get personId(): string {
        return this.$route.params.personId
    }

    private goToAuthorGraph() {
        this.$router.push("author-graph")
    }

    private goToTermGraph() {
        this.$router.push("entity-graph")
    }

    private goToDocuments() {
        this.$router.push("documents")
    }

    private async mounted() {
        await fetchAllData(this.$store)
    }
}
