











































import Vue from "vue"
import Component from "vue-class-component"
import {InvestigationTermEntity, InvestigationTermGroupEntity} from "@/types/investigations"
import {getTermGroups, getTerms} from "@/store/investigation/investigation-terms-module"
import {getExternalSeries, getTimeline, toggleExternalSeries, toggleTimelineTerm, toggleTimelineTermGroup} from "@/store/investigation/graph-module"

@Component
export default class TimelineTermsPane extends Vue {

    private get allTerms(): InvestigationTermEntity[] {
        return getTerms(this.$store)
    }

    get allTermGroups(): InvestigationTermGroupEntity[] {
        return getTermGroups(this.$store)
    }

    get allExternalSeries(): string[] {
        return getExternalSeries(this.$store)
    }

    private termsOf(group: InvestigationTermGroupEntity): InvestigationTermEntity[] {
        return this.allTerms.filter(term => group.id === term.group)
    }

    private isGroupSelected(group: InvestigationTermGroupEntity): boolean {
        return !!getTimeline(this.$store).selectedTermGroups.find(it => it === group.name)
    }

    private isTermSelected(term: InvestigationTermEntity): boolean {
        return !!getTimeline(this.$store).selectedTerms.find(it => it === term.term)
    }

    private isExternalSeriesSelected(externalSeries: string): boolean {
        return getTimeline(this.$store).selectedExternalSeries === externalSeries
    }

    private selectTermGroup(group: InvestigationTermGroupEntity) {
        toggleTimelineTermGroup(this.$store, group)
    }

    private selectTerm(term: InvestigationTermEntity) {
        toggleTimelineTerm(this.$store, term)
    }

    private selectExternalSeries(externalSeries: string) {
        toggleExternalSeries(this.$store, externalSeries)
    }
}
