







import Vue from "vue"
import Component from "vue-class-component"
import HeaderBar from "@/components/header/HeaderBar.vue"

@Component({
        components: {HeaderBar}
    })
    export default class MainPage extends Vue {
    }
