


























































import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import KendoEditorWrapper from "@/components/KendoEditorWrapper.vue"
import {getInvestigation} from "@/store/investigation/investigation-module"
import {
    addObservation,
    editObservation,
    getObservations, getObservationSections,
    ObservationEntity,
    ObservationSectionEntity
} from "@/store/investigation/observation-module"
import {Prop, Watch} from "vue-property-decorator"
import ReferencedEntityPreview from "@/views/investigation/previews/ReferencedEntityPreview.vue"
import {ObservationType} from "@/types/investigations"
import GenericDialogNew, {createDialogMountingPoint} from "@/components/GenericDialogNew.vue"
import UploadImagePane from "@/components/UploadImagePane.vue"
import {ImageCropData} from "@/api/investigation-service"
import {PermissionsMixin} from "@/store/user-mixin"

export function openCreateObservationDialog(parent: Vue, options: CreateObservationDialogOptions) {
    new (Vue.extend(CreateObservationDialog))({parent, store: parent.$store, router: parent.$router, propsData: {options}}).$mount(createDialogMountingPoint())
}

export class CreateObservationDialogOptions {
    public type!: ObservationType
    public observation?: ObservationEntity
    public referencedEntity?: string
    public observationBody?: string
    public observationTitle?: string
    public allowImageUpload?: boolean = false
}

@Component({
    components: {UploadImagePane, GenericDialogNew, KendoEditorWrapper, ReferencedEntityPreview}
})
export default class CreateObservationDialog extends mixins(Vue, PermissionsMixin) {

    @Prop()
    private options!: CreateObservationDialogOptions

    private pages: string[] = ["Details"]
    private currentPage: string = "Details"
    private title: string = ""
    private body: string = ""
    private imageId: string | null = null
    private imageCropData: ImageCropData | null = null
    private selectedSection: ObservationSectionEntity | null = null

    private availableSections: ObservationSectionEntity[] = []


    public async mounted() {
        if (this.options.allowImageUpload) {
            this.pages.push("Image")
        }

        if (this.options.observation) {
            this.title = this.options.observation.title
            this.body = this.options.observation.observation
            this.imageId = this.options.observation.imageId
        }
        if (this.options.observationBody) {
            this.body = this.options.observationBody
        }
        if (this.options.observationTitle) {
            this.title = this.options.observationTitle
        }

        this.availableSections = getObservationSections(this.$store)
        this.selectedSection = this.availableSections.find(x => x.id === this.options.observation?.section) || null

        this.$nextTick(() => {
            const title = this.$refs.title as HTMLInputElement
            title.focus()
        })
    }


    @Watch("imageCropData")
    private imageFileWatcher() {
        if (!this.imageCropData) {
            this.imageId = null
        }
    }


    private close() {
        (this.$refs.genericDialog as GenericDialogNew).close()
    }


    private save() {
        if (this.title) {
            const observations = getObservations(this.$store)
            const body = this.trimBody(this.body)
            const imageId = this.imageId
            const observation: ObservationEntity = this.options.observation ?
                {
                    ...this.options.observation,
                    observation: body,
                    title: this.title.trim(),
                    section: this.selectedSection?.id || null,
                    imageId
                }
                :
                {
                    observation: body,
                    title: this.title.trim(),
                    referencedObject: this.options.referencedEntity,
                    section: this.selectedSection?.id || null,
                    ordinal: Math.max(...observations.filter(o => o.section === null).map(it => it.ordinal)) + 1,
                    imageId
                }

            const investigation = getInvestigation(this.$store)!!
            const {investigationType} = investigation
            const observationType = this.options.type
            const imageCropData = this.imageCropData ? this.imageCropData!! : undefined
            const observationToSend = {observation, investigationType, observationType, imageCropData}
            this.options.observation ?
                editObservation(this.$store, observationToSend) :
                addObservation(this.$store, observationToSend)

            this.close()
        }
    }

    private trimBody(body: string): string {
        if (!this.isFacultyWorksUser) {
            return body
        }
        if (!body) {
            return ""
        }
        // single tags are problematic with the current abstract slicing algorithm
        const re2 = RegExp("<\\s*/?\\s*(br|p)\\s*/?\\s*>", "gi")
        return body
            .replace(re2, "\n")
            .split(/(?:\n|&nbsp;)+/gim)
            .filter(x => x.length > 0)
            .map(x => `<p>${x.trim()}</p>`).join("")
    }

    private setPersonNameInTitle(name: string) {
        this.title = name
    }
}
