























import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"
import {renameTermGroup} from "@/store/investigation/investigation-terms-module"
import {InvestigationTermGroupEntity} from "@/types/investigations"
import GenericDialogNew, {createDialogMountingPoint} from "@/components/GenericDialogNew.vue"

export function openRenameTermGroupDialog(parent: Vue, options: RenameTermGroupDialogOptions) {
    new (Vue.extend(RenameTermGroupDialog))({parent, store: parent.$store, router: parent.$router, propsData: {options}}).$mount(createDialogMountingPoint())
}

export interface RenameTermGroupDialogOptions {
    readonly group: InvestigationTermGroupEntity
}

@Component({
    components: { GenericDialogNew }
})
export default class RenameTermGroupDialog extends Vue {

    @Prop()
    private options!: RenameTermGroupDialogOptions

    private groupName: string = ""


    public mounted() {
        Vue.nextTick(() => {
            this.groupName = this.options.group.name

            const groupNameText = this.$refs.groupNameText as HTMLInputElement
            groupNameText.focus()
        })
    }

    private dialogAppeared() {
        const textInput = this.$refs.groupNameText as HTMLInputElement
        textInput.select()
        textInput.focus()
    }

    private close() {
        (this.$refs.genericDialog as GenericDialogNew).close()
    }


    private async renameGroup() {
        this.close()
        await renameTermGroup(this.$store, {...this.options.group, name: this.groupName})
    }
}
