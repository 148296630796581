
































import Vue from "vue"
import Component from "vue-class-component"
import {
  getEntityRelationshipForSummary,
  getInvestigation,
  setEntityRelationshipForSummary
} from "@/store/investigation/investigation-module"
import investigationService from "@/api/investigation-service"
import {Prop, Watch} from "vue-property-decorator"
import {EntityRelationshipSummaryEntity, MentionedEntity} from "@/types/investigations"
import DocumentExcerptsPane from "@/views/investigation/panes/DocumentExcerptsPane.vue"

@Component({
    components: {
        DocumentExcerptsPane
    }
})
export default class EntityRelationshipSummaryPane extends Vue {

    @Prop()
    private entities!: MentionedEntity[]

    private isDataFetched = true
    private entityRelationshipSummary: EntityRelationshipSummaryEntity | null = null

    private async mounted() {
        await this.entityChanged()
    }

    @Watch("entities")
    private async entityChanged() {
        const investigation = getInvestigation(this.$store)!
        this.isDataFetched = false
        this.entityRelationshipSummary = await investigationService.getEntityRelationshipForSummary(investigation.id, investigation.investigationType, this.entities[0], this.entities[1])
        this.isDataFetched = true
    }

    private get isInRightPane() {
        return getEntityRelationshipForSummary(this.$store) != null
    }

    private close() {
        setEntityRelationshipForSummary(this.$store, null)
    }
}
