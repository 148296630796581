






















































import Vue from "vue"
import Component from "vue-class-component"
import AuthPageContainer from "@/components/AuthPageContainer.vue"
import userService from "../api/user-service"
import {isDirectSignUp} from "@/store/environment-module"
import {signIn} from "@/store/auth-module"

@Component({
    components: {AuthPageContainer}
})
export default class SignUpPage extends Vue {

    private email: string = ""
    private password: string = ""
    private name: string = ""
    private errorMessage: string = ""

    private emailHasFocus: boolean = false
    private passwordHasFocus: boolean = false
    private nameHasFocus: boolean = false
    private emailError: boolean = false
    private passwordError: boolean = false
    private nameError: boolean = false
    private sending: boolean = false
    private sent: boolean = false
    private error: boolean = false

    private get isDirectSignUp() {
        return isDirectSignUp(this.$store)
    }

    private async create() {
        this.emailError = this.email.trim() === ""
        this.passwordError = this.password.trim() === ""
        this.nameError = this.name.trim() === ""

        if (this.emailError || this.passwordError || this.nameError) {
            return
        }

        this.sending = true

        try {
            if (this.isDirectSignUp) {
                await userService.createUser(this.name, this.email, this.password)
                await signIn(this.$store, {email: this.email, password: this.password})
                this.$router.push("/")
            } else {
                await userService.signUp(this.name, this.email, this.password)
                this.sent = true
            }

        } catch (error) {
            switch (error.response.data) {
                case "Duplicate":
                    this.errorMessage = `There is an existing account associated with ${this.email}`
                    break
                case "Invalid user data":
                    this.errorMessage = "Please make sure Nickname contains at least 2 alphanumeric characters"
                    break
                case "Email error":
                    this.errorMessage = "Email error"
                    break
                default:
                    this.errorMessage = "Oops! Sign Up failed. Please try again."
            }
            this.error = true
            this.sending = false
        }
    }
}

