























import Vue from "vue"
import Component from "vue-class-component"
import GenericDialog from "@/components/GenericDialog.vue"
import {createObservationSection} from "@/store/investigation/observation-module"

@Component({
    components: {GenericDialog}
})
export default class CreateObservationSectionDialog extends Vue {

    private isOpen = false
    private sectionName: string = ""


    public open() {
        this.isOpen = true
        this.sectionName = ""
        document.addEventListener("keyup", this.handleKey)

        Vue.nextTick(() => {
            const sectionNameText = this.$refs.sectionNameText as HTMLInputElement
            sectionNameText.focus()
        })
    }


    private close() {
        this.isOpen = false
        document.removeEventListener("keyup", this.handleKey)
    }


    private handleKey(e: KeyboardEvent) {
        switch (e.key) {
            case "Escape":
                this.close()
                break
            case "Enter":
              this.createsection()
              break
        }
    }

    private async createsection() {
        this.close()
        await createObservationSection(this.$store, this.sectionName)
    }
}
