import axios from "axios"
import {ClusterReport} from "@/types/clusters"


export default {

    async merge(investigationId: string): Promise<string> {
        const response = await axios.post(`/api/admin/merge/investigations/${investigationId}`)
        return response.data as string
    },

    async mergeAll(): Promise<string> {
        const response = await axios.post(`/api/admin/merge/investigations`)
        return response.data as string
    },

    async createMergeReport(personIds: string[]): Promise<Blob> {
        const response = await axios.post(`/api/admin/merge/report`, personIds, { responseType: "arraybuffer" })
        return new Blob([response.data])
    },


    async createMergeFinalReport(mainPersonId: string, personIds: string[]): Promise<FinalReportDecision[]> {
        const response = await axios.post(`/api/admin/merge/final-report`, { mainPersonId, personIds })
        return response.data as FinalReportDecision[]
    },


    async debugMerge(fileKey: string, personId: string, goodDocumentIds: string[], badDocumentIds: string[]): Promise<string> {
        const response = await axios.post(`/api/admin/merge/debug`, { fileKey, personId, goodDocumentIds, badDocumentIds })
        return response.data as string
    },


    async clusterReportUsingFile(file: File): Promise<ClusterReport> {
        const formData = new FormData()
        formData.set("file", file)
        formData.set("fileName", file.name)

        const response = await axios.post(`/api/admin/clusters/report-by-file`, formData)
        return response.data as ClusterReport
    },


    async clusterReport(investigationId: string, cvFile: File | null, doiList: string[]): Promise<ClusterReport> {
        const formData = new FormData()
        formData.set("investigationId", investigationId)
        formData.set("doiList", JSON.stringify(doiList))
        if (cvFile) {
            formData.set("cvFile", cvFile)
        }

        const response = await axios.post(`/api/admin/clusters/generate-report`, formData)
        return response.data as ClusterReport
    },


    async getHealthChecks(): Promise<HealthChecks> {
        const response = await axios.get(`/maintenance/healthcheck`)
        return response.data as HealthChecks
    },


    async createBatchAccuracyReport(file: File): Promise<string> {
        const formData = new FormData()
        formData.set("file", file)

        const response = await axios.post("/api/admin/clusters/batch-accuracy-report", formData)
        return response.data as string
    },

    async generateQualityEstimationReport(): Promise<string> {
        const response = await axios.get("/api/admin/clusters/quality-estimation-report")
        return response.data as string
    },

    async generateAuthorFeedbackReport(): Promise<string> {
        const response = await axios.get("/api/admin/clusters/author-feedback-report")
        return response.data as string
    }
}


export interface FinalReportDecision {
    readonly id1: number,
    readonly id2: number,
    readonly totalScore: number,
    readonly scores: { [key: string]: number }
}


export interface HealthChecks {
    readonly merge: any
    readonly normalization: any
}
