











import Vue from "vue"
import Component from "vue-class-component"
import { createUserAndSignIn } from "../store/auth-module"
import AuthPageContainer from "@/components/AuthPageContainer.vue"

@Component({
    components: { AuthPageContainer }
})
export default class RegisterAccountPage extends Vue {

    private createUserAndSignIn = this.$wrap(createUserAndSignIn)

    private error: boolean = false

    private async mounted() {
        try {
            await this.createUserAndSignIn(this.$route.query[`token`] as string)
            this.$router.push("/")
        } catch (e) {
            this.error = true
        }
    }
}
