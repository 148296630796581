







import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"

@Component
export default class CheckBox extends Vue {

    @Prop()
    private label!: string

    @Prop()
    private value!: boolean

    private newValue: boolean = false


    private mounted() {
        this.newValue = this.value
    }

    private toggle() {
        this.newValue = !this.newValue
        this.$emit("input", this.newValue)
    }
}
