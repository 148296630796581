



























































































































import Vue from "vue"
import Component from "vue-class-component"
import {
    createTermGroup,
    deleteTermGroup,
    getTermGroups,
    getTerms,
    modifyTerm,
    modifyTermGroup,
    moveToGroup,
    removeTerm,
    renameTerm
} from "@/store/investigation/investigation-terms-module"
import {InvestigationTermEntity, InvestigationTermGroupEntity, InvestigationType, TermGroupModifier, TermModifier} from "@/types/investigations"
import {showTermPopupMenu} from "@/store/investigation/dialogs/term-popup-menu-module"
import PopupMenu from "@/components/PopupMenu.vue"
import BatchTermsDialog from "@/views/investigation/dialogs/BatchTermsDialog.vue"
import {copyToClipboard, wrapActiveRequests} from "@/utils/utils"
import {fetchDocumentMarkups, getCancelToken, getInvestigation, getIsBusy, getIsRenderingGraph} from "@/store/investigation/investigation-module"
import {Watch} from "vue-property-decorator"
import {getGroupsExpansion, setGroupsExpansion} from "@/store/investigation/documents-module"
import CreateTermTemplate from "@/views/investigation/templates/CreateTermTemplate.vue"
import {getGroupColor} from "@/utils/color-utils"
import {openConfirmationDialog} from "@/views/investigation/dialogs/ConfirmationDialog.vue"
import {openRenameTermGroupDialog} from "@/views/investigation/dialogs/RenameTermGroupDialog.vue"
import {openCreateTermGroupDialog} from "@/views/investigation/dialogs/CreateTermGroupDialog.vue"
import {openAddPredefinedTermGroupsDialog} from "@/views/investigation/dialogs/AddPredefinedTermGroupsDialog.vue"
import {isShowPredefinedTermGroups} from "@/store/environment-module"
import { openRenameTermDialog } from '../dialogs/RenameTermDialog.vue'


@Component({
    components: {CreateTermTemplate, PopupMenu, BatchTermsDialog}
})
export default class TermsPane extends Vue {

    private newTerm: string = ""
    private isInTransition = false
    private termContext: InvestigationTermEntity | null = null
    private groupContext: InvestigationTermGroupEntity | null = null
    private expandedStates: { [key: string]: boolean } = {}

    get allTermGroups(): InvestigationTermGroupEntity[] {
        return getTermGroups(this.$store)
    }

    get termGroups(): InvestigationTermGroupEntity[] {
        const groups = this.allTermGroups
        return this.typedNewTerm ?
            groups.filter(group => this.termsOf(group).length) :
            groups
    }

    get isBusy(): boolean {
        return getIsBusy(this.$store) || getIsRenderingGraph(this.$store)
    }

    private get filterItems() {
        const items: Array<{ type: string, name: string }> = []

        const groupItems = this.termGroups
            .filter(it => it.modifier === "Mandatory")
            .map(it => ({type: "group", name: it.name}))
            .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

        const mandatoryItems: Array<{type: string, name: string}> = []
        const excludedItems: Array<{type: string, name: string}> = []
        this.terms.forEach(it => {
            if (it.modifier === "Mandatory") {
                mandatoryItems.push({type: "mandatory", name: it.term})
            }
            if (it.modifier === "Exclude") {
                excludedItems.push({type: "exclude", name: it.term})
            }
        })

        mandatoryItems.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        excludedItems.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

        return [...groupItems, ...mandatoryItems, ...excludedItems]
    }

    private get typedNewTerm() {
        return this.newTerm.length > 0
    }

    private get termExists() {
        const terms = getTerms(this.$store).map(term => term.term.toLocaleLowerCase())
        return terms.includes(this.newTerm.toLocaleLowerCase())
    }

    private get terms(): InvestigationTermEntity[] {
        const terms = this.allTerms
        return this.typedNewTerm ?
            terms.filter(term => term.term.toLowerCase().includes(this.newTerm.toLowerCase())) :
            terms
    }

    private get allTerms(): InvestigationTermEntity[] {
        return getTerms(this.$store)
    }

    private get isDefaultGroupInContext() {
        return this.termGroups.length === 1
    }

    private get investigationType(): InvestigationType {
        return getInvestigation(this.$store)!.investigationType
    }

    private mounted() {
        this.$on("closedOncloseSingleTermMenu", this.singleTermMenuClosed)
        this.$on("closedOncloseCreateTermMenu", this.createTermMenuClosed)
    }

    private closeFilterItem(item: { type: string, name: string }) {
        if (item.type === "mandatory" || item.type === "exclude") {
            const term = this.terms.find(investigationTerm => investigationTerm.term === item.name)!
            this.modifyTerm(term, "Regular")
        } else {
            const group = this.termGroups.find(termGroup => termGroup.name === item.name)!
            this.modifyTermGroup(group, "Regular")
        }
    }

    private termsOf(group: InvestigationTermGroupEntity): InvestigationTermEntity[] {
        return this.terms.filter(term => group.id === term.group)
    }

    @Watch("termGroups")
    private updateGroupExpansion() {
        const expandedStates = getGroupsExpansion(this.$store)
        this.termGroups.forEach(group => this.$set(this.expandedStates, group.id, expandedStates[group.id] !== undefined ? expandedStates[group.id] : true))
    }

    @Watch("expandedStates")
    private updateGroupsExpansion() {
        setGroupsExpansion(this.$store, this.expandedStates)
    }

    private isGroupDisabledForMove(termGroup: InvestigationTermGroupEntity) {
        return this.termsOf(termGroup).includes(this.termContext!)
    }

    private async moveToGroup(termGroup: InvestigationTermGroupEntity) {
        if (this.termContext) {
            const {term} = this.termContext
            const group = termGroup.id
            await moveToGroup(this.$store, {term, group})
            await fetchDocumentMarkups(this.$store)
        }

        this.termContext = null
    }

    private singleTermMenuClosed() {
        return null
    }

    private async createTermMenuClosed() {
        this.newTerm = ""
    }

    private async addTerm() {
        if (this.typedNewTerm && !this.termExists) {
            await this.$emit("openCreateTermMenu", this.$refs.addTermButton)
        }
    }

    private async modifyTerm(term: InvestigationTermEntity, modifier: TermModifier) {
        if (term.modifier === "Regular" && modifier === "Regular") {
            return
        }

        const newModifier = term.modifier === modifier ? "Regular" : modifier
        await wrapActiveRequests(this.$store, async () => {
            await modifyTerm(this.$store, {...term, modifier: newModifier})
            getCancelToken(this.$store).cancel()
            await this.$emit("refresh-documents")
        })
    }

    private async renameTerm() {
        const term = this.termContext!!
        openRenameTermDialog(this, {term, onRename: newName => this.performRenameTerm(term, newName)})
    }

    private async performRenameTerm(term: InvestigationTermEntity, newName: string) {
        await wrapActiveRequests(this.$store, async () => {
            await renameTerm(this.$store, { oldTerm: term, newName })
            getCancelToken(this.$store).cancel()
            await this.$emit("refresh-documents")
        })
    }

    private async modifyTermGroup(termGroup: InvestigationTermGroupEntity, modifier: TermGroupModifier) {
        if (termGroup.modifier === "Regular" && modifier === "Regular") {
            return
        }

        const newModifier = termGroup.modifier === modifier ? "Regular" : modifier
        await wrapActiveRequests(this.$store, async () => {
            await modifyTermGroup(this.$store, {...termGroup, modifier: newModifier})
            getCancelToken(this.$store).cancel()
            await this.$emit("refresh-documents")
        })
    }

    private async removeTerm(term: InvestigationTermEntity) {
        openConfirmationDialog({
            message: `Are you sure you want to delete "${term.term}"?`,
            title: "Delete Term",
            okText: "Delete",
            onOk: () => this.performDeleteTerm(term),
            showCancel: true
        })
    }

    private async performDeleteTerm(term: InvestigationTermEntity) {
        await removeTerm(this.$store, term.term)
        term.modifier !== "Regular" ? await this.$emit("refresh-documents") : await fetchDocumentMarkups(this.$store)
    }

    private async moveToNewGroup(term: InvestigationTermEntity, modifier: TermModifier) {
        const newGroupName = this.chooseNewGroupName()
        await createTermGroup(this.$store, newGroupName)

        const groupEntity = getTermGroups(this.$store).find(it => it.name === newGroupName)!!
        await moveToGroup(this.$store, {term: term.term, group: groupEntity.id})

        openRenameTermGroupDialog(this, {group: groupEntity!!})
    }

    private chooseNewGroupName() {
        const termGroupNames = getTermGroups(this.$store).map(it => it.name)
        let index = 0
        while (true) {
            const newGroupName = `<New Group${index === 0 ? "" : " " + index}>`
            if (!termGroupNames.find(it => it === newGroupName)) {
                return newGroupName
            }
            index++
        }
    }

    private handleTransition(el: HTMLElement, done: () => void) {
        this.isInTransition = true
        done()
        this.isInTransition = false
    }

    private async queryTermClicked(term: string, index: number) {
        const termElements = (this.$refs.queryTerms as HTMLElement[])
        const termElement = termElements.filter(element => element.innerText === term)[0]
        const termElementInner = termElement.querySelector("span")!

        await showTermPopupMenu(this.$store, {term, centered: false, termElement: termElementInner, investigationType: this.investigationType, showAddObservation: false})
    }

    private openSingleTermMenu(button: HTMLElement, term: InvestigationTermEntity) {
        this.termContext = term
        this.$nextTick(() => this.$emit("openSingleTermMenu", button))
    }

    private openTermGroupMenu(button: HTMLElement, termGroup: InvestigationTermGroupEntity) {
        this.groupContext = termGroup
        this.$nextTick(() => this.$emit("openTermGroupMenu", button))
    }

    private openMainMenu(button: HTMLElement) {
        this.$nextTick(() => this.$emit("openMainMenu", button))
    }

    private openBatchTermsDialog() {
        // @ts-ignore
        (this.$refs.batchTermsDialog as BatchTermsDialog).open({termGroup: this.groupContext})
    }

    private openCreateGroupDialog() {
        openCreateTermGroupDialog(this)
    }

    private openAddPredefinedTermGroupsDialog() {
        openAddPredefinedTermGroupsDialog(this)
    }

    private get isShowPredefinedTermGroups(): boolean {
        return isShowPredefinedTermGroups(this.$store)
    }

    private renameGroup() {
        openRenameTermGroupDialog(this, {group: this.groupContext!})
    }

    private copyToClipboard() {
        const allTerms = getTerms(this.$store).slice().map(term => term.term).join("\n")
        copyToClipboard(allTerms)
    }

    private copyGroupToClipboard() {
        const allTerms = getTerms(this.$store)
            .filter(term => term.group === this.groupContext!.id)
            .slice()
            .map(term => term.term).join("\n")
        copyToClipboard(allTerms)
        this.groupContext = null
    }


    private deleteGroup() {
        if (!this.isDefaultGroupInContext) {
            openConfirmationDialog({
                message: `Are you sure you want to delete "${this.groupContext!.name}" group? Its terms will be deleted.`,
                title: "Delete Term Group",
                okText: "Delete Group",
                onOk: () => this.performDeleteGroup(),
                showCancel: true
            })
        }
    }

    private async performDeleteGroup() {
        const hasTerms = this.termsOf(this.groupContext!!).length > 0
        await deleteTermGroup(this.$store, this.groupContext!!)
        if (hasTerms) {
            await this.$emit("refresh-documents")
        } else {
            await fetchDocumentMarkups(this.$store)
        }
    }

    private getGroupColor(group: string): string {
        return getGroupColor(group, false)
    }

    private copyTermToClipboard() {
        copyToClipboard(this.termContext!!.term)
    }

    private googleIt() {
        window.open(`https://www.google.com/search?q=${this.termContext!!.term}`, "_blank")
    }
}
