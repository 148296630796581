import {InvestigationSubType} from "@/types/investigations"

export interface InvestigationCreationType {
    readonly type: InvestigationSubType,
    readonly icon: string,
    readonly title: string,
    readonly tagline: string,
    readonly dataSources: InvestigationDataSource[],
    readonly color: string
    readonly tag: string
}

export const topicInvestigationType: InvestigationCreationType = {
    type: "Topic",
    icon: "topic",
    title: "General",
    tagline: "Uncover the ecosystem of your research field of interest",
    dataSources: ["web pages", "academic publications"],
    color: "#33B8FF",
    tag: "General"
}

export const personInvestigationType: InvestigationCreationType = {
    type: "Person",
    icon: "person",
    title: "Academic Researcher",
    tagline: "Build a full academic profile and the community around it",
    dataSources: ["academic publications"],
    color: "#AB7DF6",
    tag: "Researcher"
}

export const personGroupInvestigationType: InvestigationCreationType = {
    type: "PersonGroup",
    icon: "person-group",
    title: "Academic Research Group",
    tagline: "Build full academic profiles for multiple researchers",
    dataSources: ["academic publications"],
    color: "#4D55F8",
    tag: "Researchers"
}

export const universityInvestigationType: InvestigationCreationType = {
    type: "University",
    icon: "university",
    title: "University / Organization",
    tagline: "Map the academic ecosystem of a given university / organization",
    dataSources: ["academic publications"],
    color: "#94CA34",
    tag: "Univ. / Org."
}

export const politicalInvestigationType: InvestigationCreationType = {
    type: "Political",
    icon: "political",
    title: "Political",
    tagline: "Reveal what experts are writing about particular subjects in politics and policy",
    dataSources: ["web pages", "academic publications"],
    color: "#FACA00",
    tag: "Political"
}

export const websiteInvestigationType: InvestigationCreationType = {
    type: "WebSite",
    icon: "website",
    title: "Website",
    tagline: "Find all the pages of a given website and search within them",
    dataSources: ["web pages"],
    color: "#FD8030",
    tag: "Website"
}

export const tenderInvestigationType: InvestigationCreationType = {
    type: "Tender",
    icon: "tender",
    title: "Tender",
    tagline: "Find all tenders related to your area of business",
    dataSources: ["web pages"],
    color: "#2660AB",
    tag: "Tender"
}

export const companyInvestigationType: InvestigationCreationType = {
    type: "Company",
    icon: "company",
    title: "Company",
    tagline: "Find out all the information related to the company you are interested in",
    dataSources: ["web pages"],
    color: "#26C2CA",
    tag: "Company"
}

export const localDocumentsInvestigationType: InvestigationCreationType = {
    type: "LocalDocuments",
    icon: "local-documents",
    title: "Local Documents",
    tagline: "Discover hidden insights within your local documents",
    dataSources: [],
    color: "#CA2693",
    tag: "LocalDocuments"
}

export const researcherWorksInvestigationType: InvestigationCreationType = {
    type: "ResearcherWorks",
    icon: "person",
    title: "Launch for Researcher",
    tagline: "",
    dataSources: ["academic publications"],
    color: "#8057c3",
    tag: "Researcher"
}

export const facultyWorksInvestigationType: InvestigationCreationType = {
    type: "FacultyWorks",
    icon: "person-group",
    title: "Launch for Faculty",
    tagline: "",
    dataSources: ["academic publications"],
    color: "#5e64d3",
    tag: "Faculty"
}

export const investigationSubTypes: InvestigationCreationType[] = [
    topicInvestigationType,
    personInvestigationType,
    personGroupInvestigationType,
    universityInvestigationType,
    politicalInvestigationType,
    websiteInvestigationType,
    tenderInvestigationType,
    companyInvestigationType,
    localDocumentsInvestigationType,
    researcherWorksInvestigationType,
    facultyWorksInvestigationType
]

export function getInvestigationCreationType(subType: InvestigationSubType): InvestigationCreationType | null {
    const creationType = investigationSubTypes.find(it => it.type === subType)
    return creationType ? creationType : null
}

export type InvestigationDataSource = "web pages" | "academic publications"
