import Vue from "vue"
import Vuex, {Store} from "vuex"
import investigations, {InvestigationsState} from "@/store/investigations-module"
import auth, {AuthState} from "@/store/auth-module"
import investigation, {InvestigationState} from "@/store/investigation/investigation-module"
import environment, {EnvironmentState} from "@/store/environment-module"


export interface RootState {
    auth: AuthState
    environment: EnvironmentState
    investigations: InvestigationsState,
    investigation: InvestigationState,
}

Vue.use(Vuex)

export function buildStore(): Store<RootState> {
    return new Store<RootState>({
        modules: {
            auth,
            environment,
            investigations,
            investigation
        }
    })
}

const store = buildStore()
export default store
