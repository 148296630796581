



























import Vue from "vue"
import Component from "vue-class-component"
import {createResearcherWorksInvestigation} from "@/store/investigations-module"
import {NewInvestigationDetails} from "@/views/investigations/NewInvestigationPage.vue"
import {Watch} from "vue-property-decorator"
import { InvestigationCreationType, InvestigationDataSource, researcherWorksInvestigationType } from "@/types/new-investigation"


@Component
export default class NewPersonInvestigationDetails extends Vue implements NewInvestigationDetails {

    private instituteName: string = ""
    private facultyName: string = ""
    private researcherName: string = ""

    private get investigationType(): InvestigationCreationType {
        return researcherWorksInvestigationType
    }

    public async createInvestigation() {
        const payload = { instituteName: this.instituteName, facultyName: this.facultyName,
            researcherName: this.researcherName!, sources: ["academic publications"] as InvestigationDataSource[]}
        const highlight = await createResearcherWorksInvestigation(this.$store, payload)

        this.$router.push({name: "investigations", query: {highlight}})
    }

    private mounted() {
        this.$nextTick(() => {
            const instituteName = this.$refs.instituteName as HTMLInputElement
            instituteName.focus()
        })
        this.emitEnableNext()
    }

    @Watch("instituteName")
    @Watch("facultyName")
    @Watch("researcherName")
    private emitEnableNext() {
        const enable =
            this.instituteName.trim().length > 0 &&
            this.facultyName.trim().length > 0 &&
            this.researcherName.trim().length > 0
        this.$emit("enable-next", enable)
    }
}
