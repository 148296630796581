import axios from "axios"
import {InvestigationRequestSpec, SearchType} from "@/types/investigations"

const settings = { headers: { "Content-Type": "application/json" } }

export default {

    async export(investigationId: string, spec: InvestigationRequestSpec): Promise<Blob> {
        const response = await axios.post(`/api/investigations/topic/${investigationId}/export`, spec, { responseType: "arraybuffer" })
        return new Blob([response.data])
    },

    async merge(investigationId: string): Promise<void> {
        await axios.post(`/api/investigations/topic/${investigationId}/merge`)
    },

    async createPersonHarvests(investigationId: string, personNames: string[]): Promise<void> {
        await axios.post(`/api/investigations/topic/${investigationId}/harvest/person`,
            { searchStrings: personNames, searchType: "All" })
    }
}
