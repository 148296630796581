import axios from "axios"
import { MentionedEntityType } from "@/types/investigations"


export default {

    async removeEntities(investigationId: string, entities: { [name: string]: MentionedEntityType }) {
        await axios.post(`/api/investigations/${investigationId}/entities/remove`, entities)
    },

    async restoreAll(investigationId: string) {
        await axios.post(`/api/investigations/${investigationId}/entities/restore-all`)
    },

    async mergeEntities(investigationId: string, entityType: MentionedEntityType, names: string[]) {
        await axios.post(`/api/investigations/${investigationId}/entities/${pluralize(entityType)}/merge`, names)
    }
}


function pluralize(entityType: MentionedEntityType): string {
    return `${entityType.toLowerCase()}s`
}
