

































import Vue from "vue"
import Component from "vue-class-component"
import SplitPane from "@/components/SplittedPane.vue"
import TopsPane from "@/views/investigation/panes/TopsPane.vue"
import {clearInvestigationStore, fetchGraphData, getEntityForSummary, getEntityRelationshipForSummary, getIsBusy, getPersonForRightPaneSummary, setDisplayedResultsStatus} from "@/store/investigation/investigation-module"
import {setDateFilterYears} from "@/store/investigation/date-filter-module"
import TermsPane from "@/views/investigation/panes/TermsPane.vue"
import {webSocketManager} from "@/infrastructure/WebsocketManager"
import AuthorSummaryPane from "@/views/investigation/panes/AuthorSummaryPane.vue"
import DocumentsPane from "@/views/investigation/panes/DocumentsPane.vue"
import ReportNavigationPane from "@/views/investigation/panes/ReportNavigationPane.vue"
import EntitySummaryPane from "@/views/investigation/panes/EntitySummaryPane.vue"
import EntityRelationshipSummaryPane from "@/views/investigation/panes/EntityRelationshipSummaryPane.vue"
import SpinningLoader from "@/components/SpinningLoader.vue"
import {getGraphType} from "@/store/investigation/graph-module"

@Component({
    components: {EntityRelationshipSummaryPane, EntitySummaryPane, AuthorSummaryPane, SplitPane, TopsPane, TermsPane, ReportNavigationPane, SpinningLoader}
})
export default class TopicInvestigation extends Vue {

    private scrolledToObservation: string | null = ""
    private scrolledToSection: string | null = ""

    private get isShowingTopsPane() {
        return !this.isAuthorSummaryInRightPane && !this.isEntitySummaryVisible && !this.isEntityRelationshipSummaryVisible
    }

    private get personForRightPaneSummary() {
        return getPersonForRightPaneSummary(this.$store)
    }

    private get isAuthorSummaryInRightPane(): boolean {
        return this.personForRightPaneSummary != null
    }

    private get entityForSummary() {
        return getEntityForSummary(this.$store)
    }

    private get entityRelationshipForSummary() {
        return getEntityRelationshipForSummary(this.$store)
    }

    private get isEntitySummaryVisible(): boolean {
        return this.entityForSummary != null
    }

    private get isEntityRelationshipSummaryVisible(): boolean {
        return this.entityRelationshipForSummary != null
    }

    private get isInDocuments() {
        return this.$route.path.includes("documents")
    }

    private get isInReportEditor() {
        return this.$route.path.includes("report-editor")
    }

    private get isBusy(): boolean {
        return getIsBusy(this.$store)
    }

    private beforeCreate() {
        setDisplayedResultsStatus(this.$store, "All")
    }

    private async mounted() {
        setDateFilterYears(this.$store, {start: null, end: null})
    }

    private refreshContent() {
        if (getGraphType(this.$store) === null) {
            const documentsPane = this.$refs.content as DocumentsPane
            // @ts-ignore
            if (documentsPane.refreshResults) {
                documentsPane.refreshResults()
            }
        } else {
            fetchGraphData(this.$store)
        }
    }

    private beforeRouteLeave(to: string, from: string, next: () => void) {
        webSocketManager.unregisterInvestigation()
        clearInvestigationStore(this.$store)
        next()
    }

    private documentsUpdated() {
        const documentContainer = this.$refs.documentsContainer as HTMLDivElement
        documentContainer.scrollTop = 0
    }

    private scrollToSection(sectionId: string) {
        const content = this.$refs.content as Vue
        this.scrolledToSection = sectionId
    }

    private scrollToObservation(observationId: string) {
        const content = this.$refs.content as Vue
        this.scrolledToObservation = observationId
    }

    private resetScrollVars() {
        this.scrolledToSection = null
        this.scrolledToObservation = null
    }
}
