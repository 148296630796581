

































































































































































































































































































































import Vue from "vue"
import Component from "vue-class-component"
import adminService from "../../api/admin-service"
import {ClusterComparison, ClusterReport, ClusterReportDocument, TargetCluster} from "@/types/clusters"
import {openMessageDialog} from "@/components/MessageDialog.vue"

type DocumentColumns = "DocumentId" | "DocumentTitle" | "InCV" | "SNE" | "MAG"
type ComparisonColumns = "SNE" | "DocCount" | "MAG" | "SimilarityScore" | "SimilarityScoreDetails"
type SortDirection = "Asc" | "Desc"

type MergeGroupSelectionType = "None" | "Correct" | "Incorrect"

@Component({
    filters: {
        formatNumber: (num: number) => {
            if (num === 0) {
                return "0"
            }
            if (num != null) {
                return Intl.NumberFormat([], {style: "decimal", maximumFractionDigits: 1, useGrouping: false}).format(num)
            }
            return ""
        },
        percentage: (value: number) => {
            value = value * 100
            value = Math.round(value * 10) / 10
            return `${value}%`
        }
    }
})
export default class ClusterReportPage extends Vue {
    private investigationId: string = ""
    private clusterReport?: ClusterReport | null = null
    private primaryColoredIds: string[] = []
    private secondaryColoredIds: string[] = []
    private sortedDocuments: ClusterReportDocument[] = []
    private sortedClusterComparisons: ClusterComparison[] = []
    private mergeDecisions: { [key: string]: { totalScore: number, scores: { [key: string]: number } } } = {}
    private executing = false
    private doi?: string = ""
    private cvFile: File | null = null
    private showClusterId = true
    private compareClusterId: string | null = null
    private showMergeReport: boolean = false
    private mergeGroupSelectionType: MergeGroupSelectionType = "None"
    private mergeGroupSelection: {[key: string]: MergeGroupSelectionType} = {}
    private debugMergeResult: string = ""
    private debugMergeResultDialogOpened: boolean = false
    private s3Path: string = ""

    private documentsSortBy: DocumentColumns = "DocumentId"
    private documentsSortDir: SortDirection = "Asc"
    private comparisonsSortBy: ComparisonColumns = "SNE"
    private comparisonsSortDir: SortDirection = "Asc"
    private comparisonsMagSortDir: SortDirection = "Desc"

    private async mounted() {
        this.investigationId = this.$route.params.investigationId

        await this.getClusterReport()

        if (this.doi !== "") {
            this.comparisonsSortBy = "SimilarityScore"
            this.comparisonsSortDir = "Desc"

            const matchingClusterId = this.clusterReport!!.matchingClusterId || null
            this.selectCluster(matchingClusterId, true)
        }
    }


    private async getClusterReport() {
        this.executing = true
        const doiList = (this.doi) ? this.doi.split("\n").filter(it => it.length !== 0) : []

        this.clusterReport = null
        this.clusterReport = await adminService.clusterReport(this.investigationId, this.cvFile, doiList)
        this.sortDocuments()
        this.sortComparisons()
        this.doi = this.clusterReport.doiList.join("\n")

        this.sortedDocuments.forEach(it => {
            if (it.documentId && !this.mergeGroupSelection[it.documentId]) {
                this.$set(this.mergeGroupSelection, it.documentId, "None")
            }
        })

        this.executing = false
    }


    private async cvFileSelected(e: Event) {
        const input = e.target as HTMLInputElement
        if (input.files) {
            this.cvFile = input.files[0]
            input.value = "" // so that next upload of same file will be caught be change handler

            await this.getClusterReport()

            this.sortedDocuments.forEach(it => {
                if (this.clusterReport && it.documentId && it.appearsInCv === "Yes" && it.cluster1PersonId === this.clusterReport.matchingClusterId) {
                    this.$set(this.mergeGroupSelection, it.documentId, "Correct")
                }
            })
        }
    }


    private selectCluster(clusterId: string | null, primary: boolean) {
        const primarySet = new Set<string>()
        const secondarySet = new Set<string>()

        if (clusterId) {
            if (primary) {
                primarySet.add(clusterId)
            } else {
                secondarySet.add(clusterId)
            }

            this.clusterReport!!.documents.forEach(row => {
                if (primary) {
                    if (row.cluster2PersonId && row.cluster1PersonId === clusterId) {
                        secondarySet.add(row.cluster2PersonId)
                        if (row.documentId && (this.mergeGroupSelection[row.documentId] === this.mergeGroupSelectionType || this.mergeGroupSelection[row.documentId] === "None")) {
                            this.selectDocumentForMergeGroup(row.documentId, this.mergeGroupSelectionType)
                        }
                    }
                } else {
                    if (row.cluster1PersonId && row.cluster2PersonId === clusterId) {
                        primarySet.add(row.cluster1PersonId)
                        if (row.documentId && (this.mergeGroupSelection[row.documentId] === this.mergeGroupSelectionType || this.mergeGroupSelection[row.documentId] === "None")) {
                            this.selectDocumentForMergeGroup(row.documentId, this.mergeGroupSelectionType)
                        }
                    }
                }
            })
        }

        this.primaryColoredIds = [...primarySet]
        this.secondaryColoredIds = [...secondarySet]

        this.updateMergeDecisions(this.compareClusterId)
        this.sortComparisons()
    }


    private async updateMergeDecisions(compareClusterId: string | null) {
        this.compareClusterId = compareClusterId
        this.mergeDecisions = {}

        if (this.showMergeReport) {
            if (this.compareClusterId) {
                const personIds = this.clusterReport!!.comparisons.map(it => it.sourceClusterId)
                const allMergeDecisions = await adminService.createMergeFinalReport(this.compareClusterId, personIds)

                const sneClusterId = parseInt(this.compareClusterId, 10)
                allMergeDecisions.forEach(decision => {
                    const otherClusterId = decision.id1 === sneClusterId ? decision.id2 : decision.id1

                    const scores: { [key: string]: number } = {}
                    Object.keys(decision.scores).forEach(key => {
                        scores[key] = decision.scores[key]
                    })

                    this.mergeDecisions[otherClusterId] = {totalScore: decision.totalScore, scores}
                })
            }
        }

        this.sortComparisons()
    }


    private isPrimaryColored(personID: string): boolean {
        return this.primaryColoredIds.some(id => id === personID)
    }

    private isSecondaryColored(personID: string): boolean {
        return this.secondaryColoredIds.some(id => id === personID)
    }


    private documentsHeaderClicked(column: DocumentColumns) {
        if (this.documentsSortBy === column) {
            this.documentsSortDir = this.documentsSortDir === "Asc" ? "Desc" : "Asc"
        } else {
            this.documentsSortBy = column
            this.documentsSortDir = "Asc"
        }

        this.sortDocuments()
    }


    private sortDocuments() {
        this.sortedDocuments = []
        if (this.clusterReport) {
            let sort1Function: (c1: ClusterReportDocument, c2: ClusterReportDocument) => number
            switch (this.documentsSortBy) {
                case "DocumentId":
                    sort1Function = (c1: ClusterReportDocument, c2: ClusterReportDocument) => this.nullToEmpty(c1.documentId).localeCompare(this.nullToEmpty(c2.documentId))
                    break

                case "DocumentTitle":
                    sort1Function = (c1: ClusterReportDocument, c2: ClusterReportDocument) => c1.documentTitle.localeCompare(c2.documentTitle)
                    break

                case "InCV":
                    sort1Function = (c1: ClusterReportDocument, c2: ClusterReportDocument) => this.nullToEmpty(c1.appearsInCv + c1.patent).localeCompare(this.nullToEmpty(c2.appearsInCv + c2.patent))
                    break

                case "SNE":
                    sort1Function = (c1: ClusterReportDocument, c2: ClusterReportDocument) => this.nullToEmpty(c1.cluster1PersonId).localeCompare(this.nullToEmpty(c2.cluster1PersonId))
                    break

                default:
                    sort1Function = (c1: ClusterReportDocument, c2: ClusterReportDocument) => this.nullToEmpty(c1.cluster2PersonId).localeCompare(this.nullToEmpty(c2.cluster2PersonId))
                    break
            }

            let sort2Function = sort1Function
            if (this.documentsSortDir === "Desc") {
                sort2Function = (c1: ClusterReportDocument, c2: ClusterReportDocument) => -sort1Function(c1, c2)
            }

            this.sortedDocuments = this.clusterReport.documents.sort(sort2Function)
        }
    }


    private comparisonHeaderClicked(column: ComparisonColumns) {
        if (this.comparisonsSortBy === column) {
            this.comparisonsSortDir = this.comparisonsSortDir === "Asc" ? "Desc" : "Asc"
        } else {
            this.comparisonsSortBy = column
            this.comparisonsSortDir = "Asc"
        }

        this.sortComparisons()
    }


    private comparisonsMagHeaderClicked() {
        this.comparisonsMagSortDir = this.comparisonsMagSortDir === "Asc" ? "Desc" : "Asc"
        this.sortComparisons()
    }


    private sortComparisons() {
        this.sortedClusterComparisons = []
        if (this.clusterReport) {
            this.sortedClusterComparisons = this.clusterReport.comparisons.sort((c1: ClusterComparison, c2: ClusterComparison) => {
                let sortValue = 0
                switch (this.comparisonsSortBy) {
                    case "SNE":
                        sortValue = c1.sourceClusterId.localeCompare(c2.sourceClusterId)
                        break

                    case "DocCount":
                        sortValue = c1.sourceClusterCount - c2.sourceClusterCount
                        break

                    case "SimilarityScore":
                        const valueC1 = this.mergeDecisions[c1.sourceClusterId] ? this.mergeDecisions[c1.sourceClusterId].totalScore : 100000
                        const valueC2 = this.mergeDecisions[c2.sourceClusterId] ? this.mergeDecisions[c2.sourceClusterId].totalScore : 100000
                        sortValue = valueC1 - valueC2
                        break
                }

                if (this.comparisonsSortDir === "Desc") {
                    sortValue = -sortValue
                }
                return sortValue
            })

            const targetSortFunc = (t1: TargetCluster, t2: TargetCluster) => {
                let sortValue = t1.value.count - t2.value.count
                if (this.comparisonsMagSortDir === "Desc") {
                    sortValue = -sortValue
                }
                return sortValue
            }

            this.sortedClusterComparisons = this.sortedClusterComparisons.map(comparison => {
                return {sourceClusterId: comparison.sourceClusterId, sourceClusterCount: comparison.sourceClusterCount, targetClusters: comparison.targetClusters.sort(targetSortFunc)}
            })
        }
    }

    private nullToEmpty(str?: string) {
        if (str != null) {
            return str
        }
        return ""
    }

    private getAuthorLink(authorId: string): string {
        if (authorId.startsWith("MAG/")) {
            return `https://academic.microsoft.com/author/${authorId.substring(4)}`
        }

        if (authorId.startsWith("IEEE/")) {
            return `https://ieeexplore.ieee.org/author/${authorId.substring(5)}`
        }

        return authorId
    }


    private toggleShowClusterId() {
        this.showClusterId = !this.showClusterId
    }


    private toggleMergeReport() {
        this.showMergeReport = !this.showMergeReport
        if (this.showMergeReport) {
            const matchingClusterId = this.clusterReport!!.matchingClusterId || null
            this.updateMergeDecisions(matchingClusterId)
        }
    }


    private setMergeGroupSelectionType(type: MergeGroupSelectionType) {
        this.mergeGroupSelectionType = this.mergeGroupSelectionType === type ? "None" : type
    }


    private selectDocumentForMergeGroup(documentId: string, groupType: MergeGroupSelectionType) {
        this.$set(this.mergeGroupSelection, documentId, this.mergeGroupSelection[documentId] === groupType ? "None" : groupType)
    }


    private async findPointsOfContact() {
        if (!this.compareClusterId) {
            openMessageDialog({title: "Merge Debug Tool", message: "Main cluster not specified"})
            return
        }

        const ids1: string[] = []
        const ids2: string[] = []
        Object.keys(this.mergeGroupSelection).forEach(key => {
            if (this.mergeGroupSelection[key] === "Correct") {
                ids1.push(key)
            } else if (this.mergeGroupSelection[key] === "Incorrect") {
                ids2.push(key)
            }
        })

        this.debugMergeResult = "Calculating..."
        this.debugMergeResult = await adminService.debugMerge(this.s3Path, this.compareClusterId!!, ids1, ids2)
        this.debugMergeResultDialogOpened = true
    }
}
