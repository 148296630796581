














import Vue from "vue"
import Component from "vue-class-component"
import {fetchInvestigation, getInvestigation, getIsInReviewProfile, setActivityStatus, updateRelevancyStatus} from "@/store/investigation/investigation-module"
import InvestigationHeaderBar from "@/views/investigation/InvestigationHeaderBar.vue"
import TermPopupMenu from "@/views/investigation/popups/TermPopupMenu.vue"
import NoteDialog from "@/views/investigation/dialogs/NoteDialog.vue"
import ParticleLoadingScreen from "@/components/ParticleLoadingScreen.vue"
import moment, {Moment} from "moment"
import {getCurrentUser} from "@/store/auth-module"
import {getRollbarAccessToken} from "@/store/environment-module"
import {webSocketManager} from "@/infrastructure/WebsocketManager"
import {getDocuments} from "@/store/investigation/documents-module"
import {getTermGroups} from "@/store/investigation/investigation-terms-module"

@Component({
    components: {NoteDialog, TermPopupMenu, InvestigationHeaderBar, ParticleLoadingScreen}
})
export default class InvestigationPage extends Vue {

    private interval: any
    private investigationCreationMoment: Moment = moment()
    private shouldShowParticleLoader: boolean = false

    get isLoadingInvestigation(): boolean {
        return getInvestigation(this.$store) === null
    }

    private async mounted() {
        const investigationType = this.$route.path.includes("topic/") ? "Topic" : "Person"
        const id = this.$route.params.id
        if (investigationType === "Topic") {
            await fetchInvestigation(this.$store, {id, investigationType: "Topic"})
            const investigation = getInvestigation(this.$store)!
            this.investigationCreationMoment = moment(new Date()).subtract(investigation.age, "seconds")
            this.setShowParticleLoader()
            if (this.shouldShowParticleLoader) {
                this.interval = setInterval(this.setShowParticleLoader, 1000)
            }
        } else {
            await fetchInvestigation(this.$store, {id, investigationType: "Person"})
            this.shouldShowParticleLoader = false
        }
        const investigationId: string = getInvestigation(this.$store)!.id
        const user = getCurrentUser(this.$store)!.nickname
        const rollbarAccessToken = getRollbarAccessToken(this.$store)
        webSocketManager.registerInvestigation(
            investigationId,
            message => {
                const update = JSON.parse(message!)
                const {differentEnough, relevancyScore} = update
                setActivityStatus(this.$store, update.activityScore)
                updateRelevancyStatus(this.$store, {differentEnough, relevancyScore})
            },
            user,
            rollbarAccessToken
        )
    }

    private setShowParticleLoader() {
        this.shouldShowParticleLoader = this.calculateShouldShowParticleLoader()
        if (!this.shouldShowParticleLoader) {
            clearInterval(this.interval)
        }
    }

    private calculateShouldShowParticleLoader(): boolean {
        const investigation = getInvestigation(this.$store)!
        const passedNinetySeconds = moment(new Date()).diff(this.investigationCreationMoment, "seconds") >= 90
        const termGroups = getTermGroups(this.$store)
        const documents = getDocuments(this.$store)
        const fetchedData = termGroups.length
        const hasDocuments = documents.length
        return !passedNinetySeconds && (!fetchedData || !hasDocuments)
    }

    private beforeDestroy() {
        clearInterval(this.interval)
    }

    private get isInReviewProfile(): boolean {
        return getIsInReviewProfile(this.$store)
    }
}

