import {RootState} from ".."
import {getInvestigationId} from "./get-investigation-id"
import {ActionContext} from "vuex"
import {getStoreAccessors} from "vuex-typescript"
import observationService from "../../api/observation-service"
import {InvestigationType, ObservationInfo, ObservationType} from "@/types/investigations"
import {Moment} from "moment"
import {ImageCropData} from "@/api/investigation-service"

export interface ObservationsResponse {
    readonly observations: ObservationEntity[]
    readonly sections: ObservationSectionEntity[]
}


export interface ObservationEntity {
    readonly title: string
    readonly observation: string
    readonly time?: Moment
    id?: string
    readonly info?: ObservationInfo
    readonly referencedObject?: string
    readonly type?: ObservationType,
    section: string | null,
    ordinal: number,
    imageId: string | null,
    imageTimestamp?: number | null,
}

export interface ObservationSectionEntity {
    readonly id: string
    readonly name: string
    readonly ordinal: number
}

export interface ObservationState {
    observations: ObservationEntity[],
    observationSections: ObservationSectionEntity[],
    observationViewModeLevel: number,
    sectionsExpansion: {[key: string]: boolean},
    draggedObservation: string | null,
    sectionObservationOrder: any[]
}

const module = {
    getters: {
        getObservations(state: ObservationState): ObservationEntity[] {
            return state.observations
        },

        getObservationViewModeLevel(state: ObservationState): number {
            return state.observationViewModeLevel
        },

        getObservationSections(state: ObservationState): ObservationSectionEntity[] {
            return state.observationSections
        },

        getSectionsExpansion(state: ObservationState): {[key: string]: boolean} {
            return state.sectionsExpansion
        },

        getSectionObservationOrder(state: ObservationState): any[] {
            return state.sectionObservationOrder
        }
    },

    mutations: {
        setObservations(state: ObservationState, observations: ObservationEntity[]) {
            state.observations = observations
        },

        setObservationViewModeLevel(state: ObservationState, level: number) {
            state.observationViewModeLevel = level
        },

        setObservationSections(state: ObservationState, observationSections: ObservationSectionEntity[]) {
            state.observationSections = observationSections
        },

        updateObservation(state: ObservationState, observation: ObservationEntity) {
            const observationIndex = state.observations.findIndex(currentObservation => currentObservation.id === observation.id)

            if (observationIndex >= 0) {
                state.observations.splice(observationIndex, 1, observation)
            }
        },

        updateObservationSection(state: ObservationState, section: ObservationSectionEntity) {
            const sectionIndex = state.observationSections.findIndex(currentGroup => Number(currentGroup.id) === Number(section.id))
            if (sectionIndex >= 0) {
                state.observationSections.splice(sectionIndex, 1, section)
            }
        },

        setSectionsExpansion(state: ObservationState, expansion: {[key: string]: boolean}) {
            state.sectionsExpansion = expansion
        },

        clearSectionsExpansion(state: ObservationState) {
            state.sectionsExpansion = {}
        },

        setSectionObservationOrder(state: ObservationState, sectionObservationOrder: any[]) {
            state.sectionObservationOrder = sectionObservationOrder
        }
    },

    actions: {
        async addObservation(context: ActionContext<ObservationState, RootState>, payload: { observation: ObservationEntity, investigationType: InvestigationType, observationType: ObservationType, imageCropData?: ImageCropData }): Promise<void> {
            const {observation, investigationType, observationType, imageCropData} = payload
            const oldObservations = [...getObservations(context)]
            let newObservations = oldObservations.concat(observation)
            setObservations(context, newObservations)
            const investigationId = getInvestigationId(context, "add investigation observation")
            try {
                const result = await observationService.addInvestigationObservation(investigationId, observation, investigationType, observationType)
                if (imageCropData) {
                    result.imageId = await observationService.setInvestigationImage(investigationId, investigationType, result.id!, imageCropData)
                }
                newObservations = oldObservations.concat(result)
                setObservations(context, newObservations)
            } catch {
                setObservations(context, oldObservations)
            }
        },

        async editObservation(context: ActionContext<ObservationState, RootState>, payload: { observation: ObservationEntity, investigationType: InvestigationType, imageCropData?: ImageCropData }): Promise<void> {
            const {observation, investigationType, imageCropData} = payload
            const oldObservations = [...getObservations(context)]
            const observationIndex = oldObservations.findIndex(oldObservation => oldObservation.id === observation.id)
            const newObservations = [...oldObservations]
            newObservations.splice(observationIndex, 1, observation)
            setObservations(context, newObservations)
            try {
                const investigationId = getInvestigationId(context, "edit investigation observation")
                const result = await observationService.editInvestigationObservation(investigationId, observation, investigationType)
                let newImageId
                if (imageCropData) {
                    newImageId = await observationService.setInvestigationImage(investigationId, investigationType, observation.id!, imageCropData)
                } else {
                    await observationService.clearObservationImage(investigationId, investigationType, observation.id!)
                    newImageId = null
                }
                const updatedObservations = [...newObservations]
                observation.imageId = newImageId
                observation.imageTimestamp = new Date().getTime()
                updatedObservations.splice(observationIndex, 1, observation)
                setObservations(context, updatedObservations)
            } catch {
                setObservations(context, oldObservations)
            }
        },

        async deleteObservation(context: ActionContext<ObservationState, RootState>, payload: { observationId: string, investigationType: InvestigationType }): Promise<void> {
            const {observationId, investigationType} = payload
            const oldObservations = [...getObservations(context)]
            const observationIndex = oldObservations.findIndex(oldObservation => oldObservation.id === observationId)
            const newObservations = [...oldObservations]
            newObservations.splice(observationIndex, 1)
            setObservations(context, newObservations)
            const result = await observationService.deleteInvestigationObservation(getInvestigationId(context, "edit investigation observation"), observationId, investigationType)
            if (!result) {
                setObservations(context, oldObservations)
            }
        },

        async createObservationSection(context: ActionContext<ObservationState, RootState>, name: string): Promise<void> {
            const oldSections = [...getObservationSections(context)]
            const ordinal = oldSections.length
            const createdSection: ObservationSectionEntity = {id: "-1", name, ordinal}
            setObservationSections(context, [...oldSections, createdSection])
            const result = await observationService.createSection(getInvestigationId(context, "create group"), name, ordinal)
            setObservationSections(context, result ? result : oldSections)
        },

        async renameObservationSection(context: ActionContext<ObservationState, RootState>, section: ObservationSectionEntity): Promise<void> {
            const oldSections = [...getObservationSections(context)]
            const {id, name, ordinal} = section
            const partial = {id, name, ordinal}
            updateObservationSection(context, partial)
            const result = await observationService.renameSection(getInvestigationId(context, "rename section"), partial)
            if (!result) {
                setObservationSections(context, oldSections)
            }
        },

        async deleteObservationSection(context: ActionContext<ObservationState, RootState>, section: ObservationSectionEntity): Promise<void> {
            const result = await observationService.deleteSection(getInvestigationId(context, "delete section"), section.id)
            if (result) {
                const observations = [...getObservations(context)]
                observations
                    .filter(observation => Number(observation.section) === Number(section.id))
                    .forEach(observation => observation.section = null)
                await setObservations(context, observations)
                await setObservationSections(context, result)
            }
        },

        async setObservationSectionIndex(context: ActionContext<ObservationState, RootState>,
                                         payload: {sectionId: string, newIndex: number})  {
            const sections = getObservationSections(context)
            const sectionToMove = sections.splice(sections.findIndex(x => x.id === payload.sectionId), 1)[0]
            sections.splice(payload.newIndex, 0, sectionToMove)
            const result = await observationService.reorderSections(getInvestigationId(context, "reorder sections"), sections.map(x => x.id))
            if (result) {
                setObservationSections(context, sections)
            }
        },

        async moveToSection(context: ActionContext<ObservationState, RootState>, payload: {observation: string, section: string}): Promise<void> {
            const {observation, section} = payload
            const investigationId = getInvestigationId(context, "move observation to group")
            const oldObservation = [...getObservations(context)].find(currentObservation => currentObservation.id === observation)!
            const newObservation: ObservationEntity = {...oldObservation, section}
            updateObservation(context, newObservation)
            const result = await observationService.moveObservationToGroup(investigationId, observation, section)
            if (!result) {
                updateObservation(context, oldObservation)
            }
        },

        async reorderObservations(context: ActionContext<ObservationState, RootState>, observations: ObservationEntity[]): Promise<void> {
            const investigationId = getInvestigationId(context, "move observation to group")
            const oldObservations = [...getObservations(context)]
            setObservations(context, observations)

            const result = await observationService.reorderObservations(investigationId, observations)
            if (!result) {
                setObservations(context, oldObservations)
            }
        }
    }
}


export default module


const { read, commit, dispatch } = getStoreAccessors<ObservationState, RootState>("investigation")

export const getObservations = read(module.getters.getObservations)
export const addObservation = dispatch(module.actions.addObservation)
export const editObservation = dispatch(module.actions.editObservation)
export const deleteObservation = dispatch(module.actions.deleteObservation)
export const setObservations = commit(module.mutations.setObservations)
export const getObservationViewModeLevel = read(module.getters.getObservationViewModeLevel)
export const setObservationViewModeLevel = commit(module.mutations.setObservationViewModeLevel)
export const moveToSection = dispatch(module.actions.moveToSection)
export const reorderObservations = dispatch(module.actions.reorderObservations)

export const getObservationSections = read(module.getters.getObservationSections)
export const setObservationSections = commit(module.mutations.setObservationSections)
export const createObservationSection = dispatch(module.actions.createObservationSection)
export const renameObservationSection = dispatch(module.actions.renameObservationSection)
export const deleteObservationSection = dispatch(module.actions.deleteObservationSection)
export const setObservationSectionIndex = dispatch(module.actions.setObservationSectionIndex)
export const getSectionsExpansion = read(module.getters.getSectionsExpansion)
export const setSectionsExpansion = commit(module.mutations.setSectionsExpansion)
export const clearSectionsExpansion = commit(module.mutations.clearSectionsExpansion)
export const getSectionObservationOrder = read(module.getters.getSectionObservationOrder)
export const setSectionObservationOrder = commit(module.mutations.setSectionObservationOrder)


const updateObservation = commit(module.mutations.updateObservation)
const updateObservationSection = commit(module.mutations.updateObservationSection)
