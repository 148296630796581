import Vue from "vue"
import {KlComponent} from "@/externals/vue-keylines"

export const CustomKlChart = Vue.component('custom-kl-chart', {
    extends: KlComponent,
    data: () => ({
        type: 'chart'
    }),
    methods: {
        onLoad(options) {
        }
    }
});
