





















import Vue from "vue"
import Component from "vue-class-component"
import {InvestigationCreationType, InvestigationDataSource, websiteInvestigationType} from "@/types/new-investigation"
import {Watch} from "vue-property-decorator"
import {NewInvestigationDetails} from "@/views/investigations/NewInvestigationPage.vue"
import {createWebsiteInvestigation} from "@/store/investigations-module"

@Component
export default class NewWebsiteInvestigationDetails extends Vue implements NewInvestigationDetails {

    private investigationName: string = ""
    private websiteUrl: string = ""


    private get investigationType(): InvestigationCreationType {
        return websiteInvestigationType
    }


    public async createInvestigation() {
        const payload = {investigationName: this.investigationName!, websiteUrl: this.websiteUrl!, sources: ["web pages"] as InvestigationDataSource[]}
        const id = await createWebsiteInvestigation(this.$store, payload)

        this.$router.push({name: "topicInvestigation", params: {id}})
    }


    private mounted() {
        this.$nextTick(() => {
            const investigationName = this.$refs.investigationName as HTMLInputElement
            investigationName.focus()
        })

        this.emitEnableNext()
    }


    @Watch("investigationName")
    @Watch("websiteUrl")
    private emitEnableNext() {
        let enable = this.investigationName.trim().length > 0 && this.websiteUrl.trim().length >= 1
        if (enable) {
            try {
                const _ = new URL(this.websiteUrl)
            } catch (e) {
                enable = false
            }
        }

        this.$emit("enable-next", enable)
    }
}
