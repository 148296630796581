





























































































































import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import "keylines"
import "./CustomKeylinesChart"
import {Chart, ChartData, ChartOptions, Link, Node, NodeProperties} from "@/keylines"
import {decreaseActiveRequests, fetchGraphData, getActivityStatus, getAuthorGraphSettings, getInvestigation, getRelevancyStatus, getSelectedNodes, getSelectedTops, getShortestPath, increaseActiveRequests, saveInvestigationState, setAuthorGraphSettings, setDisambiguatePersonName, setIsRenderingGraph, setPersonForRightPaneSummary, setSelectedNodes, setSelectedTops, setShortestPath, setShowAuthorGraphLegend} from "@/store/investigation/investigation-module"
import SpinningLoader from "@/components/SpinningLoader.vue"
import PopupMenu from "@/components/PopupMenu.vue"
import {copyToClipboard, downloadBlob, wrapActiveRequests} from "@/utils/utils"
import {getGraph, removeGraphNodes, setGraphType} from "@/store/investigation/graph-module"
import TopicInvestigationService from "../../../api/topic-investigation-service"
import investigationService, {ImageCropData} from "../../../api/investigation-service"
import investigationsService from "../../../api/investigations-service"
import personInvestigationService from "../../../api/person-investigation-service"
import adminService from "../../../api/admin-service"
import {Prop, Watch} from "vue-property-decorator"
import {ColorCode, GraphSetBy, InvestigationPersonDetails, PersonEntity, RGB} from "@/types/investigations"
import {setDisambiguationPersonId} from "@/store/investigation/person-filter-module"
import moment from "moment"
import {graphLayouts, HighlightFilter} from "@/views/investigation/graphs/graph-tools"
import Speedometer from "@/views/investigation/graphs/Speedometer.vue"
import {openCreateObservationDialog} from "@/views/investigation/dialogs/CreateObservationDialog.vue"
import {isReportEnabled} from "@/store/environment-module"
import {configurationService} from "@/api/investigation-configuration-service"
import GraphSettingsDialog from "@/views/investigation/dialogs/GraphSettingsDialog.vue"
import NoResults from "@/components/NoResults.vue"
import {openAuthorDetailsDialog} from "@/views/investigation/dialogs/AuthorDetailsDialog.vue"
import {openConfirmationDialog} from "@/views/investigation/dialogs/ConfirmationDialog.vue"
import {PermissionsMixin} from "@/store/user-mixin"
import InvestigationService from "@/api/investigation-service"
import {undoMergeMessage} from "@/utils/common-messages"
import {InvestigationMixin} from "@/store/investigation-mixin"

@Component({
    components: {NoResults, GraphSettingsDialog, SpinningLoader, PopupMenu, Speedometer}
})
export default class AuthorGraph extends mixins(Vue, PermissionsMixin, InvestigationMixin) {

    private chart: Chart | null = null
    private chartData: ChartData = {type: "LinkChart", items: []}
    private selectedId: number = 0
    private originalProperties: any[] = []
    private enrichAuthorMenuTitle: string = ""
    private removePersonMenuTitle: string = ""
    private retainPersonMenuTitle: string = ""
    private mergePersonMenuTitle: string = ""
    private activityIndicatorInterval: any
    private activityIndicatorRotation: number = 0
    private menuPosition: { left: number, top: number } = {left: 0, top: 0}
    private showMerge: boolean = false
    private disableFindShortestPath: boolean = false
    private showDisambiguate: boolean = false
    private showUndoMerge: boolean = false
    private showSemanticAffinity: boolean = false
    private showMergeReport: boolean = false
    private enableLaunchWebInvestigation: boolean = false
    private enableLaunchPersonInvestigation: boolean = false
    private numberOfNodes: number = 0
    private isHiding: boolean = false
    private isContextMenuOpen: boolean = false
    private showSizeBySimilarity: boolean = false
    private showColorBySimilarity: boolean = false
    private showAddFilter: boolean = false
    private colorMap: ColorCode = {
        0: "#D6D4D4",
        1: "#33B8FF",
        2: "#81B1D3",
        3: "#FFD540",
        4: "#EA69B1",
        5: "#AB7DF6",
        6: "#FE7474",
        7: "#88CF65",
        8: "#889CFE",
        9: "#26C1C9"
    }
    private maxEdgeFactor: number = 0
    private minEdgeFactor: number = 0
    private highlightFilter: HighlightFilter = {
        filter: "",
        current: null,
        list: []
    }
    private isInFullScreen = false
    private imageDataUrl: string = ""
    private showingAdvancedMenu = false

    @Prop({type: Boolean, default: false})
    private locked!: boolean

    get isInPersonProfileSummary(): boolean {
        return this.$route.name!!.endsWith("ProfileOverview")
    }

    private get isReportEnabled(): boolean {
        return isReportEnabled(this.$store)
    }

    private get graphData() {
        return getGraph(this.$store)
    }

    private get fullScreenText() {
        return this.isInFullScreen ? "Exit Full Screen" : "Enter Full Screen"
    }

    private get settings() {
        return getAuthorGraphSettings(this.$store)
    }

    private get activityIndicator(): number {
        return getActivityStatus(this.$store)
    }

    private get hasUnselectedNodes() {
        return this.getUnselectedNodesIds().length > 0
    }

    private get resultsDifferenceColor() {
        const percentFade = Math.min(getRelevancyStatus(this.$store).relevancyScore / 100, 1)
        const startRed = 0
        const startGreen = 176
        const startBlue = 255
        const middleRed = 160
        const middleGreen = 109
        const middleBlue = 244
        const endRed = 233
        const endGreen = 30
        const endBlue = 99
        return this.colorGradient(
            percentFade,
            {red: startRed, green: startGreen, blue: startBlue},
            {red: middleRed, green: middleGreen, blue: middleBlue},
            {red: endRed, green: endGreen, blue: endBlue}
        )
    }


    public mounted() {
        const option = "score"
        const sizeBy: GraphSetBy = {option}
        setAuthorGraphSettings(this.$store, {...this.settings, sizeBy})
        this.clearHighlight()
        this.syncGraphSettings()
        setDisambiguatePersonName(this.$store, null)
        setDisambiguationPersonId(this.$store, null)

        this.$on("closedOncloseGraphContextMenu", this.contextMenuClosed)
        this.activityIndicatorInterval = setInterval(() => this.activityIndicatorRotation = this.activityIndicator + (this.activityIndicator > 0 && this.activityIndicator <= 170 ? 10 * Math.random() : 0), 500)
    }

    private contextMenuClosed() {
        this.isContextMenuOpen = false
    }

    private syncGraphSettings() {
        setGraphType(this.$store, "Author")
        this.highlightFilter.filter = this.settings.filter
        this.showSizeBySimilarity = this.settings.sizeBy.option === "similarity"
        this.showColorBySimilarity = this.settings.colorBy.option === "similarity"
        setPersonForRightPaneSummary(this.$store, this.settings.personForRightPaneSummary)
        this.$nextTick(async () => {
            const colorBySelect = (this.$refs.colorBySelect as HTMLSelectElement)
            setShowAuthorGraphLegend(this.$store, colorBySelect.value === "recentAffiliationCode")
        })
    }

    private toggleSnowflakes() {
        const showSnowflakes = !this.settings.showSnowflakes
        setAuthorGraphSettings(this.$store, {...this.settings, showSnowflakes})
        this.fetchData()
    }

    private openViewModeMenu() {
        const viewModeMenuOpenerButton = this.$refs.viewModeMenuOpener as HTMLElement
        this.$emit("openViewModeMenu", viewModeMenuOpenerButton)
    }

    private openAdvancedMenu() {
        const advancedMenuOpenerButton = this.$refs.advancedMenuOpener as HTMLElement
        this.$emit("openAdvancedMenu", advancedMenuOpenerButton)
    }

    private toggleAdvancedMenu() {
        this.showingAdvancedMenu = !this.showingAdvancedMenu
    }

    private async showHideItemsBasedOnConfiguration() {
        const configuration = await configurationService.getConfiguration(this.investigation!!.id)
        const totalNumberOfNodes = configuration.graphsSettings[`author`].totalNumberOfNodes
        const edgeThreshold = configuration.graphsSettings[`author`].edgeThreshold

        const visibleItemIds: string[] = []
        const invisibleItemIds: string[] = []
        const allNodes: Node[] = []

        this.chartData.items.forEach(item => {
            if (item.type === "node") {
                allNodes.push(item)
            } else if (item.type === "link") {
                if (edgeThreshold == null || (item.w && item.w >= edgeThreshold)) {
                    visibleItemIds.push(item.id)
                } else {
                    invisibleItemIds.push(item.id)
                }
            }
        })

        if (totalNumberOfNodes != null) {
            const allNodesIds: string[] = allNodes.sort((n1, n2) => n2.e! - n1.e!).map(node => node.id)
            allNodesIds.slice(0, totalNumberOfNodes).forEach(node => visibleItemIds.push(node))
            allNodesIds.slice(totalNumberOfNodes, allNodes.length).forEach(node => invisibleItemIds.push(node))
        } else {
            allNodes.map(node => node.id).forEach(node => visibleItemIds.push(node))
        }

        await this.chart!.show(visibleItemIds)
        await this.chart!.hide(invisibleItemIds)
    }

    private async openGraphSettings() {
        const maxNodeCount = this.chartData.items.filter(item => item.type === "node").length

        this.graphSettingsDialog().open({
            graphType: "author",
            maxEdgeFactor: this.maxEdgeFactor,
            minEdgeFactor: this.minEdgeFactor,
            maxNodeCount,

            onOk: async () => {
                await this.showHideItemsBasedOnConfiguration()
                this.calcNumberOfNodes()
                await this.performLayout(this.settings.layout)
            }
        })
    }

    private async fetchData() {
        await fetchGraphData(this.$store)
    }

    @Watch("graphData")
    private resetGraph() {
        const chart = this.chart!
        this.chartData = {type: "LinkChart", items: []}
        chart.load(this.chartData)
        if (this.graphData.nodes.length !== 0) {
            this.$nextTick(async () => {
                this.chartData = this.getChartData()
                await chart.load(this.chartData)
                await this.showHideItemsBasedOnConfiguration()
                this.calcNumberOfNodes()
                await this.performLayout(this.settings.layout)
                chart.selection(getShortestPath(this.$store).length ? getShortestPath(this.$store) : getSelectedNodes(this.$store))

                await chart.zoom("fit")
                if (getShortestPath(this.$store).length) {
                    this.drawConnectingPath()
                }
                setIsRenderingGraph(this.$store, false)
            })
        } else {
            setIsRenderingGraph(this.$store, false)
        }
    }

    private toggleFullScreen() {
        const graphContent = this.$refs.graphContent as HTMLElement
        // @ts-ignore
        KeyLines.toggleFullScreen(graphContent, isInFullScreen => this.isInFullScreen = isInFullScreen)
    }

    private getChartData(): ChartData {
        const sizeBy = (this.$refs.sizeBySelect as HTMLSelectElement).value as string
        const colorBy = (this.$refs.colorBySelect as HTMLSelectElement).value as string

        const maxDocumentCount = Math.max(...this.graphData.nodes.map(node => node.props.documentCount))
        this.graphData.nodes.forEach(node => {
            node.props.normalizedDocumentCount = Math.min(25, 1 + Math.sqrt(node.props.documentCount))
        })

        const keyLinesNodes = this.graphData.nodes
            .map(node => {
                const hasBorder = !this.isInPersonProfile && (node.props.harvesting || node.props.harvested)
                const borderStyle = hasBorder ? (node.props.harvested ? "solid" : "dashed") : null
                const c = colorBy === "similarity" ?
                    this.colorGradient(
                        node.props.colorAffinityScore,
                        {red: 205, green: 205, blue: 205},
                        {red: 172, green: 102, blue: 102},
                        {red: 139, green: 0, blue: 0}
                    ) :
                    this.colorMap[node.props[colorBy]]
                const splittedName = node.props.personName.replace(" ", "\n") as string
                const longest = Math.max(...splittedName.split("\n").map(t => t.length))
                const fontSize = longest < 10 ? 10 : Math.max(Math.round(-0.43 * longest + 12.8), 5)
                let e
                switch (sizeBy) {
                    case "score":
                        e = node.props.score
                        break
                    case "documentCount":
                        e = node.props.normalizedDocumentCount
                        break
                    case "similarity":
                        e = 0.5 + node.props.sizeAffinityScore * 9.5
                }

                return {
                    id: node.id,
                    type: "node",
                    sh: "circle",
                    ff: "Roboto",
                    t: splittedName,
                    c,
                    e,
                    b: hasBorder ? "#606060" : c,
                    bw: 6,
                    bs: borderStyle,
                    fs: fontSize,
                    fbc: "transparent",
                    d: {
                        props: node.props
                    },
                    bg: false
                } as unknown as Node
            })

        const keyLinesLinks = this.graphData.edges
            .map(edge => {
                return {
                    id: edge.id,
                    type: "link",
                    bg: false,
                    id1: edge.sourceId,
                    id2: edge.targetId,
                    c: edge.props.color,
                    w: edge.props.weight,
                    d: {
                        props: edge.props
                    }
                } as Link
            })

        let items: Array<Node | Link> = []
        items = items.concat(keyLinesNodes).concat(keyLinesLinks)

        this.maxEdgeFactor = Math.round(Math.max(...keyLinesNodes.map(node => node.e as number)) * 100) / 100
        this.minEdgeFactor = Math.round(Math.min(...keyLinesNodes.map(node => node.e as number)) * 100) / 100

        return {
            type: "LinkChart",
            items
        } as ChartData
    }

    private getSelectedNode(): Node {
        return this.chartData.items.filter(item => item.type === "node" && item.id === this.selectedId.toString())[0] as unknown as Node
    }

    private getSelectedNodes(): Node[] {
        if (this.chart) {
            const selectedIds = this.chart!.selection()
            return this.chartData.items
                .filter(item => item.type === "node")
                .filter(item => selectedIds.find(id => id === item.id)) as Node[]
        }
        return []
    }

    private getSelectedNodesIds(): string[] {
        return this.getSelectedNodes().map(node => node.id)
    }

    private getUnselectedNodesIds(): string[] {
        const selectedNodesIds = this.getSelectedNodesIds()
        return this.chartData.items
            .filter(item => item.type === "node")
            .map(item => item.id)
            .filter(item => !selectedNodesIds.find(selected => selected === item))
    }

    private openPopupMenu(id: number | null, x: number, y: number) {
        const menuRef = this.$refs.menuRef as HTMLElement

        if (id !== null) {
            this.chart!.setProperties(this.originalProperties)

            this.selectedId = id
            const chartElement = this.$refs.chart as HTMLElement
            const boundingClientRect = chartElement.getBoundingClientRect()
            this.menuPosition = {left: x + boundingClientRect.left, top: y + boundingClientRect.top}
            this.buildContextMenu()
            this.$emit("openGraphContextMenu", menuRef)
            this.isContextMenuOpen = true
        } else {
            this.$emit("closeGraphContextMenu", menuRef)
        }
    }

    private clickedGraph(id: string) {
        if (!this.isContextMenuOpen) {
            if (id) {
                this.viewProfile(parseInt(id, 10))
            } else {
                setPersonForRightPaneSummary(this.$store, null)
                setAuthorGraphSettings(this.$store, {...this.settings, personForRightPaneSummary: null})
            }
        }
    }

    private buildContextMenu() {
        const selectedNodesIds = this.getSelectedNodesIds()
        this.showMerge = selectedNodesIds.length > 1
        this.disableFindShortestPath = selectedNodesIds.length !== 2 || !this.hasConnectingPath(selectedNodesIds)
        this.setContextMenuTitles(selectedNodesIds)
        this.showMergeReport = selectedNodesIds.length > 1 && this.isOmnipotentUser
        this.showDisambiguate = selectedNodesIds.length === 1 && this.investigationType === "Person"
        this.showSemanticAffinity = selectedNodesIds.length === 1
        this.showUndoMerge = selectedNodesIds.length === 1 && this.getSelectedNodes()[0].d.props.manuallyMerged
        this.enableLaunchWebInvestigation = selectedNodesIds.length === 1
        this.enableLaunchPersonInvestigation = selectedNodesIds.length === 1
        this.showAddFilter = this.calculateShowAddFilter()
    }


    private calculateShowAddFilter(): boolean {
        return this.getSelectedNodes()
            .every(node =>
                !this.selectedTops.some(top =>
                    top.name === "predefined.persons" && top.selectedValue === node.id
                )
            )
    }


    private async addAsFilter() {
        if (this.showAddFilter) {
            this.getSelectedNodes().forEach(node => {
                this.selectedTops.push({name: "predefined.Persons", selectedDisplayValue: node.d.props.personName, selectedValue: parseInt(node.id, 10)})
            })

            await setSelectedTops(this.$store, this.selectedTops)
            await fetchGraphData(this.$store)
        }
    }


    private get selectedTops() {
        return getSelectedTops(this.$store)
    }


    private async refresh() {
        setPersonForRightPaneSummary(this.$store, null)
        setAuthorGraphSettings(this.$store, {...this.settings, personForRightPaneSummary: null})
        await this.fetchData()
    }

    private beforeDestroy() {
        setPersonForRightPaneSummary(this.$store, null)
        setIsRenderingGraph(this.$store, false)
    }

    private async klReady(chart: Chart) {
        this.chart = chart
        await this.fetchData()
        const chartOptions: ChartOptions = {
            minZoom: 0.001,
            handMode: true,
            zoom: {
                adaptiveStyling: true
            },
            linkEnds: {
                avoidLabels: false,
                spacing: "tight"
            },
            selectionColour: "#0055B3",
            drag: {
                links: false,
                panAtBoundary: true
            },
            selectedNode: {
                b: "#0055B3",
                fb: true,
                fbc: "transparent"
            },
            selectedLink: {},
            marqueeLinkSelection: "off",
            overview: {
                icon: false,
                shown: false
            },
            navigation: {
                x: 20,
                y: 10
            }
        }
        if (this.locked) {
            chartOptions.maxItemZoom = 1
            chartOptions.selectionColour = undefined
            chartOptions.drag = undefined
            chartOptions.selectedNode = undefined
            chartOptions.navigation = {
                shown: false
            }
        }

        await this.chart.options(chartOptions)
        this.chart.bind("selectionchange", this.handleSelectionChange)
        this.chart.bind("delete", (() => {
            this.hide(this.getSelectedNodesIds())
            return true
        }))
        if (this.locked) {
            await this.chart.lock(true, {})
        }
    }

    private handleSelectionChange() {
        if (!this.isHiding && !this.locked) {
            if (this.isContextMenuOpen) {
                this.chart!.selection(getSelectedNodes(this.$store))
            } else {
                this.chart!.setProperties(this.originalProperties)
                this.originalProperties = []
                setShortestPath(this.$store, [])

                const selectedNodesIds = this.getSelectedNodesIds()
                setSelectedNodes(this.$store, selectedNodesIds)
                this.selectNeighbouringNodes(1)
                this.chart!.selection(selectedNodesIds)

                if (selectedNodesIds.length === 0) {
                    this.chart!.foreground(item => true)
                    setPersonForRightPaneSummary(this.$store, null)
                    setAuthorGraphSettings(this.$store, {...this.settings, personForRightPaneSummary: null})
                }
            }
        }
    }

    private setContextMenuTitles(selectedNodes: string[]) {
        if (selectedNodes.length === 1) {
            this.enrichAuthorMenuTitle = "Enrich Author"
            this.removePersonMenuTitle = "Remove Selected Author"
        } else {
            this.enrichAuthorMenuTitle = `Enrich ${selectedNodes.length} Authors`
            this.removePersonMenuTitle = `Remove ${selectedNodes.length} Selected Authors`
        }
        this.mergePersonMenuTitle = `Merge ${selectedNodes.length} Selected Authors`

        const unselectedNodesIds = this.getUnselectedNodesIds()
        if (unselectedNodesIds.length === 1) {
            this.retainPersonMenuTitle = "Remove Unselected Author"
        } else {
            this.retainPersonMenuTitle = `Remove ${unselectedNodesIds.length} Unselected Authors`
        }
    }

    private hasConnectingPath(selectedNodes: string[]): boolean {
        const paths = this.chart!.graph().shortestPaths(selectedNodes[0], selectedNodes[1], {direction: "any"})
        const connectedNodeIds = paths.items.filter(itemId => this.chart!.getItem(itemId)!!.type === "node")
        return connectedNodeIds.length > 0
    }

    private executeIfNotDisabled(disabled: boolean, action: () => void) {
        if (!disabled) {
            action()
        }
    }

    private drawConnectingPath() {
        this.chart!.setProperties(this.originalProperties)

        const chart = this.chart!
        if (this.getSelectedNodesIds()[0] && this.getSelectedNodesIds()[1]) {
            setShortestPath(this.$store, this.getSelectedNodesIds())
            const paths = chart.graph().shortestPaths(this.getSelectedNodesIds()[0], this.getSelectedNodesIds()[1], {direction: "any"})

            const connectedNodeIds = paths.items.filter(itemId => chart.getItem(itemId)!!.type === "node")
            const unconnectedNodeIds = this.chartData.items
                .filter(item => item.type === "node")
                .map(item => item.id)
                .filter(item => !connectedNodeIds.find(connected => connected === item))

            const connectedEdgeIds = paths.items.filter(itemId => chart.getItem(itemId)!!.type === "link")
            const unconnectedEdgeIds = this.chartData.items
                .filter(item => item.type === "link")
                .map(item => item.id)
                .filter(item => !connectedEdgeIds.find(connected => connected === item))

            const unconnectedNodes: Node[] = unconnectedNodeIds.map(unconnectedNodeId => chart.getItem(unconnectedNodeId)!!) as unknown as Node[]
            const unconnectedEdges: Link[] = unconnectedEdgeIds.map(unconnectedEdgeId => chart.getItem(unconnectedEdgeId)!!) as unknown as Link[]
            const connectedEdges: Link[] = connectedEdgeIds.map(connectedEdgeId => chart.getItem(connectedEdgeId)!!) as unknown as Link[]

            this.originalProperties = []
            this.originalProperties.push(...[
                ...unconnectedNodes.map(node => ({id: node.id, bg: node.bg})),
                ...[...unconnectedEdges, ...connectedEdges].map(edge => ({id: edge.id, bg: edge.bg, w: edge.w, c: edge.c}))
            ])
            chart.setProperties([
                ...[...unconnectedNodes, ...unconnectedEdges].map(item => ({id: item.id, bg: true})),
                ...connectedEdges.map(edge => ({id: edge.id, c: "#0055B3", w: 25}))
            ])
            chart.selection(connectedNodeIds)
            setSelectedNodes(this.$store, connectedNodeIds)
        }
    }

    private enrichAuthor() {
        const selectedNodes = this.getSelectedNodes()
        if (selectedNodes.length > 1) {
            openConfirmationDialog({
                message: `You are about to enrich ${selectedNodes.length} authors. Are you sure?<br>This might take a while.`,
                title: `Enrich Authors`,
                okText: "Enrich",
                onOk: () => this.performEnrichAuthors(selectedNodes),
                showCancel: true
            })
        } else {
            this.performEnrichAuthors(selectedNodes)
        }
    }

    private disambiguateAuthor() {
        if (this.investigationType === "Person") {
            const node = this.getSelectedNode()
            if (node) {
                setDisambiguatePersonName(this.$store, node!!.d.props.personName)
                setDisambiguationPersonId(this.$store, node!!.id)
                saveInvestigationState(this.$store)
                this.$router.push({path: "disambiguate"})
            }
        }
    }

    private async downloadMergeReport() {
        const nodeIds = this.getSelectedNodesIds()
        const blob = await adminService.createMergeReport(nodeIds)
        const fileName = `${moment().format("YYYY_MM_DD_HH_mm_ss")} - merge-report.txt`
        downloadBlob(blob, fileName)
    }

    private performEnrichAuthors(selectedNodes: Node[]) {
        const properties = selectedNodes.map(node => {
            return {
                id: node.id,
                b: "#606060",
                bw: 6,
                bs: "dashed"
            } as NodeProperties
        })
        this.chart!.setProperties(properties)

        TopicInvestigationService.createPersonHarvests(this.investigation!.id, selectedNodes.map(node => node.d.props.personName))
    }

    private performRemovePersons(selectedNodes: string[]) {
        investigationService.disambiguate(this.investigation!.id, this.investigationType, selectedNodes, [], [])
        this.hide(selectedNodes)
        removeGraphNodes(this.$store, selectedNodes)
    }

    private viewProfile(profileId: number) {
        this.selectedId = profileId

        if (profileId) {
            const id = this.getSelectedNode().id
            const name = this.getSelectedNode().d.props.personName
            const documentsCount = this.getSelectedNode().d.props!.documentsCount
            const person: PersonEntity = {id, name, relevancy: "Neutral", documentsCount, filteredOut: false}
            setPersonForRightPaneSummary(this.$store, person)
            this.settings.personForRightPaneSummary = person
        } else {
            setPersonForRightPaneSummary(this.$store, null)
            setAuthorGraphSettings(this.$store, {...this.settings, personForRightPaneSummary: null})
        }
    }

    private removePerson() {
        const selectedNodes = this.getSelectedNodesIds()
        const plural = selectedNodes.length > 1 ? "s" : ""
        openConfirmationDialog({
            message: `You are about to remove ${selectedNodes.length} author${plural} from the graph. Are you sure?`,
            title: `Remove Selected Author${plural}`,
            okText: "Remove",
            onOk: () => this.performRemovePersons(selectedNodes),
            showCancel: true
        })
    }

    private invertSelection() {
        this.chart!.selection(this.getUnselectedNodesIds())
        this.handleSelectionChange()
    }

    private retainPerson() {
        const nodesToBeRemoved = this.getUnselectedNodesIds()

        const plural = nodesToBeRemoved.length > 1 ? "s" : ""
        openConfirmationDialog({
            message: `You are about to remove ${nodesToBeRemoved.length} author${plural} from the graph. Only selected authors will remain. Are you sure?`,
            title: `Remove Unselected Author${plural}`,
            okText: "Remove",
            onOk: () => this.performRemovePersons(nodesToBeRemoved),
            showCancel: true
        })
    }

    private async performLayout(layoutName: string) {
        setAuthorGraphSettings(this.$store, {...this.settings, layout: layoutName})
        const layout = graphLayouts[layoutName]
        await this.chart!.layout(layout.name, {packing: layout.packing})
    }

    private async performSizeBy() {
        this.showSizeBySimilarity = false
        const option = (this.$refs.sizeBySelect as HTMLSelectElement).value as string
        const sizeBy: GraphSetBy = {option}
        setAuthorGraphSettings(this.$store, {...this.settings, sizeBy})

        const properties: NodeProperties[] = []
        this.chart!.each({type: "node"}, node => {
            const nodeData = this.getNode(node.id)
            properties.push({id: node.id, e: sizeBy.option === "score" ? nodeData.d.props.score : nodeData.d.props.normalizedDocumentCount})
        })
        await this.chart!.animateProperties(properties, {time: 300, easing: "cubic"})
    }

    private async performColorBy() {
        this.showColorBySimilarity = false
        const option = (this.$refs.colorBySelect as HTMLSelectElement).value as string
        const colorBy: GraphSetBy = {option}
        setAuthorGraphSettings(this.$store, {...this.settings, colorBy})

        const properties: NodeProperties[] = []
        this.chart!.each({type: "node"}, node => {
            const color = this.colorMap[node.d.props[colorBy.option]]
            const borderColor = (node.d.props.harvested || node.d.props.harvesting) ? "#606060" : color

            properties.push({id: node.id, b: borderColor, c: color})
        })
        setShowAuthorGraphLegend(this.$store, colorBy.option === "recentAffiliationCode")
        await this.chart!.animateProperties(properties, {time: 300, easing: "cubic"})
    }

    private async selectNeighbouringNodes(hops: number) {
        const result = this.chart!.graph().neighbours(this.chart!.selection(), {hops, all: false})
        result.nodes.push(...this.chart!.selection())
        await this.chart!.selection(result.nodes)

        result.links!!.forEach((itemId, index) => {
            const item = this.chart!.getItem(itemId)
            this.originalProperties.push({id: itemId, c: "rgba(135, 135, 135, 0.4)", w: (item!! as Link).w, bg: true})
        })

        if (result.nodes.length !== 0 || result.links!.length !== 0) {
            await this.chart!.foreground(item => {
                if (item.type === "node" && !result.nodes.includes(item.id)) {
                    return false
                }
                if (item.type === "link" && !result.links!.includes(item.id)) {
                    return false
                }
                return true
            }, {type: "all"})
        }
        await this.chart!.setProperties(result.links!!.map(link => ({id: link, c: "rgba(0,102,215,0.53)", bg: false})))
    }

    private getNode(id: string): Node {
        return this.chartData.items.filter(item => item.type === "node" && item.id === id)[0] as Node
    }

    private copyNodeText() {
        copyToClipboard(this.getSelectedNodes().map(node => node.d.props.personName).sort((a, b) => a.localeCompare(b)).join("\n"))
    }

    private googleIt() {
        const searchString = this.getSelectedNode().d.props.personName
        window.open(`https://www.google.com/search?q=${searchString}`, "_blank")
    }

    private async createGraphObservation() {
        this.imageDataUrl = await this.chart!.toDataURL(undefined, undefined, {fit: "exact", selection: true})

        openCreateObservationDialog(this, {
            type: "Graph",
            referencedEntity: this.imageDataUrl,
            observationTitle: this.getObservationTitle()
        })
    }

    private createPersonObservation() {
        openCreateObservationDialog(this, {
            type: "Person",
            referencedEntity: this.selectedId.toString()
        })
    }

    private hide(persons: string[]) {
        this.isHiding = true
        this.chart!.hide(persons)
        setPersonForRightPaneSummary(this.$store, null)
        setAuthorGraphSettings(this.$store, {...this.settings, personForRightPaneSummary: null})
        this.isHiding = false
        this.calcNumberOfNodes()
    }

    private showMergeConfirmation() {
        const selectedNodes = this.getSelectedNodes()
        const nodeCount = selectedNodes.length
        const authors = selectedNodes.map(node => node.d.props.personName)
        let authorsHtml = `<br><ul>`
        authors.forEach(author => authorsHtml += `<li>${author}</li>`)
        authorsHtml += `</ul>`
        const nodeIds = selectedNodes.map(node => node.id)

        openConfirmationDialog({
            message: `You are about to merge ${nodeCount} authors:${authorsHtml}Are you sure?<br>This operation cannot be undone.`,
            title: "Merge Authors",
            okText: "Merge",
            onOk: () => this.performMerge(nodeIds),
            showCancel: true
        })
    }

    private async performMerge(relevantPersons: string[]) {
        const investigation = this.investigation!
        wrapActiveRequests(this.$store, async () => {
            setPersonForRightPaneSummary(this.$store, null)
            setAuthorGraphSettings(this.$store, {...this.settings, personForRightPaneSummary: null})
            await investigationService.disambiguate(investigation.id, investigation.investigationType, [], relevantPersons, [])
            await fetchGraphData(this.$store)
        })
        await investigationService.merge(investigation.id, investigation.investigationType)
    }

    private async sizeBySemanticAffinity() {
        const investigation = this.investigation!
        const selectedNode = this.getSelectedNode()
        if (selectedNode) {
            await increaseActiveRequests(this.$store)
            const affinities = await personInvestigationService.getSemanticAffinity(investigation!!.id, selectedNode.id!!)
            const nodeAffinities = this.chartData.items
                .filter(item => item.type === "node")
                .filter(node => affinities.map(affinity => affinity.personId).includes(node.id))
                .map(node => ({
                    id: node.id,
                    affinity: affinities.filter(affinity => affinity.personId === node.id)[0].semanticAffinity
                }))

            const properties = nodeAffinities.map(nodeAffinity => ({id: nodeAffinity.id, e: 0.5 + nodeAffinity.affinity * 9.5}))
            await decreaseActiveRequests(this.$store)

            await this.chart!.animateProperties(properties, {time: 300, easing: "cubic"})
            this.showSizeBySimilarity = true
            Vue.nextTick(() => {
                const sizeBySelect = (this.$refs.sizeBySelect as HTMLSelectElement)
                sizeBySelect.value = "similarity"
                const option = sizeBySelect.value
                const personId = this.getSelectedNode() ? this.getSelectedNode().id : undefined
                const sizeBy: GraphSetBy = {option, personId}
                setAuthorGraphSettings(this.$store, {...this.settings, sizeBy})
            })
        }
    }

    private async colorBySemanticAffinity() {
        const investigation = this.investigation!
        const selectedNode = this.getSelectedNode()
        if (selectedNode) {
            await increaseActiveRequests(this.$store)
            const affinities = await personInvestigationService.getSemanticAffinity(investigation!!.id, selectedNode.id!!)
            const nodeAffinities = this.chartData.items
                .filter(item => item.type === "node")
                .filter(node => affinities.map(affinity => affinity.personId).includes(node.id))
                .map(node => ({
                    id: node.id,
                    affinity: affinities.filter(affinity => affinity.personId === node.id)[0].semanticAffinity
                }))
            const startColor = {red: 205, green: 205, blue: 205}
            const endColor = {red: 139, green: 0, blue: 0}
            const properties = nodeAffinities.map(nodeAffinity => {
                const color = this.colorGradient(nodeAffinity.affinity, startColor, endColor)
                return {id: nodeAffinity.id, c: color, b: color}
            })

            await decreaseActiveRequests(this.$store)

            await this.chart!.animateProperties(properties, {time: 300, easing: "cubic"})
            this.showColorBySimilarity = true
            Vue.nextTick(() => {
                const colorBySelect = (this.$refs.colorBySelect as HTMLSelectElement)
                colorBySelect.value = "similarity"
                setShowAuthorGraphLegend(this.$store, false)
                const option = colorBySelect.value
                const personId = this.getSelectedNode() ? this.getSelectedNode().id : undefined
                const colorBy: GraphSetBy = {option, personId}
                setAuthorGraphSettings(this.$store, {...this.settings, colorBy})
            })
        }
    }

    private graphSettingsDialog(): GraphSettingsDialog {
        return this.$refs.graphSettingsDialog as GraphSettingsDialog
    }

    private colorGradient(fadeFraction: number, rgbColor1: RGB, rgbColor2: RGB, rgbColor3?: RGB) {
        let color1: RGB = rgbColor1
        let color2: RGB = rgbColor2
        let fade: number = fadeFraction

        // Do we have 3 colors for the gradient? Need to adjust the params.
        if (rgbColor3) {
            fade = fade * 2

            // Find which interval to use and adjust the fade percentage
            if (fade >= 1) {
                fade -= 1
                color1 = rgbColor2
                color2 = rgbColor3
            }
        }

        const diffRed: number = color2.red - color1.red
        const diffGreen: number = color2.green - color1.green
        const diffBlue: number = color2.blue - color1.blue

        const gradient: RGB = {
            red: Math.floor(color1.red + (diffRed * fade)),
            green: Math.floor(color1.green + (diffGreen * fade)),
            blue: Math.floor(color1.blue + (diffBlue * fade))
        }

        return `rgb(${gradient.red},${gradient.green},${gradient.blue})`
    }

    private addHalosToNodes() {
        const names = this.highlightFilter.filter.substr(1)
        if (names.length === 0) {
            this.chart!.setProperties({id: ".", ha0: undefined}, true)
        } else {
            names.split(",").forEach(name => {
                name = name.trim()
                this.chart!.each({type: "node"}, node => {
                    if (node.d.props.personName.includes(name)) {
                        this.chart!.setProperties({
                            id: node.id,
                            ha0: {
                                c: "rgb(158,161,255)",
                                r: 35,
                                w: 10
                            }
                        })
                    }
                })
            })
        }
    }

    private calcNumberOfNodes() {
        let count = 0
        this.chart!.each({type: "node"}, node => {
            if (!node.hi) {
                count++
            }
        })
        this.numberOfNodes = count
    }

    private filter() {
        setAuthorGraphSettings(this.$store, {...this.settings, filter: this.highlightFilter.filter})
        if (this.highlightFilter.filter.startsWith("!")) {
            this.addHalosToNodes()
        }

        this.highlightFilter.current = null
        this.highlightFilter.list = []

        if (this.highlightFilter.filter !== "") {
            const lowerCaseFilterName = this.highlightFilter.filter.toLowerCase()
            const filteredNodeIds = this.chartData.items
                .filter(item => item.type === "node")
                .map(item => item as NodeProperties)
                .filter(node => {
                    return this.highlightFilter.filter === "" || node.d.props.personName.toLowerCase().includes(lowerCaseFilterName)
                })
                .map(node => node.id)
            this.chart!.ping(filteredNodeIds, {
                c: "#ff0000",
                repeat: 10,
                r: 120,
                w: 100,
                time: 800
            })
            this.chart!.zoom("fit", {ids: filteredNodeIds, animate: true})
            this.highlightFilter.current = null
            this.highlightFilter.list = filteredNodeIds
        }
    }

    private calcHighlightFilterCounts() {
        if (this.highlightFilter.list.length === 0) {
            return ""
        }
        if (this.highlightFilter.current != null) {
            return `${this.highlightFilter.current + 1}/${this.highlightFilter.list.length}`
        }

        return `${this.highlightFilter.list.length}`
    }

    private highlightPrevious() {
        this.highlightJump(-1)
    }

    private highlightNext() {
        this.highlightJump(1)
    }

    private async highlightJump(direction: number) {
        if (this.highlightFilter.list === []) {
            return
        }
        if (this.highlightFilter.current === null) {
            this.highlightFilter.current = direction === 1 ? 0 : this.highlightFilter.list.length - 1
        } else {
            this.highlightFilter.current += direction
            if (this.highlightFilter.current === -1 || this.highlightFilter.current === this.highlightFilter.list.length) {
                this.highlightFilter.current = null
            }
        }

        const nodeIds = this.highlightFilter.current === null ? this.highlightFilter.list : this.highlightFilter.list[this.highlightFilter.current]

        this.chart!.ping(nodeIds, {
            c: "#ff0000",
            repeat: 3,
            r: 120,
            w: 100,
            time: 800
        })
        await this.chart!.zoom("fit", {ids: nodeIds, animate: true, time: 500})
    }

    private clearHighlight() {
        this.highlightFilter = {
            filter: "",
            current: null,
            list: []
        }
    }

    private async editAuthorDetails() {
        const node = this.getSelectedNode()
        if (node) {
            openAuthorDetailsDialog(this, {
                personId: node!!.id,
                personName: node!!.d.props.personName,
                onUpdateCallback: async (details: InvestigationPersonDetails, imageModified: boolean,
                                         imageCropData?: ImageCropData, imageUrl?: string) => {

                    const investigation = this.investigation!
                    await investigationService.updatePersonDetails(
                        investigation.id, investigation.investigationType, details)

                    if (imageModified) {
                        if (imageCropData) {
                            await InvestigationService.updatePersonImage(
                                investigation.id, investigation.investigationType, node!!.id, imageCropData, imageUrl)
                        } else {
                            await InvestigationService.clearPersonImage(
                                investigation.id, investigation.investigationType, node!!.id)
                        }
                    }
                }
            })
        }
    }

    private getObservationTitle(): string {
        const nodes = this.getSelectedNodes()
        if (nodes.length === 1) {
            return nodes[0].d.props.personName
        }

        if (nodes.length === 2) {
            return nodes[0].d.props.personName + " & " + nodes[1].d.props.personName
        }

        return "Author Graph Insight"
    }

    private undoMerge() {
        const nodes = this.getSelectedNodes()
        if (nodes.length !== 1) {
            return
        }

        const performUndoMerge = async () => {
            const investigation = this.investigation!

            setPersonForRightPaneSummary(this.$store, null)
            setAuthorGraphSettings(this.$store, {...this.settings, personForRightPaneSummary: null})
            await investigationService.undoMerge(investigation.id, investigation.investigationType, [nodes[0].id])
        }

        openConfirmationDialog({
            message: undoMergeMessage(nodes[0].d.props.personName),
            title: "Undo Manual Merge",
            okText: "Undo Merge",
            onOk: () => performUndoMerge(),
            showCancel: true
        })
    }


    private async createWebInvestigation() {
        const nodes = this.getSelectedNodes()
        if (nodes.length !== 1) {
            return
        }

        const investigation = getInvestigation(this.$store)!!
        const newInvestigation = await personInvestigationService.createCorrelatedInvestigation(getInvestigation(this.$store)!.id, nodes[0].id, investigation.investigationType)
        const href = this.$router.resolve({name: "topicInvestigation", params: {id: newInvestigation.id}}).href
        openConfirmationDialog({
            message: `A new web investigation was launched successfully.<br>Click <a href="${href}" target="_blank">here</a> to open it now.`,
            title: "New Web Investigation",
            okText: "Ok",
            onOk: () => {
                return
            },
            showCancel: false
        })
    }


    private async createPersonInvestigation() {
        const nodes = this.getSelectedNodes()
        if (nodes.length !== 1) {
            return
        }

        const newInvestigation = await investigationsService.createPerson(nodes[0].d.props.personName, true, false)
        const href = this.$router.resolve({name: "personInvestigation", params: {id: newInvestigation.id}}).href
        openConfirmationDialog({
            message: `A new academic researcher investigation was launched successfully.<br>Click <a href="${href}" target="_blank">here</a> to open it now.`,
            title: "New Academic Researcher Investigation",
            okText: "Ok",
            onOk: () => {
                return
            },
            showCancel: false
        })
    }
}

