


































































































import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import {Prop, Watch} from "vue-property-decorator"
import {BarGraphModel, BarModel, MentionedEntityType, RegionBarModel, SelectedTop} from "@/types/investigations"
import {fetchAllData, fetchAllPersonData, fetchGraphData, getInvestigation, getSelectedTops, getShowAuthorGraphLegend, saveInvestigationState, setDisambiguatePersonName, setSelectedTops, addActiveAuthor} from "@/store/investigation/investigation-module"
import TopicInvestigationService from "../api/topic-investigation-service"
import {getGraphType} from "@/store/investigation/graph-module"
import {copyToClipboard} from "@/utils/utils"
import PopupMenu from "@/components/PopupMenu.vue"
import {setDisambiguationPersonId} from "@/store/investigation/person-filter-module"
import {bulkApplyDocumentLabel, deleteDocumentLabel, getTopsExpansion, getTopsSorting, setTopExpanded, setTopSorting} from "@/store/investigation/documents-module"
import {getTerms} from "@/store/investigation/investigation-terms-module"
import {isReportEnabled} from "@/store/environment-module"
import {openCreateObservationDialog} from "@/views/investigation/dialogs/CreateObservationDialog.vue"
import RenameLabelDialog from "@/views/investigation/dialogs/RenameLabelDialog.vue"
import investigationEntityService from "@/api/investigation-entity-service"
import {openConfirmationDialog} from "@/views/investigation/dialogs/ConfirmationDialog.vue"
import {PermissionsMixin} from "@/store/user-mixin"
import {InvestigationMixin} from "@/store/investigation-mixin"
import {configurationService} from "@/api/investigation-configuration-service"

@Component({
    components: {PopupMenu, RenameLabelDialog}
})
export default class BarGraph extends mixins(Vue, PermissionsMixin, InvestigationMixin) {

    @Prop()
    private graph!: BarGraphModel

    private button?: HTMLElement
    private term?: string
    private value?: string | null = null
    private termExists: boolean = false
    private showsMore: boolean = false
    private search: string = ""
    private labelContext: string = ""

    get bars(): BarModel[] {
        const filteredBars = this.filteredBars().sort(this.barSorter)
        if (this.showsMore) {
            return filteredBars
        }
        return filteredBars.slice(0, 10)
    }


    private get expanded(): boolean {
        return getTopsExpansion(this.$store)[this.graph.type]
    }

    private set expanded(isExpanded: boolean) {
        setTopExpanded(this.$store, {type: this.graph.type, isExpanded})
    }

    private get sortedByTotal(): boolean {
        const topsSorting = getTopsSorting(this.$store)[this.graph.type]
        return topsSorting === undefined ? false : !topsSorting
    }

    private set sortedByTotal(sortedByTotal: boolean) {
        setTopSorting(this.$store, {type: this.graph.type, isSortedByRelevant: !sortedByTotal})
    }

    private get barSorter() {
        return this.sortedByTotal ?
            (a: BarModel, b: BarModel) => b.totalPercentage - a.totalPercentage :
            (a: BarModel, b: BarModel) => b.relevantPercentage - a.relevantPercentage

    }

    private get shouldShowLegend() {
        return this.graph.type === "predefined.Regions" && getShowAuthorGraphLegend(this.$store) && getGraphType(this.$store) === "Author"
    }

    private get selectedTops() {
        return getSelectedTops(this.$store)
    }

    private get shouldShowDisambiguateInAuthorTop() {
        return this.graph.type === "predefined.Persons" || this.$route.path.includes("documents")
    }

    private get isReportEnabled(): boolean {
        return isReportEnabled(this.$store)
    }

    private get shouldShowContextMenuButton() {
        return [
            "predefined.Persons",
            "predefined.Institutions",
            "predefined.Labels",
            "predefined.Email",
            "predefined.Domains",
            "predefined.Mentioned Terms",
            "entity.Mentioned Organizations",
            "entity.Mentioned Persons"
        ].includes(this.graph.type) || this.isDictionary
    }

    private get isDictionary() {
        return !this.graph.type.startsWith("predefined") && !this.graph.type.startsWith("entity")
    }

    private get toggleShowMoreText() {
        return this.showsMore ? "Close" : "Show More"
    }

    private get displayShowMoreButton() {
        return this.filteredBars().length > 10
    }

    private mounted() {
        this.changeExpansion()
        if (this.isInReviewProfile && this.graph.type === "predefined.Classifications") {
            this.expanded = true
        }
    }

    @Watch("shouldShowLegend")
    private changeExpansion() {
        this.expanded = this.shouldShowLegend
    }

    private filteredBars() {
        const bars = this.graph.bars
        const search = this.search.trim()
        return search.length > 1 ? bars.filter(bar => bar.caption.toLowerCase().includes(search.toLowerCase())) : bars
    }

    private openContextMenu(barGraphModel: BarGraphModel) {
        const contextMenuOpenerButton = this.$refs.contextMenuOpener as HTMLElement
        if (this.isDictionary) {
            this.$emit(`openDictionaryContextMenu`, contextMenuOpenerButton)
        } else {
            this.$emit(`open${barGraphModel.type}ContextMenu`, contextMenuOpenerButton)
        }
    }

    private calculateTitle(bar: BarModel) {
        return bar.title ? bar.title : bar.caption
    }

    private legendClass(bar: RegionBarModel) {
        return bar.code !== undefined ? "region" + bar.code : ""
    }

    private toSelectedTop(bar: BarModel): SelectedTop {
        return {name: this.graph.type, selectedDisplayValue: bar.caption, selectedValue: bar.value}
    }

    private async toggleBarSelection(bar: BarModel) {
        await this.setBarsSelected([bar], !this.isSelected(bar.value))
    }

    private async setBarsSelected(bars: BarModel[], selected: boolean) {
        let tops = this.selectedTops
        if (!selected) {
            const toDeselect = bars.filter(bar => this.isSelected(bar.value)).map(bar => bar.value)
            tops = tops.filter(top => top.name !== this.graph.type || !toDeselect.includes(top.selectedValue))
        } else {
            const toSelect = bars.filter(bar => !this.isSelected(bar.value))
            tops = tops.concat(toSelect.map(bar => this.toSelectedTop(bar)))
        }

        await setSelectedTops(this.$store, tops)

        getGraphType(this.$store) === null ? await this.$parent.$emit("refresh-documents") : await fetchGraphData(this.$store)
        if (this.isInPersonProfile && this.$route.path.endsWith("overview") && getGraphType(this.$store) === null) {
            await fetchGraphData(this.$store)
        }
    }

    private toggleShowMore() {
        if (!this.showsMore) {
            this.showsMore = true
        } else {
            this.showsMore = false
            this.expanded = false
        }
    }

    private isSelected(value: string) {
        return this.selectedTops.some(top => top.name === this.graph.type && top.selectedValue === value)
    }

    private selectAllVisibleItemsAllowed() {
        return this.filteredBars().length < 30
    }

    private selectAllVisibleItems(selected: boolean) {
        if (selected && !this.selectAllVisibleItemsAllowed()) {
            return
        }
        this.setBarsSelected(this.filteredBars(), selected)
    }

    // No unbind is needed since the menu items` dom elements are removed when popup is closed
    // and there is no reference kept to them.

    private copyItemsToClipboard() {
        copyToClipboard(this.graph.bars.map(bar => bar.caption).join("\r\n"))
    }

    private openTopsContextMenu(index: number, event: MouseEvent) {
        this.button = event.target as HTMLElement
        const topsContextButtons = this.$refs[`topsContextMenu` + index] as HTMLElement[]
        this.bindContextMenuItems(this.bars[index].caption, this.bars[index].value)
        this.$emit("openTopsContextMenu." + this.graph.type, topsContextButtons[0])
    }

    // setTimeout is used to wait on dom elements to render when popup is shown
    private bindContextMenuItems(term: string, value: string) {
        const terms = getTerms(this.$store).map(existingTerm => existingTerm.term.toLowerCase())
        this.term = term
        this.value = value
        this.termExists = terms.includes(term.toLowerCase())

        this.$nextTick(() => {
            setTimeout(() => {
                const copy = this.$refs.copy as HTMLElement
                copy.onclick = () => copyToClipboard(term)

                const addTerm = this.$refs.addTerm as HTMLElement
                if (addTerm && !this.termExists) {
                    addTerm.onclick = () => {
                        this.$emit("set-new-term", term)
                        this.$parent.$emit("openCreateTermMenu", this.button)
                    }
                }
                const google = this.$refs.google as HTMLElement
                google.onclick = () => window.open(`https://www.google.com/search?q=${term}`, "_blank")

                const addToWebPageSearch = this.$refs.addToWebPageSearch as HTMLElement
                if (addToWebPageSearch) {
                    addToWebPageSearch.onclick = () => this.addToWebPageSearch(term)
                }

                const createObservation = this.$refs.createObservation as HTMLElement
                if (createObservation) {
                    createObservation.onclick = () => this.createObservation()
                }

                const enrich = this.$refs.enrich as HTMLElement
                if (enrich) {
                    enrich.onclick = () => this.harvestPerson(term)
                }

                const addActiveAuthorElem = this.$refs.addActiveAuthor as HTMLElement
                if (addActiveAuthorElem) {
                    addActiveAuthorElem.onclick = () => this.addActiveAuthor(term, value)
                }

                const disambiguate = this.$refs.disambiguate as HTMLElement
                if (disambiguate) {
                    disambiguate.onclick = () => this.disambiguatePerson(term, value)
                }

                const removeOrganization = this.$refs.removeOrganization as HTMLElement
                if (removeOrganization) {
                    removeOrganization.onclick = () => this.removeOrganization(term)
                }

                const removeTerm = this.$refs.removeTerm as HTMLElement
                if (removeTerm) {
                    removeTerm.onclick = () => this.removeTerm(term)
                }

                const removeLocation = this.$refs.removeLocation as HTMLElement
                if (removeLocation) {
                    removeLocation.onclick = () => this.removeLocation(term)
                }
            }, 0)
        })
    }

    private async addToWebPageSearch(domain: string) {
        const configuration = await configurationService.getConfiguration(this.investigation!.id)
        const sites = configuration.searchDefinition.webHarvestFromSites

        if (sites.some(it => it.includes(domain))) {
            return
        }

        sites.push(domain)

        await configurationService.setConfiguration(this.investigation!.id, configuration)
    }

    private createObservation() {
        openCreateObservationDialog(this, {
            type: "Person",
            referencedEntity: this.value!!.toString()
        })
    }

    private harvestPerson(title: string) {
        const investigation = getInvestigation(this.$store)!
        TopicInvestigationService.createPersonHarvests(investigation.id, [title])
    }

    private addActiveAuthor(title: string, id: string) {
        addActiveAuthor(this.$store, {activeAuthorId: id, activeAuthorName: title})
    }

    private disambiguatePerson(title: string, id: string) {
        const investigation = getInvestigation(this.$store)!
        setDisambiguatePersonName(this.$store, title)
        setDisambiguationPersonId(this.$store, id.toString())
        saveInvestigationState(this.$store)
        this.$router.push({path: "disambiguate"})
        fetchAllPersonData(this.$store)
    }

    private removeOrganization(name: string) {
        this.removeEntity(name, "ORGANIZATION", "Organization")
    }


    private removeTerm(name: string) {
        this.removeEntity(name, "TERM", "Term")
    }


    private removeLocation(name: string) {
        this.removeEntity(name, "LOCATION", "Location")
    }


    private removeEntity(name: string, entityType: MentionedEntityType, entityDescription: string) {
        const performRemoveEntity = async () => {
            const investigation = getInvestigation(this.$store)!!
            await investigationEntityService.removeEntities(investigation.id, { [name]: entityType })
            getGraphType(this.$store) === null ? await fetchAllData(this.$store) : await fetchGraphData(this.$store)
        }

        openConfirmationDialog({
            message: `You are about to remove the ${entityDescription.toLowerCase()} '${name}'. Are you sure?`,
            title: `Remove ${entityDescription}`,
            okText: "Remove",
            onOk: () => performRemoveEntity(),
            showCancel: true
        })
    }


    private openLabelMenu(button: HTMLElement, label: string) {
        this.labelContext = label
        this.$nextTick(() => this.$emit("openLabelMenu", button))
    }

    private copyLabelToClipboard() {
        copyToClipboard(this.labelContext)
        this.$emit("closeLabelMenu")
    }

    private renameLabel() {
        if (this.labelContext !== "") {
            // @ts-ignore
            (this.$refs.renameLabelDialog as RenameLabelDialog).open({
                label: this.labelContext,
                investigationType: this.investigationType,
                labelType: "Document"
            })
            this.$emit("closeLabelMenu")
        }
    }

    private deleteLabel() {
        if (this.labelContext !== "") {
            openConfirmationDialog({
                message: `Are you sure you want to delete label "${this.labelContext}"?<br>This label will be removed from any document it was associated with.`,
                title: "Delete Label",
                okText: "Delete Label",
                onOk: () => this.performDeleteLabel(),
                showCancel: true
            })
            this.$emit("closeLabelMenu")
        }
    }

    private bulkApplyLabel(add: boolean) {
        if (this.labelContext !== "") {
            openConfirmationDialog({
                message: `Are you sure you want to ${add ? "add" : "remove"} label "${this.labelContext}" ${add ? "to" : "from"} the current set of documents?`,
                title: `${add ? "Add" : "Remove"} Label`,
                okText: "Apply",
                onOk: () => this.performBulkApplyLabel(add),
                showCancel: true
            })
            this.$emit("closeLabelMenu")
        }
    }

    private async refreshResults() {
        await fetchAllData(this.$store)
        this.$emit("documentsUpdated")
    }

    private async performBulkApplyLabel(add: boolean) {
        const label = this.labelContext!!
        const {investigationType} = this
        await bulkApplyDocumentLabel(this.$store, {label, add, investigationType})
        await this.refreshResults()
    }

    private async performDeleteLabel() {
        const label = this.labelContext!!
        const {investigationType} = this
        await deleteDocumentLabel(this.$store, {label, investigationType})
        this.closeFilter(label)
    }

    private closeFilter(label: string) {
        const newSelectedTops = getSelectedTops(this.$store).filter(activeFilter => activeFilter.selectedValue !== label)
        setSelectedTops(this.$store, newSelectedTops)
        this.$emit("closeLabelsMenu")
        this.$parent.$emit("refresh-documents")
    }

    private graphTitle(graph: BarGraphModel): string {
        if (this.isInPersonProfile) {
            if (graph.title === "Classifications") {
                return "Publication Types"
            }
            if (graph.title === "Authors") {
                return "Co-Authors"
            }
            if (graph.title === "Institutes of Authors") {
                return "Institutes"
            }
            if (graph.title === "Countries of Author Institutes") {
                return "Countries"
            }
        }
        return graph.title
    }
}
