


















































import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import {DocumentEntity, Relevancy} from "@/types/investigations"
import {Prop} from "vue-property-decorator"
import {
    removeDocumentLabel,
    updateDocument,
    updateFullTextFile,
    updateDocumentCoverImage,
    updateDocumentAbstractImage,
    setDocumentStatus
} from "@/store/investigation/documents-module"
import {isReportEnabled, isShowingDocumentLabels} from "@/store/environment-module"
import {getObservations} from "@/store/investigation/observation-module"
import {openEditDocumentDialog} from "@/views/investigation/dialogs/EditDocumentDialog.vue"
import {PermissionsMixin} from "@/store/user-mixin"
import {InvestigationMixin} from "@/store/investigation-mixin"
import {fetchTopLabels, getMatchingResearcherIds, getBWJournals} from "@/store/investigation/investigation-module"
import InvestigationService from "@/api/investigation-service"

@Component
export default class DocumentItemHeader extends mixins(Vue, PermissionsMixin, InvestigationMixin) {

    @Prop()
    private document!: DocumentEntity

    @Prop()
    private isEditable!: boolean


    get showDocumentLabels(): boolean {
        return isShowingDocumentLabels(this.$store)
    }

    private get byResearchersLabel() {
        const hasResearchers = Object.keys(getMatchingResearcherIds(this.$store)).length > 0
        const hasJournals = getBWJournals(this.$store).size > 0
        if (!hasResearchers && !hasJournals) {
            return
        }
        switch (this.document.byTheResearchers) {
            case null: return "Check"
            case true: return "Include"
            case false: return "Exclude"
        }
    }

    private get documentLabels() {
        const labels = [...this.document.labels]
        labels.sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()))
        return labels
    }

    private get classification(): string {
        return this.document.classification
    }

    private get originalLanguage(): string | undefined {
        if (this.document.originalLanguage === "English") {
            return undefined
        }
        return this.document.originalLanguage
    }

    private async deleteLabel(label: string) {
        await removeDocumentLabel(this.$store, {label, document: this.document, investigationType: this.investigationType})
        await fetchTopLabels(this.$store)
    }

    private get isReportEnabled(): boolean {
        return isReportEnabled(this.$store)
    }

    private openLabelsMenu() {
        if (this.isEditable) {
            const labelsMenuOpenerButton = this.$refs.labelsMenuOpener as HTMLElement
            this.$emit("openLabelsMenu", labelsMenuOpenerButton)
        }
    }

    private labelButtonTitle(labels: string[]) {
        return labels.length > 0 ? "Add / Edit Labels: " + labels.join(`, `) : "Add Label"
    }

    private starredChanged() {
        if (this.isEditable) {
            const value = !this.document.starred
            this.$emit("starred-changed", value)
            setDocumentStatus(this.$store, {id: this.document.id, touched: true, starred: value})
        }
    }

    private relevancyChanged(relevancyClicked: Relevancy) {
        if (this.isEditable) {
            this.$emit("relevancy-changed", this.document.relevancy !== relevancyClicked ? relevancyClicked : "Neutral")
        }
    }

    private hasObservation(document: DocumentEntity) {
        const observations = getObservations(this.$store)
        return observations.some(observation => observation.referencedObject === this.document.id)
    }

    private openObservationDialog() {
        if (this.isEditable) {
            this.$emit("create-observation", this.document.id)
        }
    }

    private editDocument() {
        openEditDocumentDialog({
            doc: this.document,
            callback: async (documentType, overwrites, fullTextFile,
                             coverImage: { modified: boolean, file: File | null, journalImage: boolean },
                             abstractImage: { modified: boolean, file: File | null }
            ) => {
                if (overwrites) {
                    await updateDocument(this.$store, {id: this.document.id, overwrites})
                }
                if (coverImage.modified) {
                    await updateDocumentCoverImage(this.$store,
                        {id: this.document.id, file: coverImage.file, journalImage: coverImage.journalImage})
                }
                if (abstractImage.modified) {
                    await updateDocumentAbstractImage(this.$store,
                        {id: this.document.id, file: abstractImage.file})
                }
                if (fullTextFile) {
                    await updateFullTextFile(this.$store, {id: this.document.id, file: fullTextFile})
                }
            }
        })
    }

    private reHarvestDocument(document: DocumentEntity) {
        InvestigationService.createTopicHarvest(
            this.investigation!.id, this.investigationType, document.title.text, false, false)
    }

    private reHarvestDocumentByUrl(document: DocumentEntity) {
        InvestigationService.createTopicHarvest(this.investigation!.id, this.investigationType, document.url,
            true, false, true)
    }

    private classificationClassName(classification: string) {
        if (!classification) {
            return ""
        }
        return "classification-" + classification
            .replace("(Unclassified)", "unclassified")
            .replace(/[()]/g, "")
            .replace(/\s/g, "-")
            .toLowerCase()
    }

    private toggleAcceptedLabel() {
        this.$emit("toggleAcceptedLabel")
        setDocumentStatus(this.$store, {id: this.document.id, touched: true})
    }

    private toggleFixLabel() {
        this.$emit("toggleLabel", "fix")
        setDocumentStatus(this.$store, {id: this.document.id, touched: true})
    }
}
