











































import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import {Prop, Watch} from "vue-property-decorator"
import investigationService, {ImageCropData} from "@/api/investigation-service"
import {getInvestigation, preprocessPersonImage} from "@/store/investigation/investigation-module"
import {InvestigationPersonDetails} from "@/types/investigations"
import GenericDialogNew, {createDialogMountingPoint} from "@/components/GenericDialogNew.vue"
import UploadImagePane from "@/components/UploadImagePane.vue"
import {PermissionsMixin} from "@/store/user-mixin"

export function openAuthorDetailsDialog(parent: Vue, options: AuthorDetailsDialogOptions) {
    new (Vue.extend(AuthorDetailsDialog))({parent, store: parent.$store, router: parent.$router, propsData: {options}}).$mount(createDialogMountingPoint())
}

export interface AuthorDetailsDialogOptions {
    readonly personId: string,
    readonly personName: string,
    readonly onUpdateCallback: (details: InvestigationPersonDetails,
                                imageModified: boolean,
                                imageCropData?: ImageCropData,
                                imageUrl?: string) => void
}

@Component({
    components: {UploadImagePane, GenericDialogNew}
})
export default class AuthorDetailsDialog extends mixins(Vue, PermissionsMixin) {

    @Prop()
    private options!: AuthorDetailsDialogOptions

    private universityProfileUrl?: string = ""
    private imageId: string | null = null
    private imageCropData: ImageCropData | null = null
    private imageModified: boolean = false
    private personName: string = ""
    private originalName: string = ""
    private imageUrl: string | null = null

    public async mounted() {
        const investigation = getInvestigation(this.$store)!!
        const details = await investigationService.getPersonDetails(investigation.id, investigation.investigationType, this.options.personId)
        this.universityProfileUrl = details.universityProfileUrl
        this.imageId = details.authorImageId ? details.authorImageId : null
        this.personName = this.originalName = details.name

        if (this.isOmnipotentUser) {
            Vue.nextTick(() => {
                const universityProfileUrl = this.$refs.universityProfileUrl as HTMLInputElement
                universityProfileUrl.focus()
            })
        }
    }

    private pasteImageUrl(e: ClipboardEvent) {
        if (e.clipboardData !== null) {
            this.imageUrl = e.clipboardData.getData("text/plain")
            this.imageModified = true
        }
    }

    private close() {
        (this.$refs.genericDialog as GenericDialogNew).close()
    }

    private async preprocess() {
        await preprocessPersonImage(this.$store, {
            personId: this.options.personId,
            imageData: this.imageCropData,
            imageUrl: this.imageUrl
        }).then(base64 => {
            this.imageUrl = base64
        })
    }

    private async ok() {
        const details: InvestigationPersonDetails = {
            id: this.options.personId,
            name: this.personName,
            universityProfileUrl: this.universityProfileUrl,
            authorImageId: this.imageId as string | undefined
        }

        this.close()
        this.options.onUpdateCallback(details, this.imageModified,
            this.imageCropData || undefined, this.imageUrl || undefined)
    }

    @Watch("imageCropData")
    private imageFileModified() {
        this.imageModified = true
    }
}
