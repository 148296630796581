


























import Vue from "vue"
import Component from "vue-class-component"
import {
    fetchAllData,
    fetchGraphData,
    getDisplayedResultsStatus,
    getInvestigation,
    setDisplayedResultsStatus
} from "@/store/investigation/investigation-module"
import {getGraphType} from "@/store/investigation/graph-module"
import {DisplayedResultsStatus} from "@/types/investigations"
import {getLabelText} from "@/components/filter/DocumentLabelFilterUtil"
import {bulkMarkRead, getTopsExpansion, setTopExpanded} from "@/store/investigation/documents-module"
import PopupMenu from "@/components/PopupMenu.vue"

interface DocumentLabel {
    readonly id: DisplayedResultsStatus,
    readonly caption: string
}


@Component({
    components: {PopupMenu}
})
export default class DocumentDisplayedResultsFilter extends Vue {

    private get expanded(): boolean {
        return getTopsExpansion(this.$store)["predefined.DocumentLabel"]
    }

    private set expanded(isExpanded: boolean) {
        setTopExpanded(this.$store, {type: "predefined.DocumentLabel", isExpanded})
    }

    private openContextMenu(button: HTMLElement) {
        this.$nextTick(() => this.$emit("openContextMenu", button))
    }

    private get labels(): string[] {
        const personInvestigationLabels = ["Unread", "Annotated"]
        const topicInvestigationLabels = personInvestigationLabels.concat("Relevant", "Irrelevant")
        return this.isInTopicInvestigation ? topicInvestigationLabels : personInvestigationLabels
    }

    private get isInTopicInvestigation() {
        return getInvestigation(this.$store)!.investigationType === "Topic"
    }

    private get selectedLabel(): DisplayedResultsStatus {
        return getDisplayedResultsStatus(this.$store)
    }

    private isSelected(label: string): boolean {
        return this.selectedLabel === label
    }

    private async selectLabel(label: string) {
        const isDeselectingOption = this.selectedLabel === label
        const newLabel: DisplayedResultsStatus = isDeselectingOption ? "All" : label as DisplayedResultsStatus
        setDisplayedResultsStatus(this.$store, newLabel)
        getGraphType(this.$store) === null ? await this.$parent.$emit("refresh-documents") : await fetchGraphData(this.$store)
    }

    private getLabelCaption(label: DisplayedResultsStatus): string {
        return getLabelText(label)
    }

    private async markCurrentAsRead() {
        const investigation = getInvestigation(this.$store)!!
        await bulkMarkRead(this.$store, investigation?.investigationType)
        await fetchAllData(this.$store)
    }
}
