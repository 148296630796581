






































































































































































































































import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import GenericDialog from "@/components/GenericDialog.vue"
import {
    fetchAllData,
    fetchGraphData,
    fetchTopicInvestigationExport,
    getInvestigation
} from "@/store/investigation/investigation-module"
import {InvestigationConfigurationEntity, InvestigationConfigurationTopStatus} from "@/types/investigations"
import {configurationService} from "@/api/investigation-configuration-service"
import {getGraphType} from "@/store/investigation/graph-module"
import {isShowingHebrewTutorials, isShowingTranslations} from "@/store/environment-module"
import {PermissionsMixin} from "@/store/user-mixin"
import moment from 'moment'
import {downloadBlob} from "@/utils/utils"

@Component({
    components: {GenericDialog}
})
export default class InvestigationConfigurationDialog extends mixins(Vue, PermissionsMixin) {
    private isOpen = false
    private pages: string[] = []
    private currentPage: string = ""
    private saving: boolean = false
    private errorMessage: string | null = null

    private configuration!: InvestigationConfigurationEntity
    private includeWebPages: boolean = false
    private overrideDefaultMaxResultsPerWebHarvest: boolean = false
    private maxResultsPerWebHarvest: string = "20"
    private webHarvestFromSites: string = ""
    private includeArticles: boolean = false
    private overrideDefaultMaxResultsPerArticleHarvest: boolean = false
    private maxResultsPerArticleHarvest: string = "20"
    private articleHarvestFromInstitutions: string = ""
    private topsStatuses: InvestigationConfigurationTopStatus[] = []
    private originalTopsOrder = ""
    private researchers: string = ""
    private overrideDocumentNumberLimit: boolean = false
    private documentNumberLimit: number = 0
    private whitelistJournals: string = ""
    private blacklistJournals: string = ""
    private activeAffiliations: string = ""
    private activeAuthors: string = ""

    private languageCheckboxes: { [key: string]: boolean } = {}
    private languages = [
        "Afrikaans", "Arabic", "Bulgarian", "Chinese (Simplified)", "Chinese (Traditional)", "Croatian", "Czech", "Danish", "Dutch",
        "Estonian", "Finnish", "French", "German", "Greek", "Hebrew", "Hindi", "Hungarian", "Indonesian", "Italian", "Japanese",
        "Korean", "Latvian", "Norwegian", "Persian", "Polish", "Portuguese", "Romanian", "Russian", "Serbian", "Slovak", "Slovenian",
        "Spanish", "Swahili", "Swedish", "Thai", "Turkish", "Ukrainian", "Vietnamese"
    ]
    private dictionaryFilter = ""
    private languageFilter = ""
    private startDateCutoff: string | null = null
    private startDateCutoffEnabled: boolean = true

    get showTranslations(): boolean {
        return isShowingTranslations(this.$store)
    }

    private get predefinedTops() {
        const investigation = getInvestigation(this.$store)!!
        const isPersonInvestigation = investigation.investigationType === "Person"

        return this.topsStatuses
            .filter(top => !top.dictionary)
            .filter(top => {
                if (isPersonInvestigation) {
                    return top.id !== "5a3bf2fc-4a07-4211-a9c3-216545a23626" && // languages
                        top.id !== "63ee561f-87bc-4a4b-9079-a4a1e441fd70" && // emails
                        top.id !== "ae458f09-481c-42dd-bbc4-4976c8d44027" && // mentioned persons
                        top.id !== "a6ee0028-5772-4c4f-8461-a9cb056157a8" && // mentioned organization
                        top.id !== "be92e384-011c-3731-a3f7-eab17ec40a59" && // mentioned locations
                        top.id !== "6ff1be47-734b-34ec-843f-ca4d1c27d858"    // mentioned concepts
                }

                return top.id !== "6ff1be47-734b-34ec-843f-ca4d1c27d858" // mentioned concepts
            })
    }

    private get dictionaries() {
        return this.topsStatuses.filter(top => top.dictionary)
    }

    private get sortedDictionaries() {
        return this.dictionaries
            .sort((a, b) => a.displayName.localeCompare(b.displayName))
    }

    private get sortedLanguages() {
        const showHebrew = isShowingHebrewTutorials(this.$store)

        return this.languages
            .filter(it => showHebrew ? true : it !== "Hebrew")
            .sort((a, b) => a.localeCompare(b))
    }

    private get selectedLanguages() {
        return this.languages.filter(language => this.languageCheckboxes[language])
    }

    private set selectedLanguages(languages: string[]) {
        const selectedLanguages = this.selectedLanguages
        selectedLanguages.forEach(selectedLanguage => this.languageCheckboxes[selectedLanguage] = false)
        languages.forEach(language => this.languageCheckboxes[language] = true)
    }

    private get selectedDictionaries() {
        return this.dictionaries.filter(it => it.enabled)
    }

    public async open() {
        this.dictionaryFilter = ""
        this.languageFilter = ""

        document.addEventListener("keyup", this.handleKey)

        const investigation = getInvestigation(this.$store)!!
        if (investigation.investigationType === "Topic") {
            this.pages = ["Search Settings", "Web Page Search", "Institutes Search", "Filters", "Dictionaries"]
            if (this.showTranslations) {
                this.pages.push("Languages")
            }
        } else if (investigation.investigationType === "Person") {
            this.pages = ["Search Settings", "Filters", "Dictionaries", "Researchers"]
            if (this.isFacultyWorksUser) {
                this.pages.push("Active Authors")
                this.pages.push("B&W Journals")
            }
            if (this.isOmnipotentUser) {
                this.pages.push("Performance")
            }
        }
        this.currentPage = this.pages[0]

        this.configuration = await configurationService.getConfiguration(investigation.id)
        this.includeWebPages = this.configuration.searchDefinition.includeWebPages
        this.overrideDefaultMaxResultsPerWebHarvest = this.configuration.searchDefinition.overrideDefaultMaxResultsPerWebHarvest
        this.maxResultsPerWebHarvest = this.configuration.searchDefinition.maxResultsPerWebHarvest.toString()
        this.webHarvestFromSites = this.configuration.searchDefinition.webHarvestFromSites.join("\n")
        this.researchers = this.configuration.researchers.join("\n")
        this.whitelistJournals = this.configuration.journals?.whitelist.join("\n")
        this.blacklistJournals = this.configuration.journals?.blacklist.join("\n")

        this.activeAffiliations = this.configuration.activeAffiliations?.join("\n") || ""
        this.activeAuthors = this.configuration.activeAuthors?.join("\n") || ""
        this.overrideDocumentNumberLimit = this.configuration.overrideDocumentNumberLimit
        this.documentNumberLimit = this.configuration.documentNumberLimit
        if (this.configuration.startDateCutoff) {
            this.startDateCutoff = moment(this.configuration.startDateCutoff).format("yyyy-MM-DD")
        }
        this.startDateCutoffEnabled = this.configuration.startDateCutoffEnabled
        this.includeArticles = this.configuration.searchDefinition.includeArticles
        this.overrideDefaultMaxResultsPerArticleHarvest = this.configuration.searchDefinition.overrideDefaultMaxResultsPerArticleHarvest
        this.maxResultsPerArticleHarvest = this.configuration.searchDefinition.maxResultsPerArticleHarvest.toString()
        this.articleHarvestFromInstitutions = this.configuration.searchDefinition.articleHarvestFromInstitutions.join("\n")
        this.topsStatuses = this.configuration.topsStatuses
        this.originalTopsOrder = this.collectEnabledTops()
        this.languages.forEach(language => this.$set(this.languageCheckboxes, language, false))
        this.selectedLanguages = this.configuration.translation ? this.configuration.translation.selectedLanguages : []
        this.errorMessage = ""
        this.isOpen = true
    }

    private unselectLanguage(language: string) {
        this.languageCheckboxes[language] = false
    }

    private shouldDisplayLanguage(language: string) {
        return !this.languageFilter.length || language.toLowerCase().includes(this.languageFilter.toLowerCase())
    }

    private shouldDisplayDictionary(dictionary: InvestigationConfigurationTopStatus) {
        return !this.dictionaryFilter.length || dictionary.displayName.toLowerCase().includes(this.dictionaryFilter.toLowerCase())
    }

    private collectEnabledTops(): string {
        return this.topsStatuses.filter(status => status.enabled).map(status => status.id).join(",")
    }

    private close() {
        this.isOpen = false
        document.removeEventListener("keyup", this.handleKey)
    }

    private handleKey(e: KeyboardEvent) {
        switch (e.key) {
            case "Escape":
                this.close()
                break
        }
    }

    // noinspection JSMethodCanBeStatic
    private isDateValid(date: string | null): boolean {
        return date == null || date.trim().length === 0 || moment(date).isValid()
    }

    private validate(): boolean {
        this.errorMessage = null

        if (!this.includeWebPages && !this.includeArticles) {
            this.errorMessage = "Web page and articles must not be excluded together"
            return false
        }

        const maxResultsPerWebHarvest = Number.parseFloat(this.maxResultsPerWebHarvest)
        const maxResultsPerArticleHarvest = Number.parseFloat(this.maxResultsPerArticleHarvest)

        if (!Number.isInteger(maxResultsPerWebHarvest) || maxResultsPerWebHarvest < 1 || maxResultsPerWebHarvest > 200) {
            this.errorMessage = "Max results for web harvests must be a whole number between 0 and 200"
            return false
        }

        if (!Number.isInteger(maxResultsPerArticleHarvest) || maxResultsPerArticleHarvest < 1 || maxResultsPerArticleHarvest > 200) {
            this.errorMessage = "Max results for article harvests must be a whole number between 0 and 200"
            return false
        }

        if (this.selectedLanguages.length > 2) {
            this.errorMessage = "Maximum of two languages allowed"
            return false
        }

        const MAX_RESEARCHERS = 300
        const researcherCount = this.researchers.split(/\r?\n/).length
        if (researcherCount > MAX_RESEARCHERS) {
            this.errorMessage = `No more than ${MAX_RESEARCHERS} researchers are allowed`
            return false
        }

        if (!this.isDateValid(this.startDateCutoff)) {
            this.errorMessage = "\"Starting from\" date in the Researchers tab is invalid"
            return false
        }

        return true
    }

    // noinspection JSMethodCanBeStatic
    private splitToList(str: string | null): string[] {
        if (!str) {
            return []
        }
        return str.split(/\r?\n/).map(x => x.trim()).filter(x => x.length > 0)
    }

    // noinspection JSMethodCanBeStatic
    private async downloadDictionary(dictionary: InvestigationConfigurationTopStatus) {
        const blob = await configurationService.fetchEntireDictionary(dictionary.id)
        const fileName = `${dictionary.displayName}.csv`
        downloadBlob(blob, fileName)
    }

    private async save() {
        if (!this.validate()) {
            return
        }

        this.saving = true
        const investigation = getInvestigation(this.$store)!!

        const configuration: InvestigationConfigurationEntity = {
            ...this.configuration,
            searchDefinition: {
                includeWebPages: this.includeWebPages,
                overrideDefaultMaxResultsPerWebHarvest: this.overrideDefaultMaxResultsPerWebHarvest,
                maxResultsPerWebHarvest: Number(this.maxResultsPerWebHarvest),
                webHarvestFromSites: this.splitToList(this.webHarvestFromSites),
                includeArticles: this.includeArticles,
                overrideDefaultMaxResultsPerArticleHarvest: this.overrideDefaultMaxResultsPerArticleHarvest,
                maxResultsPerArticleHarvest: Number(this.maxResultsPerArticleHarvest),
                articleHarvestFromInstitutions: this.splitToList(this.articleHarvestFromInstitutions)
            },
            topsStatuses: this.topsStatuses,
            translation: {
                selectedLanguages: this.selectedLanguages
            },
            researchers: this.splitToList(this.researchers),
            journals: {
                whitelist: this.splitToList(this.whitelistJournals),
                blacklist: this.splitToList(this.blacklistJournals)
            },
            activeAffiliations: this.splitToList(this.activeAffiliations),
            activeAuthors: this.splitToList(this.activeAuthors),
            overrideDocumentNumberLimit: this.overrideDocumentNumberLimit,
            documentNumberLimit: this.documentNumberLimit,
            startDateCutoff: moment(this.startDateCutoff),
            startDateCutoffEnabled: this.startDateCutoffEnabled
        }

        this.errorMessage = await configurationService.setConfiguration(investigation.id, configuration)
        this.saving = false
        if (!this.errorMessage) {
            this.close()

            if (this.originalTopsOrder !== this.collectEnabledTops()) {
                getGraphType(this.$store) === null ? await fetchAllData(this.$store) : await fetchGraphData(this.$store)
            }
        }
    }
}
