
















import Vue from "vue"
import Component, {mixins} from "vue-class-component"
import {getDateFilterEnd, getDateFilterStart} from "@/store/investigation/date-filter-module"
import {getDisplayedResultsStatus, getSelectedTops} from "@/store/investigation/investigation-module"
import {getSuggestedTerms} from "@/store/investigation/investigation-terms-module"
import {Prop} from "vue-property-decorator"
import {InvestigationMixin} from "@/store/investigation-mixin"

@Component
export default class NoResults extends mixins(Vue, InvestigationMixin) {

    @Prop({type: Boolean, default: false})
    private showingSuggestedTerms!: boolean

    private get hasFilter() {
        const suggestedTerms = this.showingSuggestedTerms && this.hasSuggestedTerms
        const activeFilters = getSelectedTops(this.$store)
        const hasDateFilter = getDateFilterStart(this.$store) != null || getDateFilterEnd(this.$store) != null
        const hasTopFilter = activeFilters.length > 0
        const hasDisplayStatusFilter = getDisplayedResultsStatus(this.$store) !== "All"
        return suggestedTerms || hasDateFilter || hasTopFilter || hasDisplayStatusFilter
    }

    private get hasSuggestedTerms(): boolean {
        return getSuggestedTerms(this.$store).length !== 0
    }
}
