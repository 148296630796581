
function calculateNodeOffset(element: HTMLElement, selection: Selection) {
    let offsetSum = 0
    const iter = document.createNodeIterator(element, NodeFilter.SHOW_TEXT)

    let textNode
    let found = false
    const range = selection.getRangeAt(0)
    const startContainer = range.startContainer
    do {
        textNode = iter.nextNode()
        if (textNode) {
            if (startContainer !== textNode) {
                offsetSum += textNode.textContent!.length
            } else {
                found = true
            }
        }
    } while (!found && textNode)
    return offsetSum
}


function calculateSentenceStartIndexAndLength(text: string, needleIndex: number, selectionLength: number) {
    const endSentenceSigns = [". ", "… ", "! ", "? ", "| ", ".\n\n", "…\n\n", "!\n\n", "?\n\n", "|\n\n"]
    const startIndexes: number[] = []
    const endIndexes: number[] = []
    endSentenceSigns.forEach(sign => {
        const currentStartIndex = text.substr(0, needleIndex).lastIndexOf(sign) + 1
        const currentEndIndex = text.indexOf(sign, needleIndex + selectionLength + 8) > -1 ? text.indexOf(sign, needleIndex + selectionLength + 8) : text.length
        startIndexes.push(currentStartIndex)
        endIndexes.push(currentEndIndex)
    })
    const startIndex = Math.max(...startIndexes)
    const endIndex = Math.min(...endIndexes)
    const length = endIndex - startIndex
    return {startIndex, length}
}


export const getSentence = function(element: HTMLElement, selection: Selection) {
    if (selection) {
        const text = element.innerText
        const selectionNodeStart = calculateNodeOffset(element, selection)
        const needleIndex = selectionNodeStart + selection.anchorOffset
        const selectionLength = selection.getRangeAt(0).toString().length
        const boldedText = text.substr(0, needleIndex) +
            "<strong>" + text.substr(needleIndex, selectionLength) + "</strong>" +
            text.substr(needleIndex + selectionLength)
        const {startIndex, length} = calculateSentenceStartIndexAndLength(boldedText, needleIndex, selectionLength)
        return boldedText.substr(startIndex, length).trim()
    }
}


