import {
    AuthorGraphSettings,
    BibliographicDetails, BWJournal,
    DateHistogram,
    DisplayedResultsStatus,
    DocumentEntity,
    DocumentMarkupsEntity,
    GenerateHtmlResponse,
    GraphEntity,
    GraphRequest,
    GraphType,
    InvestigationDataEntity,
    InvestigationEntity,
    InvestigationGraphDataEntity,
    InvestigationRequestSpec,
    InvestigationTermEntity,
    InvestigationTimelineDataEntity,
    InvestigationType, MatchingResearcherIds,
    MentionedEntity,
    PersonEntity,
    PersonInvestigationDataEntity,
    PersonRelevantCounts,
    RecentAffiliation,
    RelevancyStatusEntity,
    ScoredAffiliationEntity,
    ScoredAuthorEntity,
    ScoredNameEntity,
    ScoredRegionEntity,
    SelectedTop,
    TimelineChartData,
    TimelineRequest,
    TopicInvestigationDataEntity,
    TopLexemeEntity,
    Tops,
    UnifiedGraphSettings
} from "@/types/investigations"
import {RootState} from "@/store"
import investigationService, {ImageCropData} from "../../api/investigation-service"
import topicInvestigationService from "../../api/topic-investigation-service"
import personInvestigationService from "../../api/person-investigation-service"
import investigationsService from "../../api/investigations-service"
import {ActionContext, Store} from "vuex"
import {getStoreAccessors} from "vuex-typescript"
import {getInvestigationId} from "@/store/investigation/get-investigation-id"
import terms, {
    getSuggestedTerms,
    getTerms,
    setSuggestedTerms,
    setTermGroups,
    setTerms,
    SuggestedTerm,
    TermsState
} from "./investigation-terms-module"
import documents, {
    clearGroupsExpansion,
    clearTopsExpansion,
    clearTopsSorting,
    getDocuments,
    getGroupsExpansion,
    getHighlightModes,
    getTopsExpansion,
    getTopsSorting,
    setDocumentMarkups,
    setDocuments,
    setGroupsExpansion,
    setHighlightModes,
    setTopExpanded,
    setTopSorting
} from "./documents-module"
import graph, {getGraphType, setExternalSeries, setGraph, setGraphType, setTimeline} from "./graph-module"
import dateFilterModule, {
    DateFilterState,
    getDateFilterEnd,
    getDateFilterStart,
    setDateFilterYears,
    setDateHistogram
} from "./date-filter-module"
import personFilterModule, {
    getDisambiguationPersonId,
    getPersonId,
    PersonFilterState,
    setPersonId
} from "./person-filter-module"
import termPopupMenu, {TermPopupMenuData} from "./dialogs/term-popup-menu-module"
import noteDialog, {NoteDialogState} from "./dialogs/note-dialog-module"
import {Optional} from "@/types"
import {EnvironmentState, getDocumentsPageSize} from "@/store/environment-module"
import {wrapActiveRequests} from "@/utils/utils"
import axios, {CancelTokenSource} from "axios"
import observations, {
    clearSectionsExpansion,
    getObservations,
    ObservationState,
    setObservations,
    setObservationSections
} from "@/store/investigation/observation-module"
import InvestigationService from "@/api/investigation-service"
import {AuthState, isFacultyWorksUser} from "@/store/auth-module"
import {Moment} from "moment"
import {configurationService} from "@/api/investigation-configuration-service"


export interface InvestigationState extends TermsState, DateFilterState, PersonFilterState, NoteDialogState, ObservationState {
    relevantDocumentCount: number
    totalDocumentCount: number
    totalReadDocuments: number
    totalStarredDocuments: number
    investigation: InvestigationEntity | null
    documents: DocumentEntity[]
    availableDocumentIds: string[]
    currentPage: number
    documentMarkups: DocumentMarkupsEntity[]
    graph: GraphEntity
    graphType: GraphType
    timeline: TimelineChartData
    externalSeries: string[]
    viewModeLevel: number
    highlightModes: string[]
    topsExpansion: {[key: string]: boolean}
    topsSorting: {[key: string]: boolean}
    groupsExpansion: {[key: string]: boolean}
    relevancyStatus: RelevancyStatusEntity
    activityStatus: number
    activeRequests: number
    loadingInvestigation: boolean
    renderingGraph: boolean
    tops: Tops | null
    persons: PersonEntity[]
    documentsSortBy: string
    personForRightPaneSummary: PersonEntity | null
    entityRelationshipForSummary: MentionedEntity[] | null
    entityForSummary: MentionedEntity | null
    showAuthorGraphLegend: boolean
    authorGraphSettings: AuthorGraphSettings
    unifiedGraphSettings: UnifiedGraphSettings
    selectedNodes: string[]
    shortestPath: string[]
    termPopupMenuData: Optional<TermPopupMenuData>
    displayedResultsStatus: DisplayedResultsStatus
    selectedDocumentClassifications: string[]
    freeTextFilter: string | null
    selectedTops: SelectedTop[]
    includeOnlyUnlabeled: boolean
    isInProfile: boolean
    isInReviewProfile: boolean
    isInOverview: boolean
    isInDisambiguate: boolean
    recentAffiliations: RecentAffiliation[]
    bibliographicDetails: BibliographicDetails
    disambiguatePersonName: string | null
    cancelToken: CancelTokenSource
    documentLabels: string[]
    entityLabels: string[]
    personRelevantCounts: PersonRelevantCounts
    matchingResearcherIds: MatchingResearcherIds
    bwJournals: Map<string, string>
    activeAuthorIds: string[]
    filterByResearchers: boolean
    filterToDuplicates: boolean
    filterToUnaffiliated: boolean
    filterToUnknownPublishers: boolean
    filterToMissingVolume: boolean
    filterToMissingDate: boolean
    filterToSpecificUrls: string[]
}


const module = {
    namespaced: true,

    state(): InvestigationState {
        const savedViewLevelMode = localStorage.getItem("viewModeLevel")

        return {
            relevantDocumentCount: 0,
            totalDocumentCount: 0,
            totalReadDocuments: 0,
            totalStarredDocuments: 0,
            investigation: null,
            documents: [],
            currentPage: 0,
            availableDocumentIds: [],
            documentMarkups: [],
            graph: {nodes: [], edges: []},
            graphType: null,
            timeline: new TimelineChartData({dataItems: [], columns: []}, [], [], null),
            externalSeries: [],
            viewModeLevel: savedViewLevelMode ? parseInt(savedViewLevelMode, 10) : 1,
            highlightModes: ["Highlight Search Terms", "Highlight Mentioned Entities"],
            observationViewModeLevel: 1,
            topsExpansion: {},
            topsSorting: {},
            groupsExpansion: {},
            sectionsExpansion: {},
            relevancyStatus: {relevancyScore: 0, differentEnough: false},
            activityStatus: 0,
            dateHistogram: new DateHistogram(),
            dateFilterStart: null,
            dateFilterEnd: null,
            activeRequests: 0,
            loadingInvestigation: true,
            renderingGraph: false,
            tops: null,
            persons: [],
            documentsSortBy: "relevance",
            personForRightPaneSummary: null,
            entityForSummary: null,
            entityRelationshipForSummary: null,
            showAuthorGraphLegend: false,
            authorGraphSettings: {layout: "circular", sizeBy: {option: "documentCount"}, colorBy: {option: "recentAffiliationCode"}, filter: "", showSnowflakes: true, personForRightPaneSummary: null},
            unifiedGraphSettings: {layout: "circular", filter: ""},
            selectedNodes: [],
            shortestPath: [],
            termPopupMenuData: undefined,
            noteDialogData: undefined,
            personId: null,
            disambiguationPersonId: null,
            displayedResultsStatus: "All",
            selectedDocumentClassifications: [],
            freeTextFilter: null,
            selectedTops: [],
            includeOnlyUnlabeled: false,
            isInProfile: false,
            isInReviewProfile: false,
            isInOverview: false,
            isInDisambiguate: false,
            terms: [],
            suggestedTerms: [],
            termGroups: [],
            observationSections: [],
            recentAffiliations: [],
            bibliographicDetails: {articles: 0, authors: 0, bookChapters: 0, books: 0, patents: 0},
            disambiguatePersonName: null,
            cancelToken: axios.CancelToken.source(),
            documentLabels: [],
            entityLabels: [],
            observations: [],
            draggedObservation: null,
            sectionObservationOrder: [],
            personRelevantCounts: {},
            matchingResearcherIds: {},
            bwJournals: new Map(),
            activeAuthorIds: [],
            filterByResearchers: false,
            filterToDuplicates: false,
            filterToUnaffiliated: false,
            filterToUnknownPublishers: false,
            filterToMissingVolume: false,
            filterToMissingDate: false,
            filterToSpecificUrls: []
        }
    },

    getters: {
        getInvestigation(state: InvestigationState): InvestigationEntity | null {
            return state.investigation
        },

        getRelevantDocumentCount(state: InvestigationState): number {
            return state.relevantDocumentCount
        },

        getTotalDocumentCount(state: InvestigationState): number {
            return state.totalDocumentCount
        },

        getRelevancyStatus(state: InvestigationState): RelevancyStatusEntity {
            return state.relevancyStatus
        },

        getHarvestActivity(state: InvestigationState): number {
            return state.activityStatus
        },

        getAvailableDocumentIds(state: InvestigationState): string[] {
            return state.availableDocumentIds
        },

        getCurrentPage(state: InvestigationState): number {
            return state.currentPage
        },

        getDisplayedResultsStatus(state: InvestigationState): DisplayedResultsStatus {
            return state.displayedResultsStatus
        },

        getIsInProfile(state: InvestigationState): boolean {
            return state.isInProfile
        },

        getIsInReviewProfile(state: InvestigationState): boolean {
            return state.isInReviewProfile
        },

        getIsInOverview(state: InvestigationState): boolean {
            return state.isInOverview
        },

        getIsInDisambiguate(state: InvestigationState): boolean {
            return state.isInDisambiguate
        },

        getFreeTextFilter(state: InvestigationState): string | null {
            return state.freeTextFilter
        },

        getSelectedTops(state: InvestigationState): SelectedTop[] {
            return state.selectedTops
        },

        getIncludeOnlyUnlabeled(state: InvestigationState): boolean {
            return state.includeOnlyUnlabeled
        },

        getTopAuthors(state: InvestigationState): ScoredAuthorEntity[] {
            return state.tops ? state.tops.topAuthors : []
        },

        getTopJournals(state: InvestigationState): ScoredNameEntity[] {
            return state.tops ? state.tops.topJournals : []
        },

        getTopConferences(state: InvestigationState): ScoredNameEntity[] {
            return state.tops ? state.tops.topConferences : []
        },

        getTopInstitutions(state: InvestigationState): ScoredAffiliationEntity[] {
            return state.tops ? state.tops.topInstitutions : []
        },

        getTopRegions(state: InvestigationState): ScoredRegionEntity[] {
            return state.tops ? state.tops.topRegions : []
        },

        getTopAffiliationGeographies(state: InvestigationState): ScoredNameEntity[] {
            return state.tops ? state.tops.topAffiliationGeographies : []
        },

        getTopEmails(state: InvestigationState): ScoredNameEntity[] {
            return state.tops ? state.tops.topEmails : []
        },

        getTopClassifications(state: InvestigationState): ScoredNameEntity[] {
            return state.tops ? state.tops.topClassifications : []
        },

        getTopPersons(state: InvestigationState): ScoredNameEntity[] {
            return state.tops ? state.tops.topPersons : []
        },

        getTopOrganizations(state: InvestigationState): ScoredNameEntity[] {
            return state.tops ? state.tops.topOrganizations : []
        },

        getTopLocations(state: InvestigationState): ScoredNameEntity[] {
            return state.tops ? state.tops.topLocations : []
        },

        getTopConcepts(state: InvestigationState): ScoredNameEntity[] {
            return state.tops ? state.tops.topConcepts : []
        },

        getTopDomains(state: InvestigationState): ScoredNameEntity[] {
            return state.tops ? state.tops.topDomains : []
        },

        getTopLanguages(state: InvestigationState): ScoredNameEntity[] {
            return state.tops ? state.tops.topLanguages : []
        },

        getTopTerms(state: InvestigationState): ScoredNameEntity[] {
            return state.tops ? state.tops.topTerms : []
        },

        getTopLabels(state: InvestigationState): ScoredNameEntity[] {
            return state.tops ? state.tops.topLabels : []
        },

        getTopLexemes(state: InvestigationState): TopLexemeEntity[] {
            return state.tops ? state.tops.topLexemes : []
        },

        getDocumentLabels(state: InvestigationState): string[] {
            return state.documentLabels
        },

        getMentionedEntityLabels(state: InvestigationState): string[] {
            return state.entityLabels
        },

        getPersonForRightPaneSummary(state: InvestigationState): PersonEntity | null {
            return state.personForRightPaneSummary
        },

        getEntityRelationshipForSummary(state: InvestigationState): MentionedEntity[] | null {
            return state.entityRelationshipForSummary
        },

        getEntityForSummary(state: InvestigationState): MentionedEntity | null {
            return state.entityForSummary
        },

        getShowAuthorGraphLegend(state: InvestigationState): boolean {
            return state.showAuthorGraphLegend
        },

        getAuthorGraphSettings(state: InvestigationState): AuthorGraphSettings {
            return state.authorGraphSettings
        },

        getUnifiedGraphSettings(state: InvestigationState): UnifiedGraphSettings {
            return state.unifiedGraphSettings
        },

        getSelectedNodes(state: InvestigationState): string[] {
            return state.selectedNodes
        },

        getShortestPath(state: InvestigationState): string[] {
            return state.shortestPath
        },

        getPersons(state: InvestigationState): PersonEntity[] {
            return state.persons
        },

        getDocumentsSortBy(state: InvestigationState): string {
            return state.documentsSortBy
        },

        getAlertsEnabled(state: InvestigationState): boolean {
            return state.investigation ? state.investigation.alertsEnabled : false
        },

        getIsBusy(state: InvestigationState): boolean {
            return state.activeRequests > 0
        },

        getIsLoadingInvestigation(state: InvestigationState): boolean {
            return state.loadingInvestigation
        },

        getIsRenderingGraph(state: InvestigationState): boolean {
            return state.renderingGraph
        },

        getCancelToken(state: InvestigationState): CancelTokenSource {
            return state.cancelToken
        },

        getRecentAffiliations(state: InvestigationState): RecentAffiliation[] {
            return state.recentAffiliations
        },

        getDisambiguatePersonName(state: InvestigationState): string | null {
            return state.disambiguatePersonName
        },

        getPersonRelevantCounts(state: InvestigationState): PersonRelevantCounts {
            return state.personRelevantCounts
        },

        getMatchingResearcherIds(state: InvestigationState): MatchingResearcherIds {
            return state.matchingResearcherIds
        },

        getBWJournals(state: InvestigationState): Map<string, string> {
            return state.bwJournals
        },

        getActiveAuthorIds(state: InvestigationState): string[] {
            return state.activeAuthorIds
        },

        getFilterByResearchers(state: InvestigationState): boolean {
            return state.filterByResearchers
        },

        getFilterToDuplicates(state: InvestigationState): boolean {
            return state.filterToDuplicates
        },

        getFilterToUnaffiliated(state: InvestigationState): boolean {
            return state.filterToUnaffiliated
        },

        getFilterToUnknownPublishers(state: InvestigationState): boolean {
            return state.filterToUnknownPublishers
        },

        getFilterToMissingVolume(state: InvestigationState): boolean {
            return state.filterToMissingVolume
        },

        getFilterToMissingDate(state: InvestigationState): boolean {
            return state.filterToMissingDate
        },

        getFilterToSpecificUrls(state: InvestigationState): string[] {
            return state.filterToSpecificUrls
        },

        ...documents.getters,
        ...terms.getters,
        ...dateFilterModule.getters,
        ...personFilterModule.getters,
        ...termPopupMenu.getters,
        ...noteDialog.getters,
        ...graph.getters,
        ...observations.getters
    },

    mutations: {
        setInvestigation(state: InvestigationState, investigation: InvestigationEntity | null) {
            state.investigation = investigation
        },

        setTitle(state: InvestigationState, title: string) {
            state.investigation = {...state.investigation!, title}
        },

        setRelevantDocumentsCount(state: InvestigationState, relevantDocumentsCount: number) {
            state.relevantDocumentCount = relevantDocumentsCount
        },

        setActivityStatus(state: InvestigationState, activityStatus: number) {
            state.activityStatus = activityStatus
        },

        setAvailableDocumentIds(state: InvestigationState, documentIds: string[]) {
            state.availableDocumentIds = documentIds
        },

        setCurrentPage(state: InvestigationState, page: number) {
            state.currentPage = page
        },

        setRelevancyStatus(state: InvestigationState, relevancyStatus: RelevancyStatusEntity) {
            state.relevancyStatus = relevancyStatus
        },

        setDisplayedResultsStatus(state: InvestigationState, displayedResultsStatus: DisplayedResultsStatus) {
            state.displayedResultsStatus = displayedResultsStatus
        },

        setIsInProfile(state: InvestigationState, isInProfile: boolean) {
            state.isInProfile = isInProfile
        },

        setIsInReviewProfile(state: InvestigationState, isInReviewProfile: boolean) {
            state.isInReviewProfile = isInReviewProfile
        },

        setIsInOverview(state: InvestigationState, isInOverview: boolean) {
            state.isInOverview = isInOverview
        },

        setIsInDisambiguate(state: InvestigationState, isInDisambiguate: boolean) {
            state.isInDisambiguate = isInDisambiguate
        },

        setSelectedTops(state: InvestigationState, selectedTops: SelectedTop[]) {
            state.selectedTops = selectedTops
        },

        setIncludeOnlyUnlabeled(state: InvestigationState, includeOnlyUnlabeled: boolean) {
            state.includeOnlyUnlabeled = includeOnlyUnlabeled
        },

        setFreeTextFilter(state: InvestigationState, text: string | null) {
            state.freeTextFilter = text
        },

        setTops(state: InvestigationState, tops: Tops | null) {
            state.tops = tops
        },

        setTopLabels(state: InvestigationState, topLabels: ScoredNameEntity[]) {
            if (state.tops) { state.tops.topLabels = topLabels }
        },

        setDocumentCounts(state: InvestigationState, counts: { totalDocumentCount: number, relevantDocumentCount: number }) {
            state.totalDocumentCount = counts.totalDocumentCount
            state.relevantDocumentCount = counts.relevantDocumentCount
        },

        setDocumentLabels(state: InvestigationState, labels: string[]) {
            state.documentLabels = labels
        },

        setMentionedEntityLabels(state: InvestigationState, labels: string[]) {
            state.entityLabels = labels
        },

        increaseActiveRequests(state: InvestigationState) {
            state.activeRequests = state.activeRequests + 1
        },

        decreaseActiveRequests(state: InvestigationState) {
            state.activeRequests = state.activeRequests - 1
        },

        setIsLoadingInvestigation(state: InvestigationState, loading: boolean) {
            state.loadingInvestigation = loading
        },

        setIsRenderingGraph(state: InvestigationState, renderingGraph: boolean) {
            state.renderingGraph = renderingGraph
        },

        setPersons(state: InvestigationState, persons: PersonEntity[]) {
            state.persons = persons
        },

        setDocumentsSortBy(state: InvestigationState, sortBy: string) {
            state.documentsSortBy = sortBy
        },

        setPersonForRightPaneSummary(state: InvestigationState, person: PersonEntity | null) {
            state.personForRightPaneSummary = person
        },

        setEntityRelationshipForSummary(state: InvestigationState, entity: MentionedEntity[] | null) {
            state.entityRelationshipForSummary = entity
        },

        setEntityForSummary(state: InvestigationState, entity: MentionedEntity | null) {
            state.entityForSummary = entity
        },

        setShowAuthorGraphLegend(state: InvestigationState, showLegend: boolean) {
            state.showAuthorGraphLegend = showLegend
        },

        setAuthorGraphSettings(state: InvestigationState, settings: AuthorGraphSettings) {
            state.authorGraphSettings = settings
        },

        setUnifiedGraphSettings(state: InvestigationState, settings: UnifiedGraphSettings) {
            state.unifiedGraphSettings = settings
        },

        setSelectedNodes(state: InvestigationState, nodes: string[]) {
            state.selectedNodes = nodes
        },

        setShortestPath(state: InvestigationState, nodes: string[]) {
            state.shortestPath = nodes
        },

        setCancelToken(state: InvestigationState, cancelToken: CancelTokenSource) {
            state.cancelToken = cancelToken
        },

        setDisambiguatePersonName(state: InvestigationState, disambiguatePersonName: string | null) {
            state.disambiguatePersonName = disambiguatePersonName
            state.isInDisambiguate = !!disambiguatePersonName
        },

        setPersonRelevantCounts(state: InvestigationState, personRelevantCounts: PersonRelevantCounts) {
            state.personRelevantCounts = personRelevantCounts
        },

        setMatchingResearcherIds(state: InvestigationState, ids: MatchingResearcherIds) {
            state.matchingResearcherIds = ids
        },

        setBWJournals(state: InvestigationState, bwJournals: Map<string, string>) {
            state.bwJournals = bwJournals
        },

        setActiveAuthorIds(state: InvestigationState, ids: string[]) {
            state.activeAuthorIds = ids
        },

        setFilterByResearchers(state: InvestigationState, filter: boolean) {
            state.filterByResearchers = filter
        },

        setFilterToDuplicates(state: InvestigationState, filter: boolean) {
            state.filterToDuplicates = filter
        },

        setFilterToUnaffiliated(state: InvestigationState, filter: boolean) {
            state.filterToUnaffiliated = filter
        },

        setFilterToUnknownPublishers(state: InvestigationState, filter: boolean) {
            state.filterToUnknownPublishers = filter
        },

        setFilterToMissingVolume(state: InvestigationState, filter: boolean) {
            state.filterToMissingVolume = filter
        },

        setFilterToMissingDate(state: InvestigationState, filter: boolean) {
            state.filterToMissingDate = filter
        },

        setFilterToSpecificUrls(state: InvestigationState, filter: string[]) {
            state.filterToSpecificUrls = filter
        },

        clearFilters(state: InvestigationState) {
            state.displayedResultsStatus = "All"
            state.selectedTops = []
            state.dateFilterStart = null
            state.freeTextFilter = null
            state.filterByResearchers = false
            state.filterToDuplicates = false
            state.filterToUnaffiliated = false
            state.filterToUnknownPublishers = false
            state.filterToMissingVolume = false
            state.filterToMissingDate = false
            state.filterToSpecificUrls = []
        },

        ...documents.mutations,
        ...terms.mutations,
        ...dateFilterModule.mutations,
        ...personFilterModule.mutations,
        ...termPopupMenu.mutations,
        ...noteDialog.mutations,
        ...graph.mutations,
        ...observations.mutations
    },

    actions: {
        async fetchInvestigation(context: ActionContext<InvestigationState, RootState>, payload: { id: string, investigationType: InvestigationType }): Promise<void> {
            const { investigationType, id } = payload
            await wrapActiveRequests(context as unknown as Store<RootState>, async () => {
                const investigation = await investigationService.getInvestigation(id, investigationType)

                if (investigation) {
                    setInvestigation(context, investigation)
                }
            })
        },

        async fetchTopicInvestigationExport(context: ActionContext<InvestigationState, RootState>, id: string): Promise<Blob> {
            return await topicInvestigationService.export(id, getSpec(context))
        },

        async fetchPersonInvestigationExport(context: ActionContext<InvestigationState, RootState>, id: string): Promise<Blob> {
            return await personInvestigationService.export(id, getSpec(context))
        },

        async fetchInvestigationHtml(context: ActionContext<InvestigationState, RootState>,
                                     payload: { id: string, theme: string, filename: string }): Promise<GenerateHtmlResponse> {
            return await personInvestigationService.exportHtml(payload.id, payload.theme, payload.filename)
        },

        async fetchPersonProfile(context: ActionContext<InvestigationState, RootState>): Promise<void> {
            const investigation = getInvestigation(context)
            const investigationId = investigation!!.id
            const investigationType = investigation!!.investigationType
            const isInOverview = getIsInOverview(context)
            const overviewSpec: InvestigationRequestSpec = {
                dateFilter: {startYear: null, endYear: null},
                displayedResultsStatus: "All",
                selectedTops: [],
                includeOnlyUnlabeled: false,
                includeSingleAuthoredDocuments: true,
                personId: getPersonId(context),
                personName: null,
                sortDocumentsBy: getDocumentsSortBy(context),
                requestedDocumentIds: [],
                highlightMode: [],
                onlyInvestigationResearchers: false,
                onlyDuplicateDocuments: false,
                onlyUnaffiliated: false,
                onlyUnknownPublishers: false,
                onlyMissingVolume: false,
                onlyMissingDate: false,
                specificDocumentUrls: []
            }
            const spec = isInOverview ? overviewSpec : getSpec(context)
            if (getIsInReviewProfile(context)) {
                replaceSelectedClassificationsForReviewProfile(spec)
            }

            await wrapActiveRequests(context as unknown as Store<RootState>, async () => {
                const result = await investigationService.getPersonProfile(investigationId, spec, investigationType) as InvestigationDataEntity

                if (result && investigationId === getInvestigation(context)!!.id) {
                    setDateHistogram(context, result.dateHistogram)
                    setDocuments(context, result.documents)
                    setDocumentCounts(context, result.documentCounts)

                    if (getIsInReviewProfile(context)) {
                        replaceTopClassificationForReviewProfile(result)
                    }

                    setTops(context, result.tops)
                }
            })
        },

        async fetchDocumentMarkups(context: ActionContext<InvestigationState, RootState>): Promise<void> {
            const spec = getSpec(context)
            const pageSize = getPageSize(context)
            const investigationId = getInvestigationId(context, "add term")
            const documentMarkups = await investigationService.getDocumentMarkups(getInvestigationId(context, "add term"), spec, pageSize)
            if (documentMarkups && investigationId === getInvestigationId(context, "add term")) {
                setDocumentMarkups(context, documentMarkups)
            }
        },

        async updateTitle(context: ActionContext<InvestigationState, RootState>, title: string) {
            const investigation = {...getInvestigation(context), title} as InvestigationEntity
            setInvestigation(context, investigation)
            await investigationsService.update(getInvestigationId(context, "update-current-investigation"), title)
        },

        async updateAlertsEnabled(context: ActionContext<InvestigationState, RootState>, alertsEnabled: boolean) {
            const investigation = {...getInvestigation(context), alertsEnabled} as InvestigationEntity
            setInvestigation(context, investigation)
            const {title, goals} = investigation
            await investigationsService.update(getInvestigationId(context, "update-alerts-enabled"), title, goals, alertsEnabled)
        },

        async updateBWJournal(context: ActionContext<InvestigationState, RootState>, journalInfo: BWJournal) {
            const investigationId = getInvestigationId(context, "update-bw-journal")
            await configurationService.setJournalBWList(investigationId, journalInfo)
            // new map needed because of reactivity...
            const newMap = new Map<string, string>()
            context.state.bwJournals.forEach((value, key) => { newMap.set(key, value) })
            newMap.set(journalInfo.journal, journalInfo.list)
            setBWJournals(context, newMap)
        },

        async addActiveAuthor(context: ActionContext<InvestigationState, RootState>,
                              {activeAuthorId, activeAuthorName}: { activeAuthorId: string, activeAuthorName: string }) {
            const investigationId = getInvestigationId(context, "update-active-author")
            await configurationService.addActiveAuthor(investigationId, `${activeAuthorId}, ${activeAuthorName}`)
            // new list needed because of reactivity...
            const newList = context.state.activeAuthorIds.map(x => x)
            newList.push(activeAuthorId)
            setActiveAuthorIds(context, newList)
        },

        async fetchTopLabels(context: ActionContext<InvestigationState, RootState>): Promise<void> {
            const spec = getSpec(context)
            const pageSize = 0

            const investigationType = getInvestigation(context)!.investigationType
            const investigationId = getInvestigationId(context, "fetch-all-topic-data")
            const result = await investigationService.getAllData(investigationId, spec, pageSize, investigationType, getCancelToken(context).token) as TopicInvestigationDataEntity
            const investigationInStore = getInvestigation(context)
            const isInSameContext = investigationInStore && investigationInStore.id === investigationId

            if (result && isInSameContext) {
                setTopLabels(context, result.tops.topLabels)
            }
        },

        async fetchAllData(context: ActionContext<InvestigationState, RootState>): Promise<void> {
            const firstTime = getIsLoadingInvestigation(context)
            if (firstTime) {
                await fetchInvestigationLastFilter(context)
            }

            getCancelToken(context).cancel()
            setCancelToken(context, axios.CancelToken.source())
            const investigation = getInvestigation(context)
            const isInTopicInvestigation = investigation!.investigationType === "Topic"

            const isInProfile = getIsInProfile(context)
            isInProfile ? await fetchPersonProfile(context) :
                isInTopicInvestigation ? await fetchAllTopicData(context) : await fetchAllPersonData(context)
            if (!getObservations(context).length) {
                await fetchAllObservations(context)
            }
            setIsLoadingInvestigation(context, false)
        },

        async fetchAllPersonData(context: ActionContext<InvestigationState, RootState>): Promise<void> {
            const spec = getSpec(context)
            const pageSize = getPageSize(context)
            const requestedInvestigationId = getInvestigationId(context, "fetch-all-person-data")

            await wrapActiveRequests(context as unknown as Store<RootState>, async () => {
                const result = await investigationService.getAllData(requestedInvestigationId, spec, pageSize, "Person", getCancelToken(context).token) as PersonInvestigationDataEntity

                if (result) {
                    const investigationInStore = getInvestigation(context)
                    if (investigationInStore && investigationInStore.id === requestedInvestigationId) {
                        setAvailableDocumentIds(context, result.availableDocumentIds)
                        setCurrentPage(context, 1)
                        setDateHistogram(context, result.dateHistogram)
                        setDocuments(context, result.documents)
                        setDocumentCounts(context, result.documentCounts)
                        setDateHistogram(context, result.dateHistogram)
                        setTops(context, result.tops)
                        setPersons(context, result.persons)
                        setPersonRelevantCounts(context, result.personRelevantCounts)
                        setMatchingResearcherIds(context, result.matchingResearcherIds)
                        setRelevancyStatus(context, {differentEnough: false, relevancyScore: 0})
                        setDocumentLabels(context, result.labels)
                        const journals = new Map(result.bwJournals.map(x => [x.journal, x.list]))
                        setBWJournals(context, journals)
                        setActiveAuthorIds(context, result.activeAuthorIds)
                    }
                }
            })
        },

        async fetchAllTopicData(context: ActionContext<InvestigationState, RootState>): Promise<void> {
            const spec = getSpec(context)
            const pageSize = getPageSize(context)

            await wrapActiveRequests(context as unknown as Store<RootState>, async () => {
                const investigationId = getInvestigationId(context, "fetch-all-topic-data")
                const result = await investigationService.getAllData(investigationId, spec, pageSize, "Topic", getCancelToken(context).token) as TopicInvestigationDataEntity
                const investigationInStore = getInvestigation(context)
                const isInSameContext = investigationInStore && investigationInStore.id === investigationId

                function shouldUseExistingTerms(newTerms: InvestigationTermEntity[]) {
                    const oldTerms = getTerms(context)
                    const listsAreEqualSize = oldTerms.length === newTerms.length
                    const allTermsAreIncludedInNewList = oldTerms.reduce((areTheSame, oldTerm) =>
                        areTheSame && newTerms.findIndex(newTerm =>
                            newTerm.term.toLocaleLowerCase() === oldTerm.term.toLocaleLowerCase() &&
                            newTerm.group === oldTerm.group
                        ) > -1, true)
                    return listsAreEqualSize && allTermsAreIncludedInNewList
                }
                if (result && isInSameContext) {
                    const activeTerms = shouldUseExistingTerms(result.terms.active) ? getTerms(context) : result.terms.active
                    setAvailableDocumentIds(context, result.availableDocumentIds)
                    setCurrentPage(context, 1)
                    setDateHistogram(context, result.dateHistogram)
                    setDocuments(context, result.documents)
                    setDocumentCounts(context, result.documentCounts)
                    setDocumentMarkups(context, [])
                    setTerms(context, activeTerms)
                    setTermGroups(context, result.termGroups)
                    setSuggestedTerms(context, result.terms.suggested)
                    setDateHistogram(context, result.dateHistogram)
                    setTops(context, result.tops)
                    setDocumentLabels(context, result.labels)
                }
            })
        },


        async fetchNextDocuments(context: ActionContext<InvestigationState, RootState>): Promise<void> {
            const spec = getSpec(context)
            const investigationType = getInvestigation(context)!.investigationType

            const investigationId = getInvestigationId(context, "fetch-next-documents")
            const nextDocuments = await investigationService.getNextDocuments(investigationId, spec, investigationType) as DocumentEntity[]
            const investigationInStore = getInvestigation(context)
            const isInSameContext = investigationInStore && investigationInStore.id === investigationId

            if (nextDocuments && isInSameContext) {
                setCurrentPage(context, getCurrentPage(context) + 1)
                setDocuments(context, getDocuments(context).concat(nextDocuments))
            }
        },


        async fetchGraphData(context: ActionContext<InvestigationState, RootState>): Promise<void> {
            if (getGraphType(context) === "Timeline") {
                await fetchTimelineData(context)
                return
            }

            const firstTime = getIsLoadingInvestigation(context)
            if (firstTime) {
                await fetchInvestigationLastFilter(context)
            }

            const cancelToken = getCancelToken(context)
            if (cancelToken) {
                cancelToken.cancel()
            }
            setCancelToken(context, axios.CancelToken.source())
            if (getInvestigation(context)!.investigationType === "Topic") {
                await fetchGraphTopicData(context)
            } else {
                await fetchGraphPersonData(context)
            }
            setRelevancyStatus(context, {differentEnough: false, relevancyScore: 0})
            setIsLoadingInvestigation(context, false)
        },


        async fetchGraphPersonData(context: ActionContext<InvestigationState, RootState>): Promise<void> {
            setIsRenderingGraph(context, true)
            const overviewSpec: InvestigationRequestSpec = {
                dateFilter: {startYear: null, endYear: null},
                displayedResultsStatus: "All",
                selectedTops: [],
                includeOnlyUnlabeled: false,
                includeSingleAuthoredDocuments: true,
                personId: getPersonId(context),
                personName: null,
                sortDocumentsBy: getDocumentsSortBy(context),
                requestedDocumentIds: [],
                highlightMode: [],
                onlyInvestigationResearchers: false,
                onlyDuplicateDocuments: false,
                onlyUnaffiliated: false,
                onlyUnknownPublishers: false,
                onlyMissingVolume: false,
                onlyMissingDate: false,
                specificDocumentUrls: []
            }
            const isInOverview = getIsInOverview(context)
            const spec = isInOverview ? overviewSpec : getSpec(context)
            const graphType = getGraphType(context) || "Author"
            let sizeBy
            let colorBy
            if (graphType === "Author") {
                const settings = getAuthorGraphSettings(context)
                if (settings.sizeBy.option === "similarity") {
                    sizeBy = settings.sizeBy.personId
                }
                if (settings.colorBy.option === "similarity") {
                    colorBy = settings.colorBy.personId
                }
            }

            const request: GraphRequest = { spec, sizeBy, colorBy }
            await wrapActiveRequests(context as unknown as Store<RootState>, async () => {
                const investigationId = getInvestigationId(context, "fetch-all-person-graph-data")
                const result = await investigationService.getGraphData(investigationId, "Person", graphType, request, getCancelToken(context).token) as InvestigationGraphDataEntity
                const currentGraphType = getGraphType(context) || "Author"
                const isInSameContext = investigationId === getInvestigationId(context, "fetch-all-topic-graph-data") && graphType === currentGraphType

                if (result && isInSameContext) {
                    setDateHistogram(context, result.dateHistogram)
                    setGraph(context, result.graph)
                    setTops(context, result.tops)
                    setDocumentLabels(context, result.labels)
                } else {
                    setIsRenderingGraph(context, false)
                }
            })
        },

        async fetchGraphTopicData(context: ActionContext<InvestigationState, RootState>): Promise<void> {
            setIsRenderingGraph(context, true)
            const isInOverview = getIsInOverview(context)
            const overviewSpec: InvestigationRequestSpec = {
                dateFilter: {startYear: null, endYear: null},
                displayedResultsStatus: "All",
                selectedTops: [],
                includeOnlyUnlabeled: false,
                includeSingleAuthoredDocuments: true,
                personId: getPersonId(context),
                personName: null,
                sortDocumentsBy: getDocumentsSortBy(context),
                requestedDocumentIds: [],
                highlightMode: [],
                onlyInvestigationResearchers: false,
                onlyDuplicateDocuments: false,
                onlyUnaffiliated: false,
                onlyUnknownPublishers: false,
                onlyMissingVolume: false,
                onlyMissingDate: false,
                specificDocumentUrls: []
            }
            const spec = isInOverview ? overviewSpec : getSpec(context)
            const graphType = getGraphType(context) || "Author"

            let sizeBy
            let colorBy
            if (graphType === "Author") {
                const settings = getAuthorGraphSettings(context)
                if (settings.sizeBy.option === "similarity") {
                    sizeBy = settings.sizeBy.personId
                }
                if (settings.colorBy.option === "similarity") {
                    colorBy = settings.colorBy.personId
                }
            }

            const request: GraphRequest = { spec, sizeBy, colorBy }

            await wrapActiveRequests(context as unknown as Store<RootState>, async () => {
                const investigationId = getInvestigationId(context, "fetch-all-topic-graph-data")
                const result = await investigationService.getGraphData(investigationId, "Topic", graphType, request, getCancelToken(context).token) as InvestigationGraphDataEntity
                const currentGraphType = getGraphType(context) || "Author"
                const isInSameContext = investigationId === getInvestigationId(context, "fetch-all-topic-graph-data") && graphType === currentGraphType
                if (result && isInSameContext) {
                    setDateHistogram(context, result.dateHistogram)
                    await setGraph(context, result.graph)
                    setTops(context, result.tops)
                    setTerms(context, result.terms.active)
                    setTermGroups(context, result.termGroups!)
                    setDocumentLabels(context, result.labels)
                } else {
                    setIsRenderingGraph(context, false)
                }
            })
        },


        async fetchTimelineData(context: ActionContext<InvestigationState, RootState>): Promise<void> {
            getCancelToken(context).cancel()
            setCancelToken(context, axios.CancelToken.source())

            setIsRenderingGraph(context, true)

            const spec = getSpec(context)
            const graphType = getGraphType(context) || "Timeline"
            const request: TimelineRequest = { spec }
            await wrapActiveRequests(context as unknown as Store<RootState>, async () => {
                const investigation = getInvestigation(context)!
                const result = await investigationService.getTimelineData(investigation.id, investigation.investigationType, "Timeline", request) as InvestigationTimelineDataEntity
                const currentGraphType = getGraphType(context) || "Timeline"
                const isInSameContext = investigation.id === getInvestigationId(context, "fetch-timeline-data") && graphType === currentGraphType

                if (result && isInSameContext) {
                    const selectedTermGroups = result.termGroups.map(it => it.name)
                    const timelineChartData = new TimelineChartData(result.chart, selectedTermGroups, [], null)

                    setDateHistogram(context, result.dateHistogram)
                    setTimeline(context, timelineChartData)
                    setTops(context, result.tops)
                    setTerms(context, result.terms.active)
                    setTermGroups(context, result.termGroups)
                    setExternalSeries(context, result.externalSeries)
                } else {
                    setIsRenderingGraph(context, false)
                }
            })

            setIsLoadingInvestigation(context, false)
        },


        async fetchAllObservations(context: ActionContext<InvestigationState, RootState>): Promise<void> {
            await wrapActiveRequests(context as unknown as Store<RootState>, async () => {
                const investigationId = getInvestigationId(context, "fetch-all-observations")
                const result = await investigationService.getObservations(investigationId, getInvestigation(context)!.investigationType)
                setIsLoadingInvestigation(context, false)
                const investigationInStore = getInvestigation(context)
                const isInSameContext = investigationInStore && investigationInStore.id === investigationId
                if (isInSameContext && result) {
                    setObservationSections(context, result.sections)
                    setObservations(context, result.observations)
                }
            })
        },


        async fetchNextSuggestedTerms(context: ActionContext<InvestigationState, RootState>): Promise<void> {
            const oldTerms = getTerms(context)
            const toInvestigationTerm = (suggestedTerm: SuggestedTerm) => {
                const {term, group, modifier} = suggestedTerm
                return {term, group, modifier} as InvestigationTermEntity
            }

            const selectedSuggestedTerms = getSuggestedTerms(context).filter(suggestedTerm => suggestedTerm.selected).map(toInvestigationTerm)
            const rejectedSuggestedTerms = getSuggestedTerms(context).filter(suggestedTerm => !suggestedTerm.selected).map(term => term.term)
            const newInvestigationTerms: InvestigationTermEntity[] = oldTerms.concat(selectedSuggestedTerms).sort((a, b) => a.term.localeCompare(b.term))
            setTerms(context, newInvestigationTerms)

            const investigationId = getInvestigationId(context, "fetch documents")
            const spec = getSpec(context)
            const result = await investigationService.getNextSuggestedTerms(investigationId, selectedSuggestedTerms, rejectedSuggestedTerms, spec)
            const investigationInStore = getInvestigation(context)
            const isInSameContext = investigationInStore && investigationInStore.id === investigationId
            if (isInSameContext && result) {
                setSuggestedTerms(context, result)
            } else if (isInSameContext) {
                setTerms(context, oldTerms)
            }
        },

        async updateRelevancyStatus(context: ActionContext<InvestigationState, RootState>, relevancyStatus: RelevancyStatusEntity): Promise<void> {
            setRelevancyStatus(context, relevancyStatus)
            const investigation = getInvestigation(context)!
            if (investigation.investigationType === "Topic") {
                if (getGraphType(context) === null && relevancyStatus.differentEnough && !getDocuments(context).length && !getIsBusy(context)) {
                    await fetchAllTopicData(context)
                }
            }
        },

        async showOnlyMyPublications(context: ActionContext<InvestigationState, RootState>): Promise<void> {
            const investigation = getInvestigation(context)!

            const mainClusterFilter = await investigationService.getMainClusterFilter(investigation.id)
            const tops = [{name: "predefined.Persons", selectedDisplayValue: mainClusterFilter.displayName, selectedValue: mainClusterFilter.personId}] as unknown as SelectedTop[]

            setSelectedTops(context, tops)
            getGraphType(context) === null ? await fetchAllData(context) : await fetchGraphData(context)
        },

        async clearInvestigationStore(context: ActionContext<InvestigationState, RootState>): Promise<void> {
            setDateHistogram(context, new DateHistogram())
            setTerms(context, [])
            setTermGroups(context, [])
            setSuggestedTerms(context, [])
            setRelevancyStatus(context, {differentEnough: false, relevancyScore: 0})
            setActivityStatus(context, 0)
            setDocuments(context, [])
            setDocumentMarkups(context, [])
            setIsRenderingGraph(context, false)
            setGraph(context, {nodes: [], edges: []})
            setGraphType(context, null)
            setTimeline(context, new TimelineChartData({dataItems: [], columns: []}, [], [], null))
            setExternalSeries(context, [])
            setPersons(context, [])
            setPersonRelevantCounts(context, {})
            setMatchingResearcherIds(context, {})
            setBWJournals(context, new Map())
            setDocumentCounts(context, {relevantDocumentCount: 0, totalDocumentCount: 0})
            setTops(context, null)
            setDateFilterYears(context, { start: null, end: null })
            setPersonId(context, null)
            setSelectedTops(context, [])
            setIncludeOnlyUnlabeled(context, false)
            setPersonForRightPaneSummary(context, null)
            setEntityForSummary(context, null)
            setDisambiguatePersonName(context, null)
            setAuthorGraphSettings(context, {layout: "organic", sizeBy: {option: "documentCount"}, colorBy: {option: "recentAffiliationCode"}, filter: "", showSnowflakes: true, personForRightPaneSummary: null})
            setSelectedNodes(context, [])
            setShortestPath(context, [])
            setHighlightModes(context, ["Highlight Search Terms", "Highlight Mentioned Entities"])
            clearTopsExpansion(context)
            clearTopsSorting(context)
            clearGroupsExpansion(context)
            clearSectionsExpansion(context)
            setFreeTextFilter(context, null)
            setDocumentsSortBy(context, "relevance")
            setCurrentPage(context, 0)
            setAvailableDocumentIds(context, [])
            setIsLoadingInvestigation(context, true)
            setDocumentLabels(context, [])
            setFilterByResearchers(context, false)
            setFilterToDuplicates(context, false)
            setFilterToUnaffiliated(context, false)
            setFilterToUnknownPublishers(context, false)
        },

        async fetchInvestigationLastFilter(context: ActionContext<InvestigationState, RootState>): Promise<void> {
            if (!isFacultyWorksUser(context as unknown as ActionContext<AuthState, RootState>)) {
                const investigation = getInvestigation(context)!
                const lastFilter = await investigationService.getLastFilterData(investigation!.id) as InvestigationRequestSpec
                setFilterFromSpec(context, lastFilter)
            }
        },

        async preprocessPersonImage(context: ActionContext<InvestigationState, RootState>, {personId, imageData, imageUrl}: {
            personId: string, imageData: ImageCropData | null, imageUrl: string | null}): Promise<string> {

            const investigation = getInvestigation(context)!

            return await investigationService.preprocessPersonImage(
              investigation.id, investigation.investigationType, personId, imageData, imageUrl)
        },

        async updatePersonImage(context: ActionContext<InvestigationState, RootState>, {personId, imageData, imageUrl}: {
            personId: string, imageData: ImageCropData | undefined, imageUrl: string | undefined}): Promise<void> {

            const investigation = getInvestigation(context)!

            let imageId = null as string | null
            if (imageData || imageUrl) {
                imageId = await InvestigationService.updatePersonImage(
                    investigation.id, investigation.investigationType, personId, imageData, imageUrl)
            } else {
                await InvestigationService.clearPersonImage(investigation.id, investigation.investigationType,  personId)
            }

            const modifiedDocuments = getDocuments(context).map(document => {
                let documentModified = false
                const newAuthors = document.authors.map(author => {
                    if (author.id === personId) {
                        documentModified = true
                        return {...author, imageId }
                    } else {
                        return author
                    }
                })

                if (documentModified) {
                    return {...document, authors: newAuthors}
                } else {
                    return document
                }

            })
            setDocuments(context, modifiedDocuments)
        },

        async updateLastReviewTime(context: ActionContext<InvestigationState, RootState>,
                                   {personId, lastReviewTime}: { personId: string, lastReviewTime: Moment })
          : Promise<void> {

            const investigation = getInvestigation(context)!
            await investigationService.markPersonReviewed(investigation.id, investigation.investigationType, personId)

            const modifiedDocuments = getDocuments(context).map(document => {
                let documentModified = false
                const newAuthors = document.authors.map(author => {
                    if (author.id === personId) {
                        documentModified = true
                        return {...author, lastReviewTime }
                    } else {
                        return author
                    }
                })

                if (documentModified) {
                    return {...document, authors: newAuthors}
                } else {
                    return document
                }

            })
            setDocuments(context, modifiedDocuments)
        },

        ...documents.actions,
        ...terms.actions,
        ...termPopupMenu.actions,
        ...noteDialog.actions,
        ...observations.actions,
        ...graph.actions
    }
}

export default module


const { read, commit, dispatch } = getStoreAccessors<InvestigationState, RootState>("investigation")

export const getInvestigation = read(module.getters.getInvestigation)
export const setInvestigation = commit(module.mutations.setInvestigation)
export const setTitle = commit(module.mutations.setTitle)

export const getActivityStatus = read(module.getters.getHarvestActivity)
export const setActivityStatus = commit(module.mutations.setActivityStatus)
export const getRelevancyStatus = read(module.getters.getRelevancyStatus)
export const setRelevancyStatus = commit(module.mutations.setRelevancyStatus)

export const getAvailableDocumentIds = read(module.getters.getAvailableDocumentIds)
export const setAvailableDocumentIds = commit(module.mutations.setAvailableDocumentIds)
export const getCurrentPage = read(module.getters.getCurrentPage)
export const setCurrentPage = commit(module.mutations.setCurrentPage)

export const getDisplayedResultsStatus = read(module.getters.getDisplayedResultsStatus)
export const setDisplayedResultsStatus = commit(module.mutations.setDisplayedResultsStatus)

export const getIsInProfile = read(module.getters.getIsInProfile)
export const setIsInProfile = commit(module.mutations.setIsInProfile)

export const getIsInReviewProfile = read(module.getters.getIsInReviewProfile)
export const setIsInReviewProfile = commit(module.mutations.setIsInReviewProfile)

export const getIsInOverview = read(module.getters.getIsInOverview)
export const setIsInOverview = commit(module.mutations.setIsInOverview)

export const getIsInDisambiguate = read(module.getters.getIsInDisambiguate)
export const setIsInDisambiguate = commit(module.mutations.setIsInDisambiguate)

export const getSelectedTops = read(module.getters.getSelectedTops)
export const setSelectedTops = commit(module.mutations.setSelectedTops)

export const getIncludeOnlyUnlabeled = read(module.getters.getIncludeOnlyUnlabeled)
export const setIncludeOnlyUnlabeled = commit(module.mutations.setIncludeOnlyUnlabeled)

export const getFreeTextFilter = read(module.getters.getFreeTextFilter)
export const setFreeTextFilter = commit(module.mutations.setFreeTextFilter)

export const clearFilters = commit(module.mutations.clearFilters)

export const getTotalDocumentCount = read(module.getters.getTotalDocumentCount)
export const getRelevantDocumentCount = read(module.getters.getRelevantDocumentCount)
const setDocumentCounts = commit(module.mutations.setDocumentCounts)

export const getIsBusy = read(module.getters.getIsBusy)
export const increaseActiveRequests = commit(module.mutations.increaseActiveRequests)
export const decreaseActiveRequests = commit(module.mutations.decreaseActiveRequests)
export const getIsLoadingInvestigation = read(module.getters.getIsLoadingInvestigation)
export const setIsLoadingInvestigation = commit(module.mutations.setIsLoadingInvestigation)

export const getIsRenderingGraph = read(module.getters.getIsRenderingGraph)
export const setIsRenderingGraph = commit(module.mutations.setIsRenderingGraph)

export const getTopAuthors = read(module.getters.getTopAuthors)
export const getJournals = read(module.getters.getTopJournals)
export const getTopConferences = read(module.getters.getTopConferences)
export const getTopInstitutions = read(module.getters.getTopInstitutions)
export const getTopRegions = read(module.getters.getTopRegions)
export const getTopAffiliationGeographies = read(module.getters.getTopAffiliationGeographies)
export const getTopEmails = read(module.getters.getTopEmails)
export const getTopClassifications = read(module.getters.getTopClassifications)
export const getTopPersons = read(module.getters.getTopPersons)
export const getTopOrganizations = read(module.getters.getTopOrganizations)
export const getTopLocations = read(module.getters.getTopLocations)
export const getTopConcepts = read(module.getters.getTopConcepts)
export const getTopDomains = read(module.getters.getTopDomains)
export const getTopLanguages = read(module.getters.getTopLanguages)
export const getTopLabels = read(module.getters.getTopLabels)
export const getTopLexemes = read(module.getters.getTopLexemes)
export const getTopTerms = read(module.getters.getTopTerms)
const setTops = commit(module.mutations.setTops)
export const setTopLabels = commit(module.mutations.setTopLabels)

export const getDocumentLabels = read(module.getters.getDocumentLabels)
export const setDocumentLabels = commit(module.mutations.setDocumentLabels)
export const setPersonRelevantCounts = commit(module.mutations.setPersonRelevantCounts)
export const getMentionedEntityLabels = read(module.getters.getMentionedEntityLabels)
export const setMentionedEntityLabels = commit(module.mutations.setMentionedEntityLabels)
export const getMatchingResearcherIds = read(module.getters.getMatchingResearcherIds)
export const setMatchingResearcherIds = commit(module.mutations.setMatchingResearcherIds)
export const getBWJournals = read(module.getters.getBWJournals)
export const setBWJournals = commit(module.mutations.setBWJournals)
export const getActiveAuthorIds = read(module.getters.getActiveAuthorIds)
export const setActiveAuthorIds = commit(module.mutations.setActiveAuthorIds)

export const getPersonForRightPaneSummary = read(module.getters.getPersonForRightPaneSummary)
export const setPersonForRightPaneSummary = commit(module.mutations.setPersonForRightPaneSummary)

export const getEntityForSummary = read(module.getters.getEntityForSummary)
export const setEntityForSummary = commit(module.mutations.setEntityForSummary)

export const getEntityRelationshipForSummary = read(module.getters.getEntityRelationshipForSummary)
export const setEntityRelationshipForSummary = commit(module.mutations.setEntityRelationshipForSummary)

export const getShowAuthorGraphLegend = read(module.getters.getShowAuthorGraphLegend)
export const setShowAuthorGraphLegend = commit(module.mutations.setShowAuthorGraphLegend)

export const getAuthorGraphSettings = read(module.getters.getAuthorGraphSettings)
export const setAuthorGraphSettings = commit(module.mutations.setAuthorGraphSettings)
export const getUnifiedGraphSettings = read(module.getters.getUnifiedGraphSettings)
export const setUnifiedGraphSettings = commit(module.mutations.setUnifiedGraphSettings)
export const getSelectedNodes = read(module.getters.getSelectedNodes)
export const setSelectedNodes = commit(module.mutations.setSelectedNodes)
export const getShortestPath = read(module.getters.getShortestPath)
export const setShortestPath = commit(module.mutations.setShortestPath)

export const getDocumentsSortBy = read(module.getters.getDocumentsSortBy)
export const setDocumentsSortBy = commit(module.mutations.setDocumentsSortBy)

export const getPersons = read(module.getters.getPersons)
export const setPersons = commit(module.mutations.setPersons)
export const getPersonRelevantCounts = read(module.getters.getPersonRelevantCounts)
export const updatePersonImage = dispatch(module.actions.updatePersonImage)
export const preprocessPersonImage = dispatch(module.actions.preprocessPersonImage)
export const updateLastReviewTime = dispatch(module.actions.updateLastReviewTime)

export const fetchTopicInvestigationExport = dispatch(module.actions.fetchTopicInvestigationExport)
export const fetchPersonInvestigationExport = dispatch(module.actions.fetchPersonInvestigationExport)
export const fetchInvestigationHtml = dispatch(module.actions.fetchInvestigationHtml)

export const getCancelToken = read(module.getters.getCancelToken)
const setCancelToken = commit(module.mutations.setCancelToken)

export const fetchInvestigation = dispatch(module.actions.fetchInvestigation)
export const fetchPersonProfile = dispatch(module.actions.fetchPersonProfile)
export const fetchDocumentMarkups = dispatch(module.actions.fetchDocumentMarkups)
export const fetchTopLabels = dispatch(module.actions.fetchTopLabels)
export const fetchAllData = dispatch(module.actions.fetchAllData)
export const fetchAllPersonData = dispatch(module.actions.fetchAllPersonData)
export const fetchAllTopicData = dispatch(module.actions.fetchAllTopicData)
export const fetchNextDocuments = dispatch(module.actions.fetchNextDocuments)
export const fetchGraphData = dispatch(module.actions.fetchGraphData)
export const fetchGraphPersonData = dispatch(module.actions.fetchGraphPersonData)
export const fetchGraphTopicData = dispatch(module.actions.fetchGraphTopicData)
export const fetchTimelineData = dispatch(module.actions.fetchTimelineData)
export const fetchNextSuggestedTerms = dispatch(module.actions.fetchNextSuggestedTerms)
export const fetchAllObservations = dispatch(module.actions.fetchAllObservations)
export const updateTitle = dispatch(module.actions.updateTitle)
export const updateBWJournal = dispatch(module.actions.updateBWJournal)
export const updateRelevancyStatus = dispatch(module.actions.updateRelevancyStatus)
export const addActiveAuthor = dispatch(module.actions.addActiveAuthor)
export const clearInvestigationStore = dispatch(module.actions.clearInvestigationStore)
export const fetchInvestigationLastFilter = dispatch(module.actions.fetchInvestigationLastFilter)

export const getAlertsEnabled = read(module.getters.getAlertsEnabled)
export const updateAlertsEnabled = dispatch(module.actions.updateAlertsEnabled)

export const getDisambiguatePersonName = read(module.getters.getDisambiguatePersonName)
export const setDisambiguatePersonName = commit(module.mutations.setDisambiguatePersonName)

export const getFilterByResearchers = read(module.getters.getFilterByResearchers)
export const getFilterToDuplicates = read(module.getters.getFilterToDuplicates)
export const getFilterToUnaffiliated = read(module.getters.getFilterToUnaffiliated)
export const getFilterToUnknownPublishers = read(module.getters.getFilterToUnknownPublishers)
export const getFilterToMissingVolume = read(module.getters.getFilterToMissingVolume)
export const getFilterToMissingDate = read(module.getters.getFilterToMissingDate)
export const getFilterToSpecificUrls = read(module.getters.getFilterToSpecificUrls)
export const setFilterByResearchers = commit(module.mutations.setFilterByResearchers)
export const setFilterToDuplicates = commit(module.mutations.setFilterToDuplicates)
export const setFilterToUnaffiliated = commit(module.mutations.setFilterToUnaffiliated)
export const setFilterToUnknownPublishers = commit(module.mutations.setFilterToUnknownPublishers)
export const setFilterToMissingVolume = commit(module.mutations.setFilterToMissingVolume)
export const setFilterToMissingDate = commit(module.mutations.setFilterToMissingDate)
export const setFilterToSpecificUrls = commit(module.mutations.setFilterToSpecificUrls)

export const showOnlyMyPublications = dispatch(module.actions.showOnlyMyPublications)

export function getPageSize(context: ActionContext<InvestigationState, RootState>) {
    return getDocumentsPageSize(context as unknown as ActionContext<EnvironmentState, RootState>)
}


interface DisambiguationSavedState {
    selectedTops: SelectedTop[]
    dateFilterStart: number | null
    dateFilterEnd: number | null
    topsExpansion: {[key: string]: boolean}
    topsSorting: {[key: string]: boolean | undefined}
    groupsExpansion: {[key: string]: boolean}
}

export let disambiguationSavedState: DisambiguationSavedState | null = null

export function saveInvestigationState(store: Store<RootState>) {
    disambiguationSavedState = {
        selectedTops: getSelectedTops(store),
        dateFilterStart: getDateFilterStart(store),
        dateFilterEnd: getDateFilterEnd(store),
        topsExpansion: getTopsExpansion(store),
        topsSorting: getTopsSorting(store),
        groupsExpansion: getGroupsExpansion(store)
    }
    setSelectedTops(store, [])
    setDateFilterYears(store, {start: null, end: null})
    clearTopsExpansion(store)
    clearTopsSorting(store)
    clearGroupsExpansion(store)
}

export function restoreInvestigationState(store: Store<RootState>) {
    setSelectedTops(store, disambiguationSavedState!!.selectedTops)
    setDateFilterYears(store, {start: disambiguationSavedState!!.dateFilterStart, end: disambiguationSavedState!!.dateFilterEnd})
    Object.entries(disambiguationSavedState!!.topsExpansion).forEach(([type, isExpanded]) => setTopExpanded(store, {type, isExpanded}))
    Object.entries(disambiguationSavedState!!.topsSorting).forEach(([type, isSortedByRelevant]) => setTopSorting(store, {type, isSortedByRelevant: isSortedByRelevant === undefined ? true : isSortedByRelevant}))
    setGroupsExpansion(store, disambiguationSavedState!!.groupsExpansion)
}

export function getSpecForStore(store: Store<RootState>): InvestigationRequestSpec {
    return getSpec(store as unknown as ActionContext<InvestigationState, RootState>)
}

export function getSpec(context: ActionContext<InvestigationState, RootState>): InvestigationRequestSpec {
    const pageSize = getPageSize(context)
    const availableDocumentIds = getAvailableDocumentIds(context)
    const currentPage = getCurrentPage(context)
    const requestedDocumentIds = availableDocumentIds.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
    return {
        dateFilter: {startYear: getDateFilterStart(context), endYear: getDateFilterEnd(context)},
        displayedResultsStatus: getDisplayedResultsStatus(context),
        selectedTops: getSelectedTops(context),
        includeOnlyUnlabeled: getIsInReviewProfile(context) && getIncludeOnlyUnlabeled(context),
        includeSingleAuthoredDocuments: getGraphType(context) == null ? true : getAuthorGraphSettings(context).showSnowflakes,
        personId: getPersonId(context) || getDisambiguationPersonId(context),
        personName: getDisambiguatePersonName(context),
        freeText: getFreeTextFilter(context),
        sortDocumentsBy: getDocumentsSortBy(context),
        requestedDocumentIds,
        highlightMode: getHighlightModes(context),
        onlyInvestigationResearchers: getFilterByResearchers(context),
        onlyDuplicateDocuments: getFilterToDuplicates(context),
        onlyUnaffiliated: getFilterToUnaffiliated(context),
        onlyUnknownPublishers: getFilterToUnknownPublishers(context),
        onlyMissingVolume: getFilterToMissingVolume(context),
        onlyMissingDate: getFilterToMissingDate(context),
        specificDocumentUrls: getFilterToSpecificUrls(context)
    }
}


export function setFilterFromSpec(context: ActionContext<InvestigationState, RootState>, spec: InvestigationRequestSpec) {
    if (spec) {
        const selectedTops = spec.selectedTops.map(it => {
            if (it.name === "predefined.Persons") {
                return {name: it.name, selectedDisplayValue: it.selectedDisplayValue, selectedValue: parseInt(it.selectedValue, 10) }
            }
            return it
        })

        setDateFilterYears(context, {start: spec.dateFilter.startYear, end: spec.dateFilter.endYear})
        setDisplayedResultsStatus(context, spec.displayedResultsStatus)
        setSelectedTops(context, selectedTops)
        setFreeTextFilter(context, spec.freeText ? spec.freeText : null)
        setDocumentsSortBy(context, spec.sortDocumentsBy)
        setHighlightModes(context, spec.highlightMode)
    }
}


function replaceTopClassificationForReviewProfile(result: InvestigationDataEntity) {
    const newClassifications: { [key: string]: ScoredNameEntity } = {}
    result.tops.topClassifications.forEach(oldClassification => {
        let newClassification = null
        /* tslint:disable:no-string-literal */
        if (oldClassification.name === "Article" || oldClassification.name === "Book" || oldClassification.name === "Book Chapter") {
            newClassification = newClassifications["Academic Publication"]
            if (!newClassification) {
                newClassification = {name: "Academic Publication", relevantCount: 0, relevantScore: 0, totalCount: 0, totalScore: 0}
                newClassifications["Academic Publication"] = newClassification
            }
        } else if (oldClassification.name === "Patent") {
            newClassification = newClassifications["Patent"]
            if (!newClassification) {
                newClassification = {name: "Patent", relevantCount: 0, relevantScore: 0, totalCount: 0, totalScore: 0}
                newClassifications["Patent"] = newClassification
            }
        }
        /* tslint:enable:no-string-literal */
        if (newClassification) {
            newClassification.relevantCount += oldClassification.relevantCount
            newClassification.relevantScore += oldClassification.relevantScore
            newClassification.totalCount += oldClassification.totalCount
            newClassification.totalScore += oldClassification.totalScore
        }
    })
    result.tops.topClassifications = Object.values(newClassifications) as ScoredNameEntity[]
}


function replaceSelectedClassificationsForReviewProfile(spec: InvestigationRequestSpec) {
    const publicationsIndex = spec.selectedTops.findIndex(selectedTop => selectedTop.name === "predefined.Classifications" && selectedTop.selectedValue === "Academic Publication")
    const selectedTops: SelectedTop[] = []
    spec.selectedTops.forEach((top, index) => {
        if (index === publicationsIndex) {
            selectedTops.push({name: "predefined.Classifications", selectedValue: "Article", selectedDisplayValue: "Article"})
            selectedTops.push({name: "predefined.Classifications", selectedValue: "Book", selectedDisplayValue: "Book"})
            selectedTops.push({name: "predefined.Classifications", selectedValue: "Book Chapter", selectedDisplayValue: "Book Chapter"})
        } else {
            selectedTops.push(top)
        }
    })
    spec.selectedTops = selectedTops
}
