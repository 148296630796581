






import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"

@Component
    export default class PopupMenu extends Vue {

        @Prop()
        private event!: string

        @Prop()
        private closeEvent!: string

        private menuTopPosition: string = "0px"
        private menuLeftPosition: string = "0px"
        private menuRightPosition: string = ""
        private visible: boolean = false
        private parentElement: HTMLElement | null = null


        private mounted() {
            this.$parent.$on(this.event, this.showPopup)
            if (this.closeEvent) {
                this.$parent.$on(this.closeEvent, this.close)
            }
        }


        private showPopup(parentElement: HTMLElement, atBottom: boolean, alignToLeft: boolean = true) {
            this.parentElement = parentElement
            if (this.visible) {
                this.close()
            } else {
                let top: number = 0
                let left: number = 0
                this.visible = true
                this.$nextTick(() => {
                    const rect = parentElement.getBoundingClientRect()
                    const menuElement = this.$refs.menuElement as HTMLElement
                    if (menuElement) {
                      const offset = rect.bottom + menuElement.getBoundingClientRect().height > window.innerHeight ? menuElement.getBoundingClientRect().height + 30 : -15
                      const isOnLeftEdge = rect.left - menuElement.getBoundingClientRect().width < 0
                      if (offset > 0 || atBottom) {
                        menuElement.classList.remove("atTop")
                        menuElement.classList.add("atBottom")
                      } else {
                        menuElement.classList.add("atTop")
                        menuElement.classList.remove("atBottom")
                      }
                      top = rect.bottom - offset
                      let isTopCorrected = false
                      const isBottomExceedingScreen = window.innerHeight < top + menuElement.getBoundingClientRect().height
                      if (top < 0) {
                        top = 0
                        isTopCorrected = true
                      } else if (isBottomExceedingScreen) {
                        const diff = window.innerHeight - top - menuElement.getBoundingClientRect().height
                        top += diff - 20
                        isTopCorrected = true
                      }
                      left = isOnLeftEdge ? (isTopCorrected ? rect.left + 20 : 0) :
                              (isTopCorrected ? rect.left + 20 : rect.left - menuElement.getBoundingClientRect().width * .75)


                      this.menuTopPosition = `${top}px`
                      if (alignToLeft) {
                          this.menuLeftPosition = `${left}px`
                          this.menuRightPosition = ""
                      } else {
                          this.menuRightPosition = `${Math.max(window.innerWidth - menuElement.getBoundingClientRect().width - left, 15)}px`
                          this.menuLeftPosition = ""
                      }
                    }
                })


                document.body.addEventListener("click", this.onClickOutside!, true)
                document.body.addEventListener("scroll", this.onClickOutside!, true)
            }
        }


        private close() {
            this.visible = false
            this.$parent.$emit("closedOn" + this.closeEvent)
        }


        private async onClickOutside(event: Event) {
            const target = event.target as HTMLElement
            const forbidsClose = target.parentElement!.classList.contains("forbids-close") || target.classList.contains("forbids-close")
            const clickedOnOpenMenuButton = event.target === this.parentElement
            const isDisabled = target.classList.contains("disabled")
            if (!clickedOnOpenMenuButton && !isDisabled && !forbidsClose) {
                document.body.removeEventListener("click", this.onClickOutside!, true)
                document.body.removeEventListener("scroll", this.onClickOutside!, true)
                this.close()
            }
        }
    }

